
const INQUIRY_INITIALIZED = 'INQUIRY_INITIALIZED';
const LOAD_INQUIRIES = 'LOAD_INQUIRIES';
const LOAD_MORE_INQUIRIES = 'LOAD_MORE_INQUIRIES';
const INQUIRIES_LOADED = 'INQUIRIES_LOADED';
const MORE_INQUIRIES_LOADED = 'MORE_INQUIRIES_LOADED';
const INQUIRY_LIST_EMPTY = 'INQUIRY_LIST_EMPTY';
const FAILED_TO_LOAD_INQUIRIES = 'FAILED_TO_LOAD_INQUIRIES';

const INITIATE_CREATE_INQUIRY = 'INITIATE_CREATE_INQUIRY';
const INITIATE_UPDATE_INQUIRY = 'INITIATE_UPDATE_INQUIRY';
const INITIATE_DELETE_INQUIRY = 'INITIATE_DELETE_INQUIRY';

const CREATE_INQUIRY = 'CREATE_INQUIRY';
const UPDATE_INQUIRY = 'UPDATE_INQUIRY';
const DELETE_INQUIRY = 'DELETE_INQUIRY';


export default {
    INQUIRY_INITIALIZED,
    LOAD_INQUIRIES,
    LOAD_MORE_INQUIRIES,
    INQUIRIES_LOADED,
    MORE_INQUIRIES_LOADED,
    INQUIRY_LIST_EMPTY,
    FAILED_TO_LOAD_INQUIRIES,

    INITIATE_CREATE_INQUIRY,
    INITIATE_UPDATE_INQUIRY,
    INITIATE_DELETE_INQUIRY,

    CREATE_INQUIRY,
    UPDATE_INQUIRY,
    DELETE_INQUIRY
};

