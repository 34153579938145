
import { Component } from 'react';
import { Button } from 'react-bootstrap';


export interface Props {
  buttonText: string,
  newPage: boolean,
  pagePropertyId: string,
  customeURL: string,
}

export default class CallToActionButton extends Component<Props> {
    static propTypes: {};

    constructor(props: Props){
      super(props)
    }

    render(){
        let {buttonText, newPage, pagePropertyId, customeURL } = this.props;
        let URL = (pagePropertyId === 'customeURL')? customeURL: `/online/page/${pagePropertyId}`
        let target = (newPage)? '_blank': ''
       
        return (
              <Button className="" href={URL}  target={target}>{buttonText}</Button>
        );
    }
}