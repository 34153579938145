

import firebase, { firestore } from '../firebase.js';
import { CurrentEstimation, Transaction } from './payment';

export async function fetchCurrentEstimation(businessId: string){

  console.log('fetchCurrentEstimation, firebaseActions, businessId - '+ businessId);

  let currentEstimation:any = {};
  let currentEstimationRef =  firestore.collection('businesses').doc(businessId).collection('billing').doc('current');

  let currentEstimationDoc = await currentEstimationRef.get();
  
  if (currentEstimationDoc.exists){
    console.log('fetchCurrentEstimation, currentEstimationDoc exists');
    currentEstimation = currentEstimationDoc.data();

     
  }else{
      console.log(`fetchCurrentEstimation, firebaseActions, total count field not found for businessId - ${businessId}`);
  }

  return currentEstimation;
}

export function subscribeForCurrentEstimation(businessId: string, onSuccess: (currentEstimation: CurrentEstimation)=> void, onError: (error: any) => void){
  console.log('subscribeForCurrentEstimation, firebaseActions, businessId - '+ businessId);
  let currentEstimationRef =  firestore.collection('businesses').doc(businessId).collection('billing').doc('current');
  currentEstimationRef.onSnapshot(doc => {
    console.log(`Listener, doc - ${JSON.stringify(doc.data())}`);
    if(doc.exists){
      let currentEstimation = new CurrentEstimation({transaction:new Transaction(doc.data()), isUpdated: true});
      onSuccess(currentEstimation);
    }
  }, err => {
    console.log (`Listener, err - ${JSON.stringify(err)}`)
    onError(err);
  })
}

export function unSubscribeForCurrentEstimation(businessId: string){
  console.log('unSubscribeForCurrentEstimation, firebaseActions, businessId - '+ businessId);
  let currentEstimationRef =  firestore.collection('businesses').doc(businessId).collection('billing').doc('current');
  const unsub = currentEstimationRef.onSnapshot(() => {});
  unsub();
}


export async function fetchPastPayments(businessId: string){

  console.log('AnalyticsCount, firebaseActions, fetchCount, businessId - '+ businessId);

  let data: any[] = [];
  let paymentYearlyRef =  firestore.collection('businesses').doc(businessId).collection('billing').doc('past').collection('yearly');

  let paymentCollection = await paymentYearlyRef.get();
  
  if (paymentCollection.docs.length > 0){
    console.log('fetchPastPayments, paymentCollection exists');
    
    paymentCollection.forEach((doc)=>{
      data.push(fetchPaymentsByYear({businessId, year: doc.id}));
    });

    data = await Promise.all(data);

  }else{
      console.log('fetchPastPayments,  paymentCollection does not exists');
  }

  console.log(`${JSON.stringify(data)}`);

  return data;
}

export async function fetchPaymentsByYear({businessId, year}: any){
  console.log(`AnalyticsCount, firebaseActions, fetchCountByYear, businessId-${businessId}, year-${year}`);

  let yearlyData = { year: year, data: {} };
  let paymentRef = firestore.collection('businesses').doc(businessId).collection('billing').doc('past').collection('yearly').doc(year);

  let paymentDoc = await paymentRef.get();
  if (paymentDoc.exists){
    yearlyData.data = paymentDoc.data() as any;
  }else{
    console.log('AnalyticsCount, firebaseActions, businessId not found');
  }
  return yearlyData;
}


