
const ANALYTICS_INITIALIZED = 'ANALYTICS_INITIALIZED';
const LOAD_TOTAL_COUNT = 'LOAD_TOTAL_COUNT';
const TOTAL_COUNT_LOADED = 'TOTAL_COUNT_LOADED';

const LOAD_CUSTOMERS_COUNT = 'LOAD_CUSTOMERS_COUNT';
const CUSTOMERS_COUNT_LOADED = 'CUSTOMERS_COUNT_LOADED';

const LOAD_INQUIRIES_COUNT = 'LOAD_INQUIRIES_COUNT';
const INQUIRIES_COUNT_LOADED = 'INQUIRIES_COUNT_LOADED';


export default {
    ANALYTICS_INITIALIZED,

    LOAD_TOTAL_COUNT,
    TOTAL_COUNT_LOADED,

    LOAD_CUSTOMERS_COUNT,
    CUSTOMERS_COUNT_LOADED,

    LOAD_INQUIRIES_COUNT,
    INQUIRIES_COUNT_LOADED
};

