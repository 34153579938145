
import { Component } from 'react';
import _ from 'lodash';
import { Business } from 'app/business';
import { ShowBusinessHours } from './showBusinessHours';



export interface Props {
  business: Business
}

interface State { 
  
}

export default class BusinessHoursCard extends Component<Props, State> {
    static propTypes: {};

    constructor(props: Props){
      super(props)
    }

    render(){
        let {businessHours} = this.props.business;
        return (
          <ShowBusinessHours businessHours={businessHours}/>
        );
    }
}