import { CurrentEstimation } from 'app/billing/payment';
import Spinner from 'app/shared/Spinner';
import { Component } from 'react'
import PlanCard from './planCard';
import { PlanState, PlanType } from './plans';
import types from './types';

export interface Props {
    planState: PlanState;
    currentEstimation: CurrentEstimation
}


class PlanList extends Component<Props> {

  constructor(props: Props){
    super(props);
    console.log('PlanList initialized');
  }

  render(){
    let component = null;

    if(this.props.planState.state == types.PLANS_INITIALIZED){
      component = <Spinner/>;
    }else{
      component = this.renderPlanList();
    }
    return component;
  }

  renderPlanList() {
    let planState = this.props.planState;
    let freePlan = planState.getPlan(PlanType.free);
    let startPlan = planState.getPlan(PlanType.start);
    let growPlan = planState.getPlan(PlanType.grow);
    let scalePlan = planState.getPlan(PlanType.scale);
    let currentPlan = this.props.currentEstimation.transaction.plan;
    let setAsCurrntPlan = (PlanType.free === currentPlan)
    return (
      
      <div className="row pricing-table">
        <PlanCard plan={freePlan.plan} price={freePlan.subTotal} setAsCurrntPlan={(freePlan.plan === currentPlan)} tagLine={'Free forever, no credit card needed!'}>
          <ul className="list-unstyled plan-features">
            <li>Business profile</li>
            <li>Customers - 50 (total)</li>
            <li>Inquiries - 50/month</li>
            <li>Team Management</li>
            <li></li>
            <li></li>
          </ul>
        </PlanCard>
        <PlanCard plan={startPlan.plan} price={startPlan.subTotal} setAsCurrntPlan={(startPlan.plan === currentPlan)} tagLine={''}>
          <ul className="list-unstyled plan-features">
            <li>Business profile</li>
            <li>Customers - 50 (total)</li>
            <li>Inquiries - 50/month</li>
            <li>Team Management</li>
            <li></li>
            <li></li>
          </ul>
        </PlanCard>
        <PlanCard plan={growPlan.plan} price={growPlan.subTotal} setAsCurrntPlan={(growPlan.plan === currentPlan)} tagLine={''}>
          <ul className="list-unstyled plan-features">
            <li>Business profile</li>
            <li>Customers - 50 (total)</li>
            <li>Inquiries - 50/month</li>
            <li>Team Management</li>
            <li></li>
            <li></li>
          </ul>
        </PlanCard>
        <PlanCard plan={scalePlan.plan} price={scalePlan.subTotal} setAsCurrntPlan={(scalePlan.plan === currentPlan)} tagLine={''}>
          <ul className="list-unstyled plan-features">
            <li>Business profile</li>
            <li>Customers - 50 (total)</li>
            <li>Inquiries - 50/month</li>
            <li>Team Management</li>
            <li></li>
            <li></li>
          </ul>
        </PlanCard>
      </div>
    )
  }
}



export default PlanList;
