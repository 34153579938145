import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { IconButton, Img } from "app/shared";
import _ from "lodash";
import { Component } from "react";
import { Link } from "react-router-dom";
import { Product} from "./product";

export interface Props {
  title: string,
  productList: Product[],
  editable: boolean,
  onEditProduct: (productId: string) => void,
  onShowDeleteDialog: (productId: string) => void,
}


export interface State {
  items: Product[],
  showInformationDialog: boolean,
  informationText: string,
}

export class ProductMerchantList extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    console.log(`ProductMerchantList, initialized`);
    super(props);
    this.state = {
      items: this.props.productList,
      showInformationDialog: false,
      informationText: '',
    };
  }

  componentDidUpdate(previoursProps: Props){
    
    if(!_.isEqual(previoursProps.productList, this.props.productList)){
      this.setState({ items: this.props.productList})
      console.log('ProductMerchantList, Props are not equal')
    }else{
      console.log('ProductMerchantList, Props are equal')
    }
  }
  
  render() {
    let editable = this.props.editable;
    let title = this.props.title;
    console.log(`ProductMerchantList, length - ${this.props.productList.length}`);
    
    return (
      <div className="card py-0 py-md-3">
        <div className="card-body p-0 p-md-4">
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th className="d-none d-md-table-cell font-weight-bold">  </th>
                  <th className="d-none d-md-table-cell font-weight-bold"> Product </th>
                  <th className="d-none d-md-table-cell font-weight-bold "> Visible </th>
                  <th className="d-none d-md-table-cell font-weight-bold "> Quantity </th>
                  <th className="d-none d-md-table-cell font-weight-bold "> Price </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.items.map((item)=>{
                    return this.renderProduct(item, editable);
                  })
                }
              </tbody>
            </table>
          </div>      
        </div>
      </div>
    );
  }

  renderProduct(product: Product, editable: boolean){


    let {productId, name, description, picture, collection, quantity, price, isPublished} = product;

    return (
      <tr key={productId} style={isPublished?{}:{backgroundColor: '#80808047'}}>
        <td className="d-none d-md-table-cell">
          <Img className="product_image" key={Date.now()} src={picture} fallbackSrc='https://via.placeholder.com/92x92' alt="product"/>
        </td>
        <td className="d-none d-md-table-cell">
          <Link className="" to={`/products/${productId}`}>
            <a>{name}</a>
          </Link>
        </td>
        <td className="d-md-none">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <div className="mr-2">
                <Img className="product_image" key={Date.now()} src={picture} fallbackSrc='https://via.placeholder.com/92x92' alt="product"/>
              </div>
              <div className="d-flex flex-column">  
                <Link className="mb-3" to={`/products/${productId}`}>
                  <a>{name}</a>
                </Link>               
                <span className="mb-1"> {(isPublished?'Visible':'Not visible')} </span> 
                <br/>
                <span className="mb-1">Quantity: { quantity } </span>
                <br/>
                <span className="mb-1">Price: { price } </span>
              </div>  
            </div>
            {
              (editable)
              ?
              <div>
                <i id={productId} className="ti-pencil menu-icon pr-4" onClick={()=> this.props.onEditProduct(productId)}></i> 
                <i className="ti-trash menu-icon" onClick={()=> this.onShowDeleteDialog(product)}></i>
              </div>
              :null
            }
          </div>
        </td>
      
        <td className="d-none d-md-table-cell"> 
          <IconButton path={isPublished ? mdiEyeOutline : mdiEyeOffOutline} toolTipText={(isPublished?'Visible':'Not visible')} /> 
        </td>
        <td className="d-none d-md-table-cell"> 
          <a> { quantity }</a>
        </td>
        <td className="d-none d-md-table-cell"> 
          <a> { price }</a>
        </td>
          { 
            (editable)
            ? 
            <td className="d-none d-md-table-cell">
              <i id={productId} className="ti-pencil menu-icon pr-4" onClick={()=> this.props.onEditProduct(productId)}></i> 
              <i className="ti-trash menu-icon" onClick={()=> this.onShowDeleteDialog(product)}></i>
              </td>
              :
              <td className="d-none d-md-table-cell"></td>
          }
      </tr>
    );
  }

  onShowDeleteDialog(product: Product){
      this.props.onShowDeleteDialog(product.productId);
  }
}