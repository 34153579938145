
import React, { Component } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { BusinessHours, Day, Hour } from 'app/business';
import { SpecialHour } from 'app/business/businessHours';


interface Props {
    style?: any,
    businessHours: BusinessHours
  }

interface State { }

export default class HoursText extends Component<Props, State> {
    static propType: {};
    static defaultProps: {};

    render(){
        const { businessHours } = this.props;

            return (
                <span {...this.props}>{this.getDisplayText(businessHours)}</span>
            );
    }

    getHoursForTheDay(businessHours: BusinessHours, date: Date){
        console.log(`getHoursForTheDay`);
        let day = this.getSpecialHoursForTheDay(businessHours, date);

        if (day){
            return day;
        }else{
            let requestDay = format(date, 'eee').toLowerCase();
            let day: Day = new Day({day: new Date().getDay(), isClosed: true});

            businessHours.hours.every((hour: Hour) => {

                if (_.find(hour.days, (day)=> { return (day === requestDay)})){
                    day = new Day({day: new Date().getDay(), openTime: hour.openTime, closeTime: hour.closeTime, isClosed: hour.isClosed});
                    return false;
                }else{
                    return true;
                }
            })
            
            return day;
        }
    }

    getSpecialHoursForTheDay(businessHours: BusinessHours, date: Date){
        console.log(`getHoursForTheDay`);

        let day: Day | undefined = undefined

        businessHours.specialHours.every((specialHour: SpecialHour) => {

            console.log(`special hour-${new Date(specialHour.date)}, date-${date}`);

            if (new Date(specialHour.date).getDate() === date.getDate() && 
                new Date(specialHour.date).getMonth() === date.getMonth() &&
                new Date(specialHour.date).getFullYear() === date.getFullYear()){

                day = new Day({day: new Date(specialHour.date).getDay(), openTime: specialHour.openTime, closeTime: specialHour.closeTime, isClosed: specialHour.isClosed});
                day.isSpecialHours = true;
                return false;
            }else{
                return true;
            }
        })
        
        return day;
    }

    getHoursForToday(businessHours: BusinessHours){
        console.log(`getHoursForToday`);
        return this.getHoursForTheDay(businessHours, new Date());
    }

    getNextOpenDate(businessHours: BusinessHours){
        console.log(`getNextOpenDate`);
        let day: Day = new Day({});
        let i = 1;
        let found = false;
        let nextOpenDate = new Date()
        
        while (i <= 7){
            
            nextOpenDate.setDate(new Date().getDate() + i);
            
            day = this.getHoursForTheDay(businessHours, nextOpenDate);
            if (day && !day.isClosed){
                found = true;
                break
            }else{
                i = i+1;
            }
        }
        if (found){
            return {openDate: nextOpenDate, openDay: day};
        }else{
            return {openDate: null, openDay: null};
        }
    }

    getDisplayText(businessHours: BusinessHours) {
        let text = 'Closed';

        if (businessHours.isOpen24Hours){
            text = "Open 24 hours"
            return text;
        }else if (businessHours.isClosed){
            text = "Closed"
            return text;
        }
        
        let day = this.getHoursForToday(businessHours)
        
        if (day){

            let now = new Date();

            // normalize the dates for easy comparizon
            now.setDate(0);

            let openTime = new Date(day.openTime);
            let closeTime = new Date(day.closeTime);

            /*console.log(now);
            console.log(openTime);
            console.log(closeTime);
            console.log(JSON.stringify(day));*/

            if (day.isClosed){
                text = 'Closed';
                //get next open date
                let {openDate, openDay} = this.getNextOpenDate(businessHours);
                if (openDate && openDay){
                    let openTime = new Date(openDay.openTime)
                    openDate.setHours(openTime.getHours(), openTime.getMinutes());
                    text = `Closed - Opens ${format(openDate, 'p EEE')}`
                }
            }else if (day.isOpen24hours){
                text = 'Open 24 hours';
            }else if (now < openTime){
                let opens = new Date()
                opens.setHours(openTime.getHours(), openTime.getMinutes());

                text = `Closed - Opens ${format(opens, 'h bb')}`
                if (openTime.getHours() - now.getHours() <= 1){
                    text = `Closed - Opens soon ${format(opens, 'h bb')}`
                }
            }else if (now >= closeTime){
                text = `Closed`
                //get next open date
                let {openDate, openDay} = this.getNextOpenDate(businessHours);
                if (openDate && openDay){
                    let openTime = new Date(openDay.openTime)
                    openDate.setHours(openTime.getHours(), openTime.getMinutes());
                    text = `Closed - Opens ${format(openDate, 'p EEE')}`
                }
            }else if (now >= openTime && now < closeTime){
                let closes = new Date()
                closes.setHours(closeTime.getHours(), closeTime.getMinutes());
                text = `Open - Closes ${format(closes, 'h bb')}`

                if (closeTime.getHours() - now.getHours() <= 1){
                    text = `Open - Closes soon ${format(closes, 'h bb')}`
                }
            }
        }
        if (day.isSpecialHours){
            text = text + ' ' + '(Special hours)'
        }
        return text; 
    }

}
