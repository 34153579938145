
const DASHBOARD_ELEMENTS_INITIALIZED = 'DASHBOARD_ELEMENTS_INITIALIZED';
const LOAD_DASHBOARD_ELEMENTS = 'LOAD_DASHBOARD_ELEMENTS';
const DASHBOARD_ELEMENTS_LOADED = 'DASHBOARD_ELEMENTS_LOADED';
const DASHBOARD_ELEMENTS_EMPTY = 'DASHBOARD_ELEMENTS_EMPTY';
const FAILED_TO_LOAD_DASHBOARD_ELEMENTS = 'FAILED_TO_LOAD_DASHBOARD_ELEMENTS';

const INITIATE_UPDATE_DASHBOARD_ELEMENTS = 'INITIATE_UPDATE_DASHBOARD_ELEMENTS';
const UPDATE_DASHBOARD_ELEMENTS = 'UPDATE_DASHBOARD_ELEMENTS';



export default {
    DASHBOARD_ELEMENTS_INITIALIZED,
    LOAD_DASHBOARD_ELEMENTS,
    DASHBOARD_ELEMENTS_LOADED,
    DASHBOARD_ELEMENTS_EMPTY,
    FAILED_TO_LOAD_DASHBOARD_ELEMENTS,

    INITIATE_UPDATE_DASHBOARD_ELEMENTS,
    UPDATE_DASHBOARD_ELEMENTS
};

