import { PaymentState, SKU, SKUName, SKUStatus } from 'app/billing/payment';
import { SKUStatusDialog } from 'app/shared';
import { Component } from 'react';
import { Form, Modal, Button  } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { connect } from 'react-redux';
import { Customer } from './customer';
import { ErrorCode } from './types';

export interface Props {
 show: boolean,
 customer: Customer | null | undefined,
 title?: string, 
 paymentState: PaymentState,
 onHide: () => void,
 onCancel: () => void, 
 onSave: (customer: Customer) => void,
 errorCode?: ErrorCode | '',
 saveInProgress?: boolean
}

export interface State {
  validated: boolean,
  customer: any
}

export class CustomerInputDialog extends Component<Props, State> {
  customer: Customer;
  title: string;
  isEditDialog: boolean;
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('BusinessProfile initialized');
    this.customer = (this.props.customer)? this.props.customer : new Customer({name: '', phoneNumber: '', emailAddress: ''});
    this.isEditDialog = (this.props.customer && this.props.customer.customerId !== '')?true:false;

    this.title = (this.props.title)? this.props.title: ((this.isEditDialog)? 'Edit customer':'Create a new customer');
  
    this.state = {
      validated: false,
      customer: {
        name:{
          placeholder: 'Enter customer name',
          value: this.customer.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
          readOnly: false
        },
        countryCode:{
          placeholder: 'Country code',
          value: this.customer.countryCode,
          type: 'tel',
          pattern:'[0-9]{1,3}',
          required: true,
          errorText: '',
          readOnly: this.isEditDialog
        }, 
        phoneNumber:{
          placeholder: 'Enter phone number',
          value: this.customer.phoneNumber,
          type: 'tel',
          pattern:'[0-9]{1,}',
          required: true,
          errorText: '',
          readOnly: this.isEditDialog
        }, 
        emailAddress:{
          placeholder: 'Enter email address',
          value: this.customer.emailAddress,
          type: 'email',
          required: false,
          errorText: '',
          readOnly: false
         }
      }
    }
  }

  render() {
    let customersSKU = this.props.paymentState.getSKU(SKUName.customers);

      if ((customersSKU.status === SKUStatus.active) || 
          (customersSKU.status === SKUStatus.maxLimitReached && this.isEditDialog)){
        return this.renderForm();
      }else{
        return this.renderStatusMessage(customersSKU);
      }
   
  }

  renderForm() {
    let customer = this.state.customer;
    let self = this;
    let {errorCode, saveInProgress } = this.props;

    let errorMessage = '';

    if(errorCode === ErrorCode.CUSTOMER_ALREADY_EXISTING){
      errorMessage = `A customer with the same phone number is already existing.`;
    }else if (errorCode === ErrorCode.FAILED_TO_CREATE){
      errorMessage = `Failed to create a customer, please try after sometime.`;
    }
    
    return (
  
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >
          <LoadingOverlay
            active={saveInProgress}
            spinner
            text='Loading ...'
            >
            <Modal.Header closeButton>
              {this.title}
            </Modal.Header>

            <Modal.Body>

    
                  <Form className="form-sample" id="customerInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                    <div className="py-2 row">
                      <Form.Group className="col-md-12">
                        <Form.Control type={customer.name.type} name="name" pattern={customer.name.pattern} required={customer.name.required} placeholder={customer.name.placeholder} onChange={e => self.onChange(e)} value = {customer.name.value} readOnly = {customer.name.readOnly}/>
                      </Form.Group>

                      
                      <Form.Group className="col-md-4">
                        <Form.Control type={customer.countryCode.type} name='countryCode' pattern={customer.countryCode.pattern} required={customer.countryCode.required} placeholder={customer.countryCode.placeholder} onChange={e => self.onChange(e)} value = {customer.countryCode.value} readOnly = {customer.countryCode.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-md-8">
                        <Form.Control type={customer.phoneNumber.type} name='phoneNumber' pattern={customer.phoneNumber.pattern} required={customer.phoneNumber.required} placeholder={customer.phoneNumber.placeholder} onChange={e => self.onChange(e)} value = {customer.phoneNumber.value} readOnly = {customer.phoneNumber.readOnly}/>
                      </Form.Group>
              
                      <Form.Group className="col-md-12">
                        <Form.Control type={customer.emailAddress.type} name='emailAddress' pattern={customer.emailAddress.pattern} required={customer.emailAddress.required} placeholder={customer.emailAddress.placeholder} onChange={e => self.onChange(e)} value = {customer.emailAddress.value} readOnly = {customer.emailAddress.readOnly}/>
                      </Form.Group>
                    </div>
                  </Form>
                  <div className="col-md-12">
                    <div>{errorMessage}</div>
                  </div>

            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
              <button type="submit" form="customerInfo"  className="btn btn-primary btn-sm">Save</button>
            </Modal.Footer>
          </LoadingOverlay>
      </Modal>
    );
  }

  renderStatusMessage(sku: SKU){
    return(
      <SKUStatusDialog title={this.title} show={this.props.show} onHide={() => this.props.onHide()} onCancel={() => this.props.onCancel()} sku={sku}/>
    );
  }

  onChange(e: any){
    console.log('CreateCustomer, onChange');

    let customer = this.state.customer;
    let key = e.target.name;

    customer[key].value = e.target.value;
    this.setState({customer:customer});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('CreateCustomer, onSubmit');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let customer =  this.getFormData();
      console.log(JSON.stringify(customer));

      this.setState({validated: false});
      this.props.onSave(customer)
    }
  }

  getFormData(){

 
    let customerObj = Object.keys(this.state.customer).reduce((acc: any, key: string) =>{
      acc[key] = this.state.customer[key].value;
      return acc;
    }, {});


    return new Customer({...this.customer, ...customerObj}); 
  }

}

const mapStateToProps = (state: { auth: any; business: any; pageLinks: any; billing: any}) => {
  return {
    paymentState: state.billing
  };
};

const mapDispatchToProps = () => {
  return {
    
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(CustomerInputDialog));