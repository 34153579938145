import { put, takeEvery, call } from 'redux-saga/effects';

import type, { ErrorCode } from './types';
import { fetchProductList, createProduct, updateProduct, deleteProduct } from './firebaseActions';
import { Product } from './product';

//Action creators

export const loadProducts = (businessId: string, searchText='') => {
    console.log('products, action, loadProducts, businessId - '+ businessId);
    return { type: type.LOAD_PRODUCTS, businessId, searchText }
};

export const loadMoreProducts = (businessId: string, afterTimestamp=0, searchText='') => {
    console.log('products, action, loadMoreProducts, businessId - '+ businessId);
    return { type: type.LOAD_MORE_PRODUCTS, businessId, afterTimestamp, searchText }
};

export const initiateCreateProduct = (businessId: string, product: Product, onSuccess: any, onError: any) => {
    console.log('user, action, initiateCreateProduct');
    return { type: type.INITIATE_CREATE_PRODUCT, businessId, product, onSuccess, onError }
};

export const initiateSaveProduct = (businessId: string, product: Product, onSuccess: any, onError: any) => {
    console.log('user, action, initiateSaveProduct');
    let action = null;
    if (product.productId != ''){
        action = { type: type.INITIATE_UPDATE_PRODUCT, businessId, product, onSuccess, onError };
    }else{
        action = { type: type.INITIATE_CREATE_PRODUCT, businessId, product, onSuccess, onError };
    }
    return action;
};

export const initiateDeleteProduct = (businessId: string, product: Product, onSuccess: any, onError: any) => {
    console.log('user, action, initiateDeleteProduct');
    return { type: type.INITIATE_DELETE_PRODUCT, businessId, product, onSuccess, onError }
};

const retrievedProductList = (productList: Product[]) => {
    return { type: type.PRODUCTS_LOADED, productList }
};

const retrievedMoreProductList = (productList: Product[]) => {
    return { type: type.MORE_PRODUCTS_LOADED, productList }
};

const emptyProductList = () => {
    return { type: type.PRODUCT_LIST_EMPTY }
};

const failedToRetrieveProductList = () => {
    return { type: type.FAILED_TO_LOAD_PRODUCTS }
};

const productCreated = (product: Product) => {
    return { type: type.PRODUCT_CREATED, product}
};

const productUpdated = (product: Product) => {
    return { type: type.PRODUCT_UPDATED, product}
};

const productDeleted = (product: Product) => {
    return { type: type.PRODUCT_DELETED, product}
};

// Sagas

function* loadProductsAsync(action: { businessId: any; searchText: string | undefined; }) {
    console.log('product, action, loadProductsAsync - '+ JSON.stringify(action));
    try {

        const productList: Product[] = yield call(fetchProductList, action.businessId, 0, action.searchText);
        if (productList.length > 0){
            yield put(retrievedProductList(productList));
        }else{
            yield put(emptyProductList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveProductList());
    }
}

function* loadMoreProductsAsync(action: { businessId: any; afterTimestamp: number | undefined; searchText: string | undefined; }) {
    console.log('product, action, loadMoreProductsAsync - '+ JSON.stringify(action));
    try {

        const productList: Product[] = yield call(fetchProductList, action.businessId, action.afterTimestamp, action.searchText);
        if (productList.length > 0){
            yield put(retrievedMoreProductList(productList));
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
    }
}

function* createProductAsync(action: { product: Product; businessId: string; onSuccess: () => void; onError: (product: Product, errorCode: ErrorCode) => void; }) {
    console.log('product, action, createProductAsync');
   
    try {
        let product: Product = yield call(createProduct, action.businessId, action.product);
        yield put(productCreated(product));
        action.onSuccess();
    } catch (error: any) {
        console.log(`product, action, createProductAsync, failed to create, error-${error.response.status}`)
        console.log(JSON.stringify(error));
        if (error.response.status){
            action.onError(action.product, ErrorCode.PRODUCT_ALREADY_EXISTING);
        }else{
            action.onError(action.product, ErrorCode.FAILED_TO_CREATE);
        }
    }
}

function* updateProductAsync(action: { product: Product; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('product, action, updateProductAsync');
   
    try {
        let product: Product = yield call(updateProduct, action.businessId, action.product);
        yield put(productUpdated(product));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteProductAsync(action: { product: Product; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('product, action, deleteProductAsync');
   
    try {
        yield call(deleteProduct, action.businessId, action.product);
        yield put(productDeleted(action.product));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const productSagas = [
    takeEvery<any>(type.LOAD_PRODUCTS, loadProductsAsync),
    takeEvery<any>(type.LOAD_MORE_PRODUCTS, loadMoreProductsAsync),
    takeEvery<any>(type.INITIATE_CREATE_PRODUCT, createProductAsync),
    takeEvery<any>(type.INITIATE_UPDATE_PRODUCT, updateProductAsync),
    takeEvery<any>(type.INITIATE_DELETE_PRODUCT, deleteProductAsync)
];
