import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Container, InputGroup  } from 'react-bootstrap';
import { hasKey, ImagePreview, Img } from '../shared';
import { alert } from '../shared';
import { Business, Address, BusinessState } from './business'

import { initiateUpdateBusiness, initiateUpdateBusinessHours, initiateUpdateBusinessSocialLinks } from './actions';
import { AuthState } from 'app/user';
import { ShowBusinessHours } from './showBusinessHours';
import { BusinessHours, SpecialHour } from './businessHours';
import { ShowSpecialHours } from './showSpecialHours';
import { ShowSocialLinks } from './showSocialLinks';
import { PaymentState, SKUName } from 'app/billing/payment';
import countryTelData, { Country } from 'country-telephone-data';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactTags, { Tag } from 'react-tag-autocomplete'


export interface Props {
  authState: AuthState,
  businessState: BusinessState,
  paymentState: PaymentState,
  initiateUpdateBusiness: (business: Business, onSuccess: any, onError: any) => void,
  initiateUpdateBusinessHours: (businessId: string, businessHours: BusinessHours, onSuccess: any, onError: any) => void,
  initiateUpdateBusinessSocialLinks: (businessId: string, socialLinks: string[], onSuccess: any, onError: any) => void
}

export interface State {
  businessId: string, 
  editable: boolean,
  businessHours: BusinessHours,
  validated: boolean,
  editMode: boolean, 
  business: any
}

export class BusinessProfilePage extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('BusinessProfilePage initialized');
    const business = this.props.businessState.selectedBusiness;
    let selectedCountry = [];
    selectedCountry.push(business.country);
    let keywords = business.keywords.map(keyword => {
      return {id:keyword, name:keyword}
    })
  
    this.state = {
      businessId: business.businessId,
      editable: this.props.businessState.canEdit(),
      businessHours: business.businessHours,
      validated: false,
      editMode: false,
      business: {
        bannerPicture:{
          value: business.bannerPicture,
          file: null,
          noImage: 'https://via.placeholder.com/92x92',
          ref: React.createRef(),
          showPreview: false,
          preview: '',
          deletePicture: ''
        },
        profilePicture:{
          value: business.profilePicture,
          file: null,
          noImage: 'https://via.placeholder.com/92x92',
          ref: React.createRef(),
          showPreview: false,
          preview: '',
          deletePicture: ''
        },
        name:{
          placeholder: 'Enter business name',
          value: business.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
        },
        description: {
          placeholder: 'Tell what you do for others to recognize you',
          value: business.description,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
        },
        type: {
          placeholder: 'Select type of business',
          value: business.type,
          type: 'text',
          pattern:'[a-zA-Z]{1,32}',
          required: true,
          errorText: '',
        },
        keywords: {
          placeholder: 'Enter search keywords',
          value: keywords,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,14}',
          required: false,
          errorText: '',
        },
        country:{
          placeholder: 'Select a country',
          value: selectedCountry,
          type: 'text',
          required: true,
          errorText: 'Invalid country ',
          dirtyFlag: false
        },
        countryCode:{
          placeholder: 'Country code',
          value: business.countryCode,
          type: 'text',
          required: true,
          errorText: 'Invalid country code',
        },
        phoneNumber:{
          placeholder: 'Enter phone number',
          value: business.phoneNumber,
          type: 'tel',
          pattern:'[0-9]{1,}',
          required: true,
          errorText: '',
        },
        emailAddress:{
          placeholder: 'Enter email address',
          value: business.emailAddress,
          type: 'email',
          required: true,
          errorText: '',
         },
         address: { 
            street:{
              placeholder: 'Street',
              value: business.address.street,
              type: 'text',
              pattern:'[a-zA-Z0-9& ]{1,32}',
              required: true,
              errorText: '',
            },
            city:{
              placeholder: 'City',
              value: business.address.city,
              type: 'text',
              pattern:'[a-zA-Z0-9& ]{1,32}',
              required: true,
              errorText: '',
            },
            state:{
              placeholder: 'State',
              value: business.address.state,
              type: 'text',
              pattern:'[a-zA-Z0-9& ]{1,32}',
              required: true,
              errorText: '',
            },
            country:{
              placeholder: 'Country',
              value: business.address.country,
              type: 'text',
              pattern:'[a-zA-Z0-9& ]{1,32}',
              required: true,
              readOnly: true,
              errorText: '',
            },
            zipCode:{
              placeholder: 'Zip code',
              value: business.address.zipCode,
              type: 'text',
              pattern:'[0-9]{1,10}',
              required: true,
              errorText: '',
            },
         }
      }
    } as State
  }

  componentDidUpdate(){

  }

  render(){

  if (this.state.businessId !== this.props.businessState.selectedBusiness.businessId){
    this.reset();
  }

   return (
    <div className="row">
      <div className="col-xl-1 grid-margin"/>
      <div className="col-12 col-xl-10 grid-margin">
        <div className="row">
          <div className="col-12">
            {
              (this.state.editMode)
              ? this.renderEditProfile()
              : this.renderViewProfile()
            }
          </div>
        </div>
      </div>
    </div>
   );
  }

  renderEditProfile(){
    let business = this.state.business;
    let self = this;
    
    return (
 
      <div className="card">
        <div className="card-body">
          <Form className="form-sample" id="businessInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
            <div className="d-flex" style={{position: 'relative', zIndex:1}}>
              <div style={{width:"100%", height:"250px"}}>
                <Img key={business.bannerPicture.value} src={business.bannerPicture.value} fallbackSrc={business.bannerPicture.noImage} alt="profile" className="rounded border  border-gray " style={{width:"100%", height:"100%", objectFit:"cover"}}/>
              </div>
              <div style={{position: 'absolute', zIndex:3, left:"50%", top:"50%"}}>
                <i className="ti-image rounded border border-white bg-light mr-4" onClick={() => self.onPictureClick('bannerPicture')}/>
                <input style={{display:'none'}} type="file" accept="image/png, image/jpeg" name="bannerPicture" ref={business.bannerPicture.ref} onChange={e => self.onPictureSelect(e)}/>
                <ImagePreview aspect={4/1} src={business.bannerPicture.preview} name='bannerPicture' show={business.bannerPicture.showPreview} onHide={(name: string) => self.onPreviewHide(name)} onCancel={(name: string) => self.onPreviewCancel(name)} onSubmit={(name: string, croppedImageUrl: string, croppedImageFile: File) => self.onPreviewSubmit(name, croppedImageUrl, croppedImageFile)} />
                {
                  (business.bannerPicture.value !== '')? <i className="ti-close rounded border border-white bg-light " onClick={() => self.onPictureRemove('bannerPicture')}></i>:null
                }
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mx-4 mb-3 mt-sm-n5 border border-circle border-white">
              <div className="d-flex mr-2 my-3 " style={{position: 'relative', zIndex:5}}>
                <Img key={business.profilePicture.value} src={business.profilePicture.value} fallbackSrc={business.profilePicture.noImage} alt="profile" className="img-lg rounded border  border-gray" style={{width:140, height:140}}/>
                <div style={{position: 'absolute', zIndex:6, left:"30%", top:"50%"}}>
                  <i className="ti-image rounded border border-white bg-light mr-4" onClick={() => self.onPictureClick('profilePicture')}></i>
                  <input style={{display:'none'}} type="file" accept="image/png, image/jpeg" name="profilePicture" ref={business.profilePicture.ref} onChange={e => self.onPictureSelect(e)}/>
                  <ImagePreview aspect={4/4} src={business.profilePicture.preview} name='profilePicture' show={business.profilePicture.showPreview} onHide={(name: string) => self.onPreviewHide(name)} onCancel={(name: string) => self.onPreviewCancel(name)} onSubmit={(name: string, croppedImageUrl: string, croppedImageFile: File) => self.onPreviewSubmit(name, croppedImageUrl, croppedImageFile)} />
                  {
                    (business.profilePicture.value !== '')? <i className="ti-close rounded border border-white bg-light bg-gradient-light" onClick={() => self.onPictureRemove('profilePicture')}></i>:null
                  }
              </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center mr-2" >
                  <button type="button" className="btn btn-secondary btn-sm" onClick={this.onCancel.bind(this)}>Cancel</button>
                </div>
                <div className="d-flex justify-content-center" >
                  <button type="submit" form="businessInfo"  className="btn btn-primary btn-sm">Save</button>
                </div>
              </div>
            </div>

            <div className="py-2 d-flex flex-column row">
              <Form.Group className="col-md-7">
                <Form.Control type={business.name.type} name="name" pattern={business.name.pattern} required={business.name.required} placeholder={business.name.placeholder} onChange={e => self.onChange(e)} value = {business.name.value} readOnly = {business.name.readOnly}/>
              </Form.Group>
              <Form.Group className="col-md-7">
                <Form.Control className="form-control" as="textarea" rows={4} name="description" required={business.description.required} placeholder={business.description.placeholder} onChange={e => self.onChange(e)} value = {business.description.value} readOnly = {business.description.readOnly}/>
              </Form.Group>
            </div>

            <div className="d-flex flex-wrap align-items-center border-bottom row">
              <Form.Group className="col-2 col-md-auto">
                <Form.Control className="form-control" as="select" name="type"  onChange={e => self.onChange(e)} value = {business.type.value} readOnly = {business.type.readOnly}>
                  <option>Online</option>
                  <option>Instore</option>
                  <option>Online & Instore</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-12 col-md-4">
                <ReactTags
                  tags={business.keywords.value}
                  onDelete={(i) => {this.onTagDelete(i)}}
                  onAddition={(tag) => {this.onTagAdd(tag)}}
                  allowNew
                  placeholderText='Search Tags'
                  inputAttributes={{required: false}}
                />
              </Form.Group> 
            </div>                                                               
           
            <div className="py-2 row">
              <InputGroup className="col-md-6 mb-3">
                <InputGroup.Text id="basic-addon1">{`+`}</InputGroup.Text>
                <Typeahead
                    id="country-code-dropdown"
                    isInvalid={business['country'].value.length === 0}
                    labelKey={option => this.onLabelKey(option)}
                    options={countryTelData.allCountries}
                    placeholder={business.country.placeholder}
                    onChange={(e) => this.onCountryChange(e)}
                    onInputChange={(text, e) => this.onInputChange(text, e)}
                    selected={business.country.value}
                    renderInput={({ inputRef, referenceElementRef,...inputProps }: any) => (
                      <Form.Control
                        {...inputProps}
                        required={true}
                        isInvalid={business.country.dirtyFlag}
                        pattern={business.country.dirtyFlag?"___":".*"}
                        ref={(input: any) => {
                          // Be sure to correctly handle these refs. In many cases, both can simply receive
                          // the underlying input node, but `referenceElementRef can receive a wrapper node if
                          // your custom input is more complex (See TypeaheadInputMulti for an example).
                          inputRef(input);
                          referenceElementRef(input);
                        }}
                      />
                    )}
                    
                  />
                
                <Form.Control.Feedback type='invalid'> {business.country.errorText} </Form.Control.Feedback>    
              </InputGroup>

              <Form.Group className="col-md-6 mb-3">
                <Form.Control className="col-md-12" type={business.phoneNumber.type} name='phoneNumber' pattern={business.phoneNumber.pattern} required={business.phoneNumber.required} placeholder={business.phoneNumber.placeholder} onChange={e => self.onChange(e)} value = {business.phoneNumber.value} readOnly = {business.phoneNumber.readOnly}/>
              </Form.Group>
      
              <Form.Group className="col-md-12">
                <Form.Control type={business.emailAddress.type} name='emailAddress' pattern={business.emailAddress.pattern} required={business.emailAddress.required} placeholder={business.emailAddress.placeholder} onChange={e => self.onChange(e)} value = {business.emailAddress.value} readOnly = {business.emailAddress.readOnly}/>
              </Form.Group>
            </div>

            <div className="d-flex flex-wrap row align-items-center">
              <Form.Group className="col-md-4">
                <Form.Control type={business.address.street.type} name='address.street' pattern={business.address.street.pattern} required={business.address.street.required} placeholder={business.address.street.placeholder} onChange={e => self.onChange(e)} value = {business.address.street.value} readOnly = {business.address.street.readOnly}/>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control type={business.address.city.type} name='address.city' pattern={business.address.city.pattern} required={business.address.city.required} placeholder={business.address.city.placeholder} onChange={e => self.onChange(e)} value = {business.address.city.value} readOnly = {business.address.city.readOnly}/>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control type={business.address.state.type} name='address.state' pattern={business.address.state.pattern} required={business.address.state.required} placeholder={business.address.state.placeholder} onChange={e => self.onChange(e)} value = {business.address.state.value} readOnly = {business.address.state.readOnly}/>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control type={business.address.zipCode.type} name='address.zipCode' pattern={business.address.zipCode.pattern} required={business.address.zipCode.required} placeholder={business.address.zipCode.placeholder} onChange={e => self.onChange(e)} value = {business.address.zipCode.value} readOnly = {business.address.zipCode.readOnly}/>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Control type={business.address.country.type} name='address.country' pattern={business.address.country.pattern} required={business.address.country.required} placeholder={business.address.country.placeholder} onChange={e => self.onChange(e)} value = {business.address.country.value} readOnly = {business.address.country.readOnly}/>
              </Form.Group>
            </div>
           </Form>
        </div>
      </div>
    
    )
  }
 
  renderViewProfile() {
    let business = this.state.business;
    console.log(business.bannerPicture.value);
    return (
      <div>
        <div className="card mb-1">
          <div className="card-body">
            <div className="d-flex flex-column ">
              <div style={{width:"100%", height:"250px"}}>
                <Img src={business.bannerPicture.value} fallbackSrc={business.bannerPicture.noImage} alt="profile" className="rounded border  border-gray" style={{width:"100%", height:"100%", objectFit:"cover"}}/>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mx-4 mb-3 mt-sm-n5 border border-circle border-white">
              <div className="d-flex my-3">
                <Img src={business.profilePicture.value} fallbackSrc={business.profilePicture.noImage}  alt="profile" className="img-lg rounded border  border-gray" style={{width:140, height:140}}/>
              </div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center" >
                  <button type="button" className="btn btn-primary btn-sm" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={this.onEdit.bind(this)}>Edit</button>
                </div>
              </div>
            </div>
            
            <div className="border-bottom py-2">
              <h3>{business.name.value}</h3>
              <p className="py-2">{business.description.value}</p>
              <div>
                <label className="badge badge-outline-dark mr-3">{business.type.value}</label>
                {
                  business.keywords.value.map((item: any) => {
                    return <label className="badge badge-outline-dark mr-1">{item.name}</label> 
                  })
                }
              </div>                                                               
            </div>
            <div className="py-2">
              <Row className=" py-1">
                <Col xs={3} xl={1}>
                  <span className="float-left">
                    Phone
                  </span>
                </Col>
                <Col xs={9} xl={11}>
                  <span className=" text-muted">
                    {`+${business.countryCode.value} `}
                  </span>
                  <span className=" text-muted">
                    {business.phoneNumber.value}
                  </span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={3} xl={1}>
                  <span className="float-left">
                    Email
                  </span>
                </Col>
                <Col xs={9} xl={11}>
                  <span className="text-muted">
                    {business.emailAddress.value}
                  </span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={3} xl={1}>
                  <span className="float-left">
                    Address
                  </span>
                </Col>
                <Col xs={9} xl={11}>
                  <div className="text-muted">
                    <span>{business.address.street.value} </span>
                    <span>{business.address.city.value} </span>
                    <span>{business.address.state.value} </span> 
                    <span>{business.address.zipCode.value} </span>
                    <span>{business.address.country.value} </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Container fluid className="p-0">
          <Row>
            <Col xs={12} lg={6} className="d-flex align-items-stretch mb-1">
              <div className="card w-100">
                  <div className="card-body">
                    <div className="">
                      <ShowBusinessHours businessHours={this.state.businessHours} editable={this.state.editable} onChange={(businessHours) => this.onBusinessHoursChange(businessHours)} />
                    </div>
                  </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="d-flex align-items-stretch mb-1">
              <div className="card w-100">
                <div className="card-body">
                  <div className="">
                    <ShowSpecialHours specialHours={this.state.businessHours.specialHours} editable={this.state.editable} onChange={(specialHours) => this.onSpecialHoursChange(specialHours)} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="d-flex align-items-stretch mb-1">
              <div className="card w-100">
                  <div className="card-body">
                    <div className="">
                      <ShowSocialLinks socialLinks={this.props.businessState.selectedBusiness.socialLinks} editable={this.state.editable} onChange={(socialLinks: string[]) => this.onSaveSocialLinks(socialLinks)} />
                    </div>
                  </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  onPictureClick(key: string){
    console.log('onPictureClick');
    this.state.business[key].ref.current.click();
  }
 
  onPictureRemove(key: string){
    let business = this.state.business;
    if (hasKey(this.props.businessState.selectedBusiness, key)){
      if(business[key].value === this.props.businessState.selectedBusiness[key]){
        // user is deleting the existing picture
        business[key].deletePicture = business[key].value;
        business[key].value = '';
      }else{
        business[key].value = this.props.businessState.selectedBusiness[key];
      }
      business[key].file = null;
      this.setState({business:business});
    }
  }

  onPictureSelect(e: any){
    console.log('onPictureSelect');
    let key = e.target.name;
    let business = this.state.business;
    let file =  e.target.files[0];
    let reader =  new FileReader();
    
    reader.onloadend = () => {
      business[key].showPreview = true;
      business[key].preview = reader.result;
      this.setState({business:business});
    }
    reader.readAsDataURL(file);
  }

  onPreviewCancel(key: string){
    console.log('onPreviewCancel');
    let business = this.state.business;
    business[key].showPreview = false;
    business[key].preview = '';
    this.setState({business:business});
  }

  onPreviewSubmit(key: string, croppedImageUrl: string, croppedImageFile: File){
    console.log('onPreviewSubmit');
    let business = this.state.business;
    let imageFileSizeSKU = this.props.paymentState.getSKU(SKUName.imageFileSize);
    business[key].showPreview = false;
    business[key].preview = '';

    if (imageFileSizeSKU.freeUnits >  croppedImageFile.size){

      if (hasKey(this.props.businessState.selectedBusiness, key)){
        if(business[key].value === this.props.businessState.selectedBusiness[key]){
          // user is deleting the existing picture
          business[key].deletePicture = business[key].value;
        }
      }

      business[key].value = croppedImageUrl;
      business[key].file = croppedImageFile;
      this.setState({business:business});
    }else{
      alert.error(`Image size should be less than ${imageFileSizeSKU.freeUnits/1000000} KB.`);
    }
  }

  onPreviewHide(key: string){
    console.log('onHide');
    let business = this.state.business;
    business[key].showPreview = false;
    business[key].preview = '';
    this.setState({business:business});
  }

  reset(){
    let business = this.state.business;

    
    Object.keys(business).map((key) =>{

      if (key === 'address'){ 

        Object.keys(business.address).map((key) => {
          business.address[key].value = this.props.businessState.selectedBusiness.address[key];
        })

       }else if (key === 'country'){ 
        let selectedCountry = [];
        selectedCountry.push(this.props.businessState.selectedBusiness.country);
        business.country.value = selectedCountry;
        business.dirtyFlag = false;

       }else if (key === 'keywords'){
        let keywords = this.props.businessState.selectedBusiness.keywords.map(keyword => {
          return {id:keyword, name:keyword}
        })
        business[key].value = keywords;

       }else  {
        if (hasKey(this.props.businessState.selectedBusiness, key)){
          business[key].value = this.props.businessState.selectedBusiness[key];
        }
      }
    });

    business.bannerPicture.deletePicture = '';
    business.profilePicture.deletePicture = '';
    business.bannerPicture.file = null;
    business.profilePicture.file = null;

    this.setState({
      businessId: this.props.businessState.selectedBusiness.businessId,
      validated: false,
      business: business
    });
  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>){
    console.log('BusinessProfile, onChange');

    let business = this.state.business;
    let [parentKey, childKey] = e.target.name.split('.');

    if (childKey && childKey !== null){
      business[parentKey][childKey].value = e.target.value;
    }else{
      business[parentKey].value = e.target.value;
    }
    
    this.setState({business:business});
  }

  onCountryChange(e: Country[]){
    console.log(`onCountryChange, ${JSON.stringify(e)}`);
    let business = this.state.business;
    if (e[0]){
      business['country'].value = e;
      business['country'].dirtyFlag = false;
      business['countryCode'].value = e[0].dialCode;
      let removeIndex = e[0].name.indexOf('('); 
      let countryName = '';
      if (removeIndex >0){
        countryName = e[0].name.substring(0, removeIndex);
      }else{                     
        countryName = e[0].name;
      }
      business['address']['country'].value = countryName;
    }else{
      business['country'].value = e;
    }
    this.setState({business: business});
  }

  onInputChange(text: string, e:Event){
    console.log(`onInputChange, text, ${JSON.stringify(text)}`);
    let business = this.state.business;
    business['country'].dirtyFlag = true;
    this.setState({business: business});
  }

  onLabelKey(option: { name: string; dialCode: any; }){
    
    if (option.name && option.dialCode){
      let removeIndex = option.name.indexOf('('); 
      if (removeIndex >0){
        return `${option.dialCode} ${option.name.substring(0, removeIndex)}`
      }else{                     
        return `${option.dialCode} ${option.name}`
      }
    }else{
      return ''
    }
  }

  onTagAdd(tag: Tag){
    let business = this.state.business;
    business['keywords'].value.push({id: tag.id, name:tag.name.toLowerCase()});
    this.setState({business: business});
  }

  onTagDelete(i: number){
    let business = this.state.business;
    business['keywords'].value = business.keywords.value.filter((tag: any, index: number) => index !== i)
    this.setState({business: business});
  }

  onEdit(){
    this.setState({editMode: true});
  }

  onCancel(){
    this.reset();
    this.setState({editMode: false});
  }

  onBusinessHoursChange(businessHours: BusinessHours){
    //make sure spcialhours are not overwritten
    businessHours.specialHours = this.state.businessHours.specialHours;

    this.setState({businessHours: businessHours})
    this.onSaveBusinessHours(businessHours);
  }

  onSpecialHoursChange(specialHours: SpecialHour[]){
    let businessHours = this.state.businessHours;

    businessHours.specialHours = specialHours;
    this.setState({businessHours: businessHours});
    this.onSaveBusinessHours(businessHours);
  }

  onSaveBusinessHours(businessHours: BusinessHours){
    this.props.initiateUpdateBusinessHours(this.props.businessState.selectedBusiness.businessId, businessHours, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
    })
  }

  onSaveSocialLinks(socialLinks: string[]){
    this.props.initiateUpdateBusinessSocialLinks(this.props.businessState.selectedBusiness.businessId, socialLinks, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
    })
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('BusinessProfile, onSave');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let business =  this.getFormData();
      console.log(JSON.stringify(business));

      if (this.isModified(business)){
        let self = this;
        this.props.initiateUpdateBusiness(business, 
          ()=>{
            //on success
            alert.info('Successfully updated!');
            self.reset();
          }, 
          ()=>{
            //on error
            alert.error('Failed to update!');
        })
      }
      // reset 
      this.state.business.bannerPicture.deletePicture = '';
      this.state.business.profilePicture.deletePicture = '';
      this.state.business.bannerPicture.file = null;
      this.state.business.profilePicture.file = null;

      this.setState({editMode: false, validated: false});
    }
  }

  getFormData(){

    let businessObj = Object.keys(this.state.business).reduce((acc: any, key: string) =>{
      if (key === 'country'){
        acc[key] = this.state.business[key].value[0];
      }else if (key === 'keywords'){
        acc[key] = this.state.business[key].value.map((keyword: { name: string; }) => {return keyword.name})
      }else if (key !== 'address'){
        acc[key] = this.state.business[key].value;
      }
      return acc;
    }, {});

    let addressObj = Object.keys(this.state.business.address).reduce((acc: any, key: string) =>{
      acc[key] = this.state.business.address[key].value;
      return acc;
    }, {});

    let business = new Business(businessObj);
    let address = new Address(addressObj);

    business.address = address;
    business.businessId = this.props.businessState.selectedBusiness.businessId;
    business.deleteBannerPicture = this.state.business.bannerPicture.deletePicture;
    business.deleteProfilePicture = this.state.business.profilePicture.deletePicture;
    business.newBannerPictureFile = this.state.business.bannerPicture.file;
    business.newProfilePictureFile = this.state.business.profilePicture.file;

    return business; 
  }

  isModified(business: Business){
    return this.props.businessState.selectedBusiness.isDifferent(business);
  }

}

const mapStateToProps = (state: { auth: AuthState; business: BusinessState; billing: PaymentState }) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    paymentState: state.billing 
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
  return {
    initiateUpdateBusiness: (business: Business, onSuccess: any, onError: any) => {
      dispatch(initiateUpdateBusiness(business, onSuccess, onError))
    },
    initiateUpdateBusinessHours: (businessId: string, businessHours: BusinessHours, onSuccess: any, onError: any) => {
      dispatch(initiateUpdateBusinessHours(businessId, businessHours, onSuccess, onError))
    },
    initiateUpdateBusinessSocialLinks: (businessId: string, socialLinks: string[], onSuccess: any, onError: any) => {
      dispatch(initiateUpdateBusinessSocialLinks(businessId, socialLinks, onSuccess, onError))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfilePage);