
const PAGE_LINKS_INITIALIZED = 'PAGE_LINKS_INITIALIZED';

const LOAD_PAGE_LINKS = 'LOAD_PAGE_LINKS';
const PAGE_LINKS_LOADED = 'PAGE_LINKS_LOADED';
const FAILED_TO_LOAD_PAGE_LINKS = 'FAILED_TO_LOAD_PAGE_LINKS';
const INITIATE_UPDATE_PAGE_LINKS = 'INITIATE_UPDATE_PAGE_LINKS';
const UPDATED_PAGE_LINKS = 'UPDATED_PAGE_LINKS';

const LOAD_AVAILABLE_PAGE_PROPERTY = 'LOAD_AVAILABLE_PAGE_PROPERTY';
const AVAILABLE_PAGE_PROPERTY_LOADED = 'AVAILABLE_PAGE_PROPERTY_LOADED';
const FAILED_TO_LOAD_AVAILABLE_PAGE_PROPERTY = 'FAILED_TO_LOAD_AVAILABLE_PAGE_PROPERTY';

const INITIATE_CREATE_PAGE_PROPERTY = 'INITIATE_CREATE_PAGE_PROPERTY';
const PAGE_PROPERTY_CREATED = 'PAGE_PROPERTY_CREATED';
const INITIATE_UPDATE_PAGE_PROPERTY = 'INITIATE_UPDATE_PAGE_PROPERTY';
const PAGE_PROPERTY_UPDATED = 'PAGE_PROPERTY_UPDATED';
const INITIATE_UPDATE_PAGE_SIZE = 'INITIATE_UPDATE_PAGE_SIZE';
const PAGE_SIZE_UPDATED = 'PAGE_SIZE_UPDATED';
const INITIATE_DELETE_PAGE_PROPERTY = 'INITIATE_DELETE_PAGE_PROPERTY';
const PAGE_PROPERTY_DELETED = 'PAGE_PROPERTY_DELETED';



const LOAD_DOMAIN_LIST = 'LOAD_DOMAIN_LIST';
const CREATE_DOMAIN = 'CREATE_DOMAIN';
const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
const DELETE_DOMAIN = 'DELETE_DOMAIN';
const CHANGE_DOMAIN = 'CHANGE_DOMAIN';
const DOMAIN_LIST_LOADED = 'DOMAIN_LIST_LOADED';
const FAILED_TO_LOAD_DOMAIN_LIST = 'FAILED_TO_LOAD_DOMAIN_LIST';
const DOMAIN_CREATED = 'DOMAIN_CREATED';
const DOMAIN_UPDATED = 'DOMAIN_UPDATED';
const DOMAIN_DELETED = 'DOMAIN_DELETED';

const LOAD_PAGE_SETTINGS = 'LOAD_PAGE_SETTINGS';
const PAGE_SETTINGS_LOADED = 'PAGE_SETTINGS_LOADED';
const FAILED_TO_LOAD_PAGE_SETTINGS = 'FAILED_TO_LOAD_PAGE_SETTINGS';
const INITIATE_UPDATE_PAGE_SETTINGS = 'INITIATE_UPDATE_PAGE_SETTINGS';
const PAGE_SETTINGS_UPDATED = 'PAGE_SETTINGS_UPDATED';


export default {
    PAGE_LINKS_INITIALIZED,

    LOAD_PAGE_LINKS,
    PAGE_LINKS_LOADED,
    FAILED_TO_LOAD_PAGE_LINKS,
    INITIATE_UPDATE_PAGE_LINKS,
    UPDATED_PAGE_LINKS,

    LOAD_AVAILABLE_PAGE_PROPERTY,
    AVAILABLE_PAGE_PROPERTY_LOADED,
    FAILED_TO_LOAD_AVAILABLE_PAGE_PROPERTY,

    INITIATE_CREATE_PAGE_PROPERTY,
    PAGE_PROPERTY_CREATED,
    INITIATE_UPDATE_PAGE_PROPERTY,
    PAGE_PROPERTY_UPDATED,
    INITIATE_UPDATE_PAGE_SIZE,
    PAGE_SIZE_UPDATED,
    INITIATE_DELETE_PAGE_PROPERTY,
    PAGE_PROPERTY_DELETED,

    LOAD_DOMAIN_LIST,
    CREATE_DOMAIN,
    UPDATE_DOMAIN,
    DELETE_DOMAIN,
    CHANGE_DOMAIN, 
    DOMAIN_LIST_LOADED,
    FAILED_TO_LOAD_DOMAIN_LIST,
    DOMAIN_CREATED,
    DOMAIN_UPDATED,
    DOMAIN_DELETED,

    LOAD_PAGE_SETTINGS,
    PAGE_SETTINGS_LOADED,
    FAILED_TO_LOAD_PAGE_SETTINGS,
    INITIATE_UPDATE_PAGE_SETTINGS,
    PAGE_SETTINGS_UPDATED,

};

export enum ErrorCode {
    DOMAIN_ALREADY_EXISTING = '409',
    FAILED_TO_CREATE = '0'
}

