
import { Component } from 'react';
import _ from 'lodash';

import HoursText from './hoursText';
import AddressText from './addressText';
import BusinessName from './businessName';
import BusinessProfileIcon from './businessProfileIcon';
import { Business } from 'app/business';



export interface Props {
  business: Business,
  showHours: boolean,
  showAddress: boolean,
  showProfilePicture:boolean
}

interface State { 
  
}

export default class BusinessCard extends Component<Props, State> {
    static propTypes: {};

    constructor(props: Props){
      super(props)
    }

    render(){
        let { name, address, profilePicture, businessHours} = this.props.business;
        let { showHours, showAddress, showProfilePicture } = this.props;
       
        return (
              <div className="businessCard d-flex align-items-start">
                {(showProfilePicture)? <BusinessProfileIcon source={profilePicture} /> : null }
                
                <div className="d-flex flex-column align-items-start justify-content-center mx-3">
                        <BusinessName name={name}/>
                        {(showAddress)? <AddressText address={address}/> : null }
                        {(showHours)? <HoursText businessHours={businessHours}/> : null }
                </div> 
            </div>
        );
    }
}