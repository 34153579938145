import { Component } from 'react';

import { Dropdown, Form } from 'react-bootstrap';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { initiateSignOut } from '../user';
import { type,  setSelectedBusiness} from '../business';
import { settings } from 'app/config';
import { mdiTextAccount, mdiHelpCircleOutline, mdiLogoutVariant } from '@mdi/js';
import IconWithLabel from './iconWithLabel';
import Img from './img';


class Navbar extends Component {

  constructor(props){
    super(props);
    console.log('Navbar initialized');

    this.notifications = {
      isAvailable: false, 
      notificationList: []
    }

    this.state = {
      searchText: ''
    }

  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  
  renderBusinessDropdown(){
    let render = null;
    let self = this;
    if(this.props.business.state === type.LOADED && this.props.business.selectedBusiness){
      render = (
        <Dropdown>
          <Dropdown.Toggle className="nav-link m-dropdown--arrow">
            <div className="d-flex  flex-column justify-content-center">
              <a className="font-weight-normal">{ this.props.business.selectedBusiness.name }</a>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="preview-list navbar-dropdown">
            <p className="p-3 mb-0 text-black preview-subject ellipsis mb-1 font-weight-normal">Select business</p>

            { 
              this.props.business.businessList.map((item) => { 

                return (
                  <Dropdown.Item id={item.businessId} className="dropdown-item preview-item" onClick={e => self.onSelectBusiness(e)}>
                    <div className="preview-thumbnail">
                      <img src={item.profilePicture} onError={(e)=>{e.target.onerror = null; e.target.src="https://via.placeholder.com/36x36"}} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">{item.name}</h6>
                    </div>
                  </Dropdown.Item>
                );
              })
            }
            <Dropdown.Divider />
            <Dropdown.Item className="justify-content-center" as={Link} to="/createBusiness">
                <span>Create</span>
            </Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown>
      );
    }
    return render;
  }

  renderNotifications(){
    let render = null;

    if(this.props.business.state === type.LOADED){
      render = (
        <Dropdown alignRight>

          <Dropdown.Toggle className="nav-link count-indicator">
            <i className="ti-bell"></i>
            { (this.notifications.isAvailable)? <span className="count"></span>: null }
          </Dropdown.Toggle>
            {(!this.notifications.isAvailable)
            
            ? (<Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <p className="p-3 mb-0 text-black">Alerts</p>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">You don't have any alerts</h6>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
              )
            : (
                this.props.notifications.notificationList.map((item) => { 
                  return (
                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="ti-info-alt"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">item.name</h6>
                      <p className="text-gray ellipsis mb-0">
                      {item.description}
                      </p>
                    </div>
                  </Dropdown.Item>
                  );
                })
              )       
            }
        </Dropdown>
      );
    }

    return render;
  }

  renderProfileOptions(){
    let render = null;

    render = (
      <Dropdown alignRight>
        <Dropdown.Toggle className="nav-link count-indicator">
          <Img src="https://" alt="profile" fallbackInitial={'A'}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="preview-list navbar-dropdown">
          <Dropdown.Item className="dropdown-item preview-item" onClick={evt => {evt.preventDefault(); this.props.history.push("/userProfile")}}>
            <div className="d-flex align-items-center">
              <Link to="/userProfile" style={{ color: 'inherit', textDecoration: 'none' }}>
                <IconWithLabel className="pr-2" path={mdiTextAccount} label="Profile"/>
              </Link>
            </div>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item preview-item" onClick={this.onHelp.bind(this)}>
            <div className="d-flex align-items-center">
              <a href="" onClick={this.onHelp.bind(this)} style={{ color: 'inherit', textDecoration: 'none' }}>
                <IconWithLabel className="pr-2" path={mdiHelpCircleOutline} label="Help"/>
              </a>
            </div>  
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-item preview-item" onClick={this.onSignOut.bind(this)}>
            <div className="d-flex align-items-center">
              <Link to="/" onClick={this.onSignOut.bind(this)} style={{ color: 'inherit', textDecoration: 'none' }}>
                <IconWithLabel className="pr-2" path={mdiLogoutVariant} label="Logout"/>
              </Link>
            </div>  
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
    return render;
  }

  renderSearch(){
    let render = null;
    render = (
        <form className="d-flex">
          <Form.Group className="mb-0 w-100">
            <div className="input-group border d-flex justify-content-between">
              <div className="d-flex w-75">
                <Route render={({ history}) => (
                  <Form.Control type="text" className="border-0 form-control" placeholder="Search for customers, inquiries..." aria-label="Search Customer" aria-describedby="basic-addon2" onBlur={e => this.onSearchCancel(e)} onChange={e => this.onChange(e)} value={this.state.searchText} onKeyPress={(e)=> this.onSearch(e, history)}/>
                )}>

                </Route> 
              </div>
              <div type="" className="input-group-append p-2 align-items-center" style={{backgroundColor: 'white'}}>
                <i className={(this.state.searchText !== '')? 'ti-close':'d-none'} onClick={(e)=> this.onSearchCancel(e)}></i>
                <i className={(this.state.searchText === '')? 'ti-search':'d-none'}  ></i>
              </div>
              
            </div>
          </Form.Group>
        </form>
    );
    return render;
  }
  
  render () {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
              
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo mx-5" to="/"><h3><strong>Resquash</strong></h3></Link>
          <div className="navbar-brand brand-logo-mini">
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" >
              <span onClick={this.toggleOffcanvas} className="ti-menu"></span>
            </button>
            <Link className="navbar-brand brand-logo-mini d-none d-sm-inline-block" to="/"> <h4><strong>Resquash</strong></h4> </Link>
          </div>
        </div>

        <div className="navbar-menu-wrapper d-flex align-items-stretch w-80">
          <div className="d-none d-md-flex justify-content-between w-100">
             <ul className="navbar-nav mr-lg-2 w-50">
              <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
                <span className="ti-layout-grid2"></span>
              </button>
              <li className="nav-item">
                {this.renderBusinessDropdown()}
              </li>
              <li className="nav-item w-100">
                {this.renderSearch()}
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right d-flex justify-conentent-center">
              <li className="nav-item">
                {this.renderNotifications()}
              </li>
              <li className="nav-item nav-profile">
                {this.renderProfileOptions()}
              </li>
            </ul>
          </div>
          <div className="d-inline-flex d-md-none justify-content-end w-100">
            <ul className="navbar-nav mr-lg-2 w-100">
              <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
                <span className="ti-layout-grid2"></span>
              </button>
              <li className="nav-item" id="selectBusiness">
                {this.renderBusinessDropdown()}
              </li>
              <li className="nav-item nav-search mh-0" id="searchIcon">
                <div className="input-group pr-0 pl-0">
                    <div className="input-group-prepend hover-cursor" id="navbar-search-icon" onClick={ (e) => this.onShowSearchBar(e) }>
                      <span className="input-group-text" id="search">
                        <i className="ti-search"></i>
                      </span>
                    </div>
                  </div>
              </li>
              <li className="nav-item d-none w-100" id="searchBar">
                {this.renderSearch()}
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right d-flex justify-conentent-center">
              <li className="nav-item" id="notification">
                {this.renderNotifications()}
              </li>
              <li className="nav-item nav-profile" id="profile">
                {this.renderProfileOptions()}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  onHelp(e){
    e.preventDefault();
    console.log('Navbar, onHelp');
    window.open(settings.support);
  }

  onSignOut(e){
    e.preventDefault();
    console.log('Navbar, onSignOut');
    this.props.initiateSignOut();
  }

  onSelectBusiness(e){
    e.preventDefault();
    console.log('Navbar, onSelectBusiness');
    let businessId = e.currentTarget.id;
    if (businessId !== '' && businessId !== this.props.business.selectedBusiness.businessId){
      // user is selecting new business
      console.log(`Navbar, switching to business - ${businessId}`);
      this.props.setSelectedBusiness(businessId)
    }
  }

  onChange(e){
    console.log('CustomerList, onChange');
    
    this.setState({searchText:e.target.value});
  }

  onSearch(e, history){
    if (e.key === 'Enter'){
      e.preventDefault();
     
      history.push("/searchResults?searchText="+this.state.searchText);
    }
  }

  onSearchCancel(e){
    this.setState({searchText: ''});
    //this.props.loadCustomers(this.props.businessId, '');

    document.getElementById("searchBar").classList.add("d-none");

    document.getElementById("searchIcon").classList.remove("d-none");
    document.getElementById("selectBusiness").classList.remove("d-none");
    document.getElementById("notification").classList.remove("d-none");

    document.getElementById("profile").classList.add("nav-profile");
    document.getElementById("profile").classList.remove("d-none");

  }

  onShowSearchBar(e){
    e.preventDefault();
    document.getElementById("searchIcon").classList.add("d-none");
    document.getElementById("selectBusiness").classList.add("d-none");
    document.getElementById("notification").classList.add("d-none");

    document.getElementById("profile").classList.remove("nav-profile");
    document.getElementById("profile").classList.add("d-none");

    document.getElementById("searchBar").classList.remove("d-none");
  }

}

const mapStateToProps = (state) => {
  return { 
            auth: state.auth,
            business: state.business 
          };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initiateSignOut: () => {
      dispatch(initiateSignOut())
    },
    setSelectedBusiness: (businessId) => {
      dispatch(setSelectedBusiness(businessId))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));

