import type from './types';
import { createInquiry, updateInquiry, deleteInquiry } from './firebaseActions';
import { format } from 'date-fns'
import { Role } from 'app/userPermissions';
import { Business } from 'app/business';
import { RecordObject } from 'app/record';


export enum InquiryStatus{
    Unread = 'unread',
    Read = 'read',
    FollowUp = 'followUp'
}

export type InquiryData ={
    object?: RecordObject;
    inquiryId?: string;
    name?: string;
    customerId?: string;
    phoneNumber?: string;
    emailAddress?: string;
    inquiryDate?: number;
    inquiryMessage?: string;
    inquiryInternalNotes?: string;
    inquiryStatus?: InquiryStatus; 
}


export class Inquiry {
    object: RecordObject;
    inquiryId: string;
    name: string;
    customerId: string;
    phoneNumber: string;
    emailAddress: string;
    inquiryDate: number;
    inquiryMessage: string;
    inquiryInternalNotes: string;
    inquiryStatus: InquiryStatus; 

    constructor(inquiryData: InquiryData){
        console.log('Inquiry initialized');

        this.object = RecordObject.inquiry;
        this.inquiryId = inquiryData.inquiryId || '';
        this.name = inquiryData.name || '';
        this.customerId = inquiryData.customerId || '';
        this.phoneNumber = inquiryData.phoneNumber || '';
        this.emailAddress = inquiryData.emailAddress || '';
        this.inquiryDate = inquiryData.inquiryDate || new Date().getTime();
        this.inquiryMessage = inquiryData.inquiryMessage || '';
        this.inquiryInternalNotes = inquiryData.inquiryInternalNotes || '';
        this.inquiryStatus = inquiryData.inquiryStatus || InquiryStatus.Unread;

    }

    isDifferent(inquiry: Inquiry){
        let isDifferent = false;
        if (this.name !== inquiry.name || this.phoneNumber !== inquiry.phoneNumber || 
            this.emailAddress !== inquiry.emailAddress || this.inquiryMessage !== inquiry.inquiryMessage || 
            this.inquiryInternalNotes !== inquiry.inquiryInternalNotes || this.inquiryStatus !== inquiry.inquiryStatus){
            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        return {
            name: this.name,
            customerId: this.customerId,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            inquiryDate: this.inquiryDate,
            inquiryMessage: this.inquiryMessage,
            inquiryInternalNotes: this.inquiryInternalNotes,
            inquiryStatus: this.inquiryStatus
        }
    }

    copy(inquiry: Inquiry){
        Object.assign(this, inquiry);
    }

    displayInquiryDate(){
        let date = new Date(this.inquiryDate);
        let sameYear = (date.getFullYear() == new Date().getFullYear());
        let sameday = (date.getDate() == new Date().getDate());
        let displayString = '';

        if (!sameYear){
            displayString = format(date, 'MM/dd/yy'); // MM/DD/YY
        }else if (!sameday){ 
            displayString = format(date, 'MMM d'); // Jan 20
        } else{
            displayString = format(date, "hh:mm aaaaa'm'") // 2:30 pm
        }
        return displayString; 
    }

    async create (businessId: string){
        await createInquiry(businessId, this);
    }

    async update(businessId: string){
        await updateInquiry(businessId, this);
    }

    async delete(businessId: string){
        await deleteInquiry(businessId, this);
    }
}


export class InquiryState {
    state: string;
    inquiryList: Inquiry[];
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: Business) => boolean;

    constructor(){
        console.log('InquiryState initialized');

        this.state = type.INQUIRY_INITIALIZED;
        this.inquiryList = [];

        this.canCreate = function(selectedBusiness: Business){
            return this.canEdit(selectedBusiness)
        }

        this.canEdit = function (selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness: Business){
            return this.canEdit(selectedBusiness)
        }
    }
}
