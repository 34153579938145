import type from './types';
import {type as authType} from '../user';
import { type as businessType } from '../business';
import { Reward, RewardState } from './reward';

const INITIAL_STATE = new RewardState ();
   

export const rewardReducer = (state = INITIAL_STATE, action: { type: string; rewardList: Reward[]; reward: Reward; }) => {

    switch (action.type){

        case type.REWARDS_LOADED:
            console.log('rewardReducer, '+action.type);
            return {...state, state: type.REWARDS_LOADED, rewardList: action.rewardList};

        case type.MORE_REWARDS_LOADED:
            console.log('rewardReducer, '+action.type);
            Array.prototype.push.apply(state.rewardList, action.rewardList);
            return {...state, state: type.REWARDS_LOADED, rewardList: state.rewardList};

        case type.REWARD_LIST_EMPTY:
            console.log('rewardReducer, '+action.type);
            return {...state, state: type.REWARD_LIST_EMPTY, rewardList:[]};

        case type.FAILED_TO_LOAD_REWARDS:
            console.log('rewardReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_REWARDS};

        case type.REWARD_CREATED:
            console.log('rewardReducer, '+action.type);
            state.rewardList.unshift(action.reward); 
            return {...state, state: type.REWARDS_LOADED, rewardList: state.rewardList}

                
        case type.REWARD_UPDATED:
            console.log('rewardReducer, '+action.type);

            state.rewardList.find(reward => {
                if (reward.rewardId === action.reward.rewardId){
                    reward.copy(action.reward);
                }
             })

            return {...state, state: type.REWARDS_LOADED}

        case type.REWARD_DELETED:
            console.log('rewardReducer, '+action.type);

            let list = state.rewardList.filter(reward => {
                if (reward.rewardId !== action.reward.rewardId){
                    return true;
                }
            })

            return {...state, state: type.REWARDS_LOADED, rewardList: list}
    
        case businessType.BUSINESS_SELECTED:
        case authType.SIGNED_OUT:
            console.log('rewardReducer, '+action.type);
            return {...state, state: type.REWARD_INITIALIZED, rewardList:[]};

        default:
            console.log('rewardReducer, default - '+ action.type);
            return state;
    }
};
