import type from './types';
import {type as authType} from '../user';
import { Business, BusinessState } from './business';
import { BusinessHours } from './businessHours';

const INITIAL_STATE = new BusinessState ({});
   

export const businessReducer = (state = INITIAL_STATE, action: { type: string; businessList: Business[]; businessId: string ; business: Business; businessHours: BusinessHours; socialLinks: string[]}) => {

    console.log('businessReducer, '+action.type);

    switch (action.type){


        case type.LOADED:
            {
                console.log('businessReducer, '+action.type);
                let businessState = new BusinessState({...state, state: type.LOADED, businessList: action.businessList});
                //businessState.setSelectedBusiness('');
                return businessState;
            }

        case type.EMPTY:
            console.log('businessReducer, '+action.type);
            return {...state, state: type.EMPTY, businessList:null};

        case type.FAILED_TO_LOAD:
            console.log('businessReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD};

        case type.BUSINESS_SELECTED:
            {
                console.log('businessReducer, '+action.type);
                let businessState = new BusinessState({...state, state: type.LOADED});
                businessState.setSelectedBusiness(action.businessId);
                return businessState;
            }

        case type.BUSINESS_CREATED:
            state.businessList.push(action.business);
            return {...state, state: type.LOADED}

        case type.BUSINESS_UPDATED:
            state.selectedBusiness.copy(action.business);
            state.businessList.find(business => {
                if (business.businessId === action.business.businessId){
                    business.copy(action.business);
                }
             })

            return {...state, state: type.LOADED}

        case type.BUSINESS_DELETED:
            {
                let businessList = state.businessList.filter(business => {
                    if (business.businessId !== action.business.businessId){
                        return business;
                    }
                })
                let businessState = new BusinessState({...state, state: type.LOADED, businessList: businessList});
                businessState.setSelectedBusiness('');
                return businessState;
            }

        case type.BUSINESS_HOURS_UPDATED:
            console.log('businessReducer, '+action.type);
            state.selectedBusiness.businessHours = action.businessHours;
            return {...state, state: type.LOADED};

        case type.BUSINESS_SOCIAL_LINKS_UPDATED:
            console.log('businessReducer, '+action.type);
            state.selectedBusiness.socialLinks = action.socialLinks;
            return {...state, state: type.LOADED};

        case authType.SIGNED_OUT:
            console.log('businessReducer, '+action.type);
            return {...state, state: type.INITIALIZED, businessList:null, selectedBusiness: null};

        default:
            console.log('businessReducer, default - '+ action.type);
            return state;
    }
};


