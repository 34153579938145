
const PRODUCT_INITIALIZED = 'PRODUCT_INITIALIZED';
const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
const LOAD_MORE_PRODUCTS = 'LOAD_MORE_PRODUCTS';
const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
const MORE_PRODUCTS_LOADED = 'MORE_PRODUCTS_LOADED';
const PRODUCT_LIST_EMPTY = 'PRODUCT_LIST_EMPTY';
const FAILED_TO_LOAD_PRODUCTS = 'FAILED_TO_LOAD_PRODUCTS';

const INITIATE_CREATE_PRODUCT = 'INITIATE_CREATE_PRODUCT';
const INITIATE_UPDATE_PRODUCT = 'INITIATE_UPDATE_PRODUCT';
const INITIATE_DELETE_PRODUCT = 'INITIATE_DELETE_PRODUCT';

const PRODUCT_CREATED = 'PRODUCT_CREATED';
const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
const PRODUCT_DELETED = 'PRODUCT_DELETED';


export default {
    PRODUCT_INITIALIZED,
    LOAD_PRODUCTS,
    LOAD_MORE_PRODUCTS,
    PRODUCTS_LOADED,
    MORE_PRODUCTS_LOADED,
    PRODUCT_LIST_EMPTY,
    FAILED_TO_LOAD_PRODUCTS,

    INITIATE_CREATE_PRODUCT,
    INITIATE_UPDATE_PRODUCT,
    INITIATE_DELETE_PRODUCT,

    PRODUCT_CREATED,
    PRODUCT_UPDATED,
    PRODUCT_DELETED,
};

export enum ErrorCode {
    PRODUCT_ALREADY_EXISTING = '409',
    FAILED_TO_CREATE = '0'
}