
const PAYMENT_INITIALIZED = 'PAYMENT_INITIALIZED';
const LOAD_CURRENT_ESTIMATION = 'LOAD_CURRENT_ESTIMATION';
const CURRENT_ESTIMATION_LOADED = 'CURRENT_ESTIMATION_LOADED';

const LOAD_PAST_PAYMENTS = 'LOAD_PAST_PAYMENTS';
const PAST_PAYMENTS_LOADED = 'PAST_PAYMENTS_LOADED';


export default {
    PAYMENT_INITIALIZED,

    LOAD_CURRENT_ESTIMATION,
    CURRENT_ESTIMATION_LOADED,

    LOAD_PAST_PAYMENTS,
    PAST_PAYMENTS_LOADED

};