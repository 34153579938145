import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { alert, DeleteDialog, IconButton, SKUStatusDialog } from '../shared';
import { Spinner } from '../shared/Spinner';
import ProductInputDialog from './productInputDialog';
import { loadProducts, loadMoreProducts, initiateSaveProduct, initiateDeleteProduct } from "./actions";
import types, { ErrorCode } from './types';
import './product.css';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Product, ProductState } from './product';
import { PaymentState, SKU, SKUName, SKUStatus } from 'app/billing/payment';
import { ProductMerchantList } from './productMerchantList';
import { ProductList } from './productList';
import { ToggleButton } from 'react-bootstrap';
import { mdiViewGrid, mdiViewGridOutline } from '@mdi/js';


export interface Props extends RouteComponentProps{
  authState: AuthState,
  businessState: BusinessState,
  productState: ProductState,
  paymentState: PaymentState,
  businessId: string,
  loadProducts: (businessId: string, searchText: string | undefined) => void,
  loadMoreProducts: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) => void,
  initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void,
  initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void
}

export interface State {
  editable: boolean,
  searchText: string,
  showCustomerView: boolean,
  showDeleteDialog: boolean,
  selectedProduct: Product | null | undefined,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean,
  showSKUStatusDialog: boolean,
  productSKU: SKU
}

export class ProductListPage extends Component<Props, State> {
  private _handleScroll: () => void;
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductList initialized');
    this._handleScroll = this.handleScroll.bind(this);

    this.state = {
      editable: this.props.productState.canCreate(this.props.businessState.selectedBusiness),
       showDeleteDialog: false,
      showCustomerView: false,
      selectedProduct: null,
      searchText: '', 
      errorCode: '',
      saveInProgress: false,
      showSKUStatusDialog: false,
      productSKU: new SKU({})
    }
    this.props.loadProducts(this.props.businessId, '');
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render(){
    let component = null;
    if(this.props.productState.state === types.PRODUCT_INITIALIZED || this.props.productState.state === types.LOAD_PRODUCTS){
      component = <Spinner/>;
    } else if (this.props.productState.productList.length > 0 || this.state.searchText !== ''){
      component = this.renderProductList();
    }else{
      component = this.renderCreateProduct();
    }
    return component;
  }

  renderProductList() {
    let self = this;

    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-6 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>Products for {this.props.businessState.selectedBusiness.name} </h4>
                  </div>
                  <div className="col-12 col-md-3 grid-margin wrapper kanban-toolbar"> 
                    
                  </div>
                  <div className="col-0 col-md-3 grid-margin d-flex justify-content-end align-items-center"> 
                    <IconButton path={this.state.showCustomerView ? mdiViewGrid : mdiViewGridOutline} toolTipText={this.state.showCustomerView ? 'Hide card view' : 'Show card view'} onClick={() => this.setState({showCustomerView: !this.state.showCustomerView})}/>
                    <button type="button" className="d-none d-md-block btn btn-primary ml-2" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onCreateProduct()}>Create</button>
                  </div>
                </div>

                <div className="d-md-none d-block fixed-bottom product-fixed-create-button bg-primary rounded-circle d-flex justify-content-center align-items-center">
                  <i  className="ti-plus h4 font-weight-bolder text-white m-0 " style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onCreateProduct()}></i>
                </div>
                
                
                <div className="row">
                  <div className="col-12">
                      { !this.state.showCustomerView
                        ?(<ProductMerchantList 
                          title={''}
                          productList={this.props.productState.productList}
                          editable={this.props.productState.canEdit(this.props.businessState.selectedBusiness)}
                          onEditProduct={(productId: string)=> this.onEditProduct(productId)} 
                          onShowDeleteDialog={(productId: string)=> this.onShowDeleteDialog(productId)}    
                        />)
                        :(
                          <ProductList 
                          businessId={this.props.businessState.selectedBusiness.businessId}
                          productList={this.props.productState.productList}
                          canEdit={this.props.productState.canEdit(this.props.businessState.selectedBusiness)}    
                        />
                        )
                        }
                    </div>
                </div>
          </div>
        </div>
        <DeleteDialog 
              id={''} 
              confirmationText={this.state.selectedProduct?.name} 
              show={this.state.showDeleteDialog} 
              onHide={() => this.setState({showDeleteDialog: false})} 
              onCancel={() => this.setState({showDeleteDialog: false})} 
              onDelete={() => this.onDelete()}/>
        <SKUStatusDialog 
              title={'Create Product'} 
              show={this.state.showSKUStatusDialog} 
              onHide={() => {this.setState({showSKUStatusDialog: false})}} 
              onCancel={() => {this.setState({showSKUStatusDialog: false})}} 
              sku={this.state.productSKU}/>
      </div>
    )
  }

  renderCreateProduct() {
    let self = this;

    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                  <h4 className="mb-4"> Start creating products. </h4>
                  <div className="d-flex mt-4 mt-md-0">
                    <button type="button" className="btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onCreateProduct()}>Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onCreateProduct(){
    let productSKU = this.props.paymentState.getSKU(SKUName.products);
    if (productSKU.status === SKUStatus.active) {
      this.props.history.push('/products/create');
    }else{
      this.setState({showSKUStatusDialog:true, productSKU: productSKU});
    }
  }

  onEditProduct(productId: string){
    this.props.history.push(`/products/${productId}`);
  }

  onShowDeleteDialog(productId: string){
    let product = null;
    if (productId){
      product = this.props.productState.productList.find((item)=>{
        return (item.productId === productId);
      });
    }
    this.setState({showDeleteDialog: true, selectedProduct: product});
  }

  onDelete(){
    let product = this.state.selectedProduct;

    if (product){
      this.setState({showDeleteDialog: false, selectedProduct: null});
      this.deleteProduct(product);
    }
  }

  deleteProduct(product: Product){

    this.props.initiateDeleteProduct(this.props.businessId, product, 
      ()=>{
        //on success
        alert.info('Successfully deleted!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to delete!');
      })
  }

  handleScroll(){
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if ((scrollHeight - scrollTop) === clientHeight){
      console.log('ProductListPage, load more products');
      if(this.props.productState.productList.length > 0){
        //this.props.loadMoreProducts(this.props.businessId, 0, this.state.searchText);
      }
    }
  }
}


const mapStateToProps = (state: { auth: AuthState; business: BusinessState; product: ProductState; billing: PaymentState}) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    productState: state.product,
    paymentState: state.billing
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; product?: Product; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadProducts: (businessId: string, searchText: string | undefined) =>{
      dispatch(loadProducts(businessId, searchText));
    },
    loadMoreProducts: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) =>{
      dispatch(loadMoreProducts(businessId, afterTimestamp, searchText));
    },
    initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateSaveProduct(businessId, product, onSuccess, onError))
    },
    initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteProduct(businessId, product, onSuccess, onError))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListPage));

