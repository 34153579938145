import { GJSTagNames } from "../grapesjsEditor";
import { ProductList } from "app/page/business/productList";


export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.Product) ;

  editor.Components.addType(GJSTagNames.Product, {
    extend: 'react-component',
    model: {
      defaults: {
        component: ProductList,
        isNative: true,
        attributes: {
          productList: [],
          canEdit: false,
          businessId: ''
        },
        toolbar: [],
        traits: []
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.Product} model initialized`);
       
      }, 
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.Product.toUpperCase();
    }
  });
};
