import { channel } from 'redux-saga'
import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchCurrentPlans } from './firebaseActions';
import { CurrentPlans } from './plans';


import type from './types';

const customChannel = channel();

//Action creators

export const loadCurrentPlans = () => {
    console.log('loadCurrentEstimation, action');
    return { type: type.LOAD_CURRENT_PLANS }
};

export const currentPlansLoaded = (currentPlans: CurrentPlans) => {
    return { type: type.CURRENT_PLANS_LOADED, currentPlans }
};

// Sagas

function* loadCurrentPlansAsync(action: any) {
    console.log('loadCurrentPlansAsync, action - '+ JSON.stringify(action));
    try {
  
        const currentPlans: CurrentPlans = yield call(fetchCurrentPlans);
        console.log(JSON.stringify(currentPlans));
        yield put(currentPlansLoaded(currentPlans));
        
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

export const planSagas = [
    takeEvery<any>(type.LOAD_CURRENT_PLANS, loadCurrentPlansAsync),
];