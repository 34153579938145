import type from './types';
import {type as authType} from '../user';
import { DashboardElementsState } from './dashboardElement';

const INITIAL_STATE = new DashboardElementsState ();
   

export const dashboardElementsReducer = (state = INITIAL_STATE, action: { type: string; dashboardElements: any; }) => {

    switch (action.type){


        case type.DASHBOARD_ELEMENTS_LOADED:
            console.log('dashboardElementsReducer, '+action.type);
            return {...state, state: type.DASHBOARD_ELEMENTS_LOADED, dashboardElements: action.dashboardElements};

        case type.DASHBOARD_ELEMENTS_EMPTY:
            console.log('dashboardElementsReducer, '+action.type);
            return {...state, state: type.DASHBOARD_ELEMENTS_EMPTY, dashboardElements:[]};

        case type.FAILED_TO_LOAD_DASHBOARD_ELEMENTS:
            console.log('dashboardElementsReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_DASHBOARD_ELEMENTS};

                
        case type.UPDATE_DASHBOARD_ELEMENTS:
            console.log('dashboardElementsReducer, '+action.type);
            return {...state, state: type.DASHBOARD_ELEMENTS_LOADED, dashboardElements: action.dashboardElements}

    
        case authType.SIGNED_OUT:
            console.log('dashboardElementsReducer, '+action.type);
            return {...state, state: type.DASHBOARD_ELEMENTS_INITIALIZED, dashboardElements:[]};

        default:
            console.log('dashboardElementsReducer, default - '+ action.type);
            return state;
    }
};
