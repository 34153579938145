import { fork, all } from 'redux-saga/effects';
import { authSagas } from './app/user';
import { businessSagas, listenerSagas } from './app/business';
import { customerSagas } from './app/customers';
import { inquirySagas } from './app/inquiries';
import { rewardSagas } from './app/rewards';
import { userPermissionSagas } from './app/userPermissions';
import { dashboardElementsSagas } from './app/dashboard';
import { analyticsSagas } from './app/analytics';
import { billingSagas } from './app/billing';
import { searchResultSagas } from 'app/search';
import { apiSettingSagas } from 'app/apiSettings';
import { pageLinksSagas } from 'app/page';
import { planSagas } from 'app/subscription';
import { productSagas } from 'app/product';

export default function* rootSaga() {
    yield all([
        ...listenerSagas,
        ...authSagas,
        ...businessSagas,
        ...customerSagas,
        ...inquirySagas,
        ...rewardSagas,
        ...userPermissionSagas,
        ...dashboardElementsSagas,
        ...analyticsSagas,
        ...billingSagas,
        ...searchResultSagas,
        ...apiSettingSagas,
        ...pageLinksSagas,
        ...planSagas,
        ...productSagas
    ]);
}