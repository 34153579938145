
import React, { Component } from 'react';

interface Props {
    emailAddress: string
  }

interface State { }

export default class EmailAddressText extends Component<Props, State> {
    render(){
        const { emailAddress } = this.props;

            return (
                <div {...this.props} >{`${emailAddress}`}</div>
            );
        }
}

