import { Business } from "app/business";
import { BusinessPageLinks } from "app/page/business";
import { PageLinks } from "app/page/pageLinks";
import { GJSTagNames } from "../grapesjsEditor";



export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.PageLinks) ;

  editor.Components.addType(GJSTagNames.PageLinks, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessPageLinks,
        attributes: {
          links: new PageLinks({}),
          location: 'header'
        },
        toolbar: [],
        traits: [
          { 
            type: 'select', 
            label: 'Type', 
            name: 'location', 
            options: [
              
              { id: 'header', name: 'Header'},
              { id: 'footer', name: 'Footer'},
            ]
          },
        ]
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.PageLinks} model initialized`);
      },
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.PageLinks.toUpperCase();
    }
  });
};
