import type from './types';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import _ from 'lodash';


export type oAuthData ={
    clientId: string;
    clientSecret: string;
    isActive: boolean;
    scope?: string[];
}

export class OAuth {
    clientId: string;
    clientSecret: string;
    isActive: boolean;
    scope?: string[];

    constructor({clientId, clientSecret, isActive, scope}: oAuthData){
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.isActive = isActive;
        this.scope = scope;
    }

    isDifferent(oAuth: OAuth){
        return _.isEqual(this, oAuth)
    }
}

export type ApiSettingData ={
    oAuth?: OAuth[]
}

export class APISetting {
    oAuth: OAuth[] = [];

    constructor({oAuth = []}: ApiSettingData){
        console.log('APISetting initialized');
        this.oAuth = oAuth;
    }

    isDifferent(apiSetting: APISetting){
        return _.isEqual(this, apiSetting);
    }

    serialize(){
        return {
            oAuth: JSON.stringify(this.oAuth)
        }
    }

    copy(apiSetting: APISetting){
        Object.assign(this, apiSetting);
    }

}


export class APISettingState {
    state: string;
    apiSetting: APISetting;
    canRotateSecret: (selectedBusiness: Business) => boolean;
    

    
    
    constructor(){
        console.log('UserPermissionState initialized');

        this.state = type.API_SETTINGS_INITIALIZED;
        this.apiSetting = new APISetting({});
   
        this.canRotateSecret = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin));
        }

    }
}
