import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchAPISettings, changeAPISecret} from './firebaseActions';
import { APISetting } from './apiSetting';

//Action creators

export const loadAPISettings = (businessId: string) => {
    console.log('apiSettings, action, loadAPISettings, businessId - '+ businessId);
    return { type: type.LOAD_API_SETTINGS, businessId }
};

export const rotateAPISecret = (businessId: string, clientId: string, onSuccess: any, onError: any) => {
    console.log('apiSettings, action, rotateAPISecret');
    return { type: type.ROTATE_API_SECRET, businessId, clientId, onSuccess, onError }
};


const APISettingsRetrieved = (apiSetting: APISetting) => {
    return { type: type.API_SETTINGS_LOADED, apiSetting }
};

const failedToRetrieveAPISettings = () => {
    return { type: type.FAILED_TO_LOAD_API_SETTINGS }
};

const APISecretUpdated = (clientId: string, clientSecret: string) => {
    return { type: type.API_SECRET_UPDATED, clientId, clientSecret}
};


// Sagas

function* loadAPISettingAsync(action: { businessId: string; }) {
    console.log('apiSettings, action, loadAPISettingAsync - '+ JSON.stringify(action));
    try {
  
        const apiSetting: APISetting = yield call(fetchAPISettings, action.businessId);
        console.log(JSON.stringify(apiSetting));
 
        yield put(APISettingsRetrieved(apiSetting));
    
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveAPISettings());
    }
}

function* rotateAPISecretAsync(action: { businessId: string; clientId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('apiSettings, action, rotateAPISecretAsync');
   
    try {
        const clientSecret: string = yield call(changeAPISecret, action.businessId, action.clientId);
        console.log(JSON.stringify(clientSecret));
 
        yield put(APISecretUpdated(action.clientId, clientSecret));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

export const apiSettingSagas = [
    takeEvery<any>(type.LOAD_API_SETTINGS, loadAPISettingAsync),
    takeEvery<any>(type.ROTATE_API_SECRET, rotateAPISecretAsync),

];
