import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns'
import { Spinner } from '../shared/Spinner';
import InvoiceDialog from './invoiceDialog';
import { loadPastPayments } from "./actions";
import types from './types';

export class PaymentList extends Component {
  
  constructor(props){
    super(props);
    console.log('PaymentList initialized');
  
    this.state = {
      editable: this.props.billing.canCreate(this.props.business.selectedBusiness),
      showInvoiceDialog: false,
      selectedTransaction: null,
    }
    this.props.loadPastPayments(this.props.businessId);
  }

  render(){

    let component = null;
    if(this.props.billing.payment.pastPayments.isUpdated){
      if (this.props.billing.payment.pastPayments.pastTransactions.length > 0){
        component = this.renderPaymentList();
      }else{
        component = this.renderPaymenDoesNotExist();
      }
    }else{
      component = <Spinner/>;
    }
    return component;
  }


  renderPaymentList() {
    let self = this;
    let transactionList = [];
    let pastPayments = this.props.billing.payment.pastPayments;
    pastPayments.pastTransactions.map((items)=>{
        items.transactions.map((transaction) =>{
          transactionList.push(transaction);
        });
    });
  
    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card border-0">
              <div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th> Usage Period </th>
                        <th> Total </th>
                        <th className="d-none d-md-table-cell"> Due Date </th>
                        <th className="d-none d-md-table-cell"> Payment Status </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        [...transactionList].sort((a, b)=> b.period - a.period).map((transaction, index)=>{
                          return (
                            <tr key={index} style={{cursor: 'pointer'}} onClick={()=> this.onShowInvoiceDialog(transaction)}>
                              <td className="d-none d-md-table-cell"> <a> From {format (transaction.period, 'MMM d')} To {format (new Date(transaction.period.getUTCFullYear(), transaction.period.getUTCMonth()+1, 0), 'MMM d yyyy')}  </a></td>
                              <td className="d-md-none"> 
                                <a> <strong> From {format (transaction.period, 'MMM d')} To {format (new Date(transaction.period.getUTCFullYear(), transaction.period.getUTCMonth()+1, 0), 'MMM d yyyy')} </strong> </a>
                                <br/>
                                <br/>
                                <a>Due Date: {format (transaction.dueDate, 'MMM d yyyy')}</a>
                                <br/>
                                <a>Payment Status: Not required</a>
                              </td>
                              <td> <a>$ {transaction.total}</a> </td>
                              <td className="d-none d-md-table-cell"> <a>{format (transaction.dueDate, 'MMM d yyyy')}</a></td>
                              <td className="d-none d-md-table-cell"> Not required </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>      
              </div>
            </div>
          </div>
        </div>
        <InvoiceDialog key={this.state.selectedTransaction?this.state.selectedTransaction.period.getDate():null} show={this.state.showInvoiceDialog} transaction={this.state.selectedTransaction} onHide={() => this.setState({showInvoiceDialog: false, selectedTransaction: null})} onCancel={() => this.setState({showInvoiceDialog: false, selectedTransaction: null})}/>
        </div>
    )
  }

  renderPaymenDoesNotExist() {
    let self = this;
    let transactionList = [];
    let pastPayments = this.props.billing.payment.pastPayments;
    pastPayments.pastTransactions.map((items)=>{
        items.transactions.map((transaction) =>{
          transactionList.push(transaction);
        });
    });
  
    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                  <h4 className="my-4"> Past payments does not exist </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
  }
  

  onShowInvoiceDialog(transaction){
    
    this.setState({showInvoiceDialog: true, selectedTransaction: transaction});
  }

}


const mapStateToProps = (state) => {
  return { 
    auth: state.auth,
    business: state.business,
    billing: state.billing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPastPayments: (businessId) =>{
      dispatch(loadPastPayments(businessId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);

