import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState, type as AuthStateType} from './user'
import { BusinessState, type as BusinessStateType } from './business'

interface SecuredRouteProps {
  exact: true,
  path: string;
  component: React.ComponentType<any>; 
}
interface Props extends SecuredRouteProps {
  auth: AuthState,
  business: BusinessState
}

const _SecuredRoute = ({auth, business, component: Component, ...rest }: Props) => {
  console.log(`SecuredRoute ... auth state - ${auth?.state}, path- ${rest.path}`);
  
  return ( 
    <Route  {...rest} render={(props) => (
      getComponent(props, auth, business, Component)
    )} />
  );
}

const getComponent = (props: any, auth: AuthState, business: BusinessState, Component: React.ComponentType<any>) => {
  let render;

  if (auth.state !== AuthStateType.SIGNED_IN){
    console.log(`redirect - ${JSON.stringify(props.location)}`);
    render = <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

  }else if (!auth.userSession.emailVerified){
    render = <Redirect to='/activateAccount' />

  }else if (business.state !== BusinessStateType.LOADED){
    render = <Redirect to={{ pathname: '/selectBusiness', state: { from: props.location } }} />
    
  }else{
    render = <Component {...props} />
  }
  return render;
}

const mapStateToProps = (state: { auth: AuthState; business: BusinessState; }) => {
  return {
    auth: state.auth,
    business: state.business
  }
}

let X = connect(mapStateToProps, null)(_SecuredRoute) as any;

const SecuredRoute = (props: SecuredRouteProps) => {
  return (

    <X {...props} />
  );
}
export default SecuredRoute;