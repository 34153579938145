

import firebase, { firestore } from '../firebase.js';
import { Inquiry, InquiryData } from './inquiry';
import {isChars, isNumbers, isEmailAddress } from '../shared'; 

export async function fetchInquiryList(businessId: string, afterTimestamp=0, searchText=''){

  console.log('inquiry, firebaseActions, fetchInquiryList, businessId - '+ businessId);

  let inquiryList: Inquiry[] = [];
  let inquiryCollectionRef =  firestore.collection('businesses').doc(businessId).collection('inquiries');;

  let inquiryCollectionQuery = inquiryCollectionRef.orderBy('inquiryDate', 'desc');

  if (afterTimestamp != 0){
    inquiryCollectionQuery  = inquiryCollectionQuery.startAfter(afterTimestamp);
  }

  if (searchText != ''){

    if (isChars(searchText)){
      inquiryCollectionQuery = inquiryCollectionQuery.where('name', '==', searchText);
    }else if (isNumbers(searchText)){
      inquiryCollectionQuery = inquiryCollectionQuery.where('phoneNumber', '==', searchText);
    }else if (isEmailAddress(searchText)){
      inquiryCollectionQuery = inquiryCollectionQuery.where('emailAddress', '==', searchText);
    }
  }  
  
  let inquiryCollection =  await inquiryCollectionQuery.limit(10).get(); 
  
  if (inquiryCollection.docs.length > 0){
      console.log('inquiry, firebaseActions');
      
      inquiryCollection.forEach((doc)=>{
        let inquiry = new Inquiry(doc.data() as InquiryData);
        inquiry.inquiryId = doc.id;
        inquiryList.push(inquiry);
      });

  }else{
      console.log(`inquiry, firebaseActions, fetchInquiryList, inquiry list is empty for businessId - ${businessId}`);
  }

  return inquiryList;
}

export async function createInquiry(businessId: string, inquiry: Inquiry){

  console.log('firebaseActions, createInquiry, businessId - '+ businessId);

  let inquiryCollectionRef =  firestore.collection('businesses').doc(businessId).collection('inquiries');
  //const FieldValue = firestore.FieldValue;

  //inquiry.inquirySince = FieldValue.serverTimestamp();
  inquiry.inquiryDate = new Date().getTime();
  let data = inquiry.serialize();
  console.log(JSON.stringify(data));
  let res = await inquiryCollectionRef.add(data);
  inquiry.inquiryId = res.id;

  return inquiry;
}

export async function updateInquiry(businessId: string, inquiry: Inquiry){

  console.log('firebaseActions, updateInquiry, businessId - '+ businessId);

  let inquiryCollectionRef =  firestore.collection('businesses').doc(businessId).collection('inquiries');
  let inquiryRef = inquiryCollectionRef.doc(inquiry.inquiryId);
  let data = inquiry.serialize();
  console.log(JSON.stringify(data));
  await inquiryRef.update(data);
  return inquiry;
}

export async function deleteInquiry(businessId: string, inquiry: Inquiry){

  console.log('firebaseActions, deleteInquiry, businessId - '+ businessId);

  let inquiryCollectionRef =  firestore.collection('businesses').doc(businessId).collection('inquiries');
  let inquiryRef = inquiryCollectionRef.doc(inquiry.inquiryId);

  await inquiryRef.delete();
  return inquiry;
}
