
import { Component } from 'react';
import { Img } from 'app/shared';

interface Props {
     source: string,
}


export default class BusinessProfileIcon extends Component<Props> {
   
    constructor(props: Props){
        super(props);
    }

    render(){

        return (
            
            <Img src={this.props.source}  fallbackSrc={'https://via.placeholder.com/150?text=profile'} alt="profile" className="marketingCardImg" style={{width:120, height:120}}/>
        );
    }
}

