import type from './types';
import { fetchCurrentEstimation, fetchPastPayments } from './firebaseActions';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';



export class Payment {
    currentEstimation: CurrentEstimation;
    pastPayments: PastPayments; 

    constructor(){
        console.log('Payment initialized');

        this.currentEstimation = new CurrentEstimation({});
        this.pastPayments = new PastPayments({});
    }
}

export enum TransactionStatus{
    active = 'active',
    suspended = 'suspended'
}

export class Transaction{
    period: Date;
    dueDate: Date | 0;
    plan: string;
    skus: SKU[];
    subTotal: number;
    tax: number;
    total: number;
    status: TransactionStatus;
    statusReason: string;
    constructor(transaction: any){
        this.period = transaction.period?.toDate() || new Date();
        this.dueDate = 0;
        this.plan = transaction.plan || '';
        this.skus = [];
        this.subTotal = transaction.subTotal || 0;
        this.tax = transaction.tax || 0;
        this.total = transaction.total || 0;
        this.status = transaction.status || TransactionStatus.active;
        this.statusReason = transaction.statusReason || '';

        for (let key in transaction.skus){
            let value = transaction.skus[key];

            let skuStatus: SKUStatus = SKUStatus.active;
            if (this.status === TransactionStatus.suspended ){
                skuStatus = SKUStatus.suspended;
            }else if (value.units >= value.freeUnits){
                skuStatus = SKUStatus.maxLimitReached;
            }

            this.skus.push(SKU.create({name: key as SKUName, 
                                    status: skuStatus,
                                    displayName: value.displayName, 
                                    displayPosition: value.displayPosition,  
                                    aggregated: value.aggregated,
                                    showInBilling: value.showInBilling,
                                    freeUnits: value.freeUnits, 
                                    unitCost: value.unitCost, 
                                    units: value.units,
                                    cost: value.cost}));
        }

        var now = this.period;
        if (now.getMonth() == 11) {
            this.dueDate = new Date(now.getFullYear() + 1, 0, 1);
        } else {
            this.dueDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        }
    }
}

export enum SKUStatus{
    active = 'active',
    maxLimitReached = 'maxLimitReached',
    suspended = 'suspended'
}

export enum SKUName {
    customers = 'customers',
    products = 'products',
    orders = 'orders',
    inquiries = 'inquiries',
    rewards = 'rewards',
    staffAccounts = 'staffAccounts',
    onlinestore = 'onlinestore',
    pages = 'pages',
    storage = 'storage',
    domains = 'domains',
    imageFileSize = 'imageFileSize',
    dummy = 'dummy'
}

export type SKUData = {
    name?: SKUName;
    status?: SKUStatus;
    displayName?: string;
    displayPosition?: number;
    aggregated?: boolean;
    showInBilling?: boolean;
    unitCost?: number;
    freeUnits?: number;
    units?: number;
    cost?: number;
}

export class SKU{
    name: SKUName;
    status: SKUStatus;
    displayName: string;
    displayPosition: number;
    aggregated: boolean;
    showInBilling: boolean;
    unitCost: number;
    freeUnits: number;
    units: number;
    cost: number;

    constructor({name, status, displayName, displayPosition, aggregated, showInBilling, cost, freeUnits, unitCost, units}: SKUData){
        this.name = name || SKUName.dummy;
        this.status = status || SKUStatus.suspended;
        this.displayName = displayName || ''
        this.displayPosition = displayPosition || 1;
        this.aggregated = aggregated || false;
        this.showInBilling = showInBilling || false;
        this.freeUnits = freeUnits || 0;
        this.unitCost = unitCost || 0;
        this.units = units || 0;
        this.cost = cost || 0;
    }

    static create(skuData: SKUData){
        let sku: SKU;
        switch(skuData.name){
            case SKUName.customers:
                sku = new CustomersSKU(skuData);
                break;
            case SKUName.inquiries:
                sku= new InquiriesSKU(skuData);
                break;
            case SKUName.pages:
                sku= new PagesSKU(skuData);
                break;
            case SKUName.storage:
                sku= new StorageSKU(skuData);
                break;
            case SKUName.domains:
                sku= new DomainsSKU(skuData);
                break;
            case SKUName.staffAccounts:
                sku= new StaffAccountsSKU(skuData);
                break;
            case SKUName.imageFileSize:
                sku= new ImageFileSizeSKU(skuData);
                break;
            default:
                sku = new SKU(skuData);
                break;
        }
        return sku;
    }

    getMaxLimitDisplayMessage(){
        let message = {line1: 'You have reached maximum allowed limit.', line2: 'Upgrade your plan to increate the limit.'};
        return message;
    }

    getFreeUnitsDisplayText(){
        return `${this.freeUnits}`;
    }

    getUnitsDisplayText(){
        return `${this.units}`;
    }
}

export class CustomersSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached maximum number of customers (${this.freeUnits}) allowed for the current plan.`;
        message.line2 = `Upgrade your plan to increate the limit.`;
        return message;
    }
}

export class InquiriesSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached maximum number of inquiries (${this.freeUnits}) allowed for this month in the current plan.`
        message.line2 = `Upgrade your plan to increate the limit.`
        return message;
    }
}

export class PagesSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached maximum number of pages (${this.freeUnits}) allowed for the current plan.`
        message.line2 = `Upgrade your plan to create additional pages.`
        return message;
    }
}

export class StorageSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached the storage limit of ${this.getFreeUnitsDisplayText()} allowed for the current plan.`
        message.line2 = `Upgrade your plan to increate the storage size.`
        return message;
    }

    getFreeUnitsDisplayText(){
        let KB = 1024;
        let MB = KB * 1000;
        let GB = MB * 1000;
        let TB = GB * 1000;
        if (this.freeUnits < TB){
            return `${this.freeUnits/1000000000} GB`;
        }else{
            return 'Unlimited';
        }
    }

    getUnitsDisplayText(){
        let KB = 1024;
        let MB = KB * 1000;
        let GB = MB * 1000;
        if (this.units < KB){
            return `${Math.round(this.units)} Bytes`;
        }else if (this.units > KB && this.units < MB ){
            return `${Math.round(this.units/1000)} KB`;
        }else if (this.units > KB && this.units < GB ){
            return `${Math.round(this.units/1000000)} MB`;
        }else {
            return `${Math.round(this.units/1000000000)} GB`;
        }
    }
}

export class DomainsSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached maximum number of domains ${this.freeUnits} allowed for the current plan.`
        message.line2 = `Upgrade your plan to add additional domains.`
        return message;
    }
}

export class StaffAccountsSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `You have reached maximum number of staff accounts ${this.freeUnits} allowed for the current plan.`
        message.line2 = `Upgrade your plan to add additional staff accounts.`
        return message;
    }
}

export class ImageFileSizeSKU extends SKU{

    getMaxLimitDisplayMessage(){
        let message = {line1: '', line2: ''};
        message.line1 = `The file size exceeds the allowed limit of ${this.getFreeUnitsDisplayText()} for the current plan.`
        message.line2 = ``
        return message;
    }

    getFreeUnitsDisplayText(){
        return `${this.freeUnits/1000000} MB`;
    }

    getUnitsDisplayText(){
        let KB = 1024;
        let MB = KB * 1000;
        let GB = MB * 1000;
        if (this.units < KB){
            return `${this.units} Bytes`;
        }else if (this.units > KB && this.units < GB ){
            return `${this.units/1000000} MB`;
        }else {
            return `${this.units/1000000000} GB`;
        }
    }
}



export class CurrentEstimation{
    transaction: Transaction;
    isUpdated: boolean;
    static listenerOn: boolean = false;
    static subscribe: any;

    constructor({transaction, isUpdated}: {transaction?: Transaction, isUpdated?: boolean}){
        this.transaction = transaction || new Transaction({});
        this.isUpdated = isUpdated || false;
    }

    static async fetch(businessId: string){
        let transaction = await fetchCurrentEstimation(businessId);
        return new CurrentEstimation({transaction:new Transaction(transaction), isUpdated: true});
    }

    copy(currentEstimation: CurrentEstimation){
        Object.assign(this, currentEstimation);
    }

}

export class PastPayments{
    pastTransactions: any;
    isUpdated: boolean;

    constructor({pastTransactions, isUpdated}: {pastTransactions?: any, isUpdated?: boolean}){
       
        this.pastTransactions = pastTransactions|| [];
        this.isUpdated = isUpdated || false;
    }

    static async fetch(businessId: string){
        let payments = await fetchPastPayments(businessId);
        let pastTransactions: any[] = [];

        payments.map((yearlyTransactions )=>{
            let transactionList = [];
            for (const month in yearlyTransactions.data){
                let transaction = yearlyTransactions.data[month];
                transactionList.push(new Transaction(transaction));
            }
            pastTransactions.push({year: yearlyTransactions.year, transactions: transactionList});
        });

        return new PastPayments({pastTransactions: pastTransactions, isUpdated: true});
    }

    copy(pastPayments: PastPayments){
        Object.assign(this, pastPayments);
    }
}


export class PaymentState {
    state: string;
    payment: Payment;
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: Business) => boolean;
    getSKU: (skuName: SKUName) => SKU;

    constructor(){
        console.log('PaymentState initialized');

        this.state = type.PAYMENT_INITIALIZED;
        this.payment = new Payment();

        this.canCreate = function(selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canEdit = function (selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.getSKU = function(skuName: SKUName){
            let sku =  this.payment.currentEstimation.transaction.skus.find(sku => sku.name === skuName);
            if (!sku){
                sku = SKU.create({name: SKUName.dummy, status: SKUStatus.suspended, displayName: 'Dummy', displayPosition: 0, aggregated: false, showInBilling: false, freeUnits: 0, unitCost:0, units:0 });
            }
            return sku
        }
    }
}
