import { Product } from 'app/product';
import { Img } from 'app/shared';
import { Component } from 'react';

import 'app/product/product.css';

export interface Props {
  product: Product,
}

export interface State {

}

export class ProductListItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductListItem initialized');
  }

  render() {

    let {productId, name, description, picture, price, comparePrice, showPrice, showVariants} = this.props.product;
      console.log(`picture url - ${picture}, price - ${price}`);
      return (
        <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12 product-item stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="action-holder">
                <div className="d-none sale-badge bg-success">New</div>
              </div>
              <div className="product-img-outer">
                <Img className="product_image" key={Date.now()} src={picture} fallbackSrc='https://via.placeholder.com/92x92' alt="product"/>
              </div>
              <p className="product-title">{name}</p>
              <p className="product-price">{showPrice?price:''}</p>
              <p className="product-actual-price">{showPrice?(comparePrice>price?comparePrice:''):''}</p>
              { showVariants
              ?(
                <ul className="product-variation">
                  <li><a href="!#" onClick={event => event.preventDefault()}>M</a></li>
                  <li><a href="!#" onClick={event => event.preventDefault()}>L</a></li>
                  <li><a href="!#" onClick={event => event.preventDefault()}>XL</a></li>
                </ul>
              ):null
              }
              <p className="product-description">{description}</p>
            </div>
          </div>
        </div>
      );
  }
}
