import React, { Component } from 'react';
import { DeleteDialog } from '../shared';
import InquiryInputDialog from './inquiryInputDialog';
import './inquiries.css';
import { Inquiry, InquiryStatus } from './inquiry';
import { InquiryListItem } from './inquiryListItem';

export interface Props {
  businessId: string,
  inquiryList: Inquiry[],
  canEdit: boolean,
}

export interface State {
  showInquiryDialog: boolean,
  showDeleteDialog: boolean,
  selectedInquiry: Inquiry | null | undefined
}

export class InquiryList extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('InquiryList initialized');

    this.state = {
      showInquiryDialog: false,
      showDeleteDialog: false,
      selectedInquiry: null,
    }
  }

  render() {
    let { businessId, inquiryList, canEdit } = this.props;

    return (
      <div>
        {
          inquiryList.map((item)=>{
            return (
              <InquiryListItem 
                businessId={businessId}
                inquiry={item}
                canEdit={canEdit}
                onDelete={this.onDelete.bind(this)}
              />
            );
          })
        }
      </div>
    )
  }

  onDelete(inquiryId: string){
    //inquiry is deleted, do any clean up if needed.
 }
}
