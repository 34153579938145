import { User, UserSession } from "./user";

const INITIALIZED = 'INITIALIZED';
const SIGNED_IN = 'SIGNED_IN';
const SIGN_IN_PROGRESS = 'SIGN_IN_PROGRESS';
const SIGNED_OUT = 'SIGNED_OUT';

const INITIATE_SIGN_IN = 'INITIATE_SIGN_IN';
const INITIATE_SIGN_IN_WITH_GOOGLE = 'INITIATE_SIGN_IN_WITH_GOOGLE';
const INITIATE_SIGN_OUT = 'INITIATE_SIGN_OUT';

const USER_SIGNED_IN = 'USER_SIGNED_IN';
const USER_SIGNED_OUT = 'USER_SIGNED_OUT';

const INITIATE_UPDATE_USER = 'INITIATE_UPDATE_USER';

const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';

const INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET';
const PASSWORD_RESET_IN_PROGRESS = 'PASSWORD_RESET_IN_PROGRESS';
const PASSWORD_RESET_EMAIL_SENT = 'PASSWORD_RESET_EMAIL_SENT';
const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';

const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
const UPDATE_PASSWORD_IN_PROGRESS = 'UPDATE_PASSWORD_IN_PROGRESS';
const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

const INITIATE_SIGN_UP = 'INITIATE_SIGN_UP';
const SIGN_UP_IN_PROGRESS = 'SIGN_UP_IN_PROGRESS';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

const IS_ACOUNT_ACTIVATED = 'IS_ACOUNT_ACTIVATED';
const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION';
const UPDATE_USER = 'UPDATE_USER';
const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
const SEND_EMAIL_VERIFICATION_FAILED = 'SEND_EMAIL_VERIFICATION_FAILED';

const INVALID_EMAIL = 'The email and password that you entered did not match our records. Please check and try again.';
const USER_DISABLED = 'Your account is not active. Please contact us to activate.';
const USER_NOT_FOUND = 'We could not find your account.';
const WRONT_PASSWORD = 'The email and password that you entered did not match our records. Please check and try again.';
const FAILED_TO_SIGNIN = 'We could not sign you in now. Please try again after sometime.';
const PASSWORD_RESET_ERROR = 'We could not reset your password now. Please try again after sometime.';
const PASSWORD_RESET_CODE_EXPIRED = 'Password reset link expired. Please reset password again.';
const PASSWORD_RESET_CODE_INVALID = 'Password reset link not valid. Please reset password again.';
const WEAK_PASSWORD = 'Password is too weak. Please provide stong password.';
const EMAIL_ALREADY_IN_USE = 'Email is already in use.';
const OPERATION_NOT_ALLOWED = 'Email not enabled for signup. Please contact us to enable.';
const FAILED_TO_SIGNUP = 'Cannot complete your sign up now. Please try again after sometime.';
const FAILED_TO_SEND_EMAIL_VERIFICATION = 'Error sending activation email. Please try again after sometime.'

export default {
    INITIALIZED,
    SIGN_IN_PROGRESS,
    SIGNED_IN,
    SIGNED_OUT,

    INITIATE_SIGN_IN,
    INITIATE_SIGN_IN_WITH_GOOGLE,
    INITIATE_SIGN_OUT,

    USER_SIGNED_IN,
    USER_SIGNED_OUT,

    INITIATE_UPDATE_USER,
    
    SIGN_IN_FAILED,
    SIGN_OUT_FAILED,

    INITIATE_PASSWORD_RESET,
    PASSWORD_RESET_IN_PROGRESS,
    PASSWORD_RESET_EMAIL_SENT,
    PASSWORD_RESET_FAILED,

    UPDATE_PASSWORD,
    UPDATE_PASSWORD_IN_PROGRESS,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,

    INITIATE_SIGN_UP,
    SIGN_UP_IN_PROGRESS,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILED,

    IS_ACOUNT_ACTIVATED,
    SEND_EMAIL_VERIFICATION,
    UPDATE_USER,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAILED,
    
    INVALID_EMAIL,
    USER_DISABLED,
    USER_NOT_FOUND,
    WRONT_PASSWORD,
    FAILED_TO_SIGNIN,
    PASSWORD_RESET_ERROR,
    PASSWORD_RESET_CODE_EXPIRED,
    PASSWORD_RESET_CODE_INVALID,
    WEAK_PASSWORD,
    EMAIL_ALREADY_IN_USE,
    OPERATION_NOT_ALLOWED,
    FAILED_TO_SIGNUP,
    FAILED_TO_SEND_EMAIL_VERIFICATION

}

export class SignInCancelled extends Error{

    static  SIGN_IN_CANCELLED = 'SIGN_IN_CANCELLED';
    constructor(){
        super();
        this.name = SignInCancelled.SIGN_IN_CANCELLED;

    }
}

export class SignInFailed extends Error{

    static  SIGN_IN_FAILED = 'SIGN_IN_FAILED';
    constructor(message: string ){
        super(message);
        this.name = SignInFailed.SIGN_IN_FAILED;
        this.message = this.message;
    }
}

export class SignOutFailed extends Error{

    static  SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';
    constructor(){
        super();
        this.name = SignOutFailed.SIGN_OUT_FAILED;

    }
}

export interface AuthAction {
    type: string;
    user?: User;
    userSession? : UserSession;
    email?: string;
    password?: string;
    code? : any;
    newPassword? : string;
    errorMessage? : string;
    isNewUser? : boolean;
    onSuccess? : () => void;
    onError? : () => void;
}