

import firebase, { firestore } from '../firebase.js';
import {TotalCountData, YearlyCount } from './analytics.js';

export async function fetchTotalCount(businessId: string){

  console.log('TotalCount, firebaseActions, fetchTotalCount, businessId - '+ businessId);

  let totalCount: TotalCountData = {customers: 0, inquiries: 0, isUpdated: false};
  let totalCountRef =  firestore.collection('businesses').doc(businessId).collection('analytics').doc('totalCount');

  let totalCountDoc = await totalCountRef.get();
  
  if (totalCountDoc.exists){
    console.log('totalCountDoc, firebaseActions');
    totalCount = totalCountDoc.data () as TotalCountData;

     
  }else{
      console.log(`TotalCount, firebaseActions, fetchTotalCount, total count field not found for businessId - ${businessId}`);
  }

  return totalCount;
}


export async function fetchCount(businessId: string, analyticsType: string){

  console.log('AnalyticsCount, firebaseActions, fetchCount, businessId - '+ businessId);

  let yearlyCountCollection: YearlyCount[] = [];
  let yearlyCountCollectionPromise: Promise<any>[] = [];
  let countYearlyRef =  firestore.collection('businesses').doc(businessId).collection('analytics').doc(analyticsType).collection('yearly');

  let countCollection = await countYearlyRef.get();
  
  if (countCollection.docs.length > 0){
    console.log('AnalyticsCount, firebaseActions');
    
    countCollection.forEach(async (doc)=>{
      yearlyCountCollectionPromise.push(fetchCountByYear(businessId, analyticsType, doc.id));
    });

    yearlyCountCollection = await Promise.all(yearlyCountCollectionPromise);

  }else{
      console.log('AnalyticsCount, firebaseActions, fetchCount, customer count not found');
  }

  return yearlyCountCollection;
}

export async function fetchCountByYear(businessId: string, analyticsType: string, year: string){
  console.log('AnalyticsCount, firebaseActions, fetchCountByYear, businessId - '+ businessId);

  let yearlyCount: any;
  let countRef = firestore.collection('businesses').doc(businessId).collection('analytics').doc(analyticsType).collection('yearly').doc(year);

  let countDoc = await countRef.get();
  if (countDoc.exists){
    yearlyCount = {...{year: year}, ...countDoc.data()};
  }else{
    console.log('AnalyticsCount, firebaseActions, businessId not found');
  }
  return yearlyCount;
}


