import { put, takeEvery, call } from 'redux-saga/effects';

import type, { ErrorCode } from './types';
import { fetchCustomerList, createCustomer, updateCustomer, deleteCustomer } from './firebaseActions';
import { Customer } from './customer';

//Action creators

export const loadCustomers = (businessId: string, searchText='') => {
    console.log('customers, action, loadCustomers, businessId - '+ businessId);
    return { type: type.LOAD_CUSTOMERS, businessId, searchText }
};

export const loadMoreCustomers = (businessId: string, afterTimestamp=0, searchText='') => {
    console.log('customers, action, loadMoreCustomers, businessId - '+ businessId);
    return { type: type.LOAD_MORE_CUSTOMERS, businessId, afterTimestamp, searchText }
};

export const initiateCreateCustomer = (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateCustomer');
    return { type: type.INITIATE_CREATE_CUSTOMER, businessId, customer, onSuccess, onError }
};

export const initiateSaveCustomer = (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
    console.log('user, action, initiateSaveCustomer');
    let action = null;
    if (customer.customerId != ''){
        action = { type: type.INITIATE_UPDATE_CUSTOMER, businessId, customer, onSuccess, onError };
    }else{
        action = { type: type.INITIATE_CREATE_CUSTOMER, businessId, customer, onSuccess, onError };
    }
    return action;
};

export const initiateDeleteCustomer = (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateCustomer');
    return { type: type.INITIATE_DELETE_CUSTOMER, businessId, customer, onSuccess, onError }
};

const retrievedCustomerList = (customerList: Customer[]) => {
    return { type: type.CUSTOMERS_LOADED, customerList }
};

const retrievedMoreCustomerList = (customerList: Customer[]) => {
    return { type: type.MORE_CUSTOMERS_LOADED, customerList }
};

const loadCustomerList = () => {
    return { type: type.LOAD_CUSTOMERS }
};

const emptyCustomerList = () => {
    return { type: type.CUSTOMER_LIST_EMPTY }
};

const failedToRetrieveCustomerList = () => {
    return { type: type.FAILED_TO_LOAD_CUSTOMERS }
};

const customerCreated = (customer: Customer) => {
    return { type: type.CUSTOMER_CREATED, customer}
};

const customerUpdated = (customer: Customer) => {
    return { type: type.CUSTOMER_UPDATED, customer}
};

const customerDeleted = (customer: Customer) => {
    return { type: type.CUSTOMER_DELETED, customer}
};

// Sagas

function* loadCustomersAsync(action: { businessId: any; searchText: string | undefined; }) {
    console.log('customer, action, loadCustomersAsync - '+ JSON.stringify(action));
    try {

        const customerList: Customer[] = yield call(fetchCustomerList, action.businessId, 0, action.searchText);
        if (customerList.length > 0){
            yield put(retrievedCustomerList(customerList));
        }else{
            yield put(emptyCustomerList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveCustomerList());
    }
}

function* loadMoreCustomersAsync(action: { businessId: any; afterTimestamp: number | undefined; searchText: string | undefined; }) {
    console.log('customer, action, loadMoreCustomersAsync - '+ JSON.stringify(action));
    try {

        const customerList: Customer[] = yield call(fetchCustomerList, action.businessId, action.afterTimestamp, action.searchText);
        if (customerList.length > 0){
            yield put(retrievedMoreCustomerList(customerList));
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
    }
}

function* createCustomerAsync(action: { customer: Customer; businessId: string; onSuccess: () => void; onError: (customer: Customer, errorCode: ErrorCode) => void; }) {
    console.log('customer, action, createCustomerAsync');
   
    try {
        let customer: Customer = yield call(createCustomer, action.businessId, action.customer);
        yield put(customerCreated(customer));
        action.onSuccess();
    } catch (error: any) {
        console.log(`customer, action, createCustomerAsync, failed to create, error-${error.response.status}`)
        console.log(JSON.stringify(error));
        if (error.response.status){
            action.onError(action.customer, ErrorCode.CUSTOMER_ALREADY_EXISTING);
        }else{
            action.onError(action.customer, ErrorCode.FAILED_TO_CREATE);
        }
    }
}

function* updateCustomerAsync(action: { customer: Customer; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('customer, action, updateCustomerAsync');
   
    try {
        let customer: Customer = yield call(updateCustomer, action.businessId, action.customer);
        yield put(customerUpdated(customer));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteCustomerAsync(action: { customer: Customer; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('customer, action, deleteCustomerAsync');
   
    try {
        yield call(deleteCustomer, action.businessId, action.customer);
        yield put(customerDeleted(action.customer));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const customerSagas = [
    takeEvery<any>(type.LOAD_CUSTOMERS, loadCustomersAsync),
    takeEvery<any>(type.LOAD_MORE_CUSTOMERS, loadMoreCustomersAsync),
    takeEvery<any>(type.INITIATE_CREATE_CUSTOMER, createCustomerAsync),
    takeEvery<any>(type.INITIATE_UPDATE_CUSTOMER, updateCustomerAsync),
    takeEvery<any>(type.INITIATE_DELETE_CUSTOMER, deleteCustomerAsync)
];
