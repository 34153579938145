import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchSearchResultList } from './firebaseActions';
import { SearchRequest, SearchResult } from './searchResult';

//Action creators

export const loadSearchResults = (businessId: string, searchText='') => {
    console.log('searchResults, action, loadSearchResults, businessId - '+ businessId + ' searchText - '+ searchText);
    return { type: type.LOAD_SEARCH_RESULTS, businessId, searchText }
};

export const loadMoreSearchResults = (businessId: string, afterTimestamp=0, searchText='') => {
    console.log('searchResults, action, loadMoreSearchResults, businessId - '+ businessId + ' searchText - '+ searchText);
    return { type: type.LOAD_MORE_SEARCH_RESULTS, businessId, afterTimestamp, searchText }
};


const retrievedSearchResultList = (searchResultList: SearchResult[]) => {
    return { type: type.SEARCH_RESULTS_LOADED, searchResultList }
};

const retrievedMoreSearchResultList = (searchResultList: SearchResult[]) => {
    return { type: type.MORE_SEARCH_RESULTS_LOADED, searchResultList }
};

const loadSearchResultList = () => {
    return { type: type.LOAD_SEARCH_RESULTS }
};

const emptySearchResultList = () => {
    return { type: type.SEARCH_RESULT_LIST_EMPTY }
};

const failedToRetrieveSearchResultList = () => {
    return { type: type.FAILED_TO_LOAD_SEARCH_RESULTS }
};


// Sagas

function* loadSearchResultsAsync(action: { businessId: string, searchText: string }) {
    console.log('searchResult, action, loadSearchResultsAsync - '+ JSON.stringify(action));
    try {

        const searchResultList: SearchResult[] = yield call(fetchSearchResultList, new SearchRequest(action.businessId, action.searchText));
        if (searchResultList.length > 0){
            yield put(retrievedSearchResultList(searchResultList));
        }else{
            yield put(emptySearchResultList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveSearchResultList());
    }
}

function* loadMoreSearchResultsAsync(action: { businessId: string, afterTimestamp: number, searchText: string}) {
    console.log('searchResult, action, loadMoreSearchResultsAsync - '+ JSON.stringify(action));
    try {

        const searchResultList: SearchResult[] = yield call(fetchSearchResultList, new SearchRequest(action.businessId, action.searchText));
        if (searchResultList.length > 0){
            yield put(retrievedMoreSearchResultList(searchResultList));
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
    }
}

export const searchResultSagas = [
    takeEvery<any>(type.LOAD_SEARCH_RESULTS, loadSearchResultsAsync),
    takeEvery<any>(type.LOAD_MORE_SEARCH_RESULTS, loadMoreSearchResultsAsync),
];
