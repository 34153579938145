
const INITIALIZED = 'INITIALIZED';
const LOAD_BUSINESS = 'LOAD_BUSINESS';
const LOADED = 'LOADED';
const EMPTY = 'EMPTY';
const FAILED_TO_LOAD = 'FAILED_TO_LOAD';

const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';
const BUSINESS_SELECTED = 'BUSINESS_SELECTED';


const INITIATE_CREATE_BUSINESS = 'INITIATE_CREATE_BUSINESS';
const BUSINESS_CREATED = 'BUSINESS_CREATED';

const INITIATE_UPDATE_BUSINESS = 'INITIATE_UPDATE_BUSINESS';
const BUSINESS_UPDATED = 'BUSINESS_UPDATED';

const INITIATE_DELETE_BUSINESS = 'INITIATE_DELETE_BUSINESS';
const BUSINESS_DELETED = 'BUSINESS_DELETED';

const INITIATE_UPDATE_BUSINESS_HOURS = 'INITIATE_UPDATE_BUSINESS_HOURS';
const BUSINESS_HOURS_UPDATED='BUSINESS_HOURS_UPDATED';

const INITIATE_UPDATE_BUSINESS_SOCIAL_LINKS = 'INITIATE_UPDATE_BUSINESS_SOCIAL_LINKS';
const BUSINESS_SOCIAL_LINKS_UPDATED='BUSINESS_SOCIAL_LINKS_UPDATED';



export default {
    INITIALIZED,
    LOAD_BUSINESS,
    LOADED,
    EMPTY,
    FAILED_TO_LOAD,
    SET_SELECTED_BUSINESS,
    BUSINESS_SELECTED,

    INITIATE_CREATE_BUSINESS,
    BUSINESS_CREATED,
    
    INITIATE_UPDATE_BUSINESS,
    BUSINESS_UPDATED,

    INITIATE_DELETE_BUSINESS,
    BUSINESS_DELETED,

    INITIATE_UPDATE_BUSINESS_HOURS,
    BUSINESS_HOURS_UPDATED,

    INITIATE_UPDATE_BUSINESS_SOCIAL_LINKS,
    BUSINESS_SOCIAL_LINKS_UPDATED
};

export enum ErrorCode {
    ALREADY_EXISTING = '409',
    FAILED_TO_CREATE = '0'
}