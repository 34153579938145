import { put, takeEvery, call } from 'redux-saga/effects';

import type, { ErrorCode } from './types';
import { fetchRewardList, createReward, updateReward, deleteReward } from './firebaseActions';
import { Reward } from './reward';

//Action creators

export const loadRewards = (businessId: string, searchText='') => {
    console.log('rewards, action, loadRewards, businessId - '+ businessId);
    return { type: type.LOAD_REWARDS, businessId, searchText }
};

export const loadMoreRewards = (businessId: string, afterTimestamp=0, searchText='') => {
    console.log('rewards, action, loadMoreRewards, businessId - '+ businessId);
    return { type: type.LOAD_MORE_REWARDS, businessId, afterTimestamp, searchText }
};

export const initiateCreateReward = (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateReward');
    return { type: type.INITIATE_CREATE_REWARD, businessId, reward, onSuccess, onError }
};

export const initiateSaveReward = (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
    console.log('user, action, initiateSaveReward');
    let action = null;
    if (reward.rewardId != ''){
        action = { type: type.INITIATE_UPDATE_REWARD, businessId, reward, onSuccess, onError };
    }else{
        action = { type: type.INITIATE_CREATE_REWARD, businessId, reward, onSuccess, onError };
    }
    return action;
};

export const initiateDeleteReward = (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateReward');
    return { type: type.INITIATE_DELETE_REWARD, businessId, reward, onSuccess, onError }
};

const retrievedRewardList = (rewardList: Reward[]) => {
    return { type: type.REWARDS_LOADED, rewardList }
};

const retrievedMoreRewardList = (rewardList: Reward[]) => {
    return { type: type.MORE_REWARDS_LOADED, rewardList }
};

const loadRewardList = () => {
    return { type: type.LOAD_REWARDS }
};

const emptyRewardList = () => {
    return { type: type.REWARD_LIST_EMPTY }
};

const failedToRetrieveRewardList = () => {
    return { type: type.FAILED_TO_LOAD_REWARDS }
};

const rewardCreated = (reward: Reward) => {
    return { type: type.REWARD_CREATED, reward}
};

const rewardUpdated = (reward: Reward) => {
    return { type: type.REWARD_UPDATED, reward}
};

const rewardDeleted = (reward: Reward) => {
    return { type: type.REWARD_DELETED, reward}
};

// Sagas

function* loadRewardsAsync(action: { businessId: any; searchText: string | undefined; }) {
    console.log('reward, action, loadRewardsAsync - '+ JSON.stringify(action));
    try {

        const rewardList: Reward[] = yield call(fetchRewardList, action.businessId, 0, action.searchText);
        if (rewardList.length > 0){
            yield put(retrievedRewardList(rewardList));
        }else{
            yield put(emptyRewardList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveRewardList());
    }
}

function* loadMoreRewardsAsync(action: { businessId: any; afterTimestamp: number | undefined; searchText: string | undefined; }) {
    console.log('reward, action, loadMoreRewardsAsync - '+ JSON.stringify(action));
    try {

        const rewardList: Reward[] = yield call(fetchRewardList, action.businessId, action.afterTimestamp, action.searchText);
        if (rewardList.length > 0){
            yield put(retrievedMoreRewardList(rewardList));
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
    }
}

function* createRewardAsync(action: { reward: Reward; businessId: string; onSuccess: () => void; onError: (reward: Reward, errorCode: ErrorCode) => void; }) {
    console.log('reward, action, createRewardAsync');
   
    try {
        let reward: Reward = yield call(createReward, action.businessId, action.reward);
        yield put(rewardCreated(reward));
        action.onSuccess();
    } catch (error: any) {
        console.log(`reward, action, createRewardAsync, failed to create, error-${error.response.status}`)
        console.log(JSON.stringify(error));
        if (error.response.status){
            action.onError(action.reward, ErrorCode.REWARD_ALREADY_EXISTING);
        }else{
            action.onError(action.reward, ErrorCode.FAILED_TO_CREATE);
        }
    }
}

function* updateRewardAsync(action: { reward: Reward; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('reward, action, updateRewardAsync');
   
    try {
        let reward: Reward = yield call(updateReward, action.businessId, action.reward);
        yield put(rewardUpdated(reward));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteRewardAsync(action: { reward: Reward; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('reward, action, deleteRewardAsync');
   
    try {
        yield call(deleteReward, action.businessId, action.reward);
        yield put(rewardDeleted(action.reward));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const rewardSagas = [
    takeEvery<any>(type.LOAD_REWARDS, loadRewardsAsync),
    takeEvery<any>(type.LOAD_MORE_REWARDS, loadMoreRewardsAsync),
    takeEvery<any>(type.INITIATE_CREATE_REWARD, createRewardAsync),
    takeEvery<any>(type.INITIATE_UPDATE_REWARD, updateRewardAsync),
    takeEvery<any>(type.INITIATE_DELETE_REWARD, deleteRewardAsync)
];
