import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState, type } from './user'


interface Props {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
  auth?: AuthState;
}

const PrivateRoute = ({auth, component: Component, ...rest }: Props) => {
  
  console.log(`PrivateRoute ... auth state - ${auth?.state}, path- ${rest.path}`);

  let location = useLocation<{from: {pathname: string}}>();
  console.log(`PrivateRoute, location - ${JSON.stringify(location)}`);
  
  return ( 
    <Route  {...rest} render={(props) => (
      auth?.state === type.SIGNED_IN
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/login',
            state: { from: props.location}
          }} />
    )} />
  );
}

const mapStateToProps = (state: { auth: AuthState; }) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(PrivateRoute);
