
import { Address } from 'app/business';
import _ from 'lodash';
import { Component } from 'react';

interface Props {
    address: Address 
  }

interface State { }

export default class AddressText extends Component<Props, State> {
    render(){
        const { address } = this.props;
        
        if (address){
            return (
                <div {...this.props} >
                    {`${_.upperFirst(address.street)}, ${_.upperFirst(address.city)}, ${_.upperFirst(address.state)}, ${address.zipCode}`}
                </div>
            );
        }else{
            return null;
        }
    }
}

