import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchDashboardElements, updateDashboardElements} from './firebaseActions';
import { DashboardElement } from './dashboardElement';

//Action creators

export const loadDashboardElements = (businessId: string) => {
    console.log('inquiries, action, loadDashboardElements, businessId - '+ businessId);
    return { type: type.LOAD_DASHBOARD_ELEMENTS, businessId }
};


export const saveDashboardElements = (businessId: string, dashboardElements: DashboardElement[], onSuccess: any, onError: any) => {
    console.log('user, action, saveDashboardElements');
    return { type: type.INITIATE_UPDATE_DASHBOARD_ELEMENTS, businessId, dashboardElements, onSuccess, onError };
};


const dashboardElementsLoaded = (dashboardElements: DashboardElement[]) => {
    return { type: type.DASHBOARD_ELEMENTS_LOADED, dashboardElements }
};

const dashboardElementsIsEmpty = () => {
    return { type: type.DASHBOARD_ELEMENTS_EMPTY }
};

const failedToLoadDashboardElements = () => {
    return { type: type.FAILED_TO_LOAD_DASHBOARD_ELEMENTS }
};

const dashboardElementsUpdated = (dashboardElements: DashboardElement[]) => {
    return { type: type.UPDATE_DASHBOARD_ELEMENTS, dashboardElements}
};


// Sagas

function* loadDashboardElementsAsync(action: { businessId: string; }) {
    console.log('dashboardElements, action, loadDashboardElementsAsync - '+ JSON.stringify(action));
    try {
  
        const dashboardElements: DashboardElement[] = yield call(fetchDashboardElements, action.businessId);
        console.log(JSON.stringify(dashboardElements));
        if (dashboardElements.length > 0){
            yield put(dashboardElementsLoaded(dashboardElements));
        }else{
            yield put(dashboardElementsIsEmpty());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToLoadDashboardElements());
    }
}


function* updateDashboardElementsAsync(action: { businessId: string; dashboardElements: DashboardElement[]; onSuccess: () => void; onError: () => void; }) {
    console.log('dashboardElements, action, updateDashboardElementsAsync');
   
    try {
        yield call(updateDashboardElements, action.businessId, action.dashboardElements);
        yield put(dashboardElementsUpdated(action.dashboardElements));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

export const dashboardElementsSagas = [
    takeEvery<any>(type.LOAD_DASHBOARD_ELEMENTS, loadDashboardElementsAsync),
    takeEvery<any>(type.INITIATE_UPDATE_DASHBOARD_ELEMENTS, updateDashboardElementsAsync),
];
