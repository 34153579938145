import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import SearchResultListPage from './searchResultListPage';


export interface Props extends RouteComponentProps {
  authState: AuthState,
  businessState: BusinessState,
}

export interface State {

}

export class SearchResults extends Component <Props, State>{
  
  componentDidMount(){

    
  }

  render() {
    const query = this.props.location.search; // could be '?searchText=bar'
    const params = new URLSearchParams(query);
    let searchText = params.get('searchText'); // bar

    return (
      <SearchResultListPage key={searchText} businessId = {this.props.businessState.selectedBusiness.businessId} searchText={searchText?searchText:''}/>
    );
  }
}



const mapStateToProps = (state: { auth: any; business: any; }) => {
  return { 
    authState: state.auth,
    businessState: state.business 
  };
};


export default connect(mapStateToProps, null)(SearchResults);
