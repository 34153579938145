import { Component } from 'react';
import { Modal, Button  } from 'react-bootstrap';
import BillingButton from './billingButton';


export interface Props {
  title: string, 
  show: boolean,
  onHide: () => void,
  onCancel: () => void, 
}

export interface State {
  validated: boolean, 
}

export class SuspendedDialog extends Component <Props, State>{
 

  constructor(props: Props){
    super(props);
    console.log('SuspendedDialog initialized');

    this.state = {
      validated: false,
    }
  }

  render() {

    return (
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >
        <Modal.Header closeButton>
          {this.props.title}
        </Modal.Header>

        <Modal.Body>
              <p> This business account is suspened. Please review steps in the billing page to reactive.</p>
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
          <BillingButton/>
        </Modal.Footer>
      </Modal>
    );
  }

}


export default SuspendedDialog;