import type from './types';
import {type as authType} from '../user';
import { Product, ProductState } from './product';

const INITIAL_STATE = new ProductState ({});
   

export const productReducer = (state = INITIAL_STATE, action: { type: string; productList: Product[]; product: Product; }) => {

    switch (action.type){

        case type.PRODUCTS_LOADED:
            console.log('productReducer, '+action.type);
            return {...state, state: type.PRODUCTS_LOADED, productList: action.productList};

        case type.MORE_PRODUCTS_LOADED:
            console.log('productReducer, '+action.type);
            Array.prototype.push.apply(state.productList, action.productList);
            return {...state, state: type.PRODUCTS_LOADED, productList: state.productList};

        case type.PRODUCT_LIST_EMPTY:
            console.log('productReducer, '+action.type);
            return {...state, state: type.PRODUCT_LIST_EMPTY, productList:[]};

        case type.FAILED_TO_LOAD_PRODUCTS:
            console.log('productReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_PRODUCTS};

        case type.PRODUCT_CREATED:
            {
                console.log('productReducer, '+action.type);
                state.productList.unshift(action.product); 
                let productState = new ProductState({...state, state: type.PRODUCTS_LOADED, productList: state.productList});
                return productState;
            }

                
        case type.PRODUCT_UPDATED:
            {
                console.log('productReducer, '+action.type);

                state.productList.find(product => {
                    if (product.productId === action.product.productId){
                        product.copy(action.product);
                    }
                })
                let productState = new ProductState({...state, state: type.PRODUCTS_LOADED});
                return productState;
            }

        case type.PRODUCT_DELETED:
            {
                console.log('productReducer, '+action.type);

                let list = state.productList.filter(product => {
                    if (product.productId !== action.product.productId){
                        return true;
                    }
                })
                let productState = new ProductState({...state, state: type.PRODUCTS_LOADED, productList: list});
                return productState;
            }
    
        case authType.SIGNED_OUT:
            console.log('productReducer, '+action.type);
            return {...state, state: type.PRODUCT_INITIALIZED, productList:[]};

        default:
            console.log('productReducer, default - '+ action.type);
            return state;
    }
};
