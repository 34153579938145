import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, Button  } from 'react-bootstrap';

import { Transaction } from './payment';
import Invoice from './invoice';

export class InvoiceDialog extends Component {
  

  constructor(props){
    super(props);
    console.log('InvoiceDialog initialized');  
  }

  render() {
    if (this.props.transaction == null){
      return null;
    }else{
      return (
        <Modal
          size="lg"
          show={this.props.show}
          onHide={ () => this.props.onHide()}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
          >

          <Modal.Body>
            <Invoice transaction = {this.props.transaction} />
          </Modal.Body>

          <Modal.Footer className="flex-wrap">
            <Button className="btn btn-primary btn-sm" onClick={() => this.props.onCancel()}>OK</Button>
          </Modal.Footer>
        </Modal>
      );
      }
  }
}


export default InvoiceDialog;