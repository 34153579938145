import { channel } from 'redux-saga'
import { put, takeEvery, call, take, ChannelTakeEffect } from 'redux-saga/effects';

import type, { AuthAction, SignInCancelled } from './types';
import {modifyUser, signInWithEmailAndPassword, signInWithGoogle, signOut, sendPasswordResetEmail, confirmPasswordReset, createUserWithEmailAndPassword, verifyPasswordResetCode, checkWhetherAcountIsActivated, verifyEmail, applyActionCode, getUser, userOnChange, getUserId} from './firebaseActions';
import { settings } from '../config';
import { User, UserSession } from './user';

const customChannel = channel();

//Action creators

export const initiateSignIn = (email: string, password: string) => {
    console.log('user, action, initiateSignIn');
    return { type: type.INITIATE_SIGN_IN, email, password }
};

export const initiateSignInWithGoogle = () => {
    console.log('user, action, initiateSignInWithGoogle');
    return { type: type.INITIATE_SIGN_IN_WITH_GOOGLE  }
};

export const initiateSignOut = () => {
    console.log('user, action, initiateSignOut');
    return { type: type.INITIATE_SIGN_OUT }
};

export const initiatePasswordReset = (email: string) => {
    console.log('user, action, initiatePasswordReset');
    return { type: type.INITIATE_PASSWORD_RESET, email }
};

export const updatePassword = (code: any, newPassword: string) => {
    console.log('user, action, updatePassword');
    return { type: type.UPDATE_PASSWORD, code, newPassword }
};

export const initiateSignup = (email: string, password: string) => {
    console.log('user, action, initiateSignup');
    return { type: type.INITIATE_SIGN_UP, email, password }
};

export const checkPasswordResetCode = (code: any, emailHandle: (email: any)=> void, errorHandle: (error: any) => void) => {
    verifyPasswordResetCode(code)
        .then(function(email: any){
            emailHandle(email);
        })
        .catch(function(error: any){
            errorHandle(error);
        })
}

const isAcountActivated = () => {
    console.log('user, action, isAcountActivated');
    return { type: type.IS_ACOUNT_ACTIVATED }
};

export const sendEmailVerification = () => {
    console.log('user, action, sendEmailVerification');
    return { type: type.SEND_EMAIL_VERIFICATION }
};

export const initiateUpdateUser = (user: User, onSuccess:()=> void, onError: ()=>void) => {
    console.log('user, action, initiateUpdateUser');
    return { type: type.INITIATE_UPDATE_USER, user, onSuccess, onError }
};

export const processActionCode = (code:any, successHandle: () => void, errorHandle: (error: any) => void) => {
    applyActionCode(code)
        .then(function(){
            successHandle();
        })
        .catch(function(error:any){
            errorHandle(error);
        })
}

export const onSignIn = (userSession: UserSession) => {
    console.log('user, action, onSignIn');
    return { type: type.USER_SIGNED_IN, userSession}
};

export const onSignOut = () => {
    console.log('user, action, onSignOut');
    return { type: type.USER_SIGNED_OUT}
};

export const updateUser = (user: User, isNewUser: boolean) => {
    return { type: type.UPDATE_USER, user, isNewUser }
};

const signInProgress = () => {
    return { type: type.SIGN_IN_PROGRESS }
};

const signedIn = (user: User, userSession: UserSession) => {
    return { type: type.SIGNED_IN, user, userSession }
};

const signedOut = () => {
    return { type: type.SIGNED_OUT }
};

const signInFailed = (errorMessage: string) => {
    return { type: type.SIGN_IN_FAILED, errorMessage }
};

const signOutFailed = () => {
    return { type: type.SIGN_OUT_FAILED }
};

const passwordResetInProgress = () => {
    return { type: type.PASSWORD_RESET_IN_PROGRESS }
};

const passwordResetEmailSent = () => {
    return { type: type.PASSWORD_RESET_EMAIL_SENT }
};

const passwordResetFailed = (errorMessage: string) => {
    return { type: type.PASSWORD_RESET_FAILED, errorMessage }
};

const updatePasswordInProgress = () => {
    return { type: type.UPDATE_PASSWORD_IN_PROGRESS }
};

const updatePasswordSuccess = () => {
    return { type: type.UPDATE_PASSWORD_SUCCESS }
};

const updatePasswordFailed = (errorMessage: string) => {
    return { type: type.UPDATE_PASSWORD_FAILED, errorMessage }
};

const signupInProgress = () => {
    return { type: type.SIGN_UP_IN_PROGRESS }
};

const signupSuccess = () => {
    return { type: type.SIGN_UP_SUCCESS }
};

const signupFailed = (errorMessage: string) => {
    return { type: type.SIGN_UP_FAILED, errorMessage }
};

const sendEmailVerificationSucess = () => {
    return { type: type.SEND_EMAIL_VERIFICATION_SUCCESS }
};

const sendEmailVerificationFailed = (errorMessage: string) => {
    return { type: type.SEND_EMAIL_VERIFICATION_FAILED, errorMessage }
};


// Sagas

function* initiateSignInAsync(action: { email: string; password: string; }) {
    console.log('user, action, initiateSignInAsync - '+ JSON.stringify(action));
    try {
  
        const signInSession: UserSession = yield call(signInWithEmailAndPassword, {email: action.email, password: action.password});
        console.log(JSON.stringify(signInSession));
        yield put(signInProgress());
    } catch (error: any) {

        console.log(JSON.stringify(error));

        let errorCode = error.code;
        let errorMessage = '';
        
        if (errorCode === 'auth/invalid-email') {
            errorMessage = type.INVALID_EMAIL;
        } else if (errorCode === 'auth/user-disabled'){
            errorMessage = type.USER_DISABLED;
        }else if (errorCode === 'auth/user-not-found'){
            errorMessage = type.USER_NOT_FOUND;
        }else if (errorCode === 'auth/wrong-password'){
            errorMessage = type.WRONT_PASSWORD;
        }else{
            errorMessage = type.FAILED_TO_SIGNIN;
        }
        yield put(signInFailed(errorMessage));
    }
}

function* initiateSignInWithGoogleAsync(action: any) {
    console.log('user, action, initiateSignInWithGoogleAsync');


    // process signin
    try{ 
        const userSession: UserSession = yield call(signInWithGoogle, settings);
        yield put(signInProgress());
    }catch (e) {
        if (e instanceof SignInCancelled){
            yield put(signedOut());
        }else{
            let errorMessage = type.FAILED_TO_SIGNIN;
            yield put(signInFailed(errorMessage));
        }
    }

}

function* initiateSignOutAsync() {
    console.log('user, action, initiateSignOutAsync');
    try {
        yield call(signOut);

    } catch (error) {  
        console.log(JSON.stringify(error));

        yield put(signOutFailed());
    }
}

function* initiatePasswordResetAsync(action: { email: string; }) {
    console.log('user, action, initiatePasswordResetAsync');
    try {
        yield put(passwordResetInProgress());
        yield call(sendPasswordResetEmail, {email: action.email});
        yield put(passwordResetEmailSent());  
    } catch (error: any) {  
        console.log(JSON.stringify(error));
        let errorCode = error.code;
        let errorMessage = '';
        
        if(errorCode === 'auth/user-not-found'){
            errorMessage = type.USER_NOT_FOUND;
        }else{
            errorMessage = type.PASSWORD_RESET_ERROR;
        }
      yield put(passwordResetFailed(errorMessage));
    }
}

function* updatePasswordAsync(action: { code: any; newPassword: any; }) {
    console.log('user, action, updatePasswordAsync');
    try {
        yield put(updatePasswordInProgress());
        yield call(confirmPasswordReset, {code: action.code, newPassword: action.newPassword});
        yield put(updatePasswordSuccess());  
    } catch (error: any) {  
        console.log(JSON.stringify(error));
        let errorCode = error.code;
        let errorMessage = '';
        
        if(errorCode === 'auth/expired-action-code'){
            errorMessage = type.PASSWORD_RESET_CODE_EXPIRED;
        }else if (errorCode === 'auth/invalid-action-code'){
            errorMessage = type.PASSWORD_RESET_CODE_INVALID;
        }else if (errorCode === 'auth/user-disabled'){
            errorMessage = type.USER_DISABLED;
        }else if (errorCode === 'auth/user-not-found'){
            errorMessage = type.USER_NOT_FOUND
        }else if (errorCode === 'auth/weak-password'){
            errorMessage = type.WEAK_PASSWORD;
        }else{
            errorMessage = type.PASSWORD_RESET_ERROR;
        }
      yield put(updatePasswordFailed(errorMessage));
    }
}

function* initiateSignupAsync(action: { email: any; password: any; }) {
    console.log('user, action, initiateSignupAsync - '+ JSON.stringify(action));
    try {
        yield put(signupInProgress());
        const session: UserSession = yield call(createUserWithEmailAndPassword, {email: action.email, password: action.password});
        console.log(JSON.stringify(session));
        yield call(verifyEmail);
        yield put(signupSuccess());
    } catch (error: any) {

        console.log(JSON.stringify(error));

        let errorCode = error.code;
        let errorMessage = '';
        
        if(errorCode === 'auth/email-already-in-use'){
            errorMessage = type.EMAIL_ALREADY_IN_USE;
        }else if (errorCode === 'auth/invalid-email') {
            errorMessage = type.INVALID_EMAIL;
        }else if (errorCode === 'auth/operation-not-allowed'){
            errorMessage = type.OPERATION_NOT_ALLOWED;
        }else if (errorCode === 'auth/weak-password'){
            errorMessage = type.WEAK_PASSWORD;
        }else{
            errorMessage = type.FAILED_TO_SIGNUP;
        }
        yield put(signupFailed(errorMessage));
    }
}

function* isAcountActivatedAsync(action: any) {
    console.log('user, action, isAcountActivatedAsync - '+ JSON.stringify(action));
    try {
        const user: User = yield call(checkWhetherAcountIsActivated);
        console.log(JSON.stringify(user));
        yield put(updateUser(user, true));
    } catch (error) {

        console.log(JSON.stringify(error));
        let errorMessage = type.FAILED_TO_SIGNIN;
        yield put(signInFailed(errorMessage));
    }
}

function* sendEmailVerificationAsync(action: any) {
    console.log('user, action, sendEmailVerificationAsync - '+ JSON.stringify(action));
    try {
        const emailVerification: boolean = yield call(verifyEmail);
        yield put(sendEmailVerificationSucess());
    } catch (error) {

        console.log(JSON.stringify(error));

        let errorMessage = type.FAILED_TO_SEND_EMAIL_VERIFICATION;
        
        yield put(sendEmailVerificationFailed(errorMessage));
    }
}

function* userSignedInAsync(action: any) {
    console.log('user, action, userSignedInAsync - '+ JSON.stringify(action));
 
    // get user, if not available, wait to get through a callback
    let userId: string = yield call(getUserId, {uId: action.userSession.uId});
    
    if (userId){
        let user: User = yield call(getUser, {userId: userId});
        console.log('user, action, userSignedInAsync, user fetched, user - '+ JSON.stringify(user));
        yield put(signedIn(user, action.userSession));
    }else{
        userOnChange(action.userSession.uId, 
        (user)=>{
            console.log('user, action, userSignedInAsync, on callback success, user - '+ JSON.stringify(user));
            customChannel.put(signedIn(user, action.userSession));
        }, 
        (err)=>{
            console.log('user, action, userSignedInAsync, on callback failed - '+ JSON.stringify(err));
        });

        while (true) {
            const action:ChannelTakeEffect<any> = yield take(customChannel)
            yield put(action)
        }
    }
}

function* userSignedOutAsync() {
    console.log('user, action, userSignedOutAsync');
 
    yield put(signedOut());
}

function* initiateUpdateUserAsync(action: { user: User; onSuccess: () => void; onError: () => void; }) {
    console.log('user, action, initiateUpdateUserAsync - '+ JSON.stringify(action));
 
    try {
        yield call(modifyUser, {user:action.user});
        yield put(updateUser(action.user, false));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const authSagas = [
    takeEvery<any>(type.INITIATE_SIGN_IN, initiateSignInAsync),
    takeEvery<AuthAction>(type.INITIATE_SIGN_IN_WITH_GOOGLE, initiateSignInWithGoogleAsync),
    takeEvery<AuthAction>(type.INITIATE_SIGN_OUT, initiateSignOutAsync),
    takeEvery<AuthAction>(type.USER_SIGNED_IN, userSignedInAsync),
    takeEvery(type.USER_SIGNED_OUT, userSignedOutAsync),
    takeEvery<any>(type.INITIATE_UPDATE_USER, initiateUpdateUserAsync),
    takeEvery<any>(type.INITIATE_PASSWORD_RESET, initiatePasswordResetAsync),
    takeEvery<any>(type.UPDATE_PASSWORD, updatePasswordAsync),
    takeEvery<any>(type.INITIATE_SIGN_UP, initiateSignupAsync),
    takeEvery(type.IS_ACOUNT_ACTIVATED, isAcountActivatedAsync),
    takeEvery(type.SEND_EMAIL_VERIFICATION, sendEmailVerificationAsync),
    takeEvery(type.SEND_EMAIL_VERIFICATION, sendEmailVerificationAsync),
];
