import React, { Component } from 'react'
import { format } from 'date-fns'
import { toSentenceCase } from '../shared';
import { SKU, Transaction } from './payment';

export interface Props {
  businessId: string,
  transaction: Transaction
}

export interface State {

}

export class Invoice extends Component<Props, State> {

  render() {
    let transaction = this.props.transaction;

    return (
      <div>
        <div className="">
          <div className="">
            <div className="card border-0">
             
                <div className="container-fluid d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start my-3">
                    <div className="d-flex flex-column pl-0">
                      <h5 className="mb-0">Usage Period: From {format (transaction.period, 'MMM d')} To {format (new Date(transaction.period.getUTCFullYear(), transaction.period.getUTCMonth()+1, 0), 'MMM d yyyy')}</h5>
                      <br/>
                    </div>
                    <div className="d-flex flex-column mr-5">
                      <h5>Plan: {toSentenceCase(transaction.plan)} </h5>
                      <h5>Due: ${transaction.subTotal}</h5>
                      {(transaction.subTotal > 0)?<h5>Due Date: {format(transaction.dueDate, 'MMM d yyyy')}</h5>:null}
                    </div>
                </div>

                <div className="container-fluid d-flex justify-content-center w-100 p-0">
                  <div className="table-responsive w-100">
                      <table className="table">
                        <thead>
                          <tr className="bg-light">
                              <th className="">#</th>
                              <th className="">Description</th>
                              <th className="text-right d-none d-md-table-cell">Used</th>
                              <th className="text-right d-none d-md-table-cell">Free Units</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
    
                            [...transaction.skus].sort((a, b)=> a.displayPosition - b.displayPosition ).map((item: SKU, index)=>{
                              if(item.showInBilling){
                                return (
                                  <tr key={item.name} className="text-right">
                                    <td className="text-left">{item.displayPosition}</td>
                                    <td className="text-left d-md-none">
                                    <strong> {item.displayName} </strong>
                                    <br/> 
                                    <br/>
                                      Quantity: {item.getUnitsDisplayText()} 
                                    <br/>
                                      Free Units: {item.getFreeUnitsDisplayText()} 
                                    <br/>
                                      
                                    </td>
                                    <td className="text-left d-none d-md-table-cell">{item.displayName}</td>
                                    <td className="d-none d-md-table-cell">{item.getUnitsDisplayText()}</td>
                                    <td className="d-none d-md-table-cell">{item.getFreeUnitsDisplayText()}</td>
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Invoice
