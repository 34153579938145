import { Component } from 'react';
import Icon from './icon';
import { mdiAlertOutline } from '@mdi/js';


export interface Props {
 message: string
}

export interface State {

}

export class WarningMessage extends Component <Props, State>{  

  constructor(props: Props){
    super(props);
    console.log('WarningMessage initialized');

     this.state = {
 
     }
  }

  render() {

    return (
        <div className="d-flex flex-row align-items-center" {...this.props}>
          <Icon className="pr-2" path={mdiAlertOutline}/>
          <div className="text-muted"> {this.props.message} </div>
        </div>
    );
  }
}


export default WarningMessage;