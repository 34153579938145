import type from './types';
import { createReward, updateReward, deleteReward } from './firebaseActions';
import { format } from 'date-fns'
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import { RecordObject } from 'app/record';


export enum RewardType {
    spend = 'spend'
}

export type RewardData ={
    object?: RecordObject;
    rewardId?: string;
    rewardType?: RewardType;
    name: string;
    amountSpentPerPoint?: number;
    minPointsToRedeem?: number;
    minAmountToRedeem?: number;
    lastModified?: number;

}

export class Reward {
    object: RecordObject;
    rewardId: string;
    rewardType: RewardType;
    name: string;
    amountSpentPerPoint: number;
    minPointsToRedeem: number;
    minAmountToRedeem: number;
    lastModified: number;

    constructor(rewardDate: RewardData){
        console.log('Reward initialized');

        this.object = RecordObject.reward;
        this.rewardId = rewardDate.rewardId || '';
        this.rewardType = rewardDate.rewardType || RewardType.spend;
        this.name = rewardDate.name || '';
        this.amountSpentPerPoint = rewardDate.amountSpentPerPoint || 0;
        this.minPointsToRedeem = rewardDate.minPointsToRedeem || 0;
        this.minAmountToRedeem = rewardDate.minAmountToRedeem || 0;
        this.lastModified = rewardDate.lastModified || new Date().getTime();

    }

    isDifferent(reward: Reward){
        let isDifferent = false;
        if (this.rewardType !== reward.rewardType ||
            this.name !== reward.name || 
            this.amountSpentPerPoint !== reward.amountSpentPerPoint ||
            this.minPointsToRedeem !== reward.minPointsToRedeem ||
            this.minAmountToRedeem !== reward.minAmountToRedeem){
            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        return {
            rewardType: this.rewardType,
            name: this.name,
            amountSpentPerPoint: this.amountSpentPerPoint,
            minPointsToRedeem: this.minPointsToRedeem,
            minAmountToRedeem: this.minAmountToRedeem,
        }
    }

    copy(reward: Reward){
        Object.assign(this, reward);
    }

    displaylastModified(){
        let date = new Date(this.lastModified);
        let sameYear = (date.getFullYear() == new Date().getFullYear());
        let sameday = (date.getDate() == new Date().getDate());
        let displayString = '';

        if (!sameYear){
            displayString = format(date, 'MM/dd/yy'); // MM/DD/YY
        }else if (!sameday){ 
            displayString = format(date, 'MMM d'); // Jan 20
        } else{
            displayString = format(date, "hh:mm aaaaa'm'") // 2:30 pm
        }
        return displayString; 
    }
}


export class RewardState {
    state: string;
    rewardList: Reward[];
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(){
        console.log('RewardState initialized');

        this.state = type.REWARD_INITIALIZED;
        this.rewardList = [];

        this.canCreate = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }
    }
}
