import { Business } from "app/business";
import { BusinessContact } from "app/page/business";
import { GJSTagNames } from "../grapesjsEditor";



export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.Contact) ;

  editor.Components.addType(GJSTagNames.Contact, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessContact,
        attributes: {
          business: new Business({}),
          showHours: true,
        },
        toolbar: [],
        traits: []
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.Contact} model initialized`);
        // @ts-ignore
        //this.on('style:property:update', this.handleStyleChange);
        
        // @ts-ignore
        //this.listenTo(this, 'change:attributes:showHours', this.handleAttributeChange);
      },

      handleStyleChange() {
        console.log('Model Style updated ');
      },
      handleAttributeChange(changes: any) {
        // @ts-ignore
        let showHours: boolean = this.getAttributes().showHours;
        console.log(`Model Attributes updated, showHours - ${showHours}`);
        
      },      
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.Contact.toUpperCase();
    }
  });

  editor.BlockManager.add('Contact', {
    label: getBockLabel(),
    category: 'Elements',
    content: '<div><h4> Contact Us </h4><Contact></Contact></div>'
  });
};


function getBockLabel(){
  return `<div class='gjs-fonts'>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24">
            <path d="M22,3H2A2,2 0 0,0 0,5V19A2,2 0 0,0 2,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M22,19H2V5H22V19M19,18L21,16L19.5,14H17.85C17.63,13.37 17.5,12.7 17.5,12C17.5,11.3 17.63,10.63 17.85,10H19.5L21,8L19,6C17.7,7 16.73,8.38 16.28,10C16.1,10.64 16,11.31 16,12C16,12.69 16.1,13.36 16.28,14C16.73,15.61 17.7,17 19,18M9,12A3,3 0 0,0 12,9A3,3 0 0,0 9,6A3,3 0 0,0 6,9A3,3 0 0,0 9,12M9,8A1,1 0 0,1 10,9A1,1 0 0,1 9,10A1,1 0 0,1 8,9A1,1 0 0,1 9,8M15,16.59C15,14.09 11.03,13 9,13C6.97,13 3,14.09 3,16.59V18H15V16.59M5.5,16C6.22,15.5 7.7,15 9,15C10.3,15 11.77,15.5 12.5,16H5.5Z" />
          </svg>          
          <div>Contact</div>
          </div>`
}
