import { Role } from 'app/userPermissions';
import type from './types';

type Position = {
    x: number, 
    y: number,
    w: number, 
    h: number, 
    minW: number,
    minH: number
}

export type DashboardElementData = {
    type: string, 
    visible: boolean,
    fixed: boolean, 
    position: Position
}

export class DashboardElement {
    key: string;
    type: string;
    visible: boolean;
    fixed: boolean;
    position: Position; 

    constructor(dashboardElementData: DashboardElementData){
        console.log('DashboardElement initialized');
        
        this.key = dashboardElementData.type || '';
        this.type = dashboardElementData.type || '';
        this.visible = dashboardElementData.visible || true;
        this.fixed = dashboardElementData.fixed || false;
        this.position = dashboardElementData.position || {x: 0, y: 1, w: 2, h: 3, minW: 4, minH: 2};

    }

    isDifferent(inquiry: { visible: boolean; position: { x: number; y: number; w: number; h: number; }; }){
        let isDifferent = false;
        if (this.visible !== inquiry.visible || 
            this.position.x !== inquiry.position.x || 
            this.position.y !== inquiry.position.y || 
            this.position.w !== inquiry.position.w || 
            this.position.h !== inquiry.position.h ){

            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        return {
            type: this.type,
            visible: this.visible,
            fixed: this.fixed,
            position: {x: this.position.x, y: this.position.y, w: this.position.w, h: this.position.h, minW: this.position.minW, minH: this.position.minH}
        }
    }

    copy(dashboardElement: DashboardElement){
        Object.assign(this, dashboardElement);
    }
}

export class DashboardElementsState {
    state: string;
    dashboardElements: DashboardElement[];
    canCreate: (selectedBusiness: any) => boolean;
    canEdit: (selectedBusiness: any) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(){
        console.log('DashboardElementsState initialized');

        this.state = type.DASHBOARD_ELEMENTS_INITIALIZED;
        this.dashboardElements = [];

        this.canCreate = function(selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }
    }
}
