
import { GJSTagNames } from '../grapesjsEditor';
import { BusinessFooter } from '../../business';
import { Business, BusinessHours } from 'app/business';

export default (editor: any) => {
  editor.Components.addType(GJSTagNames.SectionFooter, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessFooter,
        stylable: ['background-color', 'color'],
        resizable: false,
        editable: false,
        draggable: false,
        droppable: false,
        removable: false,
        attributes: {
          business: new Business({}),
          businessHours: new BusinessHours({}),
          editable: false,
          showContact: true,
          showHours: true,
          showSocialLinks: false
        },
        toolbar: [],
        traits: [
          { 
            type: 'checkbox', 
            label: 'Contact', 
            name: 'showContact', 
          },
          { 
            type: 'checkbox', 
            label: 'Hours', 
            name: 'showHours', 
          },
          { 
            type: 'checkbox', 
            label: 'Social Links', 
            name: 'showSocialLinks', 
          }
        ]
      }
    },
    isComponent: (el: { tagName: string; }) => el.tagName === GJSTagNames.SectionFooter.toUpperCase()
  });
};
