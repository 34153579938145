
export type UserData = {
    userId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNo?: string;
    receiptId?: string;
    location?: string;
    status?: string;
}

export class User { 
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    receiptId: string;
    location: string;
    status: string;
    
    constructor({firstName, lastName, email, phoneNo, receiptId, location, status}: UserData){
        console.log('User initialized');
        this.userId = '';
        this.firstName = firstName || '';
        this.lastName = lastName || '';
        this.email = email || '';
        this.phoneNo = phoneNo || '';
        this.receiptId = receiptId || '';
        this.location = location || '';
        this.status = status || 'Active';
    }

    serialize(){
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNo: this.phoneNo,
            email: this.email,
            location: this.location,
        }
    }

    isDifferent(user: User){
        let isDifferent = false;
        if (this.firstName !== user.firstName|| this.lastName !== user.lastName || this.phoneNo !== user.phoneNo || this.location !== user.location){
            isDifferent = true;
        }
        return isDifferent;
    }
}

export class UserSession {
    uId: string;
    displayName: string;
    email: string;
    phoneNo: string;
    emailVerified: boolean;

    constructor({uid, displayName, email, phoneNumber, emailVerified}: any){
      console.log('User initialized');
      
      this.uId = uid;
      this.displayName = displayName || '';
      this.email = email || '';
      this.phoneNo = phoneNumber || '';
      this.emailVerified = emailVerified || false;

      console.log('User - '+ this.email);
    }
  }
  