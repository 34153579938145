import { Component } from 'react'
import { PlanState, PlanType } from './plans';
import _ from 'lodash';

import { SKU, SKUName } from 'app/billing/payment';
import Spinner from 'app/shared/Spinner';
import types from './types';
import Accordion from 'react-bootstrap/esm/Accordion';
import { Button } from 'react-bootstrap';


export interface Props {
  planState: PlanState
}

const businessFeatures = [SKUName.customers, SKUName.products, SKUName.orders, SKUName.inquiries, SKUName.rewards, SKUName.staffAccounts];
const onlineStoreFeatures = [SKUName.onlinestore, SKUName.domains, SKUName.pages, SKUName.storage, SKUName.imageFileSize]
export class FeatureList extends Component<Props> {

  render(){
    let component = null;

    if(this.props.planState.state == types.PLANS_INITIALIZED){
      component = <Spinner/>;
    }else{
      component = this.renderFeatureAccodion();
    }
    return component;
  }

  renderFeatureAccodion(){
    return (<div className="pb-5">
      <div className="d-none d-md-block">
        <div className="">
          <div className="card border-0">
            <div className="justify-content-center w-100 p-0">
              <Accordion>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" className="text-decoration-none"> 
                  <h5 className="text-center">Compare Plans</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  {this.renderFeatureList()}
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }

  renderFeatureList() {
    let planState = this.props.planState;
    let freePlan = planState.getPlan(PlanType.free);
    let startPlan = planState.getPlan(PlanType.start);
    let growPlan = planState.getPlan(PlanType.grow);
    let scalePlan = planState.getPlan(PlanType.scale);

    return (

            <div className="table-responsive w-100">
                <table className="table">
                  <thead>
                    <tr className="">
                        <th className="text-left d-none d-md-table-cell"></th>
                        <th className="text-left d-none d-md-table-cell">{_.upperFirst(freePlan.plan)}</th>
                        <th className="text-left d-none d-md-table-cell">{_.upperFirst(startPlan.plan)}</th>
                        <th className="text-left d-none d-md-table-cell">{_.upperFirst(growPlan.plan)}</th>
                        <th className="text-left d-none d-md-table-cell">{_.upperFirst(scalePlan.plan)}</th>
                      </tr>
                  </thead>
                  <tbody>
                    {

                      businessFeatures.map((skuName: SKUName, index)=>{
                        let freeSKU = freePlan.getSKU(skuName);
                        let startSKU = startPlan.getSKU(skuName);
                        let growSKU = growPlan.getSKU(skuName);
                        let scaleSKU = scalePlan.getSKU(skuName);
                          return (
                            <tr key={skuName} className="text-left">
                              <td className="d-none d-md-table-cell">{freeSKU.displayName}</td>
                              <td className="d-none d-md-table-cell">{freeSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{startSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{growSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{scaleSKU.getFreeUnitsDisplayText()}</td>
                            </tr>
                          )
                      })
                    }
                    <tr key="online" className="text-left bg-light">
                    <td className="d-none d-md-table-cell"><strong>Online</strong></td>
                    <td colSpan={4}></td>
                    </tr>
                    {

                        onlineStoreFeatures.map((skuName: SKUName, index)=>{
                        let freeSKU = freePlan.getSKU(skuName);
                        let startSKU = startPlan.getSKU(skuName);
                        let growSKU = growPlan.getSKU(skuName);
                        let scaleSKU = scalePlan.getSKU(skuName);
                          return (
                            <tr key={skuName} className="text-left">
                              <td className="d-none d-md-table-cell">{freeSKU.displayName}</td>
                              <td className="d-none d-md-table-cell">{freeSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{startSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{growSKU.getFreeUnitsDisplayText()}</td>
                              <td className="d-none d-md-table-cell">{scaleSKU.getFreeUnitsDisplayText()}</td>
                            </tr>
                          )
                      })
                    }
                  </tbody>
                </table>
              </div>
            );
  }
}

export default FeatureList
