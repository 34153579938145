import _ from 'lodash';
import { Component } from 'react';
import { Form, Modal, Button, InputGroup  } from 'react-bootstrap';
import { ErrorCode } from './types';

export interface Props {

  title?: string,
  show: boolean,
  onHide: () => void,
  onCancel: () => void,
  onSave: (socialLink: string) => void
  errorCode?: ErrorCode | '',
  }

export interface State { 
  validated: boolean,
  socialLink: any,
}
export class SocialLinkInputDialog extends Component<Props, State> {
  title: string;

  constructor(props: Props){
    super(props);
    console.log('SocialLinkInputDialog initialized');
    
    this.title = 'Add a social link';

     
    this.state = {
      validated: false,
      socialLink:{
        placeholder: 'Enter a social link',
        value: '',
        type: 'text',
        required: true,
      }
    }
  }

  render() {
    let socialLink = this.state.socialLink;
    let self = this;
  
    return (
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >

        <Modal.Header closeButton>
          {this.title}
        </Modal.Header>

        <Modal.Body>
 
              <Form className="form-sample" id="socialLink"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                <div className="py-2 row justify-content-baseline">
           
                <InputGroup className="col-md-12">
                    
                    <Form.Control 
                      type={socialLink.type} 
                      name="name" 
                      required={socialLink.required} 
                      placeholder={socialLink.placeholder} 
                      onChange={e => self.onChange(e)} 
                      value = {socialLink.value} 
                    />
                  </InputGroup>
                </div>
              </Form>
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
          <button type="submit" form="socialLink"  className="btn btn-primary btn-sm" onClick={(e) => this.onSave(e)}>Save</button>
        </Modal.Footer>
      </Modal>
    );
  }

  onChange(e: any){
    console.log('SocialLinkInputDialog, onChange');

    let socialLink = this.state.socialLink;
    let key = e.target.name;

    socialLink.value = e.target.value;
    this.setState({socialLink: socialLink});
  }


  
  onSave(e: any){
    console.log('SocialLinkInputDialog, onSave');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget.form;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let socialLink =  this.getFormData();
      console.log(JSON.stringify(socialLink));

      this.setState({validated: false});
      
      
      
      this.props.onSave(socialLink)
    }else{
      console.log('not valid');
    }
  }

  getFormData(){
    return this.state.socialLink.value;
  }

}


export default SocialLinkInputDialog;