import type from './types';
import { createUserPermission, updateUserPermission, deleteUserPermission } from './firebaseActions';

export enum Role{
    Admin = 'admin',
    Editor = 'editor',
    Viewer = 'viewer'
}

export type UserPermissionData ={
    userId?: string;
    firstName?: string;
    emailAddress?: string;
    role?: Role;
}

export class UserPermission {
    userId: string;
    firstName: string;
    emailAddress: string;
    role: Role;

    constructor({userId, firstName, emailAddress, role}: UserPermissionData){
        console.log('UserPermission initialized');

        this.userId = userId || '';
        this.firstName = firstName || '';
        this.emailAddress = emailAddress || '';
        this.role = role || Role.Viewer;
    }

    isDifferent(userPermission: UserPermission){
        let isDifferent = false;
        if (this.emailAddress !== userPermission.emailAddress || this.role !== userPermission.role){
            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        return {
            role: this.role,
        }
    }

    copy(userPermission: UserPermission){
        Object.assign(this, userPermission);
    }

    async create (businessId: string): Promise<any>{
        await createUserPermission(businessId, this);
    }

    async update(businessId: string){
        await updateUserPermission(businessId, this);
    }

    async delete(businessId: string){
        await deleteUserPermission(businessId, this);
    }
}


export class UserPermissionState {
    state: string;
    userPermissionList: UserPermission[];
    canAdd: (userRole: Role) => boolean;
    canEdit: (userRole: Role, roleTobeEdited: Role) => boolean;
    getAllowedRoles: (userRole: Role) => Role[];
    
    
    constructor(){
        console.log('UserPermissionState initialized');

        this.state = type.USER_PERMISSION_INITIALIZED;
        this.userPermissionList = [];
    

        

        this.canAdd = function (userRole: Role): boolean{
            return (userRole === Role.Admin);
        }

        this.canEdit = function (userRole: Role, roleTobeEdited: Role): boolean{
            return (userRole === Role.Admin);
        }

        this.getAllowedRoles = function(userRole: Role): Role[]{
            let allowedRoles:Role[] = [];
            if (userRole === Role.Admin){
                allowedRoles.push(Role.Admin);
                allowedRoles.push(Role.Editor);
                allowedRoles.push(Role.Viewer);
            }else if (userRole === Role.Editor){
                allowedRoles.push(Role.Editor);
                allowedRoles.push(Role.Viewer);
            }
            return allowedRoles;
        }
    }
}
