import { DeleteDialog, Img } from 'app/shared';
import { Component } from 'react';
import { connect } from 'react-redux';
import { initiateSaveProduct, initiateDeleteProduct } from './actions';
import { Product } from './product';
import { alert } from '../shared';
import './product.css';
import { ErrorCode } from './types';
import { Card } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

export interface ProductSearchItemProp extends RouteComponentProps{
  businessId: string,
  product: Product,
  canEdit: boolean,
  onDelete?: (productId: string) => void,
}

export interface Props extends ProductSearchItemProp{
  initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void,
  initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void
}

export interface State {
  product: Product,
  showDeleteDialog: boolean,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean
}

class _ProductSearchItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductSearchItem initialized');

    this.state = {
      product: this.props.product,
      showDeleteDialog: false,
      errorCode:   '',
      saveInProgress: false
    }
  }

  render() {

    let {productId, name, description, picture, collection, price, isPublished, quantity} = this.props.product;
      console.log(`picture url - ${picture}, price - ${price}`);
      return (
        <Card className="product-item">
          <Card.Header className="">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <div className="mr-2 product-img-outer product-search-img">
                  <Img className="product_image" key={Date.now()} src={picture} fallbackSrc='https://via.placeholder.com/92x92' alt="product"/>
                </div>
                <div className="d-flex flex-column">  
                  <Link className="mb-3" to={`/products/${productId}`}>
                    <a>{name}</a>
                  </Link>               
                  <span className="mb-1"> {(isPublished?'Visible':'Not visible')} </span> 
                  <br/>
                  <span className="mb-1">Quantity: { quantity } </span>
                  <br/>
                  <span className="mb-1">Price: { price } </span>
                </div>  
              </div>
              {
                (this.props.canEdit)
                ?
                <div>
                  <i id={productId} className="ti-pencil menu-icon pr-4" onClick={()=> this.onEditProduct(productId)}></i> 
                  <i className="ti-trash menu-icon" onClick={()=> this.onShowDeleteDialog()}></i>
                </div>
                :null
              }
            </div>
          </Card.Header>
          <DeleteDialog 
              id={productId} 
              confirmationText={name} 
              show={this.state.showDeleteDialog} 
              onHide={() => this.setState({showDeleteDialog: false})} 
              onCancel={() => this.setState({showDeleteDialog: false})} 
              onDelete={(productId: string) => this.onDelete(productId)}/>
        </Card>
      );
  }

  onEditProduct(productId: string){
    this.props.history.push(`/products/${productId}`);
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true });
  }

  onDelete(productId: string){

    this.setState({showDeleteDialog: false });

    this.props.initiateDeleteProduct(this.props.businessId, this.state.product, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })

    if(this.props.onDelete){
      this.props.onDelete(productId)
    }
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; product?: Product; onSuccess?: any; onError?: any; }) => void) => {
  return {
    initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateSaveProduct(businessId, product, onSuccess, onError))
    },
    initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteProduct(businessId, product, onSuccess, onError))
    }
  };
};

let X =  connect(null, mapDispatchToProps)(_ProductSearchItem);


class ProductSearchItem extends Component<ProductSearchItemProp> {
  render() {
    return <X {...this.props} />
  };
}

export default withRouter(ProductSearchItem);