
import React, { Component } from 'react';

interface Props {
    style?: any,
    name: string
  }

interface State { }

export default class BusinessName extends Component<Props, State> {
    static propType: {};
    static defaultProps: {};

    render(){
        const { style={}, name } = this.props;

        return (
            <h4 {...this.props}>{name}</h4>
        );
    }
}

