import { DeleteDialog } from 'app/shared';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, PromptProps } from 'react-router-dom';
import { initiateSaveCustomer, initiateDeleteCustomer } from './actions';
import { Customer, CustomerData } from './customer';
import CustomerInputDialog from './customerInputDialog';
import { alert } from '../shared';
import './customers.css';
import { ErrorCode } from './types';

export interface CustomerListItemProp {
  businessId: string,
  customer: Customer,
  canEdit: boolean,
  onDelete?: (customerId: string) => void,
}

export interface Props extends CustomerListItemProp{
  initiateSaveCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => void,
  initiateDeleteCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => void
}

export interface State {
  customer: Customer,
  showCustomerDialog: boolean,
  showDeleteDialog: boolean,
}

class _CustomerListItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('CustomerListItem initialized');

    this.state = {
      customer: this.props.customer,
      showCustomerDialog: false,
      showDeleteDialog: false,
    }
  }

  render() {

    let {customerId, name, phoneNumber, emailAddress} = this.state.customer;

      return (
        <Card className="mb-2" key={customerId}>
            <Card.Header className="inquiry-header" style={{backgroundColor: 'inherit'}}>
              <div className="d-flex flex-row justify-content-between align-items-center"> 
                <div className="w-75">
                  <Link to={`/customers/${customerId}`} className='name'> <h4> <strong> {name} </strong></h4></Link>
                  <Link to={`mailto:${emailAddress}`} className='emailAddress'> <p className="text-muted">{emailAddress}</p></Link>
                  <Link to={`tel:${phoneNumber}`} className='phoneNumber'> <p className="text-muted">{phoneNumber}</p></Link>
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-md-none" >Since {this.state.customer.displayCustomerSince()}</h6>
                </div> 
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-none d-md-block" >Since {this.state.customer.displayCustomerSince()}</h6>
                  {
                    (this.props.canEdit)
                    ? (
                      <div className="d-flex">  
                        <i id={customerId} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onShowCustomerDialog()}}></i> 
                        <i className="ti-trash menu-icon " onClick={(e)=> { e.stopPropagation(); this.onShowDeleteDialog()}}></i> 
                      </div>
                      )
                    : null
                  }
                </div>
              </div>
            </Card.Header >
            <CustomerInputDialog key={customerId} show={this.state.showCustomerDialog} customer={this.state.customer} onHide={() => this.setState({showCustomerDialog: false})} onCancel={() => this.setState({showCustomerDialog: false})} onSave={(customer: Customer) => this.onSave(customer)}/>
            <DeleteDialog id={customerId} confirmationText={phoneNumber} show={this.state.showDeleteDialog} onHide={() => this.setState({showDeleteDialog: false})} onCancel={() => this.setState({showDeleteDialog: false})} onDelete={(customerId: string) => this.onDelete(customerId)}/>
        </Card>
      );
  }

  onShowCustomerDialog(){
    this.setState({showCustomerDialog: true });
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true });
  }

  onSave(customer: Customer){
    this.setState({customer: customer, showCustomerDialog: false });

    this.props.initiateSaveCustomer(this.props.businessId, customer, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      (customer: Customer, errorCode: ErrorCode)=>{
        //on error

        alert.error('Failed to update!');
      })
  }

  onDelete(customerId: string){

    this.setState({showDeleteDialog: false });

    this.props.initiateDeleteCustomer(this.props.businessId, this.state.customer, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })

    if(this.props.onDelete){
      this.props.onDelete(customerId)
    }
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; customer?: Customer; onSuccess?: any; onError?: any; }) => void) => {
  return {
    initiateSaveCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
      dispatch(initiateSaveCustomer(businessId, customer, onSuccess, onError))
    },
    initiateDeleteCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteCustomer(businessId, customer, onSuccess, onError))
    }
  };
};

let X =  connect(null, mapDispatchToProps)(_CustomerListItem);


export class CustomerListItem extends Component<CustomerListItemProp> {
  render() {
    return <X {...this.props} />
  };
}