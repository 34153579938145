import type from './types';
import {type as authType} from '../user';
import {type as businessType} from '../business';
import { UserPermission, UserPermissionState } from './userPermission';

const INITIAL_STATE = new UserPermissionState ();
   

export const userPermissionReducer = (state = INITIAL_STATE, action: { type: string; userPermissionList: any; userPermission: UserPermission; }) => {

    switch (action.type){


        case type.USER_PERMISSION_LOADED:
            console.log('userPermissionReducer, '+action.type);
            return {...state, state: type.USER_PERMISSION_LOADED, userPermissionList: action.userPermissionList};

        case type.USER_PERMISSION_LIST_EMPTY:
            console.log('userPermissionReducer, '+action.type);
            return {...state, state: type.USER_PERMISSION_LIST_EMPTY, userPermissionList:[]};

        case type.CREATE_USER_PERMISSION:
            console.log('userPermissionReducer, '+action.type);

            state.userPermissionList.push(action.userPermission);
            return {...state, state: type.USER_PERMISSION_LOADED}

                
        case type.UPDATE_USER_PERMISSION:
            console.log('userPermissionReducer, '+action.type); 

            state.userPermissionList.find(userPermission => {
                if (userPermission.userId === action.userPermission.userId){
                    userPermission.copy(action.userPermission);
                }
             })

            return {...state, state: type.USER_PERMISSION_LOADED}

        case type.DELETE_USER_PERMISSION:
            console.log('userPermissionReducer, '+action.type);

            let list = state.userPermissionList.filter(userPermission => {
                if (userPermission.userId !== action.userPermission.userId){
                    return true;
                }
            })

            return {...state, state: type.USER_PERMISSION_LOADED, userPermissionList: list}
        
        case businessType.BUSINESS_SELECTED:
            console.log('userPermissionReducer, '+action.type);
            return {...state, state: type.USER_PERMISSION_INITIALIZED, userPermissionList:[]};

        case authType.SIGNED_OUT:
            console.log('userPermissionReducer, '+action.type);
            return {...state, state: type.USER_PERMISSION_INITIALIZED, userPermissionList:[]};

        default:
            console.log('userPermissionReducer, default - '+ action.type);
            return state;
    }
};
