import React, { Component } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import type from './types';

import { loadBusinesses, setSelectedBusiness } from './actions';
import { AuthState } from 'app/user';
import { BusinessState } from '.';
import { Spinner } from '../shared/Spinner';

export interface Props extends RouteComponentProps{
  authState: AuthState,
  businessState: BusinessState,
  setSelectedBusiness: (businessId: string) => void,
  loadBusinesses: (userId: string) => void
}

export interface State {

}

export class SelectBusiness extends Component<Props, State> {

  constructor(props: Readonly<Props>){
    super(props);
    console.log('SelectBusiness initialized');
    this.props.loadBusinesses(this.props.authState.user.userId);
  }

  render(){

    let render;
    let location:any = this.props.location;
    let pathname = location.state?((location.state.from.pathname === location.pathname)? '/': location.state.from.pathname):'/';
    let search = location.state?location.state.from.search: '';
    
    if(this.props.businessState.state == type.EMPTY){
      render = <Redirect to='/createBusiness' />
    }else if(this.props.businessState.state == type.LOADED){
      let businessId: string | null = new URLSearchParams(search).get('b');

      console.log(`SelectBusiness, redirected pathname - ${JSON.stringify(location)}, businessId-${businessId} from query`);

      if (!businessId){
        //use from local storage
        businessId = BusinessState.retrieveSelectedBusinessIdFromLocalStorage();
        if (businessId && !this.props.businessState.getBusiness(businessId)){
          //businessId is not available in the list, reset to null
          businessId = null;
        }
      }

      if (!businessId){
        // use from the list
        businessId = this.props.businessState.businessList[0].businessId;
      }

      if (businessId){
        console.log(`SelectBusiness, set selected business id - ${businessId}`)
        this.props.setSelectedBusiness(businessId);
      }

      render = <Redirect to={{
                      pathname: pathname,
                      search: search
                    }}  
                />
    }else{
      //error case, try loading business again
      //this.props.loadBusinesses(this.props.authState.user.userId);
      render = <Spinner/>
    }
    return render;
  }

  selectBusiness() {
    return (
      <div>
        <div className="d-flex align-items-center auth pt-5 px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h4>Select business</h4>
                <p></p>
                <a href= '' onClick={this.onSelectBusiness.bind(this)} className="auth-link text-black">Click here to select business</a>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onSelectBusiness(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('SelectBusiness, onContactForBusinessSetup');
    this.props.setSelectedBusiness('');
    this.props.history.push("/dashboard");
  }

}


const mapStateToProps = (state: { auth: any; business: any; }) => {
  return { 
            authState: state.auth,
            businessState: state.business 
          };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId?: string; userId?: string; }) => void) => {
  return {
    setSelectedBusiness: (businessId: string) => {
      dispatch(setSelectedBusiness(businessId))
    },
    loadBusinesses: (userId: string) => {
      dispatch(loadBusinesses(userId))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectBusiness));
