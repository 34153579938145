
const SEARCH_RESULT_INITIALIZED = 'SEARCH_RESULT_INITIALIZED';
const LOAD_SEARCH_RESULTS = 'LOAD_SEARCH_RESULTS';
const LOAD_MORE_SEARCH_RESULTS = 'LOAD_MORE_SEARCH_RESULTS';
const SEARCH_RESULTS_LOADED = 'SEARCH_RESULTS_LOADED';
const MORE_SEARCH_RESULTS_LOADED = 'MORE_SEARCH_RESULTS_LOADED';
const SEARCH_RESULT_LIST_EMPTY = 'SEARCH_RESULT_LIST_EMPTY';
const FAILED_TO_LOAD_SEARCH_RESULTS = 'FAILED_TO_LOAD_SEARCH_RESULTS';





export default {
    SEARCH_RESULT_INITIALIZED,
    LOAD_SEARCH_RESULTS,
    LOAD_MORE_SEARCH_RESULTS,
    SEARCH_RESULTS_LOADED,
    MORE_SEARCH_RESULTS_LOADED,
    SEARCH_RESULT_LIST_EMPTY,
    FAILED_TO_LOAD_SEARCH_RESULTS,
};