

import firebase, { firestore } from '../firebase';
import { APISetting, OAuth } from './apiSetting';
import { User, createUser } from '../user';
import { makeid } from 'app/shared';

export async function fetchAPISettings(businessId: string){

  console.log('apiSettings, firebaseActions, fetchAPISettings, businessId - '+ businessId);

  let apiSetting: APISetting = new APISetting({})
  let oAuthPromiseList: Promise<OAuth>[] = [];
  let oAuthCollectionRef =  firestore.collection('businesses').doc(businessId).collection('oAuth');

  let oAuthCollection = await oAuthCollectionRef.get();
  
  if (oAuthCollection.docs.length > 0){
      console.log('apiSettings, firebaseActions');
      
      oAuthCollection.forEach((doc)=>{
        oAuthPromiseList.push(getOAuth(businessId, doc.id));
      });

      apiSetting.oAuth = await Promise.all(oAuthPromiseList);

  }else{
      console.log(`apiSettings, firebaseActions, fetchAPISettings, OAuth list is empty for businessId - ${businessId}`);
  }

  return apiSetting;
}

export async function getOAuth(businessId: string, clientId: string){
  console.log('apiSettings, firebaseActions, getOAuth, clientId - '+ clientId);

  let oAuth: OAuth = new OAuth({clientId:'', clientSecret:'', isActive: false, scope:[] });
  let oAuthRef =  firestore.collection('businesses').doc(businessId).collection('oAuth').doc(clientId);

  let oAuthDoc = await oAuthRef.get();
  if (oAuthDoc.exists){
    let clientId = oAuthDoc.data()?.clientId;
    let clientSecret = oAuthDoc.data()?.clientSecret;
    let isActive = oAuthDoc.data()?.isActive;
    oAuth = new OAuth({clientId, clientSecret, isActive, scope:[] });
  }

  return oAuth;
}

export async function changeAPISecret(businessId: string, clientId: string){

  console.log('apiSettings, rotateAPISecret, businessId - '+ businessId);
  let oAuthRef =  firestore.collection('businesses').doc(businessId).collection('oAuth').doc(clientId);
  let newSecret:string = makeid(32);
  await oAuthRef.update({clientSecret: newSecret});
  return newSecret;
}
