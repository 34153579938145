import type from './types';
import {type as authType} from '../user';
import {type as businessType} from '../business';
import { PageLinks, PageLinksState } from './pageLinks';
import { Domain } from './domain';
import _ from 'lodash';
import { PageSettings } from './pageSettings';
import { AvailablePageProperty, PageProperty } from './pageProperty';

const INITIAL_STATE = new PageLinksState ({});
   

export const pageLinksReducer = (state = INITIAL_STATE, action: { type: string; pageLinks: any; availablePageProperty: any; pageProperty: PageProperty, domainList: Domain[]; domain: Domain; pageSettings: PageSettings;}) => {

    switch (action.type){


        case type.PAGE_LINKS_LOADED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, pageLinks: action.pageLinks}));
                
        case type.UPDATED_PAGE_LINKS:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, pageLinks: action.pageLinks}));


        case type.AVAILABLE_PAGE_PROPERTY_LOADED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, availablePageProperty: action.availablePageProperty}));
                
        case type.PAGE_PROPERTY_CREATED:
            {
                console.log('pageLinksReducer, '+action.type);
                state.availablePageProperty.availablePageProperty.push(action.pageProperty);
                let availablePageProperty = new AvailablePageProperty({availablePageProperty: state.availablePageProperty.availablePageProperty})
                return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, availablePageProperty: availablePageProperty}));
            }

        case type.PAGE_PROPERTY_UPDATED:
            {
                console.log('pageLinksReducer, '+action.type);
                //remove existing page property
                _.remove(state.availablePageProperty.availablePageProperty, (item) =>{
                    return (item.pagePropertyId === action.pageProperty?.pagePropertyId)
                });

                // add new page property
                state.availablePageProperty.availablePageProperty.push(action.pageProperty);
            
                let availablePageProperty = new AvailablePageProperty({availablePageProperty:state.availablePageProperty.availablePageProperty});
                return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, availablePageProperty: availablePageProperty}));
            }
        case type.PAGE_SIZE_UPDATED:
            {
                console.log('pageLinksReducer, '+action.type);
                //remove existing page property
                let existingPageProperty = state.availablePageProperty.availablePageProperty.find((item) =>{
                    return (item.pagePropertyId === action.pageProperty?.pagePropertyId)
                });

                if (existingPageProperty){
                    existingPageProperty.size = action.pageProperty.size;
                }
           
                let availablePageProperty = new AvailablePageProperty({availablePageProperty:state.availablePageProperty.availablePageProperty});
                return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, availablePageProperty: availablePageProperty}));
            }

        case type.PAGE_PROPERTY_DELETED:
            {
                console.log('pageLinksReducer, '+action.type);
                _.remove(state.availablePageProperty.availablePageProperty, (item) =>{
                    return (item.pagePropertyId === action.pageProperty?.pagePropertyId)
                });
            
                let availablePageProperty = new AvailablePageProperty({availablePageProperty:state.availablePageProperty.availablePageProperty});
                return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, availablePageProperty: availablePageProperty}));
            }

        case type.DOMAIN_LIST_LOADED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, domainList: action.domainList}));
                
        case type.DOMAIN_CREATED:
            console.log('pageLinksReducer, '+action.type);
            state.domainList.push(action.domain);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, domainList: state.domainList}));

        case type.DOMAIN_UPDATED:
            console.log('pageLinksReducer, '+action.type);
            let domain = _.find(state.domainList, (domain: Domain) =>{
                return (domain.domainId == action.domain.domainId);
            })
            domain && domain.copy(action.domain);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, domainList: state.domainList}));

        case type.DOMAIN_DELETED:
            console.log('pageLinksReducer, '+action.type);
            _.remove(state.domainList, (domain: Domain) =>{
                return (domain.domainId == action.domain.domainId);
            })
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, domainList: state.domainList}));
    
        
        case type.PAGE_SETTINGS_LOADED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, pageSettings: action.pageSettings}));
                
        case type.PAGE_SETTINGS_UPDATED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState(Object.assign({}, state, {state: type.PAGE_LINKS_LOADED, pageSettings: action.pageSettings}));

        case businessType.BUSINESS_SELECTED:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState ({});

        case authType.SIGNED_OUT:
            console.log('pageLinksReducer, '+action.type);
            return new PageLinksState ({});

        default:
            console.log('pageLinksReducer, default - '+ action.type);
            return state;
    }
};
