import type, { AuthAction } from './types';
import { AuthState } from './auth';

const INITIAL_STATE = new AuthState ();
   

export const authReducer = (state = INITIAL_STATE, action:AuthAction) => {

    switch (action.type){


        case type.SIGNED_IN:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGNED_IN, user: action.user, userSession: action.userSession, errorMessage:''};

        case type.SIGN_IN_PROGRESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_IN_PROGRESS, errorMessage:''};

        case type.SIGN_IN_FAILED:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_IN_FAILED, errorMessage:action.errorMessage};

        case type.SIGNED_OUT:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGNED_OUT, user: null, userSession: null, errorMessage:''};

        case type.SIGN_OUT_FAILED:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_OUT_FAILED, errorMessage: ''};
            
        case type.PASSWORD_RESET_IN_PROGRESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.PASSWORD_RESET_IN_PROGRESS, errorMessage:''};

        case type.PASSWORD_RESET_EMAIL_SENT:
            console.log('authReducer, '+action.type);
            console.log(action.errorMessage);
            return {...state, state: type.PASSWORD_RESET_EMAIL_SENT, errorMessage: ''};

        case type.PASSWORD_RESET_FAILED:
            console.log('authReducer, '+action.type);
            return {...state, state: type.PASSWORD_RESET_FAILED, errorMessage:action.errorMessage};

        case type.UPDATE_PASSWORD_IN_PROGRESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.UPDATE_PASSWORD_IN_PROGRESS, errorMessage:''};

        case type.UPDATE_PASSWORD_SUCCESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.UPDATE_PASSWORD_SUCCESS, errorMessage:''}; 
            
        case type.UPDATE_PASSWORD_FAILED:
            console.log('authReducer, '+action.type);
            return {...state, state: type.UPDATE_PASSWORD_FAILED, errorMessage:action.errorMessage};

        case type.SIGN_UP_IN_PROGRESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_UP_IN_PROGRESS, errorMessage:''};

        case type.SIGN_UP_SUCCESS:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_UP_SUCCESS, errorMessage:'', isNewUser: true};

        case type.SIGN_UP_FAILED:
            console.log('authReducer, '+action.type);
            return {...state, state: type.SIGN_UP_FAILED, errorMessage:action.errorMessage};

        case type.UPDATE_USER:
            console.log('authReducer, '+action.type);
            return {...state, user:action.user, errorMessage:'', isNewUser: action.isNewUser};
        default:
            console.log('authReducer, default - '+ action.type);
            return state;
    }
};
