import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Spinner } from '../shared/Spinner';

import { initiatePasswordReset } from './actions';
import type from './types';
import { AuthState } from './auth';

export interface Props {
  auth: AuthState,
  initiatePasswordReset: (email: string) => void,
}

export interface State {
  email: string,
  password: string,
  isEmailInvalid: boolean,
  isPasswordInvalid: boolean,
  emailErrorText: string, 
  passwordErrorText: string
}

export class PasswordReset extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('PasswordReset initialized');
    this.state = {
      email: '',
      password: '',
      isEmailInvalid: false,
      isPasswordInvalid: false,
      emailErrorText: '',
      passwordErrorText: ''
    }
  }

  render(){

    let render;
    
    if (this.props.auth.state == type.INITIALIZED || this.props.auth.state == type.PASSWORD_RESET_IN_PROGRESS){
      render = this.loading();
    }else if(this.props.auth.state == type.PASSWORD_RESET_EMAIL_SENT){
      render = this.passwordResetEmailSent();
    }else {
      render = this.passwordReset();
    }
    return render;
  }

  loading(){
    return ( <Spinner/> );
  }

  passwordResetEmailSent() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4> Password Reset </h4>
                <p> We've sent an email to {emailMask(this.state.email)}. Click the link in the email to reset your password.</p>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  passwordReset() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4>Password Reset</h4>
                <h6 className="font-weight-light">Enter your email</h6>
                <Form className="pt-3">
                  <Form.Group controlId="email" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="email" 
                      placeholder="Email" 
                      onChange={this.onChangeEmail.bind(this)} 
                      required
                      isInvalid = {this.state.isEmailInvalid}
                      value = {this.state.email}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.emailErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/" onClick={this.onPasswordReset.bind(this)} type="submit">CONTINUE</Link>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <div className="text-danger"> {this.props.auth.errorMessage} </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onChangeEmail(e: { target: { value: any; }; }){
    this.setState({email: e.target.value, isEmailInvalid: false});
  }

  onPasswordReset(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('PasswordReset, onPasswordReset');
    if (this.isFieldsValid()){
      this.props.initiatePasswordReset(this.state.email);
    }
  }

  isFieldsValid(){

    let isFieldValid = true;

    if(this.state.email === ''){
      this.setState({isEmailInvalid: true, emailErrorText: 'Email adress required'});
      isFieldValid = false;
    }else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(this.state.email))){
      this.setState({isEmailInvalid: true, emailErrorText: 'Valid email adress required'});
      isFieldValid = false;
    }

    console.log('isFieldValid - '+ JSON.stringify(isFieldValid));
    return isFieldValid;
  }

}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; email: string; }) => void) => {
  return {
    initiatePasswordReset: (email: string) => {
      dispatch(initiatePasswordReset(email))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

function emailMask(email: string) {
	var maskedEmail = email.replace(/([^@\.])/g, "*").split('');
	var previous	= "";
	for(let i=0;i<maskedEmail.length;i++){
		if (i<=1 || previous == "@"){
			maskedEmail[i] = email[i];
		}
		previous = email[i];
	}
	return maskedEmail.join('');
}
