import { Business, BusinessHours } from "app/business";
import BusinessHoursCard from "app/page/business/businessHoursCard";
import { GJSTagNames } from "../grapesjsEditor";



export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.BusinessHours) ;

  editor.Components.addType(GJSTagNames.BusinessHours, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessHoursCard,
        attributes: {
          business: new Business({}),
        },
        toolbar: [],
        traits: []
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.BusinessHours} model initialized`);
      },   
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.BusinessHours.toUpperCase();
    }
  });

  editor.BlockManager.add('Business Hours', {
    label: getBockLabel(),
    category: 'Elements',
    content: '<div><h4> Business Hours </h4><BusinessHours></BusinessHours></div>'
  });
};


function getBockLabel(){
  return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24">
              <path  d="M18 4H2V2H18V4M17.5 13H16V18L19.61 20.16L20.36 18.94L17.5 17.25V13M24 17C24 20.87 20.87 24 17 24C13.47 24 10.57 21.39 10.08 18H2V12H1V10L2 5H18L19 10V10.29C21.89 11.16 24 13.83 24 17M3.04 10H16.96L16.36 7H3.64L3.04 10M4 16H10V12H4V16M22 17C22 14.24 19.76 12 17 12S12 14.24 12 17 14.24 22 17 22 22 19.76 22 17Z" />
            </svg>          
            <div class="py-2">Business Hours</div>
          </div>`
}
