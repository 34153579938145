import _ from 'lodash';
import React from 'react';
import { Component } from 'react';
import { Form, Modal, Button, Col, Row  } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { BusinessHours, Hour } from './businessHours';
import { ErrorCode } from './types';
import TimePicker from 'react-time-picker';
import { Icon } from 'app/shared';
import { mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';

export interface Props {
 show: boolean,
 businessHours: BusinessHours,
 title?: string, 
 onHide: () => void,
 onCancel: () => void, 
 onSave: (businessHours: BusinessHours) => void,
 errorCode?: ErrorCode | '',
 saveInProgress?: boolean
}

export interface State {
  validated: boolean;
  businessHours: any;
  hours: Hour[] | undefined;
}

export class BusinessHoursInputDialog extends Component<Props, State> {
  businessHours: BusinessHours;
  title: string;
  dayHourRef: React.RefObject<DayHours>[] = [];
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('BusinessHoursInputDialog initialized');
    this.businessHours = (this.props.businessHours)? new BusinessHours(this.props.businessHours) : new BusinessHours({});

    this.title = (this.props.title)? this.props.title: 'Edit business hours';
  
    this.state = {
      validated: false,
      businessHours: {
        isOpen24Hours:{
          placeholder: 'Open 24 Hours',
          value: this.businessHours.isOpen24Hours,
          type: 'checkbox',
          required: false,
          readOnly: false
        },
        isClosed:{
          placeholder: 'Closed',
          value: this.businessHours.isClosed,
          type: 'checkbox',
          required: false,
          readOnly: false
        },         
      },
      hours: this.businessHours?.hours 
    }
  }

  componentDidUpdate(previoursProps: Props){
    if (!_.isEqual(previoursProps.businessHours, this.props.businessHours)){
    

      let businessHours = this.state.businessHours;
      businessHours.isOpen24Hours.value = this.props.businessHours.isOpen24Hours;
      businessHours.isClosed.value = this.props.businessHours.isClosed;

      this.setState({
        businessHours: businessHours,
        hours: new BusinessHours(this.props.businessHours).hours //deep copy needed
      })
    }
  }

  render() {
    let businessHours = this.state.businessHours;

    this.dayHourRef = [];
    let self = this;
    let {errorCode, saveInProgress } = this.props;

    let errorMessage = '';

    if (errorCode === ErrorCode.FAILED_TO_CREATE){
      errorMessage = `Failed to update businessHours, please try after sometime.`;
    }
    
    return (
  
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
       
        >
          <LoadingOverlay
            active={saveInProgress}
            spinner
            text='Loading ...'
            >
            <Modal.Header closeButton>
              {this.title}
            </Modal.Header>

            <Modal.Body>

    
                  <Form className="form-sample" id="businessHoursInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                    <div className="py-2">
                      <Row>
                          <Col xs={6}>
                            <Form.Group className="col-md-12">
                              <Form.Check type={businessHours.isOpen24Hours.type} name="isOpen24Hours" required={businessHours.isOpen24Hours.required} label={businessHours.isOpen24Hours.placeholder} onChange={e => self.isOpen24HoursChanged(e)} checked = {businessHours.isOpen24Hours.value} readOnly = {businessHours.isOpen24Hours.readOnly}/>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group className="col-md-4">
                              <Form.Check type={businessHours.isClosed.type} name='isClosed' required={businessHours.isClosed.required} label={businessHours.isClosed.placeholder} onChange={e => self.isClosedChanged(e)} checked = {businessHours.isClosed.value} readOnly = {businessHours.isClosed.readOnly}/>
                            </Form.Group>
                          </Col>
                      </Row>
                      </div>
                  </Form>
                {
                  this.state.hours?.map((hour: Hour, index: number) => {
                    console.log(` hours, ${JSON.stringify(index)}`);
                    let ref: React.RefObject<DayHours> = React.createRef();
                    this.dayHourRef.push(ref);

                    return (
                      <DayHours 
                        ref={ref}
                        index={index}
                        days={hour.days}
                        openTime={hour.openTime}
                        closeTime={hour.closeTime}
                        isClosed={hour.isClosed}
                        disabled={this.state.businessHours.isOpen24Hours.value || this.state.businessHours.isClosed.value }
                        onDelete={(index: number) => this.onDeleteHour(index)}
                      />
                    );
                  })
                }    
                <div className="col-md-12 d-flex justify-content-center" onClick={() => this.onAddHour()}>
                  <Icon className="pr-3" path={mdiPlusCircleOutline} />
                </div>
                <div className="col-md-12">
                  <div>{errorMessage}</div>
                </div>

            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
              <button type="submit" form="businessHoursInfo"  className="btn btn-primary btn-sm">Save</button>
            </Modal.Footer>
          </LoadingOverlay>
      </Modal>
    );
  }
  

  isOpen24HoursChanged(e: any){
    console.log('BusinessHoursInputDialog, onChange');
    console.log(e)
    let businessHours = this.state.businessHours;
   
    businessHours.isOpen24Hours.value = e.target.checked;
    if (businessHours.isOpen24Hours.value){
      businessHours.isClosed.value = false;
    }
    this.setState({businessHours:businessHours});
  }

  isClosedChanged(e: any){
    console.log('BusinessHoursInputDialog, onChange');
    console.log(e)
    let businessHours = this.state.businessHours;
    
    businessHours.isClosed.value = e.target.checked;

    if(businessHours.isClosed.value){
      businessHours.isOpen24Hours.value = false
    }
    this.setState({businessHours:businessHours});
  }

  onAddHour(){
    let hours = this.state.hours
    hours?.push(new Hour({}));
    this.setState({
      hours: hours
    })
  }

  onDeleteHour(index: number){
    console.log(`onDeleteHour, before - ${JSON.stringify(this.props.businessHours)}`);
    let hours = this.state.hours
    hours?.splice(index, 1);
    this.setState({
      hours: hours
    })

    console.log(`onDeleteHour, after - ${JSON.stringify(this.props.businessHours)}`);
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('BusinessHoursInputDialog, onSubmit');

    console.log(`onSubmit, old - ${JSON.stringify(this.props.businessHours)}`);

    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let businessHours =  this.getFormData();
      console.log(`onSubmit, new - ${JSON.stringify(businessHours)}`);

      this.setState({validated: false});
      this.props.onSave(businessHours)
    }
  }

  getFormData(){

 
    let businessHoursObj = Object.keys(this.state.businessHours).reduce((acc: any, key: string) =>{
      acc[key] = this.state.businessHours[key].value;
      return acc;
    }, {});

    console.log(`businessHoursObj, ${JSON.stringify(businessHoursObj)}`)

    let hours: Hour[] = [];
    this.dayHourRef.map(ref => {
      let hour = ref.current?.getFormData();
      if (hour) {
         hours.push(hour);
      }
    })

    console.log(`hours, ${JSON.stringify(hours)}`)
    return new BusinessHours({...businessHoursObj, ...{hours: hours}}); 
  }

}


export default BusinessHoursInputDialog;


export interface DayHoursProps {
  index: number,
  days: string[], 
  openTime: number | undefined,
  closeTime: number | undefined,
  isClosed: boolean | undefined,
  disabled: boolean,
  onDelete: (index: number) => void
 }
 
 export interface DayHoursState {
   validated: boolean,
   businessHours: any
 }
 
 export class DayHours extends Component<DayHoursProps, DayHoursState> {

   constructor(props: Readonly<DayHoursProps>){
     super(props);
     console.log('DayHours initialized');

     this.state = {
       validated: false,
       businessHours: {
         openTime:{
           placeholder: 'Open Time',
           value: this.props.openTime,
           type: 'text',
           required: false,
           readOnly: false
         },
         closeTime:{
           placeholder: 'Close Time',
           value: this.props.closeTime,
           type: 'text',
           required: false,
           readOnly: false
         },
         isClosed:{
           placeholder: 'Closed',
           value: this.props.isClosed,
           type: 'checkbox',
           required: true,
           readOnly: false
         },  
         days:{
           mon:{
              placeholder: 'Mon',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },    
            tue:{
              placeholder: 'Tue',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
            wed:{
              placeholder: 'Wed',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
            thu:{
              placeholder: 'Thu',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
            fri:{
              placeholder: 'Fri',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
            sat:{
              placeholder: 'Sat',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
            sun:{
              placeholder: 'Sun',
              value: false,
              type: 'checkbox',
              required: true,
              readOnly: false
            },  
         }      
       }
     }

     this.props.days.map((day) => {
      this.state.businessHours.days[day].value = true;
     });
   }
 
   render() {
     let businessHours = this.state.businessHours;
     let self = this;
    
     
     return ( 
          <div className="py-2  d-flex border-top align-items-center justify-content-between">  
            <Form className="col-11 form-sample" noValidate validated={this.state.validated}>
              <fieldset disabled={this.props.disabled}>
                
                  
                  <div className="d-inline-flex flex-wrap align-items-baseline justify-content-center">
                    { 
                      ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => {
                        return (
                          <div className="pr-3 d-inline-flex align-items-baseline">
                            <Form.Group>
                              <Form.Check type={businessHours.days[day].type} name={day}  required={businessHours.days[day].required} label={businessHours.days[day].placeholder} onChange={e => self.onDaysChange(e)} checked = {businessHours.days[day].value} readOnly = {businessHours.days[day].readOnly}/>
                            </Form.Group>
                          </div>
                        )
                      })
                    }       
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="d-inline-flex flex-wrap  flex-row align-items-baseline justify-content-center">
                      <div className="pr-2 d-inline-flex align-items-baseline">
                        <h5 className="text-muted pr-2">Opens</h5>
                        <TimePicker className="border border-0" disabled={this.state.businessHours.isClosed.value} name='openTime' disableClock={true} clearIcon={null} onChange={openTime => self.onOpenTimeChange(openTime)} value={new Date(businessHours.openTime.value)} />
                      </div>
                      <div className="pr-2 d-inline-flex align-items-baseline">
                        <h5 className="text-muted pr-2">Closes</h5>
                        <TimePicker className="border border-0" disabled={this.state.businessHours.isClosed.value} name='closeTime' disableClock={true} clearIcon={null} onChange={closeTime => self.onCloseTimeChange(closeTime)} value={new Date(businessHours.closeTime.value)} />
                      </div>
                    </div>
                    <div className="pr-2">
                      <Form.Group>
                        <Form.Check type={businessHours.isClosed.type} name='isClosed'  required={businessHours.isClosed.required} label={businessHours.isClosed.placeholder} onChange={e => self.onChange(e)} checked = {businessHours.isClosed.value} readOnly = {businessHours.isClosed.readOnly}/>
                      </Form.Group>
                    </div>
                  </div>
              </fieldset>
            </Form>
            <div className="col-1">
              <div className="d-inline-flex justify-content-end" onClick={() => this.onDeleteHour()}>
                <Icon className="pr-3" path={mdiTrashCanOutline} />
              </div>
            </div>
          </div>
     );
   }
 
   onChange(e: any){
     console.log('DayHours, onChange');
     console.log(e);
     let businessHours = this.state.businessHours;
     let key = e.target.name;
 
     if (e.target.type === 'checkbox'){
      businessHours[key].value = e.target.checked;
     }else{
      businessHours[key].value = e.target.value;
     }
     
     this.setState({businessHours:businessHours});
   }

   onOpenTimeChange(openTime: any){
    let businessHours = this.state.businessHours;
    businessHours.openTime.value = new Date(`2022-01-01T${openTime}:00`).getTime();
    this.setState({businessHours:businessHours});
   }

   onCloseTimeChange(closeTime: any){
    let businessHours = this.state.businessHours;
    businessHours.closeTime.value = new Date(`2022-01-01T${closeTime}:00`).getTime();
    console.log(businessHours.closeTime.value)
    this.setState({businessHours:businessHours});
   }

   onDaysChange(e: any){
    console.log('DayHours, onDaysChange');

    let businessHours = this.state.businessHours;
    let key = e.target.name;

    if (e.target.type === 'checkbox'){
     businessHours.days[key].value = e.target.checked;
    }else{
     businessHours.days[key].value = e.target.value;
    }
    
    this.setState({businessHours:businessHours});
  }
 
  onDeleteHour(){
    this.props.onDelete(this.props.index)

  }
 
   getFormData(){

     let businessHoursObj = Object.keys(this.state.businessHours).reduce((acc: any, key: string) =>{
       if (key !== 'days'){
        acc[key] = this.state.businessHours[key].value;
       }
       return acc;
     }, {});

     let days = Object.keys(this.state.businessHours.days).reduce((acc: any, key: string) =>{
        if(this.state.businessHours.days[key].value){
          acc.push(key)
        }
        return acc;
    }, []);
 
 
     return new Hour({ days: days, ...businessHoursObj});
   }
 
 }
