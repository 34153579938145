import { SKU, SKUStatus } from 'app/billing/payment';
import { Component } from 'react';
import SuspendedDialog from './suspendedDialog';
import UpgradeDialog from './upgradeDialog';


export interface Props {
  title: string, 
  sku: SKU,
  show: boolean,
  onHide: () => void,
  onCancel: () => void, 
}

export interface State {
  validated: boolean, 
}

export class SKUStatusDialog extends Component <Props, State>{
 

  constructor(props: Props){
    super(props);
    console.log('SKUStatusDialog initialized');

    this.state = {
      validated: false,
    }
  }

  render() {

    const {title, show, onHide, onCancel } = this.props;
    const message = this.props.sku.getMaxLimitDisplayMessage();
    switch (this.props.sku.status){

      case SKUStatus.maxLimitReached:
        return (
          <UpgradeDialog title={title} show={show} onHide={ () => onHide()} onCancel={() => onCancel()}>
            <div>
              <p>{message.line1}</p>
              <p>{message.line2}</p>
            </div>
          </UpgradeDialog>
        );
        

      case SKUStatus.suspended:
        return (
        <SuspendedDialog title={title} show={show} onHide={ () => onHide()} onCancel={() => onCancel()}/>
        );
      default:
        return null;
    }
    
  }

}


export default SKUStatusDialog;