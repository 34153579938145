import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchUserPermissionList } from './firebaseActions';
import { UserPermission } from './userPermission';

//Action creators

export const loadUserPermissions = (businessId: string) => {
    console.log('userPermissions, action, loadUserPermissions, businessId - '+ businessId);
    return { type: type.LOAD_USER_PERMISSIONS, businessId }
};

export const initiateCreateUserPermission = (businessId: string, userPermission: UserPermission, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateUserPermission');
    return { type: type.INITIATE_CREATE_USER_PERMISSION, businessId, userPermission, onSuccess, onError }
};

export const initiateSaveUserPermission = (businessId: string, userPermission: UserPermission, onSuccess: any, onError: any) => {
    console.log('user, action, initiateSaveUserPermission');
    let action = null;
    if (userPermission.userId != ''){
        action = { type: type.INITIATE_UPDATE_USER_PERMISSION, businessId, userPermission, onSuccess, onError };
    }else{
        action = { type: type.INITIATE_CREATE_USER_PERMISSION, businessId, userPermission, onSuccess, onError };
    }
    return action;
};

export const initiateDeleteUserPermission = (businessId: string, userPermission: UserPermission, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateUserPermission');
    return { type: type.INITIATE_DELETE_USER_PERMISSION, businessId, userPermission, onSuccess, onError }
};

const retrievedUserPermissionList = (userPermissionList: UserPermission[]) => {
    return { type: type.USER_PERMISSION_LOADED, userPermissionList }
};

const emptyUserPermissionList = () => {
    return { type: type.USER_PERMISSION_LIST_EMPTY }
};

const failedToRetrieveUserPermissionList = () => {
    return { type: type.FAILED_TO_LOAD_USER_PERMISSIONS }
};

const createUserPermission = (userPermission: UserPermission) => {
    return { type: type.CREATE_USER_PERMISSION, userPermission}
};

const updateUserPermission = (userPermission: UserPermission) => {
    return { type: type.UPDATE_USER_PERMISSION, userPermission}
};

const deleteUserPermission = (userPermission: UserPermission) => {
    return { type: type.DELETE_USER_PERMISSION, userPermission}
};

// Sagas

function* loadUserPermissionsAsync(action: { businessId: string; }) {
    console.log('userPermission, action, loadUserPermissionsAsync - '+ JSON.stringify(action));
    try {
  
        const userPermissionList: UserPermission[] = yield call(fetchUserPermissionList, action.businessId);
        console.log(JSON.stringify(userPermissionList));
        if (userPermissionList.length > 0){
            yield put(retrievedUserPermissionList(userPermissionList));
        }else{
            yield put(emptyUserPermissionList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveUserPermissionList());
    }
}

function* createUserPermissionAsync(action: { userPermission: UserPermission; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('userPermission, action, createUserPermissionAsync');
   
    try {
        yield call(action.userPermission.create.bind(action.userPermission), action.businessId);
        yield put(createUserPermission(action.userPermission));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* updateUserPermissionAsync(action: { userPermission: UserPermission; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('userPermission, action, updateUserPermissionAsync');
   
    try {
        yield call(action.userPermission.update.bind(action.userPermission), action.businessId);
        yield put(updateUserPermission(action.userPermission));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteUserPermissionAsync(action: { userPermission: UserPermission; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('userPermission, action, deleteUserPermissionAsync');
   
    try {
        yield call(action.userPermission.delete.bind(action.userPermission), action.businessId);
        yield put(deleteUserPermission(action.userPermission));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const userPermissionSagas = [
    takeEvery<any>(type.LOAD_USER_PERMISSIONS, loadUserPermissionsAsync),
    takeEvery<any>(type.INITIATE_CREATE_USER_PERMISSION, createUserPermissionAsync),
    takeEvery<any>(type.INITIATE_UPDATE_USER_PERMISSION, updateUserPermissionAsync),
    takeEvery<any>(type.INITIATE_DELETE_USER_PERMISSION, deleteUserPermissionAsync)
];
