import { put, takeEvery, call } from 'redux-saga/effects';

import type, { ErrorCode } from './types';
import { changeDomain, createDomain, createPageProperty, deleteDomain, deletePageProperty, fetchDomainList, retrieveAvailablePageProperty, retrievePageLinks, retrievePageSettings, updateDomain, updatePageLinks, updatePageProperty, updatePageSettings, updatePageSize } from './firebaseActions';
import { PageLinks } from './pageLinks';
import { AvailablePageProperty, PageProperty } from './pageProperty';
import { Domain } from './domain';
import { PageSettings } from './pageSettings';

//Action creators

export const loadPageLinks = (businessId: string) => {
    console.log('pageLinks, action, loadPageLinks, businessId - '+ businessId);
    return { type: type.LOAD_PAGE_LINKS, businessId }
};

export const initiateUpdatePageLinks = (businessId: string, pageLinks: PageLinks, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateUpdatePageLinks');

    return { type: type.INITIATE_UPDATE_PAGE_LINKS, businessId, pageLinks, onSuccess, onError };
};

const retrievedPageLinks = (pageLinks: PageLinks) => {
    return { type: type.PAGE_LINKS_LOADED, pageLinks }
};

const failedToRetrievePageLinks = () => {
    return { type: type.FAILED_TO_LOAD_PAGE_LINKS }
};


const updatedPageLinks = (pageLinks: PageLinks) => {
    return { type: type.UPDATED_PAGE_LINKS, pageLinks}
};

export const loadAvailablePageProperty = (businessId: string) => {
    console.log('pageLinks, action, loadAvailablePageProperty, businessId - '+ businessId);
    return { type: type.LOAD_AVAILABLE_PAGE_PROPERTY, businessId }
};

export const initiateCreatePageProperty = (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateCreatePageProperty');
    return { type: type.INITIATE_CREATE_PAGE_PROPERTY, businessId, pageProperty, onSuccess, onError };
};

export const initiateUpdatePageProperty = (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateUpdatePageProperty');
    return { type: type.INITIATE_UPDATE_PAGE_PROPERTY, businessId, pageProperty, onSuccess, onError };
};

export const initiateUpdatePageSize = (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateUpdatePageSize');
    return { type: type.INITIATE_UPDATE_PAGE_SIZE, businessId, pageProperty, onSuccess, onError };
};

export const initiateDeletePageProperty = (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateDeletePageProperty');
    return { type: type.INITIATE_DELETE_PAGE_PROPERTY, businessId, pageProperty, onSuccess, onError };
};


const retrievedAvailablePageProperty = (availablePageProperty: AvailablePageProperty) => {
    return { type: type.AVAILABLE_PAGE_PROPERTY_LOADED, availablePageProperty }
};

const failedToRetrieveAvailablePageProperty = () => {
    return { type: type.FAILED_TO_LOAD_AVAILABLE_PAGE_PROPERTY }
};

const pagePropertyCreated = (pageProperty: PageProperty) => {
    return { type: type.PAGE_PROPERTY_CREATED, pageProperty}
};

const pagePropertyUpdated = (pageProperty: PageProperty) => {
    return { type: type.PAGE_PROPERTY_UPDATED, pageProperty}
};

const pagePropertyDeleted = (pageProperty: PageProperty) => {
    return { type: type.PAGE_PROPERTY_DELETED, pageProperty}
};


export const loadDomainList = (businessId: string) => {
    console.log('pageLinks, action, loadAvailablePageProperty, businessId - '+ businessId);
    return { type: type.LOAD_DOMAIN_LIST, businessId }
};

export const initiateCreateDomain = (businessId: string, domain: Domain, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateCreateDomain');

    return { type: type.CREATE_DOMAIN, businessId, domain, onSuccess, onError };
};

export const initiateUpdateDomain = (businessId: string, domain: Domain, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateUpdateDomain');

    return { type: type.UPDATE_DOMAIN, businessId, domain, onSuccess, onError };
};

export const initiateDeleteDomain = (businessId: string, domain: Domain, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateDeleteDomain');

    return { type: type.DELETE_DOMAIN, businessId, domain, onSuccess, onError };
};

export const initiateChangeDomain = (businessId: string, domain: Domain, oldDomain: Domain, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateChangeDomain');

    return { type: type.CHANGE_DOMAIN, businessId, domain, oldDomain, onSuccess, onError };
};

const domainListLoaded = (domainList: Domain[]) => {
    return { type: type.DOMAIN_LIST_LOADED, domainList }
};

const failedToLoadDomainList = () => {
    return { type: type.FAILED_TO_LOAD_DOMAIN_LIST }
};


const domainCreated = (domain: Domain) => {
    return { type: type.DOMAIN_CREATED, domain}
};

const domainUpdated = (domain: Domain) => {
    return { type: type.DOMAIN_UPDATED, domain}
};

const domainDeleted = (domain: Domain) => {
    return { type: type.DOMAIN_DELETED, domain}
};


// page settings
export const loadPageSettings = (businessId: string) => {
    console.log('pageLinks, action, loadPageSettings, businessId - '+ businessId);
    return { type: type.LOAD_PAGE_SETTINGS, businessId }
};

export const initiateUpdatePageSettings = (businessId: string, pageSettings: PageSettings, onSuccess: any, onError: any) => {
    console.log('pageLinks, action, initiateUpdatePageSettings');

    return { type: type.INITIATE_UPDATE_PAGE_SETTINGS, businessId, pageSettings, onSuccess, onError };
};

const pageSettingsLoaded = (pageSettings: PageSettings) => {
    return { type: type.PAGE_SETTINGS_LOADED, pageSettings }
};

const failedToLoadPageSettings = () => {
    return { type: type.FAILED_TO_LOAD_PAGE_SETTINGS }
};


const pageSettingsUpdated = (pageSettings: PageSettings) => {
    return { type: type.PAGE_SETTINGS_UPDATED, pageSettings}
};

// Sagas

// page links
function* loadPageLinksAsync(action: { businessId: string; }) {
    console.log('pageLinks, action, loadPageLinksAsync - '+ JSON.stringify(action));
    try {
  
        const pageLinks: PageLinks = yield call(retrievePageLinks, action.businessId);

        console.log(JSON.stringify(pageLinks));
        yield put(retrievedPageLinks(pageLinks));
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrievePageLinks());
    }
}

function* updatePageLinksAsync(action: { businessId: string; pageLinks: PageLinks; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, updatePageLinksAsync');
   
    try {
        yield call(updatePageLinks, action.businessId, action.pageLinks);
        yield put(updatedPageLinks(action.pageLinks));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

// page property
function* loadAvailablPagePropertyAsync(action: { businessId: string; }) {
    console.log('pageLinks, action, loadAvailablPagePropertyAsync - '+ JSON.stringify(action));
    try {
  
        const availablePageProperty: AvailablePageProperty = yield call(retrieveAvailablePageProperty, action.businessId);

        console.log(JSON.stringify(availablePageProperty));
        yield put(retrievedAvailablePageProperty(availablePageProperty));
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveAvailablePageProperty());
    }
}

function* createPagePropertyAsync(action: { businessId: string; pageProperty: PageProperty; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, createPagePropertyAsync');
   
    try {
        yield call(createPageProperty, action.businessId, action.pageProperty);
        yield put(pagePropertyCreated(action.pageProperty));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* updatePagePropertyAsync(action: { businessId: string; pageProperty: PageProperty; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, updatePagePropertyAsync');
   
    try {
        yield call(updatePageProperty, action.businessId, action.pageProperty);
        yield put(pagePropertyUpdated(action.pageProperty));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* updatePageSizeAsync(action: { businessId: string; pageProperty: PageProperty; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, updatePageSizeAsync');
   
    try {
        yield call(updatePageSize, action.businessId, action.pageProperty);
        yield put(pagePropertyUpdated(action.pageProperty));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deletePagePropertyAsync(action: { businessId: string; pageProperty: PageProperty; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, deletePagePropertyAsync');
   
    try {
        yield call(deletePageProperty, action.businessId, action.pageProperty);
        yield put(pagePropertyDeleted(action.pageProperty));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

// domain list
function* loadDomainListAsync(action: { businessId: string; }) {
    console.log('pageLinks, action, loadAvailablDomainAsync - '+ JSON.stringify(action));
    try {
  
        const domainList: Domain[] = yield call(fetchDomainList, action.businessId);

        console.log(JSON.stringify(domainList));
        yield put(domainListLoaded(domainList));
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToLoadDomainList());
    }
}

function* createDomainAsync(action: { businessId: string; domain: Domain; onSuccess: () => void; onError: (domain: Domain, errorCode: ErrorCode) => void; }) {
    console.log('pageLinks, action, createDomainAsync');
   
    try {
        yield call(createDomain, action.businessId, action.domain);
        yield put(domainCreated(action.domain));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError(action.domain, ErrorCode.DOMAIN_ALREADY_EXISTING);
    }
}

function* updateDomainAsync(action: { businessId: string; domain: Domain; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, updateAvailableDomainAsync');
   
    try {
        yield call(updateDomain, action.businessId, action.domain);
        yield put(domainUpdated(action.domain));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteDomainAsync(action: { businessId: string; domain: Domain; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, deleteDomainAsync');
   
    try {
        yield call(deleteDomain, action.businessId, action.domain);
        yield put(domainDeleted(action.domain));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* changeDomainAsync(action: { businessId: string; domain: Domain; oldDomain: Domain, onSuccess: () => void; onError: (domain: Domain, errorCode: ErrorCode) => void; }) {
    console.log('pageLinks, action, changeDomainAsync');
   
    try {
        yield call(changeDomain, action.businessId, action.domain, action.oldDomain);
        yield put(domainUpdated(action.domain));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError(action.domain, ErrorCode.DOMAIN_ALREADY_EXISTING);
    }
}

// page settings
function* loadPageSettingsAsync(action: { businessId: string; }) {
    console.log('pageLinks, action, loadPageSettingsAsync - '+ JSON.stringify(action));
    try {
  
        const pageSettings: PageSettings = yield call(retrievePageSettings, action.businessId);

        console.log(JSON.stringify(pageSettings));
        yield put(pageSettingsLoaded(pageSettings));
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToLoadPageSettings());
    }
}

function* updatePageSettingsAsync(action: { businessId: string; pageSettings: PageSettings; onSuccess: () => void; onError: () => void; }) {
    console.log('pageLinks, action, updatePageSettingsAsync');
   
    try {
        yield call(updatePageSettings, action.businessId, action.pageSettings);
        yield put(pageSettingsUpdated(action.pageSettings));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

export const pageLinksSagas = [
    takeEvery<any>(type.LOAD_PAGE_LINKS, loadPageLinksAsync),
    takeEvery<any>(type.INITIATE_UPDATE_PAGE_LINKS, updatePageLinksAsync),

    takeEvery<any>(type.LOAD_AVAILABLE_PAGE_PROPERTY, loadAvailablPagePropertyAsync),
    takeEvery<any>(type.INITIATE_CREATE_PAGE_PROPERTY, createPagePropertyAsync),
    takeEvery<any>(type.INITIATE_UPDATE_PAGE_PROPERTY, updatePagePropertyAsync),
    takeEvery<any>(type.INITIATE_UPDATE_PAGE_SIZE, updatePageSizeAsync),
    takeEvery<any>(type.INITIATE_DELETE_PAGE_PROPERTY, deletePagePropertyAsync),

    takeEvery<any>(type.LOAD_DOMAIN_LIST, loadDomainListAsync),
    takeEvery<any>(type.CREATE_DOMAIN, createDomainAsync),
    takeEvery<any>(type.UPDATE_DOMAIN, updateDomainAsync),
    takeEvery<any>(type.DELETE_DOMAIN, deleteDomainAsync),
    takeEvery<any>(type.CHANGE_DOMAIN, changeDomainAsync),

    takeEvery<any>(type.LOAD_PAGE_LINKS, loadPageSettingsAsync),
    takeEvery<any>(type.INITIATE_UPDATE_PAGE_SETTINGS, updatePageSettingsAsync),
];
