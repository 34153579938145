import { BusinessState } from 'app/business';
import { Component } from 'react';
import { Tabs, Tab, Container, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';
import { initiateUpdatePageSize, initiateUpdatePageSettings, loadAvailablePageProperty, loadDomainList, loadPageLinks, loadPageSettings } from './actions';
import { deleteAsset, downloadPage, uploadAsset, uploadPage } from './firebaseActions';

import { PageLinksState } from './pageLinks';
import { AvailablePageProperty, PageProperty } from './pageProperty';
import { PageSettings } from './pageSettings';
import types from './types';
import { alert, IconButton } from '../shared';
import { GrapesjsEditor } from './grapesjsEditor/grapesjsEditor';
import Spinner from 'app/shared/Spinner';
import { mdiExitToApp } from "@mdi/js";
import { DomainType } from './domain';
import { calculateObjectSize }  from 'bson';
import { Payment, PaymentState, SKUName } from 'app/billing/payment';
import { loadProducts, ProductState } from 'app/product';
import _ from 'lodash';

export interface PageProps {
  businessState: BusinessState;
  pageLinksState: PageLinksState;
  paymentState: PaymentState,
  productState: ProductState,
  loadAvailablePageProperty:(businessId: string) => void;
  loadPageLinks: (businessId: string) => void;
  loadPageSettings: (businessId: string) => void;
  initiateUpdatePageSettings: (businessId: string, pageSettings: PageSettings, onSuccess: any, onError: any) => void;
  loadDomainList: (businessId: string) => void;
  initiateUpdatePageSize: (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => void;
  loadProducts: (businessId: string) => void;
  location: any
}

function Page(props: PageProps) {
  
  let params: any = useParams();
  let pageId =  (params.id)?params.id: 'page1';

  if (props.pageLinksState.state !== types.PAGE_LINKS_LOADED){
    props.loadAvailablePageProperty(props.businessState.selectedBusiness.businessId);
    props.loadPageLinks(props.businessState.selectedBusiness.businessId);
    props.loadPageSettings(props.businessState.selectedBusiness.businessId);
    props.loadDomainList(props.businessState.selectedBusiness.businessId);
    props.loadProducts(props.businessState.selectedBusiness.businessId);
  }

  return (
    <GPage key={props.businessState.selectedBusiness.businessId+pageId} 
            pageId={pageId} 
            businessState={props.businessState} 
            pageLinksState={props.pageLinksState} 
            pageSettingsUpdated={(pageSettings: PageSettings)=> pageSettingsUpdated(props, pageSettings)} 
            pageSizeUpdated={(pageProperty: PageProperty)=> pageSizeUpdated(props, pageProperty)} 
            payment={props.paymentState.payment}
            productState={props.productState}
            location={props.location}/> 
  )
}

const pageSettingsUpdated = (props:PageProps, pageSettings: PageSettings) => {
  props.initiateUpdatePageSettings(props.businessState.selectedBusiness.businessId, pageSettings, 
    ()=> {
      console.log(`Page, successfully updated the page settings`);
    }, 
    ()=>{
      console.log(`Page, failed to updated the page settings`);
    });
}

const pageSizeUpdated = (props:PageProps, pageProperty: PageProperty) => {
  props.initiateUpdatePageSize(props.businessState.selectedBusiness.businessId, pageProperty, 
    ()=> {
      console.log(`Page, page size updated`);
    }, 
    ()=>{
      console.log(`Page, page size update failed`);
    });
}

const mapStateToProps = (state: { business: any; pageLinks:any, billing:any, product:any}) => {
  return { 
    businessState: state.business, 
    pageLinksState: state.pageLinks, 
    paymentState: state.billing,
    productState: state.product
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; availablePageProperty?: AvailablePageProperty; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadAvailablePageProperty: (businessId: string) =>{
      dispatch(loadAvailablePageProperty(businessId));
    },
    loadPageLinks: (businessId: string) =>{
      dispatch(loadPageLinks(businessId));
    },
    loadPageSettings: (businessId: string) =>{
      dispatch(loadPageSettings(businessId));
    },
    initiateUpdatePageSettings: (businessId: string, pageSettings: PageSettings, onSuccess: any, onError: any) =>{
      dispatch(initiateUpdatePageSettings(businessId, pageSettings, onSuccess, onError));
    },
    loadDomainList: (businessId: string) =>{
      dispatch(loadDomainList(businessId));
    },
    initiateUpdatePageSize: (businessId: string, pageProperty: PageProperty, onSuccess: any, onError: any) => {
      dispatch(initiateUpdatePageSize(businessId, pageProperty, onSuccess, onError))
    },
    loadProducts: (businessId: string) =>{
      dispatch(loadProducts(businessId));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));



export interface Props {
  pageId: string; 
  businessState: BusinessState;
  pageLinksState: PageLinksState;
  payment: Payment;
  productState: ProductState;
  pageSettingsUpdated: (pageSettings: PageSettings) => void; 
  pageSizeUpdated: (pageProperty: PageProperty) => void;
  location: any;
}

export interface State {
  editor: GrapesjsEditor | null;
  editable: boolean;
  editMode: boolean;
  isPageLoading: boolean;
  isContentChanged: boolean;
  pageSize: Number;
  pageSizeBkup: Number;
}

class GPage extends Component<Props, State> {

  constructor(props: any){
    super(props);

    this.state = {
      editor: null,
      editable: this.props.businessState.canEdit(),
      editMode: false,
      isPageLoading: false,
      isContentChanged: false,
      pageSize: 0,
      pageSizeBkup: 0
    }
  }

  async componentDidMount(){
    console.log('GPage, component did mount');
    let businessId = this.props.businessState.selectedBusiness.businessId;
    let business = this.props.businessState.selectedBusiness;
    let pageLinks = this.props.pageLinksState.pageLinks;
    let pageSettings = this.props.pageLinksState.pageSettings;
  

    this.setState({editor: null, isPageLoading: true});
    // download page
    let loadData = await downloadPage(businessId, this.props.pageId);
    console.log(`GPage, Downloded page -${JSON.stringify(loadData)}`)
    this.setState({isPageLoading: false});
   
    let self = this;
    let editor = new GrapesjsEditor({
        containerId: 'editor', 
        setHeader: () => { return self.setHeader() },
        setFooter: () => { return self.setFooter() },
        setBusiness: () => { return self.setBusiness() },
        setProduct: () => { return self.setProduct() },
        setPageLinks: () => { return self.setPageLinks() },
        onChange: () => { return self.onChange()},
        getAvailablePageProperty: () => { 
          return self.props.pageLinksState.availablePageProperty 
        },
        uploadAsset: (pictureFile) => { return self.uploadAsset(pictureFile)},
        onStoreStart: (data: any) => { self.onStoreStart(data)},
        onAddImage: (pictureUrl: string ) => { self.onAddImage(pictureUrl)},
        onAssetRemove: (pictureUrl: string ) => { self.onAssetRemove(pictureUrl)}
      });

    editor.loadPageData(loadData);

    // load component settings
    editor.loadComponentSettings(business, pageLinks, pageSettings);

    editor.loadAssets(pageSettings);

    let pageSize = calculateObjectSize(loadData);

    //save editor
    this.setState({editor: editor, pageSize: pageSize});
     
  }

  componentDidUpdate(previousProps: Props){
    console.log(`GPage, component did update`);
    let business = this.props.businessState.selectedBusiness;
    let pageLinks = this.props.pageLinksState.pageLinks;
    let pageSettings = this.props.pageLinksState.pageSettings;


    if (this.state.editor && !this.state.editMode){
      if(!_.isEqual(previousProps, this.props)){
        this.state.editor.loadComponentSettings(business, pageLinks, pageSettings);
        this.state.editor.loadAssets(pageSettings);
      }
    }
  }

  render() {
    console.log('GPage, component render');
    let businessName = this.props.businessState.selectedBusiness.name;
    let availablePageProperty = this.props.pageLinksState.availablePageProperty.availablePageProperty;
    let currentPageProperty = availablePageProperty.find(pageProperty => pageProperty.pagePropertyId === this.props.pageId);
    let defaultDomain = this.props.pageLinksState.domainList.find(domain => domain.type === DomainType.default);
    let viewPageUrl = 'https://' + defaultDomain?.name + '/'+ this.props.pageId;
    
    return (
      <div>
        <div id="navbar" className="sidenav d-flex flex-column">
          <Navbar  expand="lg" className="px-0 pb-0">
            <Container className="flex-column flex-xg-row px-0">
              <Navbar.Brand href="">
                {businessName}
                <Link className="" to={''}>
                  <IconButton path={mdiExitToApp} toolTipText={"Exit to app"} toolTipPosition="right"/>
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {
              (this.state.editMode)?
              (<Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  
                  <Nav.Item><span>{currentPageProperty?.name}</span></Nav.Item>

                </Nav>
              </Navbar.Collapse>)
              :
              (
                <nav className="sidebar sidebar-offcanvas" id="sidebar-readonlymode">
                  <ul className="nav">
                  {
                    availablePageProperty.map(pageProperty =>{
                      return (
                        <li className={(currentPageProperty?.pagePropertyId === pageProperty.pagePropertyId)?"nav-item active": "nav-item"}> <Link className={ (currentPageProperty?.pagePropertyId === pageProperty.pagePropertyId)? 'nav-link active' : 'nav-link text-muted' } to={`/online/page/${pageProperty.pagePropertyId}`}> <span className="menu-title"> {pageProperty.name} </span></Link></li>
                      )
                    })
                  }
                  </ul>
                </nav>
              )
              }
            </Container>
          </Navbar>
                    
          <div className="my-4" style={{display: (this.state.editMode)?'block':'none'}}>
            <Tabs defaultActiveKey="settings" id="grapesjs-configuration" className="justify-content-center pb-2" variant="pills">
              <Tab eventKey="settings" title="Settings">
                <div className="">
                  <div id="traits-container" className="my-3"></div>
                  <div> </div>
                  <div id="styles-container"></div>
                </div>
              </Tab>
              <Tab eventKey="blocks" title="Blocks">
                <div id="blocks"></div>
              </Tab>
            </Tabs>
          </div>

        </div>
        {  (this.state.isPageLoading)?
        (<Spinner/>):   
        (<div className="main-content">
          <nav className="navbar navbar-light">
            <div className="container-fluid">
              <div className="d-flex">
                <div className="panel__devices"></div>
              </div>

            { (this.state.editable)?
            
            (<div className="d-flex justify-content-center align-items-baseline mr-5 my-2">
                  <a className="page-size cursor-default pe-none mr-3 text-muted">{Math.round(this.state.pageSize.valueOf()/1024)} KB</a>
                  <div className="panel__basic-actions mr-2"></div>
                  {  (!this.state.editMode)? 
                  (
                      <div className="d-flex justify-content-center align-items-center">
                        <a className="view-page mr-2 text-muted" href={viewPageUrl} target="_blank" rel="noopener noreferrer">View page</a>
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.onEnableEditMode.bind(this)}>Edit</button>
                      </div>
                  ):(
                      <div className="d-flex justify-content-center mr-2">
                        
                        <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={this.onCancelEditMode.bind(this)}>Cancel</button>
                      
                        <button type="button" disabled={!this.state.isContentChanged} className="btn btn-primary btn-sm" onClick={this.onSaveEditMode.bind(this)}>Save</button>
                    
                      </div>
                    )
                  } 
                </div>):null
            }
            </div>
          </nav>
          <div id="editor"> </div>
        </div>)
        }
      </div>
    )
  }

  isPathActive(path: string) {
    return this.props.location.pathname.startsWith(path);
  }

  onEnableEditMode(){
    this.setState({editMode: true, isContentChanged: false, pageSizeBkup: this.state.pageSize});
    // enable edit for all components
    this.state.editor?.onEdit();


  }

  onCancelEditMode(){
    this.setState({editMode: false, isContentChanged: false, pageSize: this.state.pageSizeBkup});

    // disable edit for all coponents
    this.state.editor?.onDiscard();
  }

  async onSaveEditMode(){
    this.setState({isContentChanged: false});

    
      if (this.state.editor ){
        console.log(`GPage, editor is available`);

        let storageSKU = this.props.payment.currentEstimation.transaction.skus.find(sku => sku.name === SKUName.storage);
        //page data
        let data = this.state.editor.getPageData();
        let nativeComponents = this.state.editor.getNativeComponents();

        let pageSize = calculateObjectSize(data);
        this.setState({pageSize: pageSize});

        let changeInStorage = storageSKU?(storageSKU.units + (pageSize - this.state.pageSizeBkup.valueOf())):-1;
        if (storageSKU && changeInStorage < storageSKU.freeUnits){

          let businessId = this.props.businessState.selectedBusiness.businessId;
          this.state.editor?.onSave();

          //update page settings
          let pageSettings = this.state.editor.getPageSettings();

          this.props.pageLinksState.pageSettings.header = pageSettings.header;
          this.props.pageLinksState.pageSettings.footer = pageSettings.footer;
          this.props.pageSettingsUpdated(this.props.pageLinksState.pageSettings);
          console.log(`Save page ${JSON.stringify(this.props.pageLinksState.pageSettings.assets)}`);

          //update page settings
          let availablePageProperty = this.props.pageLinksState.availablePageProperty.availablePageProperty;
          let currentPageProperty = availablePageProperty.find(pageProperty => pageProperty.pagePropertyId === this.props.pageId);
          if(currentPageProperty){
            currentPageProperty.size = pageSize;
            currentPageProperty.components = nativeComponents;
            this.props.pageSizeUpdated(currentPageProperty);
          }

          //upload page

          try{
            await uploadPage(businessId, this.props.pageId, data);
            alert.info('Page saved & published!');
            this.setState({editMode: false});
          }catch(e){
            alert.error('Failed to save the page!');
            this.setState({editMode: false});
          }
        }else{
          console.log(`GPage, page size exceeds the storage limit page size -${pageSize}, allocedStorage-${storageSKU?.freeUnits}, changeInStorage-${changeInStorage}`);
          alert.error('Exceeds allowed storage limit');
        }
      }else{
        console.log(`GPage, editor is null`);
      }
  }

  onChange(){
    console.log(`GPage, onChange`);
    this.setState({isContentChanged: true});
  }

  setHeader(){
    let business = this.props.businessState.selectedBusiness;
    let pageLinks = this.props.pageLinksState.pageLinks;
    let pageSettings = this.props.pageLinksState.pageSettings;

    return {attributes: Object.assign({}, pageSettings.header.attributes, {business: business, businessHours: business.businessHours, links: pageLinks}), style: pageSettings.header.style};
  }

  setFooter(){
    let business = this.props.businessState.selectedBusiness;
    let pageLinks = this.props.pageLinksState.pageLinks;
    let pageSettings = this.props.pageLinksState.pageSettings;

    return {attributes: Object.assign({}, pageSettings.footer.attributes, {business: business, businessHours: business.businessHours, links: pageLinks}), style: pageSettings.footer.style}
  }

  setBusiness(){
    let business = this.props.businessState.selectedBusiness;
    return {attributes: {business: business}};
  }

  setProduct(){
    let business = this.props.businessState.selectedBusiness;
    let productList = this.props.productState.productList;
    return {attributes: {productList: productList, businessId: business.businessId}};
  }

  setPageLinks(){
    let pageLinks = this.props.pageLinksState.pageLinks;
    return {attributes: {links: pageLinks}};
  }

  async uploadAsset(pictureFile: File){
    console.log(`GPage, uploadAsset, file name-${pictureFile.name}, size-${pictureFile.size}}`)
    let imageFileSizeSKU = this.props.payment.currentEstimation.transaction.skus.find(sku => sku.name === SKUName.imageFileSize);
    let storageSKU = this.props.payment.currentEstimation.transaction.skus.find(sku => sku.name === SKUName.storage);
    
    if (imageFileSizeSKU && pictureFile.size > imageFileSizeSKU.freeUnits){
      console.log(`GPage, uploadAsset, image file greater than allowed image size uploaded- ${pictureFile.size}, allowed- ${imageFileSizeSKU.freeUnits} `)
      alert.error(`Exceeds allowed file size. Should be less than ${imageFileSizeSKU.freeUnits/1000000} MB.`);
      return Promise.reject();

    }else if(storageSKU && storageSKU.units > storageSKU.freeUnits){
      console.log(`GPage, uploadAsset, storge size exceeded the allowed limit storage consumed-${storageSKU.units}`)
      alert.error('Exceeds allowed storage limit');
      return Promise.reject();

    }else if (imageFileSizeSKU && storageSKU){
      let business = this.props.businessState.selectedBusiness;
      let pictureUrl = await uploadAsset(business.businessId, pictureFile);

      let pageSettings = this.props.pageLinksState.pageSettings;
      pageSettings.addImage(pictureUrl, pictureFile.size);
      this.props.pageSettingsUpdated(pageSettings);

      return JSON.stringify({
        data: [
          pictureUrl
        ]
      });
    }else{
      console.log(`GPage, uploadAsset, no access to image file size & storage skus`);
      alert.error('Sorry, something had gone wrong. Please refresh and try again');
      return Promise.reject();
    }
  }

  async deleteAsset(pictureUrl: string){
    await deleteAsset(pictureUrl);
  }

  onAddImage(pictureUrl: string){
    console.log(`GPage, onAddImage, picture url - ${pictureUrl}`);
    // user is adding image through add image url and not doing upload
    let pageSettings = this.props.pageLinksState.pageSettings;
    pageSettings.addImage(pictureUrl, 0);
    this.props.pageSettingsUpdated(pageSettings);
  }

  onAssetRemove(pictureUrl: string){
    console.log(`GPage, onAssetRemove, picture url - ${pictureUrl}`);
    let pageSettings = this.props.pageLinksState.pageSettings;
    pageSettings.removeImage(pictureUrl);
    this.props.pageSettingsUpdated(pageSettings);

    // delete from service as well
    this.deleteAsset(pictureUrl);
  }

  onStoreStart(data: any){
    console.log(`GPage, onStoreStart`);
    // using only to find the modified page size
    let pageSize = calculateObjectSize(data);
    this.setState({pageSize: pageSize});
  }

}
