import React, { Component } from 'react';
import './rewards.css';
import { Reward } from './reward';
import { RewardListItem } from './rewardListItem';

export interface Props {
  businessId: string,
  rewardList: Reward[],
  canEdit: boolean,
}

export interface State {
  
}

export class RewardList extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('RewardList initialized');
  }

  render() {
    let { businessId, rewardList, canEdit } = this.props;

    return (
      <div>
        {
          rewardList.map((item)=>{
            return (
              <RewardListItem 
                key={item.rewardId}
                businessId={businessId}
                reward={item}
                canEdit={canEdit}
                onDelete={this.onDelete.bind(this)}
              />
            );
          })
        }
      </div>
    )
  }

  onDelete(rewardId: string){
      //reward is deleted, do any clean up if needed.
  }
}
