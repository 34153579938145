import { AvailablePageProperty } from "app/page/pageProperty";
import { GJSTagNames } from "../grapesjsEditor";

export type CallToActionConfig = {
  option: any;
  getAvailablePageProperty?: () => AvailablePageProperty;
}

export default (editor: any, options: CallToActionConfig) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.CtaButton) ;

  let defaults = {
    getAvailablePageProperty: () => new AvailablePageProperty({})
  }

  let config = Object.assign({}, defaults, options);
  
  editor.Components.addType(GJSTagNames.CtaButton, {
    extend: 'text',
    model: {
      defaults: {
        components: 'Get Started',
        styles: getStyles(),
        getAvailablePageProperty: config.getAvailablePageProperty,
        highlightable: false,
        attributes: {
          type: 'button',
          pagePropertyId: '',
          newTab: false,
          href: '',
          customeURL: '',
        },
        toolbar: [],
        traits: [
          { 
            type: 'select', 
            label: 'Page', 
            name: 'pagePropertyId', 
            options: [
              
              { id: 'customeURL', name: 'Custome URL'},
            ]
          },
          { 
            type: 'checkbox', 
            label: 'Open link in new tab', 
            name: 'newTab', 
          },
        ],
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.CtaButton} model initialized`);

        // @ts-ignore
        let pagePropertyId = this.getAttributes().pagePropertyId;
        if(pagePropertyId === 'customeURL'){
          this.removeCustomeURLTrait();
          this.addCustomeURLTrait();
        }

        // @ts-ignore
        editor.on('component:selected', this.onSelect.bind(this));
        // @ts-ignore
        this.listenTo(this, 'change:attributes:pagePropertyId', this.handlePageIdChanged);
      },

      handlePageIdChanged(){
        // @ts-ignore
        let pagePropertyId = this.getAttributes().pagePropertyId;
        if(pagePropertyId === 'customeURL'){
          this.removeCustomeURLTrait();
          this.addCustomeURLTrait();
          // @ts-ignore
          this.addAttributes({'href': ''});
        }else{
          this.removeCustomeURLTrait();
          let href = `${pagePropertyId}`
          // @ts-ignore
          this.addAttributes({'href': href});
        }
      },

      addCustomeURLTrait(){
        // @ts-ignore
        this.addTrait({
            name: 'customeURL', 
            type: 'text', 
            label: 'Custome URL', 
            placeholder: 'https://yoursite.com'
        }, {at:2});
      },

      removeCustomeURLTrait(){
        // @ts-ignore
        this.removeTrait('customeURL')
      },

      onSelect(){
        console.log(`GrapesJS, ${GJSTagNames.CtaButton} onSelect`);

        // @ts-ignore
        let getAvailablePageProperty = this.get('getAvailablePageProperty');
        let options: any = [];

        if (getAvailablePageProperty){
          let availablePageProperty: AvailablePageProperty = getAvailablePageProperty();
          options = availablePageProperty.availablePageProperty.map(pageProperty => {
            return {id: pageProperty.pagePropertyId, name: pageProperty.name}
          })
        }

        options.push({id: 'customeURL', name: 'Custome URL'});
        // @ts-ignore
        this.getTrait('pagePropertyId').set('options', options);
      },
    },
    view:{
      
    },

    isComponent: (el: { tagName: string; }) => { return el.tagName === GJSTagNames.CtaButton.toUpperCase();},
  });

  editor.BlockManager.add('Button', {
    label: getBockLabel(),
    category: 'Elements',
    content: '<CtaButton></CtaButton>'
  });
};

function getStyles(){
  return `
    CtaButton {
      
    }
    CtaButton:hover{
      cursor: pointer;
    }`
  }

  function getBockLabel(){
    return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24">
              <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
            </svg>          
            <div>Link</div>
            </div>`
  }
  