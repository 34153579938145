import Container from 'react-bootstrap/Container';
import React, { Component } from "react";
import BusinessCard from "./businessCard";
import BusinessDescription from "./businessDescription";
import BusinessKeywords from "./businessKeywords";
import { Business, BusinessHours } from 'app/business';
import { PageLinks, PageLinkLocation } from '../pageLinks';
import BusinessPageLinks from './businessPageLinks';


export interface Props {
   business: Business;
   businessHours: BusinessHours;
   links: PageLinks;
   showHours: boolean;
   showAddress: boolean,
   showProfilePicture:boolean
}

class BusinessHeader extends Component<Props>{

    render(){

        const { business, showHours, showAddress, showProfilePicture } = this.props;
        return(
        <header className="businessHeader" data-gjs-editable={false}>
            <Container >
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6">
                        <BusinessCard business={this.props.business} showHours={showHours} showAddress={showAddress} showProfilePicture={showProfilePicture}/>
                    </div>
                    <div className="col-12 col-md-4 col-lg-6 py-3 py-md-0 d-flex justify-content-center justify-content-md-center">
                        <div className="d-flex flex-column justify-content-start"> 
                            <BusinessDescription description={this.props.business.description}/>
                            <BusinessKeywords keywords={business.keywords}/>
                        </div>
                    </div>
                </div>
                <BusinessPageLinks links={this.props.links} location={PageLinkLocation.header}/>
            </Container>
        </header>
        );
    }
}

export default BusinessHeader;