import type from './types';
import {type as authType} from '../user';
import {type as businessType} from '../business';
import { APISetting, APISettingState } from './apiSetting';

const INITIAL_STATE = new APISettingState ();
   

export const apiSettingReducer = (state = INITIAL_STATE, action: { type: string; apiSetting: any; clientId: string; clientSecret: string; }) => {

    switch (action.type){


        case type.API_SETTINGS_LOADED:
            console.log('apiSettingReducer, '+action.type);
            return {...state, state: type.API_SETTINGS_LOADED, apiSetting: action.apiSetting};

        case type.FAILED_TO_LOAD_API_SETTINGS:
            console.log('apiSettingReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_API_SETTINGS, apiSetting: new APISetting({})};
            
        case type.API_SECRET_UPDATED:
            console.log('apiSettingReducer, '+action.type); 

            state.apiSetting.oAuth.find(oAuth => {
                if (oAuth.clientId === action.clientId){
                    oAuth.clientSecret = action.clientSecret;
                }
             })

            return {...state, state: type.API_SETTINGS_LOADED}
       
        case businessType.BUSINESS_SELECTED:
            console.log('apiSettingReducer, '+action.type);
            return {...state, state: type.API_SETTINGS_INITIALIZED, apiSetting: new APISetting({})};

        case authType.SIGNED_OUT:
            console.log('apiSettingReducer, '+action.type);
            return {...state, state: type.API_SETTINGS_INITIALIZED, apiSetting: new APISetting({})};

        default:
            console.log('apiSettingReducer, default - '+ action.type);
            return state;
    }
};
