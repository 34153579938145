export type MetadataData = {

    createdBy?: string;
    createdAt?: number;
    lastModifiedAt?: number;
    deletedBy?: string;
    deletedAt?: number;
    reasonToDelete?: string;
    callToDiscuss?: boolean;
}

export class Metadata {
    createdBy: string;
    createdAt: number;
    lastModifiedAt: number;
    deletedBy: string;
    deletedAt: number;
    reasonToDelete: string;
    callToDiscuss: boolean;

    constructor(metadata: MetadataData){
        this.createdBy = metadata.createdBy || '';
        this.createdAt = metadata.createdAt || 0;

        this.lastModifiedAt = metadata.lastModifiedAt || 0;

        this.deletedBy = metadata.deletedBy || '';
        this.deletedAt = metadata.deletedAt || 0;
        this.reasonToDelete = metadata.reasonToDelete || '';
        this.callToDiscuss = metadata.callToDiscuss || false;
    }

    isDifferent(address: Metadata){
        let isDifferent = false;
        if (this.createdBy !== address.createdBy || this.createdAt !== address.createdAt || this.lastModifiedAt !== address.lastModifiedAt){
            isDifferent = true;
        }
        return isDifferent;
    }

    serialize(){
        return {
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            lastModifiedAt: this.lastModifiedAt,
            deletedBy: this.deletedBy,
            deletedAt: this.deletedAt,
            reasonToDelete: this.reasonToDelete,
            callToDiscuss: this.callToDiscuss
        };
    }

    create(createdBy: string|null|undefined){
        if (createdBy && createdBy !== null){
            this.createdBy = createdBy;
            this.createdAt = new Date().getTime();
            this.lastModifiedAt = this.createdAt;
        }else{
            throw 'CreatedBy cant be null or undefined';
        }

    }

    update(){
        this.lastModifiedAt = new Date().getTime();
    }

    delete(deletedBy: string|null|undefined, reasonToDelete: string, callToDiscuss: boolean){
        if (deletedBy && deletedBy !== null){
            this.deletedBy = deletedBy;
            this.deletedAt = new Date().getTime();
            this.reasonToDelete = reasonToDelete;
            this.callToDiscuss = callToDiscuss;
        }else{
            throw 'DeletedBy cant be null or undefined';
        }

    }
}