import type from './types';
import {type as authType} from '../user';
import { SearchResult, SearchResultState } from './searchResult';

const INITIAL_STATE = new SearchResultState ();
   

export const searchResultReducer = (state = INITIAL_STATE, action: { type: string; searchResultList: SearchResult[];}) => {

    switch (action.type){

        case type.SEARCH_RESULTS_LOADED:
            console.log('searchResultReducer, '+action.type);
            return {...state, state: type.SEARCH_RESULTS_LOADED, searchResultList: action.searchResultList};

        case type.MORE_SEARCH_RESULTS_LOADED:
            console.log('searchResultReducer, '+action.type);
            Array.prototype.push.apply(state.searchResultList, action.searchResultList);
            return {...state, state: type.SEARCH_RESULTS_LOADED, searchResultList: state.searchResultList};

        case type.SEARCH_RESULT_LIST_EMPTY:
            console.log('searchResultReducer, '+action.type);
            return {...state, state: type.SEARCH_RESULT_LIST_EMPTY, searchResultList:[]};

        case type.FAILED_TO_LOAD_SEARCH_RESULTS:
            console.log('searchResultReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_SEARCH_RESULTS};

       
        case authType.SIGNED_OUT:
            console.log('searchResultReducer, '+action.type);
            return {...state, state: type.SEARCH_RESULT_INITIALIZED, searchResultList:[]};

        default:
            console.log('searchResultReducer, default - '+ action.type);
            return state;
    }
};
