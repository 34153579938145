import Nouislider from "nouislider-react";
import { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

export interface ImpagePreviewProps {
    name: string,
    aspect: any,
    src: string,
    show: boolean,
    onHide: (name: string) => void,
    onCancel: (name: string) => void,
    onSubmit: (key: string, croppedImageUrl: string, croppedImageFile: File) => void
  }
  
  export interface ImagePreviewState {
    
  }
  
  export class ImagePreview extends Component<ImpagePreviewProps, ImagePreviewState> {
  
    state = {
      imageSrc:
        'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000',
      crop: { x: 5, y: 10 },
      zoom: 1,
      aspect: this.props.aspect,
      croppedAreaPixels: {} 
    }
  
    render(){
      
      return(
        <Modal
          show={this.props.show}
          onHide={ () => this.props.onHide(this.props.name)}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
          >
  
          <Modal.Header closeButton>
          </Modal.Header>
  
          <Modal.Body>
            <div style={{width:700, height:300}}>
              <Cropper
                image={this.props.src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
          </Modal.Body>
  
          <Modal.Footer className="flex-wrap">
            <div style={{width:"45%"}} >
              <Nouislider range={{ min: 1, max: 5 }} start={this.state.zoom} connect={[true, false]} className="slider-primary" onSlide={this.onSlide}/>
            </div>
            <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel(this.props.name)}>Cancel</Button>
            <Button variant="primary btn-sm m-2" onClick={this.onSubmit.bind(this)}>Apply</Button>
          </Modal.Footer>
        </Modal>
  
      );
    }
  
    onCropChange = (crop: any) => {
      this.setState({ crop })
    }
  
    onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
      console.log(croppedArea, croppedAreaPixels)
      this.setState({croppedAreaPixels});
    }
  
    onZoomChange = (zoom: any) => {
      this.setState({ zoom })
    }
  
    onSlide = (render: any, handle: any, value: number[], un: any, percent: any) => {
      this.setState({
        zoom: value[0].toFixed(2),
      });
    };
  
    async onSubmit(){
      console.log('ImagePreview, onSubmit')
      const [croppedImageUrl, croppedImageFile] = await getCroppedImg(this.props.src, this.state.croppedAreaPixels)
      console.log('done', { croppedImageUrl })
      this.props.onSubmit(this.props.name, croppedImageUrl, croppedImageFile)
    }
  
  }