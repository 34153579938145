import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Spinner } from '../shared/Spinner';

import { initiateSignIn, initiateSignInWithGoogle } from './actions';
import type from './types';

import google from '../../assets/img/btn_google_signin.png'
import { AuthState } from './auth';

export interface Props {
  auth: AuthState,
  initiateSignIn: (email: string, password: string) => void,
  initiateSignInWithGoogle: () => void
}

export interface State {
  email: string,
  password: string,
  isEmailInvalid: boolean,
  isPasswordInvalid: boolean,
  emailErrorText: string, 
  passwordErrorText: string
}

export class Login extends Component<Props, State> {

  constructor(props: Props){
    super(props);
    console.log('Login initialized');
    this.state = {
      email: '',
      password: '',
      isEmailInvalid: false,
      isPasswordInvalid: false,
      emailErrorText: '',
      passwordErrorText: ''
    }
  }

  render(){
    let render = null;
    
      if (this.props.auth.state == type.INITIALIZED || this.props.auth.state == type.SIGN_IN_PROGRESS) {
        render = this.loading();
      }else if (this.props.auth.state == type.SIGNED_OUT || this.props.auth.state == type.SIGN_IN_FAILED){
        render = this.login();
      } 
      return render;
  }

  loading(){
    return ( <Spinner/> );
  }

  login() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <Form className="pt-3">
                  <Form.Group controlId="email" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="email" 
                      placeholder="Email" 
                      onChange={this.onChangeEmail.bind(this)} 
                      required
                      isInvalid = {this.state.isEmailInvalid}
                      value = {this.state.email}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.emailErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="password" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="password" 
                      placeholder="Password" 
                      onChange={this.onChangePassword.bind(this)} 
                      required
                      isInvalid = {this.state.isPasswordInvalid}
                      value = {this.state.password}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.passwordErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/" onClick={this.onSignIn.bind(this)} type="submit">SIGN IN</Link>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="text-center font-weight-light">
                      Don't have an account? <a href="/signup" className="text-primary">Create</a>
                    </div>
                    <a href="/passwordReset"  className="auth-link text-black">Forgot password?</a>
                  </div>
                  <div className="mt-1 d-flex justify-content-center">
                    <h6 className="font-weight-light">Or</h6>
                  </div>
                  <div className="mt-1 d-flex justify-content-center">
                    <img src={google} className="btn" style={socialSignIn} onClick={this.onSignInWithGoogle.bind(this)}/>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <div className="text-danger"> {this.props.auth.errorMessage} </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onChangeEmail(e: { target: { value: any; }; }){
    this.setState({email: e.target.value, isEmailInvalid: false});
  }

  onChangePassword(e: { target: { value: any; }; }){
    this.setState({password: e.target.value, isPasswordInvalid: false});
  }

  onSignIn(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('login, onSignIn');
    if (this.isFieldsValid()){
      this.props.initiateSignIn(this.state.email, this.state.password);
    }
  }

  onSignInWithGoogle(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('login, onSignInWithGoogle')
    this.props.initiateSignInWithGoogle();
  }

  isFieldsValid(){

    let isFieldValid = true;

    if(this.state.email === ''){
      this.setState({isEmailInvalid: true, emailErrorText: 'Email adress required'});
      isFieldValid = false;
    }else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(this.state.email))){
      this.setState({isEmailInvalid: true, emailErrorText: 'Valid email adress required'});
      isFieldValid = false;
    }


    if(this.state.password === ''){
      this.setState({isPasswordInvalid: true, passwordErrorText: 'Password required'});
      isFieldValid = false;
    }

    console.log('isFieldValid - '+ JSON.stringify(isFieldValid));
    return isFieldValid;
  }

}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; email?: string; password?: string; }) => void) => {
  return {
    initiateSignIn: (email: string, password: string) => {
      dispatch(initiateSignIn(email, password))
    },
    initiateSignInWithGoogle: () => {
      dispatch(initiateSignInWithGoogle())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const socialSignIn = {
  height: '60px',
  padding: 0
}