
import React, { Component } from 'react';

interface Props {
    style?: any,
    description: string
  }

interface State { }

export default class BusinessDescription extends Component<Props, State> {
    static propType: {};
    static defaultProps: {};

    render(){
        const { style={}, description } = this.props;

        return (
            <h4 {...this.props}>{description}</h4>
        );
    }
}

