import { PaymentState, SKUName } from 'app/billing/payment';
import { ImagePreview, Img, alert } from 'app/shared';
import _ from 'lodash';
import React from 'react';
import { Component } from 'react';
import { Form  } from 'react-bootstrap';
import { Product } from './product';
import { ErrorCode } from './types';

export interface Props {
 
 product: Product | null | undefined,
 editMode: boolean,
 onSave: (product: Product) => void,
 saveRef: any;
 errorCode: ErrorCode | '',
 saveInProgress: boolean,
 paymentState: PaymentState,
}

export interface State {
  validated: boolean,
  product: any
}

export class ProductMerchantDetails extends Component<Props, State> {
  disabled: boolean;
  product: Product;
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductMerchantDetails initialized');
    this.product = (this.props.product)? this.props.product : new Product({});
    this.disabled = !this.props.editMode || this.props.saveInProgress
    
    this.state = {
      validated: false,
      product: {
        isPhysicalProduct:{
          placeholder: 'Physical product',
          value: this.product.isPhysicalProduct,
          type: 'checkbox',
          required: false,
          readOnly: false
        },
        name:{
          placeholder: 'Enter product name',
          value: this.product.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
          readOnly: false
        },
        description: {
          placeholder: 'Enter product description',
          value: this.product.description,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
          readOnly: false
        },
        picture:{
          value: this.product.picture,
          file: null,
          noImage: 'https://via.placeholder.com/92x92',
          ref: React.createRef(),
          showPreview: false,
          preview: '',
          deletePicture: '',
          originalPicture: this.product.picture,
          readOnly: false
        },
        collection:{
          placeholder: 'Enter product collection',
          value: this.product.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
          readOnly: false
        },
        price:{
          placeholder: 'Enter price',
          value: this.product.price,
          type: 'number',
          pattern:'[0-9.]{1,}',
          required: true,
          errorText: '',
          min: 0,
          step: 0.01,
          readOnly: false
        },
        comparePrice:{
          placeholder: 'Enter compare price',
          value: this.product.comparePrice,
          type: 'number',
          pattern:'[0-9.]{1,}',
          required: true,
          errorText: '',
          min: 0,
          step: 0.01,
          readOnly: false
        },
        cost:{
          placeholder: 'Enter cost',
          value: this.product.cost,
          type: 'number',
          pattern:'[0-9.]{1,}',
          required: true,
          errorText: '',
          min: 0,
          step: 0.01,
          readOnly: false
        },
        showPrice:{
          placeholder: 'Show price',
          value: this.product.showPrice,
          type: 'checkbox',
          required: false,
          readOnly: false
        },
        quantity:{
          placeholder: 'Enter units',
          value: this.product.quantity,
          type: 'number',
          pattern:'[0-9]{1,}',
          required: true,
          errorText: '',
          min: 0,
          readOnly: false
        },
        hasUnlimitedQuantity:{
          placeholder: 'Unlimited quantity',
          value: this.product.hasUnlimitedQuantity,
          type: 'checkbox',
          required: false,
          readOnly: false
        },
        isPublished:{
          placeholder: 'Publish',
          value: this.product.isPublished,
          type: 'checkbox',
          required: false,
          readOnly: false
        },
      }
    }
  }

  componentDidUpdate(previoursProps: Props){
    
    if(!_.isEqual(previoursProps, this.props)){
      this.disabled = !this.props.editMode || this.props.saveInProgress

      let product = this.state.product;
      for (const key in product) {
        if (this.props.product){
          product[key].value = this.props.product[(key as keyof Product)];
        }
      }
      this.setState({product: product})
      console.log('ProductMerchantDetails, Props are not equal')
    }else{
      console.log('ProductMerchantDetails, Props are equal')
    }
  }

  render() {
      return this.renderForm();
  }

  renderForm() {
    let product = this.state.product;
    let self = this;
    let {errorCode, saveInProgress } = this.props;

    let errorMessage = ''; 

    if(errorCode === ErrorCode.PRODUCT_ALREADY_EXISTING){
      errorMessage = `A product with the same phone number is already existing.`;
    }else if (errorCode === ErrorCode.FAILED_TO_CREATE){
      errorMessage = `Failed to create a product, please try after sometime.`;
    }
    
    return (
            <div className="card py-3">
              <div className="card-body"> 
                  <div className="col-md-12">
                    <div>{errorMessage}</div>
                  </div>
                  <Form className="form-sample" id="productInfo" noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                  <fieldset disabled={this.disabled}>
                    <div className="py-2 row">
                      <div className="col-12 d-flex justify-content-center mb-4" style={{position: 'relative', zIndex:1}}>
                        <div style={{width:"20%"}}>
                          <Img key={product.picture.value} src={product.picture.value} fallbackSrc={product.picture.noImage} alt="profile" className="rounded border  border-gray " style={{width:"100%", height:"100%", objectFit:"cover"}}/>
                        </div>
                        <div className={this.disabled?"d-none":"d-block"} style={{position: 'absolute', zIndex:3, left:"50%", top:"50%"}}>
                          <i className="ti-image rounded border border-white bg-light mr-4" onClick={() => self.onPictureClick('picture')}/>
                          <input style={{display:'none'}} type="file" accept="image/png, image/jpeg" name="picture" ref={product.picture.ref} onChange={e => self.onPictureSelect(e)}/>
                          <ImagePreview aspect={4/4} src={product.picture.preview} name='picture' show={product.picture.showPreview} onHide={(name: string) => self.onPreviewHide(name)} onCancel={(name: string) => self.onPreviewCancel(name)} onSubmit={(name: string, croppedImageUrl: string, croppedImageFile: File) => self.onPreviewSubmit(name, croppedImageUrl, croppedImageFile)} />
                          {
                            (product.picture.value !== '')? <i className="ti-close rounded border border-white bg-light " onClick={() => self.onPictureRemove('picture')}></i>:null
                          }
                        </div>
                      </div>
                      <Form.Group className="col-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type={product.name.type} name="name" pattern={product.name.pattern} required={product.name.required} placeholder={product.name.placeholder} onChange={e => self.onChange(e)} value = {product.name.value} readOnly = {product.name.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-12">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className="form-control" as="textarea" rows={4} name="description" required={product.description.required} placeholder={product.description.placeholder} onChange={e => self.onChange(e)} value = {product.description.value} readOnly = {product.description.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-md-12">
                        <Form.Label>Collection</Form.Label>
                        <Form.Control type={product.collection.type} name='collection' pattern={product.collection.pattern} required={product.collection.required} placeholder={product.collection.placeholder} onChange={e => self.onChange(e)} value = {product.collection.value} readOnly = {product.collection.readOnly}/>
                      </Form.Group>
                      <hr/>

                      <Form.Group className="col-6">
                        <Form.Check type={product.isPublished.type} name='isPublished' label={product.isPublished.placeholder} onChange={e => self.onCheckboxChange(e)} checked = {product.isPublished.value} readOnly = {product.isPublished.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Check type={product.isPhysicalProduct.type} name='isPhysicalProduct' label={product.isPhysicalProduct.placeholder} onChange={e => self.onCheckboxChange(e)} checked = {product.isPhysicalProduct.value} readOnly = {product.isPhysicalProduct.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type={product.price.type} name='price' min={product.price.min} step={product.price.step} required={product.price.required} placeholder={product.price.placeholder} onChange={e => self.onChange(e)} value = {product.price.value} readOnly = {product.price.readOnly}/>
                      </Form.Group>
                      <Form.Group className="col-6">
                        <Form.Label></Form.Label>
                        <Form.Check type={product.showPrice.type} name='showPrice' label={product.showPrice.placeholder} onChange={e => self.onCheckboxChange(e)} checked = {product.showPrice.value} readOnly = {product.showPrice.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Label>Compare Price</Form.Label>
                        <Form.Control type={product.comparePrice.type} name='comparePrice' min={product.comparePrice.min} step={product.comparePrice.step} required={product.comparePrice.required} placeholder={product.comparePrice.placeholder} onChange={e => self.onChange(e)} value = {product.comparePrice.value} readOnly = {product.comparePrice.readOnly}/>
                      </Form.Group>
                      <Form.Group className="col-6">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control type={product.cost.type} name='cost' min={product.cost.min} step={product.cost.step} required={product.cost.required} placeholder={product.cost.placeholder} onChange={e => self.onChange(e)} value = {product.cost.value} readOnly = {product.cost.readOnly}/>
                      </Form.Group>

                      <Form.Group className="col-6">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type={product.quantity.type} name='quantity' min={product.quantity.min} step={product.quantity.step} required={product.quantity.required} placeholder={product.quantity.placeholder} onChange={e => self.onChange(e)} value = {product.quantity.value} readOnly = {product.quantity.readOnly}/>
                      </Form.Group>
                      <Form.Group className="col-6">
                        <Form.Label></Form.Label>
                        <Form.Check type={product.hasUnlimitedQuantity.type} name='hasUnlimitedQuantity' label={product.hasUnlimitedQuantity.placeholder} onChange={e => self.onCheckboxChange(e)} checked = {product.hasUnlimitedQuantity.value} readOnly = {product.hasUnlimitedQuantity.readOnly}/>
                      </Form.Group>
                      {(this.disabled)?null:(<button ref={this.props.saveRef} type="submit" style={{ display: 'none' }} />)}
                    </div>
                    </fieldset>
                  </Form>
              </div>
            </div>
    );
  }


  onChange(e: any){
    console.log('CreateProduct, onChange');

    let product = this.state.product;
    let key = e.target.name;
   
    product[key].value = e.target.value;
    
    this.setState({product:product});
  }

  onCheckboxChange(e: any){
    console.log('CreateProduct, onCheckboxChange');

    let product = this.state.product;
    let key = e.target.name;
    if (e.target.checked){
      product[key].value = true;
    }else{
      product[key].value = false;
    }

    if (key === 'hasUnlimitedQuantity'){
      product.quantity.readOnly =  product[key].value;
    }

    this.setState({product:product});
  }

  onPictureClick(key: string){
    console.log('onPictureClick');
    this.state.product[key].ref.current.click();
  }
 
  onPictureRemove(key: string){
    let product = this.state.product;
    
      if(product[key].value === product[key].originalPicture){
        // user is deleting the existing picture
        product[key].deletePicture = product[key].value;
        product[key].value = '';
      }else{
        product[key].value = product[key].originalPicture;
      }
      product[key].file = null;
      this.setState({product:product});
  }

  onPictureSelect(e: any){
    console.log('onPictureSelect');
    let key = e.target.name;
    let product = this.state.product;
    let file =  e.target.files[0];
    let reader =  new FileReader();
    
    reader.onloadend = () => {
      product[key].showPreview = true;
      product[key].preview = reader.result;
      this.setState({product:product});
    }
    reader.readAsDataURL(file);
  }

  onPreviewCancel(key: string){
    console.log('onPreviewCancel');
    let product = this.state.product;
    product[key].showPreview = false;
    product[key].preview = '';
    this.setState({product:product});
  }

  onPreviewSubmit(key: string, croppedImageUrl: string, croppedImageFile: File){
    console.log('onPreviewSubmit');
    let product = this.state.product;
    let imageFileSizeSKU = this.props.paymentState.getSKU(SKUName.imageFileSize);
    product[key].showPreview = false;
    product[key].preview = '';

    if (imageFileSizeSKU.freeUnits >  croppedImageFile.size){
    
      if(product[key].value === product[key].originalPicture){
        // user is deleting the existing picture
        product[key].deletePicture = product[key].value;
      }
      
      product[key].value = croppedImageUrl;
      product[key].file = croppedImageFile;
      
      
      console.log(`cropped image file size - ${croppedImageFile.size}`)
    
      this.setState({product:product});
    }else{
      //file size is big,
      alert.error(`Image size should be less than ${imageFileSizeSKU.freeUnits/1000000} KB.`);
    }
  }

  onPreviewHide(key: string){
    console.log('onHide');
    let product = this.state.product;
    product[key].showPreview = false;
    product[key].preview = '';
    this.setState({product:product});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('CreateProduct, onSubmit');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let product =  this.getFormData();
      console.log(JSON.stringify(product));

      this.setState({validated: false});
      this.props.onSave(product);
    }
  }

  getFormData(){

 
    let productObj = Object.keys(this.state.product).reduce((acc: any, key: string) =>{
      acc[key] = this.state.product[key].value;
      return acc;
    }, {});


    let product = new Product({...this.product, ...productObj});

    product.deletePicture = this.state.product.picture.deletePicture;
    product.newPictureFile = this.state.product.picture.file; 

    return product;
  }

}


export default ProductMerchantDetails;