

import firebase, { firestore } from '../firebase.js';
import { CurrentPlans } from './plans';

export async function fetchCurrentPlans(){

  console.log('fetchCurrentPlans, firebaseActions');

  let currentPlans:CurrentPlans = new CurrentPlans({});
  let currentPlansRef =  firestore.collection('plans').doc('current');

  let currentPlansDoc = await currentPlansRef.get();
  
  if (currentPlansDoc.exists){
    console.log('fetchCurrentPlans, currentPlansDoc exists');
    currentPlans = new CurrentPlans(currentPlansDoc.data() as any);

  }else{
      console.log(`fetchCurrentPlans, firebaseActions, current plans not found`);
  }

  return currentPlans;
}


