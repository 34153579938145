import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { sendEmailVerification } from './actions';
import { AuthState } from './auth';


export interface Props {
  auth: AuthState,
  sendEmailVerification: () => void,
}

export interface State {
  sendEmailInProgress: boolean,
}

export class ActivateAccount extends Component<Props, State> {

  constructor(props: Props){
    super(props);
    console.log('ActivateAccount initialized');

    this.state = {
      sendEmailInProgress: false
    }
  }

  render(){

    let render;
    
    if (this.props.auth.userSession.emailVerified){
      render = <Redirect to='/dashboard' />
    }else {
      console.log('ActivateAccount, email not verified - '+ this.props.auth.user.email);
      render = this.activateAccount();
    }
    return render;
  }

  activateAccount() {
    return (
      <div>
        <div className="d-flex align-items-center auth p-5">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h4>One last step to get started!</h4>
                <p>We've sent an email to {emailMask(this.props.auth.user.email)}. Click the link in the email to activate your account.</p>
                <div className="mt-5 d-flex justify-content-center">
                  <div className="text-danger"> {this.props.auth.errorMessage} </div>
                </div>
                {
                (!this.state.sendEmailInProgress)
                ? <a href= '' onClick={this.onSendEmailVerification.bind(this)} className="auth-link text-black">Resend activation email.</a>
                : <Spinner animation="border" variant="secondary"/>
                }
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }


  onSendEmailVerification(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('ActivateAccount, onSendEmailVerification');
    this.setState({sendEmailInProgress: true});
    this.props.sendEmailVerification();
    let self = this;
    setTimeout(()=>{
      self.setState({sendEmailInProgress: false});
    }, 1000);
  }

}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; }) => void) => {
  return {
    sendEmailVerification: () => {
      dispatch(sendEmailVerification())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);

function emailMask(email: string ) {
	var maskedEmail = email.replace(/([^@\.])/g, "*").split('');
	var previous	= "";
	for(let i=0;i<maskedEmail.length;i++){
		if (i<=1 || previous == "@"){
			maskedEmail[i] = email[i];
		}
		previous = email[i];
	}
	return maskedEmail.join('');
}
