
export interface AppSettings{
  appName: string;
  webClientId: string;
  forceConcentPrompt: boolean;
  siteURL: string;
  termsOfService: string;
  privacyPolicy: string;
  passwordReset: string;
  brandName: string;
  brandMessage: string;
  apiURL: string;
  appStore: string;
  playStore: string;
  storage: string;
  support: string;
}

export const settings: AppSettings = {
  appName: 'Resquash',
  webClientId: '377730056925-2flnm7sreclj13cg4hgsacuc4ujd1qfc.apps.googleusercontent.com',
  forceConcentPrompt: false,
  siteURL: 'https://resquash.com/',
  termsOfService: 'https://www.resquash.com/terms.html',
  privacyPolicy: 'https://www.resquash.com/privacy.html',
  passwordReset: 'https://business.resquash.com/passwordReset',
  brandName: 'Resquash',
  brandMessage: 'Sign in to stay connected with your customers!',
  apiURL: 'https://resquash-api-sa-4tiyq199.uc.gateway.dev/v1',

  appStore: 'https://apple.com',
  playStore: 'http://play.google.com/store/resquash',
  storage: 'https://firebasestorage.googleapis.com/v0/b/receipts-76467.appspot.com/o/landingpage%2Fimages%2F',
  support: 'https://resquash.canny.io'
};
