import { ClassAttributes, Component, ReactNode, useRef } from 'react';
import { connect, Omit } from 'react-redux';
import { alert, DeleteDialog, IconButton } from '../shared';
import { Spinner } from '../shared/Spinner';
import { initiateSaveProduct, initiateDeleteProduct } from "./actions";
import types, { ErrorCode } from './types';
import './product.css';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Product, ProductState } from './product';
import { PaymentState } from 'app/billing/payment';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { mdiArrowLeft } from '@mdi/js';
import ProductMerchantDetails from './productMerchantDetails';
import React from 'react';


export interface Props extends RouteComponentProps {
  authState: AuthState,
  businessState: BusinessState,
  productState: ProductState,
  paymentState: PaymentState,
  params: any,
  initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void,
  initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void
}

export interface State {
  editable: boolean,
  showEditMode: boolean,
  showDeleteDialog: boolean,
  product: Product | null | undefined,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean,
  saveRef: any,
  productBkup: Product | null | undefined,
  isCreateReques: boolean
}

export class ProductMerchantDetailsPage extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductMerchantDetailsPage initialized');

    this.state = {
      editable: this.props.productState.canCreate(this.props.businessState.selectedBusiness),
      showEditMode: false,
      showDeleteDialog: false,
      product: null,
      errorCode: '',
      saveInProgress: false,
      saveRef: React.createRef(),
      productBkup: null,
      isCreateReques: false
    }
    
  }

  componentDidMount() {
    //get product id from url and set product

    let id  = this.props.params.id;
    if (id === 'create'){
      this.setState({isCreateReques: true, showEditMode: true});

    }else{
      let product = this.getProduct(id);
      if (product){
        this.setState({product: product, productBkup: product});
        console.log(`product found, ${product.name}`)
      }else{
        console.log(`product not found`)
      }
    }

  }
  componentWillUnmount() {
    
  }

  render(){
    let component = null;
    if(this.props.productState.state === types.PRODUCT_INITIALIZED || this.props.productState.state === types.LOAD_PRODUCTS){
      component = <Spinner/>;
    } else{
      component = this.renderPage();
    }
    return component;
  }

  renderPage() {
    let self = this;
    let showBackButton = !(this.state.showEditMode || this.state.saveInProgress);

    return (
    
      <div>
        <div className="row">
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-start"> 
                    <div className={(showBackButton)?"nav-link d-block": "d-none"} onClick={() => this.props.history.goBack()}>
                      <IconButton  path={mdiArrowLeft} toolTipText={'Back'} />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 grid-margin wrapper kanban-toolbar"> 
                    
                  </div>
                  <div className="col-0 col-md-3 grid-margin d-flex justify-content-end"> 
                    {
                      (this.state.showEditMode)
                      ?(<div className="d-flex justify-content-end">
                          <button type="button" className="d-block btn btn-primary mr-2" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onCancelEditMode()} disabled={this.state.saveInProgress} >Cancel</button>
                          <button type="button" className="d-block btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.save()} disabled={this.state.saveInProgress} >{this.state.saveInProgress?'Saving...': 'Save'}</button>
                        </div>
                        )
                      :(
                        <div className="d-flex justify-content-end">
                          <button type="button" className="d-block btn btn-primary mr-2" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowEditMode()}>Edit</button>
                          <button type="button" className="d-block btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowDeleteDialog()}>Delete</button>
                        </div>
                      )
                    }
                    </div>
                </div>

                <div className="d-md-none d-block fixed-bottom product-fixed-create-button bg-primary rounded-circle d-flex justify-content-center align-items-center">
                  <i  className="ti-pencil h4 font-weight-bolder text-white m-0 " style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowEditMode()}></i>
                </div>
                
                
                <div className="row">
                  <div className="col-12">
                      <ProductMerchantDetails 
                        key={this.state.product?.productId}
                        product={this.state.product}
                        editMode={this.state.showEditMode}
                        saveRef={this.state.saveRef}
                        onSave={(product: Product) => this.onSave(product)}
                        saveInProgress={this.state.saveInProgress}
                        errorCode={this.state.errorCode} 
                        paymentState={ this.props.paymentState}                      
                     />
                  </div>
                </div>
          </div>
        </div>
        <DeleteDialog 
              id={''} 
              confirmationText={this.state.product?.name} 
              show={this.state.showDeleteDialog} 
              onHide={() => this.setState({showDeleteDialog: false})} 
              onCancel={() => this.setState({showDeleteDialog: false})} 
              onDelete={() => this.onDelete()}/>
      </div>
    )
  }

  getProduct(productId: string){
    let product = null;
    if (productId){
      product = this.props.productState.productList.find((item)=>{
        return (item.productId === productId);
      });
    }
    return product;
  }
  
  onShowEditMode(){
    this.setState({showEditMode: true, errorCode: '', saveInProgress: false});
  }

  onCancelEditMode(){
    this.setState({showEditMode: false, errorCode: '', saveInProgress: false, product: this.state.productBkup});
    if (this.state.isCreateReques){
      this.props.history.goBack();
    }
  }

  save(){
    this.state.saveRef.current.click();
  }

  onSave(product: Product){
    let self = this;
    let businessId = this.props.businessState.selectedBusiness.businessId;
    this.setState({saveInProgress: true, product: product})
    this.props.initiateSaveProduct(businessId, product, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
        self.setState({showEditMode: false, errorCode: '', saveInProgress: false});
        if (self.state.isCreateReques){
          this.props.history.goBack();
        }
      }, 
      (product: Product, errorCode: ErrorCode)=>{
        //on error
        alert.error('Failed to update!');
        self.setState({showEditMode: true, errorCode: errorCode, saveInProgress: false});
      })
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true});
  }

  onDelete(){
    let product = this.state.product;

    if (product){
      this.setState({showDeleteDialog: false });
      this.deleteProduct(product);
    }
  }

  deleteProduct(product: Product){

    let businessId = this.props.businessState.selectedBusiness.businessId;
    this.props.initiateDeleteProduct(businessId, product, 
      ()=>{
        //on success
        alert.info('Successfully deleted!');
        
      }, 
      ()=>{
        //on error
        alert.error('Failed to delete!');
      })
  }

}


const mapStateToProps = (state: { auth: AuthState; business: BusinessState; product: ProductState; billing: PaymentState}) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    productState: state.product,
    paymentState: state.billing
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; product?: Product; onSuccess?: any; onError?: any; }) => void) => {
  return {
    initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateSaveProduct(businessId, product, onSuccess, onError))
    },
    initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteProduct(businessId, product, onSuccess, onError))
    }
  };
};

let ProductMerchantDetailsPage_ = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductMerchantDetailsPage));

export default (props: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<Component<Pick<Omit<ClassAttributes<ProductMerchantDetailsPage> & Props, "authState" | "businessState" | "productState" | "paymentState" | "initiateSaveProduct" | "initiateDeleteProduct">, "params" | keyof ClassAttributes<ProductMerchantDetailsPage>>, any, any>> & Readonly<Pick<Omit<ClassAttributes<ProductMerchantDetailsPage> & Props, "authState" | "businessState" | "productState" | "paymentState" | "initiateSaveProduct" | "initiateDeleteProduct">, "params" | keyof ClassAttributes<ProductMerchantDetailsPage>>> & Readonly<{ children?: ReactNode; }>) => (
  <ProductMerchantDetailsPage_
      {...props}
      params={useParams()}
  />
);
