import { DeleteDialog } from 'app/shared';
import _ from 'lodash';
import { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './inquiries.css';
import { Inquiry, InquiryStatus } from './inquiry';
import InquiryInputDialog from './inquiryInputDialog';
import { alert } from '../shared';
import { initiateSaveInquiry, initiateDeleteInquiry } from './actions';
import { connect } from 'react-redux';


export interface InquiryListItemProp {
  businessId: string,
  inquiry: Inquiry,
  canEdit: boolean,
  onDelete?: (customerId: string) => void,
}

export interface Props extends InquiryListItemProp{
  initiateSaveInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) =>  void,
  initiateDeleteInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) =>  void
}

export interface State {
  inquiry: Inquiry,
  showInquiryDialog: boolean,
  showDeleteDialog: boolean,
  dialogKey: number
}

export class _InquiryListItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('InquiryListItem initialized');

    this.state = {
      inquiry: this.props.inquiry,
      showInquiryDialog: false,
      showDeleteDialog: false,
      dialogKey: Math.random()
    }
  }

  render() {

    let {inquiryId, name, phoneNumber, emailAddress, inquiryDate, inquiryMessage, inquiryInternalNotes, inquiryStatus } = this.state.inquiry;

      return (
        <Card className="mb-2" key={inquiryId}>
            <Card.Header className="inquiry-header" style={{backgroundColor: 'inherit'}}>
              <div className="d-flex flex-row justify-content-between align-items-center"> 
                <div className="w-75">
                  <Link to={`/inquiries/${inquiryId}`} className='name'> <h4> <strong> {name} </strong></h4></Link>
                  <Link to={`mailto:${emailAddress}`} className='emailAddress'> <p className="text-muted">{emailAddress}</p></Link>
                  <Link to={`tel:${phoneNumber}`} className='phoneNumber'> <p className="text-muted">{phoneNumber}</p></Link>
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-md-none" >{this.state.inquiry.displayInquiryDate()}</h6>
                </div> 
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-none d-md-block" >{this.state.inquiry.displayInquiryDate()}</h6>
                  {
                    (this.props.canEdit)
                    ? (
                      <div className="d-flex">  
                        <i id={inquiryId} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onShowInquiryDialog()}}></i> 
                        <i className="ti-trash menu-icon " onClick={(e)=> { e.stopPropagation(); this.onShowDeleteDialog()}}></i> 
                      </div>
                      )
                    : null
                  }
                </div>
              </div>
            </Card.Header>
            <Card.Body className={(inquiryMessage.length > 0)? 'd-block':'d-none'}>
              <div>
                  {inquiryMessage}
              </div> 
            </Card.Body>
            <Card.Footer style={{backgroundColor: 'inherit'}}>
              <div className="d-flex justify-content-between align-items-center"> 
                  <p className="text-muted">
                    {inquiryInternalNotes}
                  </p>
                  <div className="badge inquiry-status"> 
                    <select name='status' required={true} onChange={(e) => this.onStatusChange(e)} value = {_.upperFirst(inquiryStatus)} style={{border:0, outline:0, backgroundColor: 'inherit'}}>
                      <option value="Unread">{_.upperFirst(InquiryStatus.Unread)}</option>
                      <option value="Read">{_.upperFirst(InquiryStatus.Read)}</option>
                      <option value="FollowUp">{_.upperFirst(InquiryStatus.FollowUp)}</option>
                    </select>
                  </div>
              </div>
            </Card.Footer>
            <InquiryInputDialog key={this.state.dialogKey} show={this.state.showInquiryDialog} inquiry={this.state.inquiry} onHide={() => this.setState({showInquiryDialog: false})} onCancel={() => this.setState({showInquiryDialog: false})} onSave={(inquiry: Inquiry) => this.onSave(inquiry)}/>
            <DeleteDialog key={inquiryId} id={inquiryId} confirmationText={phoneNumber} show={this.state.showDeleteDialog} onHide={() => this.setState({showDeleteDialog: false})} onCancel={() => this.setState({showDeleteDialog: false})} onDelete={(inquiryId: string) => this.onDelete(inquiryId)}/>
        </Card>
      );
  }

  onStatusChange(e: any){
    console.log('InquiryListItem, onStatusChange');

    let inquiry = this.state.inquiry;
    inquiry.inquiryStatus = e.target.value;
    this.onSave(inquiry);
  }

  onShowInquiryDialog(){
    this.setState({showInquiryDialog: true });
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true});
  }
  
  onSave(inquiry: Inquiry){
    this.setState({inquiry: inquiry, showInquiryDialog: false, dialogKey: Math.random() });
    let self = this;

    this.props.initiateSaveInquiry(this.props.businessId, inquiry, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })
  }

  onDelete(inquiryId: string){

    this.setState({showDeleteDialog: false});

    this.props.initiateDeleteInquiry(this.props.businessId, this.state.inquiry, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })

      if (this.props.onDelete){
        this.props.onDelete(inquiryId);
      }
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; inquiry: Inquiry; onSuccess: any; onError: any; }) => void) => {
  return {
    initiateSaveInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
      dispatch(initiateSaveInquiry(businessId, inquiry, onSuccess, onError))
    },
    initiateDeleteInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteInquiry(businessId, inquiry, onSuccess, onError))
    }
  };
};

let X =  connect(null, mapDispatchToProps)(_InquiryListItem);


export class InquiryListItem extends Component<InquiryListItemProp> {
  render() {
    return <X {...this.props} />
  };
}