import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { alert } from '../shared';

import { initiateUpdateUser } from './actions';
import type from './types';
import { User } from './user';
import { AuthState } from './auth';

type Callback = () => void;

interface FormField {
  placeholder: string
  value: string,
  type: string,
  pattern?: string,
  required?: boolean,
  errorText?: string,
  readOnly? : boolean,
  validate?: any
}

type FieldName =  'firstName' | 'lastName' | 'phoneNo' | 'email' | 'location' | 'status';

interface UserInfo {
  'firstName': FormField,
  'lastName': FormField,
  'phoneNo': FormField,
  'email': FormField,
  'location': FormField,
  'status': FormField
}

export interface Props {
  auth: AuthState,
  initiateUpdateUser: (user: User, onSuccess: Callback, onError: Callback) => void,
}

export interface State {
  validated: boolean,
  editMode: boolean,
  userInfo: UserInfo
}

export class UserProfile extends Component<Props, State> {

  constructor(props: Props){
    super(props);
    console.log('UserProfile initialized');
    const user = this.props.auth.user;
  
    this.state = {
      validated: false,
      editMode: false,
      userInfo: {
        firstName:{
          placeholder: 'First Name',
          value: user.firstName,
          type: 'text',
          pattern:'[a-zA-Z0-9]{1,32}',
          required: true,
          errorText: '',
          validate: validate
        },
        lastName: {
          placeholder: 'Last Name',
          value: user.lastName,
          type: 'text',
          pattern:'[a-zA-Z0-9]{1,32}',
          required: true,
          errorText: 'Please enter your last name',
          validate: validate
        },
        phoneNo:{
          placeholder: 'Phone',
          value: user.phoneNo,
          type: 'tel',
          pattern:'[0-9]{1,}',
          required: false,
          errorText: 'Valid phone number required',
          validate: validate
        },
        email:{
          placeholder: 'Email',
          value: user.email,
          type: 'email',
          required: true,
          errorText: '',
          readOnly: true,
          validate: null,
        },
        location:{
          placeholder: 'Location',
          value: user.location,
          type: 'text',
          pattern:'[a-zA-Z0-9]{1,32}',
          required: false,
          errorText: '',
          validate: validate
        },
        status:{
          placeholder: 'Status',
          value: user.status,
          type: 'text',
          required: false,
          errorText: '',
          readOnly: true,
          validate: null
        }
      }
    }
  }

  renderShowProfile(){
    const userInfo: UserInfo = this.state.userInfo;
    let render = null;

    render = (
      <div className="py-4">
        {
          Object.keys(userInfo).map((key) =>{
            return (
              <div className="py-3 border-bottom ">
                <div className="d-flex align-items-baseline justify-content-between flex-column flex-md-row">
                  <span className="float-left pb-2 pb-md-0" style={{flex:1}}>
                    {userInfo[key as FieldName].placeholder}
                  </span>
                  <div className="d-flex justify-content-end float-right text-muted" style={{flex:2}}>
                    <span className="text-muted ">
                      {userInfo[key as FieldName].value}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
    
    return render;
  }

  renderEditProfile(){
    const userInfo = this.state.userInfo;
    let render = null;

    render = (
      <Form className="form-sample py-4 mx-2" id="userInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
        {
          Object.keys(userInfo).map((key) =>{
            let self = this;
            return (
              <div className="py-1 ">
                <div className="d-flex align-items-baseline justify-content-between flex-column flex-md-row">
                  <span className="pb-2 pb-md-0" style={{flex:1}}>
                    {userInfo[key as FieldName ].placeholder} {(userInfo[key as FieldName ].readOnly || userInfo[key as FieldName ].required )?'':'(Optional)'}
                  </span>
                  <div className="text-muted" style={{flex:2}}>
                    <Form.Group className="" controlId={key}>
                      <Form.Control  
                      type={userInfo[key as FieldName ].type}
                      name={key}
                      pattern={userInfo[key as FieldName ].pattern}
                      required={userInfo[key as FieldName ].required}
                      placeholder={userInfo[key as FieldName ].placeholder}
                      onChange={e => self.onChange(e)} 
                      value = {userInfo[key as FieldName ].value}
                      readOnly = {userInfo[key as FieldName ].readOnly}
                      className="text-right form-control-sm"
                      />
                      <Form.Control.Feedback type="invalid" className="text-right">
                        {userInfo[key as FieldName ].errorText}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Form>
    );
    return render;
  }

  render() {
  
    return (
      <div>
        <div className="row p-5">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-3"> </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    {
                      (this.state.editMode) 
                      ? (
                        <div>
                          <div className="py-3 d-flex justify-content-end">
                            <div className="d-flex justify-content-between">
                              <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={this.onCancel.bind(this)}>Cancel</button>
                              <button type="submit" form="userInfo" className="btn btn-primary btn-sm" >Save</button>
                            </div>
                          </div>
                          { this.renderEditProfile() }
                        </div>
                        )
                      : (
                        <div> 
                          <div className="py-3 d-flex justify-content-end">
                            <div className="d-flex justify-content-between">
                              <button type="button" className="btn btn-primary btn-sm" onClick={this.onEdit.bind(this)}>Edit</button>
                            </div>
                          </div>
                          { this.renderShowProfile() }
                        </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  reset(){
    let userInfo = this.state.userInfo;
    Object.keys(userInfo).map((key) =>{
      userInfo[key as FieldName ].value = this.props.auth.user[key as FieldName];
    });

    this.setState({validated: false, editMode: false, userInfo:userInfo});
  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>){
    console.log('UserProfile, onChange');
    let userInfo = this.state.userInfo;
    let key = e.target.name;

    userInfo[key as FieldName ].value = e.target.value;
    this.setState({userInfo:userInfo});
  }

  onEdit(){
    this.setState({editMode: true});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('UserProfile, onSave');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let data =  this.getFormData();
      console.log(JSON.stringify(data));
      let user = new User(data);
      user.userId = this.props.auth.user.userId;
      if (this.isModified(user)){
        this.props.initiateUpdateUser(user, 
          ()=>{
            //on success
            alert.info('Successfully updated!');
          }, 
          ()=>{
            //on error
            alert.error('Failed to update!');
        })
      }
      this.setState({editMode: false});
    }
  }

  onCancel(){
    this.reset();
    this.setState({editMode: false});
  }

  getFormData(){
    const userInfo: UserInfo = this.state.userInfo;

    let data = Object.keys(userInfo).reduce((acc, key) =>{
      if (userInfo[key as FieldName ].readOnly !== true){
        acc[key as FieldName] = userInfo[key as FieldName ].value;
      }
      return acc;
    }, {'firstName': '', 'lastName': '', 'phoneNo': '', 'email': '', 'location': '', 'status': ''});

    return data; 
  }

  isModified(user: User){
    return this.props.auth.user.isDifferent(user);
  }
}

const validate = (value: any) => {

  return [true,  'validation error'];
}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; user: User; onSuccess: () => void; onError: () => void; }) => void) => {
  return {
    initiateUpdateUser: (user: User, onSuccess: Callback, onError: Callback) => {
      dispatch(initiateUpdateUser(user, onSuccess, onError))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);