
import { Component } from 'react';

interface Props {
    keywords: string[]
  }

interface State { }

export default class BusinessKeywords extends Component<Props, State> {
    render(){
        const { keywords } = this.props;

      
            return (
                <a {...this.props} >
                    {
                        keywords.map(keyword =>{
                            return (
                                <span className="px-2 border rounded">{keyword}</span>
                            )
                        })
                    }
                </a>
            );
    }
}

