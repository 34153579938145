import cogoToast from 'cogo-toast';

const toastoptions = {
    hideAfter: 5,
    position: 'top-right',
    heading: 'Attention'
}

export const alert = {
    info: (message) => {
        cogoToast.info(message, toastoptions);
    },
    error: (message) => {
        cogoToast.error(message, toastoptions);
    }
}