import { subscribeForBillingChanges } from "app/billing/actions"
import { channel } from 'redux-saga';
import { all, cancel, fork, take, takeLatest } from "redux-saga/effects"
import type from './types';

export const backgroundListener = channel();

export function* listener() {
    console.log(`listener, initialized`);
    let tasks: any[] = [];
    try{
        while ( true ) {
            
            // starts the task in the background
    
            // wait for the user stop action
            const action: {type: string, businessId: string} = yield take(backgroundListener)

            if (action.type === type.SET_SELECTED_BUSINESS){
                console.log('listener, ' +  action.type + ', businessId -'+ action.businessId);
                
                let businessId = action.businessId;
                // cancel all previous listener tasks
                yield all (tasks.map(task => {
                    cancel(task);
                }));
                // reset tasks
                tasks = [];

                // create new listerner task for businessId
                // @ts-ignore
                tasks.push(yield fork(subscribeForBillingChanges, businessId));
            }else{
                console.log('listener, ' +  action.type + ' not handled');
            }
        
        }
    } finally {
        console.log('listener, finally, clearing all resources');
        // cancel all previous listener tasks
        yield all (tasks.map(task => {
            cancel(task);
        }));
        // reset tasks
        tasks = [];
    }
  }

  export const listenerSagas = [
    takeLatest<any>(type.LOAD_BUSINESS, listener)
  ];
