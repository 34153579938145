import Container from 'react-bootstrap/Container';
import { Component } from "react";
import { PageLink, PageLinkLocation, PageLinks } from "../pageLinks";


export interface Props {
   links: PageLinks,
   location: PageLinkLocation
}

class BusinessPageLinks extends Component<Props>{

    render(){

        const { links, location } = this.props;
        let linkItems = (location === PageLinkLocation.header)?links.headerPageLinks:links.footerPageLinks;
        return(

            <Container> 
                <div className="row">
                    <div className="col-12 p-3 d-flex justify-content-center">
                        { getLinks(linkItems) }
                    </div>
                </div>
            </Container>
   
        );
    }
}

export default BusinessPageLinks;

function getLinks(links: PageLink[]){
    return (links)?
                links.map((link, index) => {
                    if (link.isEnabled){
                        return (
                            <a className="px-1 mx-2  border-3 border-dark" href={`/online/page/${link.path}`}>{link.label}</a>
                        )
                    }
                })
                
            : null
}
