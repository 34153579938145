// @ts-ignore
import grapesjs from 'grapesjs';
// @ts-ignore
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-blocks-basic/dist/grapesjs-blocks-basic.min.js';
import 'grapesjs-plugin-ckeditor/dist/grapesjs-plugin-ckeditor.min.js'
import './style.css';

import BaseReactComponent from './base-react-component';
import SectionHeader from './blocks/sectionHeader';
import SectionFooter from './blocks/sectionFooter';

import SectionWithImage from './blocks/sectionWithImage';
import SectionContactus from './blocks/sectionContactUs';
import SectionWithProduct from './blocks/sectionWithProduct';

import Product from './elements/product';
import Contact from './elements/contact';
import CallToAction from './elements/callToAction';
import InquiryForm from './elements/inquiryForm';
import BusinessCard from './elements/businessCard';
import PageLinksEl from './elements/pageLinks';
import BusinessHours from './elements/businessHours';

import { Business } from 'app/business';
import { PageLinks } from '../pageLinks';
import { PageSettings } from '../pageSettings';
import { AvailablePageProperty, PageComponent } from '../pageProperty';
import _ from 'lodash';
import { makeid } from 'app/shared';


export enum GJSTagNames {
  SectionHeader = 'SectionHeader',
  SectionFooter = 'SectionFooter',

  SectionWithImage = 'SectionWithImage',
  SectionContactUs = 'SectionContactUs',
  SectionWithProduct = 'SectionWithProduct',

  Contact = 'Contact',
  CtaButton = 'CtaButton',
  InquiryForm = 'InquiryForm',
  Product = 'Product',
  BusinessCard = 'BusinessCard',
  PageLinks = 'PageLinks',
  BusinessHours = 'BusinessHours'
}

export type CmponentConfigData = {
  attributes: any,
  style?: any
}

export type GrapesjsEditorData = {
  containerId: string,
  setHeader: () => CmponentConfigData,
  setFooter: () => CmponentConfigData,
  setBusiness: () => CmponentConfigData,
  setProduct: () => CmponentConfigData,
  setPageLinks: () => CmponentConfigData,
  onChange: () => void,
  getAvailablePageProperty: () => AvailablePageProperty,
  uploadAsset: (pictureFile: any) => any,
  onStoreStart: (data: any) => void,
  onAddImage: (pictureUrl: string) => void,
  onAssetRemove: (pictureUrl: string) => void
}

export class GrapesjsEditor {

  setHeader: () => CmponentConfigData;
  setFooter: () => CmponentConfigData;
  setBusiness: () => CmponentConfigData;
  setProduct: () => CmponentConfigData;
  setPageLinks: () => CmponentConfigData;
  onChange: () => void;
  getAvailablePageProperty: () => AvailablePageProperty;
  uploadAsset: (pictureFile: any) => any;
  onStoreStart: (data: any) => void;
  onAddImage: (pictureUrl: string) => void;
  onAssetRemove: (pictureUrl: string) => void;

  private containerId: string;
  private editor: any = null;
  private backup: {pageData: any, pageSettings: PageSettings|null} = {pageData: null, pageSettings: null};




  constructor(grapesjsEditorData: GrapesjsEditorData){
    console.log (`GrapesjsEditor, constructor`); 

    this.containerId = '#' + grapesjsEditorData.containerId || '';
    this.setHeader = grapesjsEditorData.setHeader ||(() => { throw 'Unhandled header config callback'});
    this.setFooter = grapesjsEditorData.setFooter ||(() => { throw 'Unhandled footer config callback'});
    this.setBusiness = grapesjsEditorData.setBusiness ||(() => { throw 'Unhandled business config callback'});
    this.setProduct = grapesjsEditorData.setProduct ||(() => { throw 'Unhandled product config callback'});
    this.setPageLinks = grapesjsEditorData.setPageLinks ||(() => { throw 'Unhandled page link callback'});
    this.onChange = grapesjsEditorData.onChange || (() => { throw 'Unhandled onChanged callback'});
    this.getAvailablePageProperty = grapesjsEditorData.getAvailablePageProperty || (() => { throw 'unhandled getAvailablePageProperty' });
    this.uploadAsset = grapesjsEditorData.uploadAsset || (() => { throw 'unhandled uploadAsset' });
    this.onStoreStart = grapesjsEditorData.onStoreStart || (() => { throw 'unhandled onStoreStart' });
    this.onAddImage = grapesjsEditorData.onAddImage || (() => { throw 'unhandled onAddImage' });
    this.onAssetRemove = grapesjsEditorData.onAssetRemove || (() => { throw 'unhandled onAssetRemove' });

    this.init();
  }

  init(){
    console.log (`GrapesjsEditor, init`); 
    let getAvailablePageProperty = this.getAvailablePageProperty.bind(this);
    let uploadAsset = this.uploadAsset.bind(this);
    let onAddImage = this.onAddImage.bind(this);

    let editor = grapesjs.init({
      container: this.containerId,
      storageManager: false,
      noticeOnUnload: false,
      fromElement: true,
      height: '100%',
      autoSave: false,
      blockManager: {
        appendTo: "#blocks",
      },
      styleManager: {
        appendTo: "#styles-container",
        sectors:[{
          name: 'Color',
          open: true,
          properties: ['color', 'background-color', 'background'],
        }, {
          name: 'Spacing',
          open: false,
          properties: ['margin', 'padding', 'border', 'border-radius'],
        }]
      },
      traitManager: {
        appendTo: "#traits-container",
      },
      selectorManager: {
        componentFirst: 1,
      },
      assetManager: {
        upload: 'https://', //we are not using this url. this is needed for grapesjs to trigger upload
        autoAdd: true,
        embedAsBase64: false,
        customFetch: async (url:any, options:any) => {
          for (const file of options.body.values()) {
            return await uploadAsset(file);
          }
        },
        handleAdd: (textFromInput: any) => {
            editor.AssetManager.add(textFromInput);
            onAddImage(textFromInput);
        }
      },
      panels: { defaults: [] },
      plugins: getPlugins(),
      canvas: {
        scripts:['https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js', 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js'],
        styles: ['https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css', 'https://receipts-cdn.web.app/receipt.css'    
        ],
      },
      pluginsOpts: {
        [CallToAction as any]: { getAvailablePageProperty:  getAvailablePageProperty},
        "gjs-blocks-basic": getBlocksBasicOptions(),
        'gjs-plugin-ckeditor' : getCkeditorOptions()
      },
     
    })

    this.editor = editor;
    this.setup();

  }

  setup(){
    console.log (`GrapesjsEditor, setup`);

    this.editor.getConfig().showDevices = 0;
    this.setupPanels();
    this.setupCallBacks();
  }

  setupPanels(){
    console.log (`GrapesjsEditor, setupPanels`);

    // Device icon
    this.editor.Panels.addPanel({
      id: 'panel__devices', 
      el: '.panel__devices',
      visible: false,
      buttons: [
          { id: "set-device-desktop", command: function (e: { setDevice: (arg0: string) => any; }) { return e.setDevice("Desktop") }, className: "fa fa-desktop", active: 1 },
          { id: "set-device-tablet", command: function (e: { setDevice: (arg0: string) => any; }) { return e.setDevice("Tablet") }, className: "fa fa-tablet" },
          { id: "set-device-mobile", command: function (e: { setDevice: (arg0: string) => any; }) { return e.setDevice("Mobile portrait") }, className: "fa fa-mobile" }
      ]
    });

    this.editor.Panels.addPanel({
      id: 'panel__basic-actions', 
      el: '.panel__basic-actions',
      visible: false,
      buttons: [
        { id: 'undo', className: 'fa fa-undo', command: (e: { runCommand: (arg0: string) => any; }) => e.runCommand('core:undo'), attributes: { title: 'Undo' } },
        { id: 'redo', className: 'fa fa-repeat', command: (e:any) => e.runCommand('core:redo'), attributes: { title: 'Redo' } },
        { id: 'sw-visibility', className: 'fa fa-square-o', command: 'sw-visibility', active: false, context: 'sw-visibility', attributes: { title: 'View components' }}
      ]
    });
    
  }

  setupCallBacks(){
    console.log (`GrapesjsEditor, setupCallBacks`);
    let self = this;

    this.editor.on('load', function(e: any) {
      console.log(`GrapesjsEditor, grapesjs loaded, ${e}`);
      setTimeout(() => {
        self.enableReadOnlyMode();
      }, 5);
    });

    this.editor.on('storage:load', function(e: any) {
      console.log(`GrapesjsEditor, storage:load, ${e}`);
    });

    this.editor.on('storage:start:store', function(data: any) {
      console.log(`GrapesjsEditor, storage:start:store, ${data}`);
      self.onStoreStart(data);
    });
    
    this.editor.on('storage:error', function(e: any) {
      console.log(`GrapesjsEditor, storage:error, ${e}`);
    });

    this.editor.on('component:update:attributes', function(model: any, e: any) {
      
    });

    this.editor.on('styleable:change', function(model: any, prop:any) {
      console.log('GrapesjsEditor, styleable:change');
    });

    this.editor.on('component:selected', function(model: any) {
      self.removeTraites(model);    
    });

    this.editor.on('block:drag:start', function(model: any){
      //self.editor.runCommand('sw-visibility');
    });

    this.editor.on('block:drag:stop', function(model: any){
      self.setComponentSettings(model);
      //self.editor.stopCommand('sw-visibility');
    });

    this.editor.on('component:mount', function(model: any){
      self.setComponentSettings(model);
    });

    this.editor.on('rteToolbarPosUpdate', (pos: any) => {
      if (pos.top <= pos.canvasOffsetTop) {
        pos.top = pos.top - 60;
      }
    });

    this.editor.on('change:changesCount', (editorModel: any, changes:any) => { 
      console.log(`onChange, ${changes}`)
      self.onChange();
     });

     this.editor.on('asset:add', (asset: any) => { 
      console.log(`GrapesjsEditor, asset:add, ${asset.getFilename()}`);
     });

     this.editor.on('asset:remove', (asset: any) => { 
      console.log(`GrapesjsEditor, asset:remove, ${asset.getFilename()}`);
      self.onAssetRemove(asset.getSrc());
      self.editor.refresh();
     });
  }

  setComponentSettings(model: any){
    if (model){
      console.log(`GrapesjsEditor, setComponentSettings, component name - ${model.getName()} `)
      
      let componentData: CmponentConfigData | undefined = undefined;

      switch (model.getName()){

        case GJSTagNames.SectionHeader:
          componentData = this.setHeader();
          break;
        case GJSTagNames.SectionFooter:
          componentData = this.setFooter();
          break;
        case GJSTagNames.Contact:
          componentData = this.setBusiness();
          break;
        case GJSTagNames.Product:
          {
            componentData = this.setProduct();
            if (!model.getAttributes().id){
              console.log(`GrapesjsEditor, setComponentSettings, setting id for Product since not found`)
              componentData.attributes.id = makeid(5);
            }
            break;
          }
        case GJSTagNames.BusinessCard:
          componentData = this.setBusiness();
          break;
        case GJSTagNames.PageLinks:
          componentData = this.setPageLinks();
          break;
        case GJSTagNames.BusinessCard:
          componentData = this.setBusiness();
          break;
        default:
          break;
      }

      if (componentData && componentData.attributes){
        model.addAttributes(componentData.attributes)
      }
      if (componentData && componentData.style){
        model.setStyle(componentData.style)
      }
    }
  }

  
  loadComponentSettings(business: Business, pageLinks: PageLinks, pageSettings: PageSettings){
    console.log (`GrapesjsEditor, loadComponentSettings`);

    let wrapperComponent = this.editor.DomComponents.getWrapper();

    // get component for Section Header
    
    let businessHeaderComp = wrapperComponent.findType(GJSTagNames.SectionHeader);
    if (businessHeaderComp && businessHeaderComp[0]){
      console.log(businessHeaderComp[0]);
      businessHeaderComp[0].addAttributes(Object.assign({}, pageSettings.header.attributes, {business: business, businessHours: business.businessHours, links: pageLinks.headerPageLinks}));
      businessHeaderComp[0].setStyle(pageSettings.header.style);
    }else{
      console.log (`GrapesjsEditor, loadComponentSettings, failed to find ${GJSTagNames.SectionHeader} component`);
    }

    // get component for Section Footer
    let sectionFooterComp = wrapperComponent.findType(GJSTagNames.SectionFooter);
    if (sectionFooterComp && sectionFooterComp[0]){
      sectionFooterComp[0].addAttributes(Object.assign({}, pageSettings.footer.attributes, {business: business, businessHours: business.businessHours, links: pageLinks.footerPageLinks}));
      sectionFooterComp[0].setStyle(pageSettings.footer.style);
    }else{
      console.log (`GrapesjsEditor, loadComponentSettings, failed to find ${GJSTagNames.SectionFooter} component`);
    }
  }

  loadPageData(pageData: any){
    console.log (`GrapesjsEditor, loadPageData`);

    if (pageData && Object.keys(pageData).length !== 0 && pageData.constructor === Object){
      console.log ('GrapesjsEditor, loadPageData, loading page data');
      this.editor.loadData(pageData);
    }else{
      console.log ('GrapesjsEditor, loadPageData, page data is not available');
      this.setComponents();
    }

  }

  setComponents(){
    console.log ('GrapesjsEditor, setComponents');

    let components = `
    <div class="d-flex flex-column">
      <SectionHeader>
      </SectionHeader>

      <SectionWithImage></SectionWithImage>

      <SectionFooter class="flex-grow-1">
      </SectionFooter>
    </div>
     `

    this.editor.setComponents(components);
  }

  loadAssets(pageSettings: PageSettings){
    console.log(`GrapesjsEditor, loadAssets`);

    
    let assetManager = this.editor.AssetManager;
    let existingAssets = assetManager.getAll().map((asset:any) => asset.getSrc());
    let x = pageSettings.getImageUrls();
    
    let newAssets = _.without(pageSettings.getImageUrls(), ...existingAssets);
    assetManager.add(newAssets);

    let assetsToRemove = _.without(existingAssets, ...pageSettings.getImageUrls());
    assetsToRemove.map(pictureUrl => assetManager.remove(pictureUrl));
  }

  getPageData(){
    return this.editor.storeData();
  }


  getPageSettings(){

    let pageSettings: PageSettings = new PageSettings({});
    let wrapperComponent = this.editor.DomComponents.getWrapper();

    // get component for Section Header
    
    let businessHeaderComp = wrapperComponent.findType(GJSTagNames.SectionHeader);
    if (businessHeaderComp && businessHeaderComp[0]){
      console.log(businessHeaderComp[0]);

      let attributes = businessHeaderComp[0].getAttributes();
      delete attributes.business;
      delete attributes.businessHours;
      delete attributes.links;
      let style = businessHeaderComp[0].getStyle();
      pageSettings.header = Object.assign({}, {attributes: attributes, style: style});
      
    }else{
      console.log (`GrapesjsEditor, setAttributes, failed to find ${GJSTagNames.SectionHeader} component`);
    }

    // get component for Section Footer
    let sectionFooterComp = wrapperComponent.findType(GJSTagNames.SectionFooter);
    if (sectionFooterComp && sectionFooterComp[0]){

      let attributes = sectionFooterComp[0].getAttributes();
      delete attributes.business;
      delete attributes.businessHours;
      delete attributes.links;
      let style = sectionFooterComp[0].getStyle();
      pageSettings.footer = Object.assign({}, {attributes: attributes, style: style});
      
    }else{
      console.log (`GrapesjsEditor, setAttributes, failed to find ${GJSTagNames.SectionFooter} component`);
    }

    return pageSettings;
  }

  getNativeComponents(){
    let nativeComponents: PageComponent[] = [];
    let wrapperComponent = this.editor.DomComponents.getWrapper();
    
    wrapperComponent.onAll((component: any) => {
      if (component.get('isNative')) {
        let attributes = component.getAttributes();
        console.log(`GrapesjsEditor, getNativeComponents, attributes, ${JSON.stringify(attributes)}`)
        let id:string = attributes.id;
        let tagName:string = component.get('tagName');
        let item: PageComponent = {id: id, tagName: tagName, attributes:{}};
        nativeComponents.push(item);
      }
    })
    console.log(`GrapesjsEditor, getNativeComponents, ${JSON.stringify(nativeComponents)}`);
    return nativeComponents;
  }

  getDirtyCount(){
    return this.editor.getDirtyCount();
  }

  onEdit(){

    // get page data and page settings
    // we need to save to restore in case if user discards the changes
    this.backup.pageData = this.getPageData();
    this.backup.pageSettings = this.getPageSettings();
    this.enableEditMode();
  }

  onDiscard(){
    // use backup if available 
    if (this.backup.pageData && this.editor.getDirtyCount() >= 1){
      this.loadPageData(this.backup.pageData);
    }
    if (this.backup.pageSettings && this.editor.getDirtyCount() >= 1){
      //this.loadPageS(this.backup.pageSettings);
    }
    this.enableReadOnlyMode();

    this.clearBackup();

  }

  onSave(){
    this.clearBackup();
    this.enableReadOnlyMode();
  }

  private removeTraites(model: any){
    console.log(`GrapesjsEditor, removeTraites, component name - ${model.getName()}`);
    model.removeTrait(['id', 'title']);
  }
  
  private removeShowSocialLinks(model: any, socialLinks: string[]){
    let name = model.getName();
    console.log(`GrapesjsEditor, removeShowSocialLinks, name-${name}`);
    if (name === GJSTagNames.SectionFooter && socialLinks.length === 0){
      model.removeTrait(['showSocialLinks']);
    }
  }

  private enableEditMode(){
    console.log (`GrapesjsEditor, enableEditMode`);
    this.editor.stopCommand('preview');
    this.enableTextEdit();
  }

  private enableReadOnlyMode(){
    console.log (`GrapesjsEditor, enableReadOnlyMode`);
    this.editor.runCommand('preview');
    this.disableTextEdit();
  }

  private clearBackup(){
    //clear backup
    this.backup.pageData = null;
    this.backup.pageSettings = null;
  }

  private disableTextEdit(){
    this.editor.DomComponents.getWrapper().onAll((comp:any) => 
      comp.set({ editable: false, locked: true, selectable: false })
    );
  }

  private enableTextEdit(){
    this.editor.DomComponents.getWrapper().onAll((comp:any) => 
      comp.set({ editable: true, locked: false, selectable:true})
    );
  }

  private getCallToActionOptions(){
    return {
      options: {
        getAvailablePageProperty: this.getAvailablePageProperty.bind(this)
      }
    }
  }

}


  // plugin options 

  function getBlocksBasicOptions(){
    return { 
      
        category: 'Elements',
        blocks: ['text', 'image', 'video', 'map','column1', 'column2', 'column3', 'column3-7']

    };
  }

  function getCkeditorOptions() {
    return {
      options: {
        language: 'en',
        startupFocus: true,
        allowedContent: true,
        enterMode: CKEDITOR.ENTER_BR,
        toolbar: [
            { name: 'basicStyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'RemoveFormat' ] },
            { name: 'moreStuff', items: ['NumberedList', 'BulletedList'] },
            { name: 'colors', items: ['TextColor'] },
            { name: 'paragraph2', items: ['JustifyLeft', 'JustiftyCenter', 'JustifyRight'] },
            { name: 'textFormat', items: ['Format']}
        ],
        removePlugins: "exportpdf"
      },
      position: 'right',
    }
}

function getPlugins(){
  return [BaseReactComponent, 
          SectionHeader, SectionFooter, SectionWithImage, SectionContactus, SectionWithProduct,
          Product, Contact, CallToAction, InquiryForm, BusinessCard, PageLinksEl, BusinessHours,
          'gjs-blocks-basic', 'gjs-plugin-ckeditor'];
}



