import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Component } from 'react';
import { connect } from 'react-redux';
import InquiryListPage from './inquiryListPage';



export interface Props {
  authState: AuthState,
  businessState: BusinessState,
}

export interface State {

}

export class Inquiries extends Component<Props, State> {
  
  render() {
    return (
      <InquiryListPage key={this.props.businessState.selectedBusiness.businessId} businessId = {this.props.businessState.selectedBusiness.businessId}/>
    );
  }
}



const mapStateToProps = (state: { auth: AuthState; business: BusinessState; }) => {
  return { 
    authState: state.auth,
    businessState: state.business 
  };
};


export default connect(mapStateToProps, null)(Inquiries);