import { Component } from 'react';
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';
import MDI_Icon from '@mdi/react';


export interface Props {
 className?: string 
 path: string,
 toolTipText?: string
}

export interface State {

}

export class Icon extends Component <Props, State>{  

  constructor(props: Props){
    super(props);
    console.log('Icon initialized');

     this.state = {
 
     }
  }

  render() {

    return (
      
        (this.props.toolTipText)
        ? <div {...this.props}>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.toolTipText}</Tooltip>}>
              <span>
                <MDI_Icon path={this.props.path} size={1} rotate={0} color="grey"/>
              </span>
            </OverlayTrigger>
          </div>
        : <span {...this.props}>
            <MDI_Icon path={this.props.path} size={1}  rotate={0} color="grey"/>
          </span>
    );
  }
}


export default Icon;