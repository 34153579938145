import _ from "lodash";

export class Hour {
    days: string[] = [];
    openTime: number = new Date(`2022-01-01T08:00:00`).getTime();
    closeTime: number = new Date(`2022-01-01T22:00:00`).getTime();
    isClosed: boolean = false;

    constructor(hourData: any){
        this.days = hourData.days || [];
        this.openTime = hourData.openTime || new Date(`2022-01-01T08:00:00`).getTime();
        this.closeTime = hourData.closeTime || new Date(`2022-01-01T22:00:00`).getTime();
        this.isClosed = hourData.isClosed? hourData.isClosed: false;
    }

    serialize(){
        console.log('Hour, serialize');
        return { 
            days: this.days,
            openTime: this.openTime,
            closeTime: this.closeTime,
            isClosed: this.isClosed
        };
    }
}

export class SpecialHour {
    date: number = new Date().getTime();
    openTime: number = new Date(`2022-01-01T08:00:00`).getTime();
    closeTime: number = new Date(`2022-01-01T22:00:00`).getTime();
    isClosed: boolean = false;

    constructor(hourData: any){
        this.date = hourData.date || new Date().getTime();
        this.openTime = hourData.openTime || new Date(`2022-01-01T08:00:00`).getTime();
        this.closeTime = hourData.closeTime || new Date(`2022-01-01T22:00:00`).getTime();
        this.isClosed = hourData.isClosed? hourData.isClosed: false;
    }

    serialize(){
        console.log('SpecialHour, serialize');
        return { 
            date: this.date,
            openTime: this.openTime,
            closeTime: this.closeTime,
            isClosed: this.isClosed
        };
    }
}

export type BusinessHoursData = {

    businessHoursId?: string;
    isOpen24Hours?: boolean;
    isClosed?: boolean;
    hours?: Hour[];
    specialHours?: SpecialHour[];
    lastModified?: number;
}

export class BusinessHours {

    businessHoursId: string;
    isOpen24Hours: boolean;
    isClosed: boolean;
    hours: Hour[];
    specialHours: SpecialHour[];
    lastModified: number;


    constructor(businessHoursData: BusinessHoursData){

        console.log(`BusinessHours, constructor, ${JSON.stringify(businessHoursData)}`)

        this.businessHoursId = businessHoursData.businessHoursId || '';
        this.isOpen24Hours = businessHoursData.isOpen24Hours?businessHoursData.isOpen24Hours: false;
        this.isClosed = businessHoursData.isClosed? businessHoursData.isClosed: false;
        this.hours = businessHoursData.hours?businessHoursData.hours.map((hour) => { return new Hour(hour)}):[];
        this.specialHours = businessHoursData.specialHours?businessHoursData.specialHours.map((specialHour) => { return new SpecialHour(specialHour)}):[];
        this.lastModified = businessHoursData.lastModified || new Date().getTime();
    }

    isDifferent(businessHours: BusinessHours){
        return !_.isEqual(this, businessHours)
    }

    serialize(){
        console.log('BusinessHours, serialize');
        return {
            isOpen24Hours: this.isOpen24Hours,
            isClosed: this.isClosed,
            hours: this.hours.map((hour: Hour) =>{
                         console.log('hour');
                         (hour instanceof Hour)? console.log('yes instance of Hour'): console.log('not an instance of Hour')
                         return hour.serialize();
                     }),
            specialHours: this.specialHours.map((specialHour: SpecialHour) =>{
                             return specialHour.serialize();
                         }),
        };
    }
}


export class Day {
    day: number;
    openTime: number;
    closeTime: number;
    isClosed: boolean;
    isOpen24hours!: boolean;
    isSpecialHours: boolean;

    constructor({day, openTime, closeTime, isClosed}: {day?: number, openTime?: number, closeTime?: number, isClosed?: boolean}){
        this.day = day || 0;

        if (openTime){
            let date = new Date();
            date.setDate(0);
            let openDate = new Date(openTime);
            date.setHours(openDate.getHours(), openDate.getMinutes());
            this.openTime = openDate.getTime();
        }else{
            this.openTime = 0;
        }

        if (closeTime){
            let date = new Date();
            date.setDate(0);
            let closeDate = new Date(closeTime);
            date.setHours(closeDate.getHours(), closeDate.getMinutes())
            this.closeTime = date.getTime();
        }else{
            this.closeTime = 0;
        }
        
        this.isClosed = (isClosed)? (isClosed):false;
        this.isOpen24hours = false;
        this.isSpecialHours = false;
    }
}
