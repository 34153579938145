import { Business, BusinessHours } from "app/business";
import BusinessCard from "app/page/business/businessCard";
import { GJSTagNames } from "../grapesjsEditor";



export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.BusinessCard) ;

  editor.Components.addType(GJSTagNames.BusinessCard, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessCard,
        attributes: {
          business: new Business({}),
          showHours: true,
          showAddress: true,
          showProfilePicture:true
        },
        toolbar: [],
        traits: [
          { 
            type: 'checkbox', 
            label: 'Hours', 
            name: 'showHours', 
          },
          { 
            type: 'checkbox', 
            label: 'Address', 
            name: 'showAddress', 
          },
          { 
            type: 'checkbox', 
            label: 'Profile Picture', 
            name: 'showProfilePicture', 
          },
        ]
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.BusinessCard} model initialized`);
        // @ts-ignore
        //this.on('style:property:update', this.handleStyleChange);
        
        // @ts-ignore
        //this.listenTo(this, 'change:attributes:showHours', this.handleAttributeChange);
      },

      handleStyleChange() {
        console.log('Model Style updated ');
      },
      handleAttributeChange(changes: any) {
        // @ts-ignore
        let showHours: boolean = this.getAttributes().showHours;
        console.log(`Model Attributes updated, showHours - ${showHours}`);
        
      },      
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.BusinessCard.toUpperCase();
    }
  });

  editor.BlockManager.add('Business Card', {
    label: getBockLabel(),
    category: 'Elements',
    content: '<BusinessCard></BusinessCard>'
  });
};


function getBockLabel(){
  return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24">
              <path fill="#000000" d="M12,15H10V13H12V15M18,15H14V13H18V15M8,11H6V9H8V11M18,11H10V9H18V11M20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20M4,6V18H20V6H4Z" />
            </svg>         
            <div class="py-2">Business Card</div>
          </div>`
}
