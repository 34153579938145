import { Product } from 'app/product';
import { Component } from 'react';
import 'app/product/product.css';
import { ProductListItem }  from './productListItem';

export interface Props {
  businessId: string,
  productList: Product[],
  canEdit: boolean,
}

export interface State {
  
}

export class ProductList extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductList initialized');
  }

  render() {
    let { productList } = this.props;

    return (
      <div className="container">
        <div className="row product-item-wrapper">
          {
            productList.map((item)=>{
              if(item.isPublished){
                return (
                  <ProductListItem 
                    key={item.productId}
                    product={item}
                    />
                );
              }
            })
          }
        </div>
      </div>
    )
  }
}
