import _ from "lodash";
import { normalizeUrl } from "./utilities";

export enum SocialPlatform {
    twitter = 'twitter',
    instagram = 'instagram',
    facebook = 'facebook',
    snapchat = 'snapchat',
    youtube = 'youtube',
    linkedin = 'linkedin',
    whatsapp = 'whatsapp',
    pinterest = 'pinterest',
    share = 'share'
}

export type SocialData = {

    url?: string;
    socialPlatform?: SocialPlatform;
}

export class Social {

    url: string;
    socialPlatform: SocialPlatform;


    constructor(socialData: SocialData){

        console.log(`Social, constructor, ${JSON.stringify(socialData)}`)

        this.url = normalizeUrl(socialData.url);
        this.socialPlatform = socialData.socialPlatform || this.getSocialPlatform();
    }

    isDifferent(social: Social){
        return !_.isEqual(this, social)
    }

    serialize(){
        console.log('Social, serialize');
        return {
            url: this.url,
            socialPlatform: this.socialPlatform,
        };
    }

    getIcon(): string{
        //example icon name icons8-twitter-48.png

        return `icons8-${this.socialPlatform}-48.png`
    }

    getFallbackIcon(): string{
        return `icons8-${SocialPlatform.share}-48.png`
    }

    getSocialPlatform(): SocialPlatform{
        
        //twitter.com
        let hostname = new URL(this.url).hostname;
        let names = _.split(hostname, '.'); // [twitter, com]
        if (names.length >= 2){
            let position = names.length - 2; // 0
            return names[position] as SocialPlatform;
        }else{
            return SocialPlatform.share;
        }
    }

}

