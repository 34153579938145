import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { APISettings } from './apiSettings';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import SecuredRoute from './securedRoute';


import Spinner from './shared/Spinner';

const Login = lazy(() => import('./user/login'));
const PasswordReset = lazy(() => import('./user/passwordReset'));
const UpdatePassword = lazy(() => import('./user/updatePassword'));
const VerifyEmail = lazy(() => import('./user/verifyEmail'));
const Signup = lazy(() => import('./user/signup'));

const ActivateAccount = lazy(() => import('./user/activateAccount'));
const SelectBusiness = lazy(() => import('./business/selectBusiness'));
const CreateBusiness = lazy(() => import('./business/createBusiness'));
const UserProfile = lazy(() => import('./user/userProfile'));

const Dashboard = lazy(() => import('./dashboard/dashboard'));
const Pricing = lazy(() => import('./subscription/pricing'));
const BusinessProfile = lazy(() => import('./business/businessProfile'));
const Customers = lazy(() => import('./customers/customers'));
const Products = lazy(() => import('./product/products'));
const ProductMerchantDetailsPage = lazy(() => import('./product/productMerchantDetailsPage'));
const Inquiries = lazy(() => import('./inquiries/inquiries'));
const Rewards = lazy(() => import('./rewards/rewards'));
const SearchResults = lazy(() => import('./search/searchResults'));

const Loyalty = lazy(() => import('./loyalty/loyalty'));

const UserPermissions = lazy(() => import('./userPermissions/userPermissions'));

const ShowSetup = lazy(() => import('./page/showSetup'));
const Page = lazy(() => import('./page/page'));

const Billing = lazy(() => import('./billing/billing'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <PublicRoute path="/login" component={ Login as any } />       
          <PublicRoute path="/passwordReset" component={ PasswordReset } />  
          <Route path="/updatePassword" component={ UpdatePassword } />  
          <Route path="/verifyEmail" component={ VerifyEmail } />  
          <PublicRoute path="/signup" component={ Signup } />

          <PrivateRoute exact path="/activateAccount" component={ ActivateAccount } />
          <PrivateRoute exact path="/selectBusiness" component={ SelectBusiness } />
          <PrivateRoute exact path="/createBusiness" component={ CreateBusiness } />
          <PrivateRoute exact path="/userProfile" component={ UserProfile } />

          <SecuredRoute exact path="/" component={ Dashboard } />
          <SecuredRoute exact path="/dashboard" component={ Dashboard } />
          <SecuredRoute exact path="/plan" component={ Pricing } />
          <SecuredRoute exact path="/businessProfile" component={ BusinessProfile } />
          <SecuredRoute exact path="/customers" component={ Customers } />
          <SecuredRoute exact path="/products" component={ Products } />
          <SecuredRoute exact path="/products/:id" component={ ProductMerchantDetailsPage } />
          <SecuredRoute exact path="/inquiries" component={ Inquiries } />
          <SecuredRoute exact path="/rewards" component={ Rewards } />

          <SecuredRoute exact path="/searchResults" component={ SearchResults } />

          <SecuredRoute exact path="/loyalty" component={ Loyalty } />

          <SecuredRoute exact path="/settings/userPermissions" component={ UserPermissions } />
          <SecuredRoute exact path="/settings/api" component={ APISettings } />

          <SecuredRoute exact path="/online/setup" component={ ShowSetup } />

          <SecuredRoute exact path="/online/home" component={ Page } />

          <SecuredRoute exact path="/online/page/:id" component={ Page } />

          <SecuredRoute exact path="/billing" component={ Billing } />

          <Route path="/error-404" component={ Error404 } />
          <Route path="/error-500" component={ Error500 } />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;