
import { GJSTagNames } from '../grapesjsEditor';


const script = function(this: any) {
  console.log('InquiryForm', this);

  let onSubmit = async (event: any) => {
    console.log('InquiryForm, form submitted');
    event.preventDefault();
 
    // Get data from the form.
    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      message: event.target.message.value,
    }

    // Send the data to the server in JSON format.
    const JSONdata = JSON.stringify(data)

    // API endpoint where we send form data.
    const endpoint = '/api/inquiries'

    const options = {
      // The method is POST because we are sending data.
      method: 'POST',
      // Tell the server we're sending JSON.
      headers: {
        'Content-Type': 'application/json',
      },
      // Body of the request is the JSON data we created above.
      body: JSONdata,
    }

    // Send the form data and get a response.
    const response = await fetch(endpoint, options)

    // Get the response data from server as JSON.
    // If server returns the name submitted, that means the form works.
    const result = await response.json()
    //alert(`Is this your full name: ${result.data}`)

    event.target.reset();
    this.innerHTML = "<p class='m-2'> Thanks. We will get in touch with you.</p>"
  }



  let forms = this.getElementsByTagName('form');
  for (let form of forms){
    form.addEventListener('submit', onSubmit);
    console.log('InquiryForm, event listener added', form);
  }

};


export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.InquiryForm) ;

  editor.Components.addType(GJSTagNames.InquiryForm, {
    model: {
      defaults: {
        components: getComponents(),
        styles: getStyles(),
        script,
        attributes: {
          
        },
        toolbar: [],
        traits: [
         
        ]
      },

      init(){
        console.log(`GrapesJS, ${GJSTagNames.InquiryForm} model initialized`);
      }
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.InquiryForm.toUpperCase();
    }
  });
};

function getComponents(){
  return `
        <div>
          <h4> Contact Us </h4>
          <form class="d-flex flex-column" onsubmit="onSubmit()">
            <div class="form-group mb-2">
              <input type="text" name="name" class="form-control" id="exampleFormControlInput1" placeholder="Name" required>
            </div>
            <div class="form-group mb-2">
              <input type="email" name="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required>
            </div>
            <div class="form-group mb-2">
              <input type="tel" name="phone" class="form-control" id="exampleFormControlInput1" placeholder="e.g 224-334-9994" required>
            </div>
            <div class="form-group mb-3">
              <label for="exampleFormControlTextarea1" class="am-pre mb-1">Tell us what your are looking for</label>
              <textarea name="message" class="form-control am-post" id="exampleFormControlTextarea1" rows="3" maxlength="1200"></textarea>
            </div>
            <button type="submit" class="rounded-md px-10 py-2">Send Message</button>
          </form>
        </div>
        `
}


function getStyles(){
  return `
    .container-width{
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
    }
    .am-sect{
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .am-content{
      float:left;
      
      padding:7px;
      color: #444;
      font-weight: 100;
    }
    .am-pre{
      padding:7px;
      color:#b1b1b1;
      font-size:15px;
    }
    .am-title{
      padding:7px;
      font-size:25px;
      font-weight: 400;
    }
    .am-desc{
      padding:7px;
      font-size:17px;
      line-height:25px;
    }
    .am-post{
      padding:7px;
      line-height:25px;
      font-size:13px;
    }
    .clearfix{ clear:both}`
    
}

function getBockLabel(){
  return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M33.25 40.7v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6ZM7 35v-1.55h1.55V35Zm5.1 0v-1.55h11.1q.05.45.075.775.025.325.025.775ZM7 26.85V25.3h1.55v1.55Zm5.1 0V25.3h15.45q-.45.35-.85.75-.4.4-.75.8ZM7 18.7v-1.55h1.55v1.55Zm5.1 0v-1.55H37v1.55ZM7 10.55V9h1.55v1.55Zm5.1 0V9H37v1.55Z"/></svg>
            <div>Inquiry</div>
          </div>`
}