import { DeleteDialog, getConfirmationText } from 'app/shared';
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, PromptProps } from 'react-router-dom';
import { initiateSaveReward, initiateDeleteReward } from './actions';
import { Reward, RewardData } from './reward';
import RewardInputDialog from './rewardInputDialog';
import { alert } from '../shared';
import './rewards.css';
import { ErrorCode } from './types';

export interface RewardListItemProp {
  businessId: string,
  reward: Reward,
  canEdit: boolean,
  onDelete?: (rewardId: string) => void,
}

export interface Props extends RewardListItemProp{
  initiateSaveReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => void,
  initiateDeleteReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => void
}

export interface State {
  reward: Reward,
  showRewardDialog: boolean,
  showDeleteDialog: boolean,
}

class _RewardListItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('RewardListItem initialized');

    this.state = {
      reward: this.props.reward,
      showRewardDialog: false,
      showDeleteDialog: false,
    }
  }

  render() {

    let {rewardId, name, amountSpentPerPoint, minPointsToRedeem, minAmountToRedeem} = this.state.reward;

      return (
        <Card className="mb-2" key={rewardId}>
            <Card.Header className="inquiry-header" style={{backgroundColor: 'inherit'}}>
              <div className="d-flex flex-row justify-content-between align-items-center"> 
                <div className="w-75">
                  <h4> <strong> {name} </strong></h4>
                  <p className="text-muted">{`Customers earn one point for every ${amountSpentPerPoint} dollars spent`}</p>
                  <p className="text-muted">{`Customers can redeem ${minPointsToRedeem} points for ${minAmountToRedeem} dollar discount`}</p>
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-md-none" >Since {this.state.reward.displaylastModified()}</h6>
                </div> 
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="card-subtitle mb-2 text-muted mr-2 mr-sm-4 d-none d-md-block" >Since {this.state.reward.displaylastModified()}</h6>
                  {
                    (this.props.canEdit)
                    ? (
                      <div className="d-flex">  
                        <i id={rewardId} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onShowRewardDialog()}}></i> 
                        <i className="ti-trash menu-icon " onClick={(e)=> { e.stopPropagation(); this.onShowDeleteDialog()}}></i> 
                      </div>
                      )
                    : null
                  }
                </div>
              </div>
            </Card.Header >
            <RewardInputDialog key={rewardId} show={this.state.showRewardDialog} reward={this.state.reward} onHide={() => this.setState({showRewardDialog: false})} onCancel={() => this.setState({showRewardDialog: false})} onSave={(reward: Reward) => this.onSave(reward)}/>
            <DeleteDialog id={rewardId} confirmationText={getConfirmationText()} show={this.state.showDeleteDialog} onHide={() => this.setState({showDeleteDialog: false})} onCancel={() => this.setState({showDeleteDialog: false})} onDelete={(rewardId: string) => this.onDelete(rewardId)}/>
        </Card>
      );
  }

  onShowRewardDialog(){
    this.setState({showRewardDialog: true });
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true });
  }

  onSave(reward: Reward){
    this.setState({reward: reward, showRewardDialog: false });

    this.props.initiateSaveReward(this.props.businessId, reward, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      (reward: Reward, errorCode: ErrorCode)=>{
        //on error

        alert.error('Failed to update!');
      })
  }

  onDelete(rewardId: string){

    this.setState({showDeleteDialog: false });

    this.props.initiateDeleteReward(this.props.businessId, this.state.reward, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })

    if(this.props.onDelete){
      this.props.onDelete(rewardId)
    }
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; reward?: Reward; onSuccess?: any; onError?: any; }) => void) => {
  return {
    initiateSaveReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
      dispatch(initiateSaveReward(businessId, reward, onSuccess, onError))
    },
    initiateDeleteReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteReward(businessId, reward, onSuccess, onError))
    }
  };
};

let X =  connect(null, mapDispatchToProps)(_RewardListItem);


export class RewardListItem extends Component<RewardListItemProp> {
  render() {
    return <X {...this.props} />
  };
}