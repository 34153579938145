
import { GJSTagNames } from '../grapesjsEditor';

export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.SectionContactUs) ;

  editor.Components.addType(GJSTagNames.SectionContactUs, {
    model: {
      defaults: {
        components: getComponents(),
        styles: getStyles(),
        attributes: {
          
        },
        toolbar: [],
        traits: [
         
        ]
      },

      init(){
        console.log(`GrapesJS, ${GJSTagNames.SectionContactUs} model initialized`);
      }
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.SectionContactUs.toUpperCase();
    }
  });

  editor.BlockManager.add('SectionContactUs', {
    label: getBockLabel(),
    category: 'Sections',
    content: '<SectionContactUs></SectionContactUs>'
  });

};

function getComponents(){
  return `
  <section class="am-sect d-flex justify-content-center">
    <div class="container-width m-2 m-md-5">
      <div class="container d-flex flex-column flex-lg-row justify-content-center align-items-center">
        <img class="img-phone" onmousedown="return false" src=""/>
        <div class="am-content col-12 col-lg-6 d-flex flex-column justify-content-center">
          <InquiryForm></InquiryForm>
        </div>
      </div>
    </div>
  </section>`
}

function getBockLabel(){
  return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24">
              <path d="M17 14V17H14V19H17V22H19V19H22V17H19V14M20 11V12.3C19.4 12.1 18.7 12 18 12C16.8 12 15.6 12.4 14.7 13H7V11H20M12.1 17H7V15H12.8C12.5 15.6 12.2 16.3 12.1 17M7 7H20V9H7V7M5 19H7V21H3V3H7V5H5V19Z" />
            </svg>
            <div>Contact Us</div>
          </div>`
}


function getStyles(){
  return `
    .container-width{
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
    }
    .am-sect{
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .am-content{
      float:left;
      
      padding:7px;
      color: #444;
      font-weight: 100;
    }
    .am-pre{
      padding:7px;
      color:#b1b1b1;
      font-size:15px;
    }
    .am-title{
      padding:7px;
      font-size:25px;
      font-weight: 400;
    }
    .am-desc{
      padding:7px;
      font-size:17px;
      line-height:25px;
    }
    .am-post{
      padding:7px;
      line-height:25px;
      font-size:13px;
    }
    .clearfix{ clear:both}`
    
}