import { Component } from 'react';
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';
import Icon from '@mdi/react';
import { Placement } from 'react-bootstrap/esm/Overlay';


export interface Props {
 path: string,
 onClick?: () => void,
 toolTipText: string,
 toolTipPosition?:Placement,
 size?: number
}

export interface State {

}

export class IconButton extends Component <Props, State>{  

  constructor(props: Props){
    super(props);
    console.log('IconButton initialized');

     this.state = {
 
     }
  }

  render() {
    let size = this.props.size? this.props.size:1;
    return (
      <OverlayTrigger placement={this.props.toolTipPosition} overlay={<Tooltip id="tooltip-disabled">{this.props.toolTipText}</Tooltip>}>
          <span className="d-inline-block pl-2" onClick={()=> (this.props.onClick)?this.props.onClick():null}>
            <Icon path={this.props.path} size={size} horizontal vertical rotate={180} color="grey"/>
          </span>
      </OverlayTrigger>
    );
  }
}


export default IconButton;