
export enum RecordObject {
    business = 'business',
    globalCustomer = 'globalCustomer',
    customer = 'customer',
    inquiry = 'inquiry',
    reward = 'reward',
    product = 'product',
    domain = 'domain',
}

export let RecordObjectToCode = new Map<RecordObject, string>();
RecordObjectToCode.set(RecordObject.business, '211');
RecordObjectToCode.set(RecordObject.globalCustomer, '311');
RecordObjectToCode.set(RecordObject.customer, '411');
RecordObjectToCode.set(RecordObject.inquiry, '511');
RecordObjectToCode.set(RecordObject.reward, '611');
RecordObjectToCode.set(RecordObject.product, '711');



export let RecordCodeToObject = new Map<string, RecordObject>();
RecordCodeToObject.set('211', RecordObject.business);
RecordCodeToObject.set('311', RecordObject.globalCustomer);
RecordCodeToObject.set('411', RecordObject.customer);
RecordCodeToObject.set('511', RecordObject.inquiry);
RecordCodeToObject.set('611', RecordObject.reward);
RecordCodeToObject.set('711', RecordObject.product);

