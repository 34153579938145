import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { UserPermission } from 'app/userPermissions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RewardListPage from './rewardListPage';


export interface Props {
  authState: AuthState,
  businessState: BusinessState,
}

export interface State {

}

export class Rewards extends Component <Props, State>{
  
  render() {
    return (
      <RewardListPage key={this.props.businessState.selectedBusiness.businessId} businessId = {this.props.businessState.selectedBusiness.businessId}/>
    );
  }
}



const mapStateToProps = (state: { auth: any; business: any; }) => {
  return { 
    authState: state.auth,
    businessState: state.business 
  };
};


export default connect(mapStateToProps, null)(Rewards);