
import { Component } from 'react';
import _ from 'lodash';
import { Img as Image } from 'app/shared';
import { settings } from 'app/config';
import { imageLoader } from 'app/shared';

let twitterColor = 'icons8-twitter-48.png';
let instagramColor = 'icons8-instagram-48.png';
let facebookColor = 'icons8-facebook-48.png';
let snapchatColor = 'icons8-snapchat-48.png';
let youtubeColor = 'icons8-youtube-48.png';
let linkedinColor = 'icons8-linkedin-48.png';
let whatsappColor = 'icons8-whatsapp-48.png';
let pinterestColor = 'icons8-pinterest-48.png';
let shareColor = 'icons8-share-48.png';

let appStoreIcon = 'App_Store_blk.svg'
let playStoreIcon = 'google-play-badge-edited.png'



export interface Props {
  socialLinks: string[]
}

export default class FollowUs extends Component<Props> {
    static propTypes: {};

    constructor(props: Props){
      super(props)
    }

    render(){
       
        return (
              <div className="d-flex flex-column justify-content-start p-2">
                {
                  (this.props.socialLinks.length > 0)
                  ? <div className="">
                      <h5> Follow Us</h5>
                    </div>
                  : null
                }
                <div className="d-flex justify-content-start pb-2">
                  {
                    getSocialLinks(this.props.socialLinks)
                  }                       
                </div> 
                <div className="d-none justify-content-start">
                  <AppStoreButton img={appStoreIcon} action={settings.appStore}>App store</AppStoreButton>
                  <AppStoreButton img={playStoreIcon} action={settings.playStore}> Play store </AppStoreButton>
                </div>
            </div>
        );
    }
}


function getSocialLinks(socialLinks: any[]){
  return (socialLinks)?
              socialLinks.map((link, index) => {
                let socialLink = getSocialLink(link);
                  return (
                    <a key={index} className="px-1" target="_blank" rel="noopener noreferrer" href={socialLink.url}>
                        <Image className="img-fluid" loader={imageLoader} src={socialLink.image} height="40" width="40" alt={socialLink.hostname} fallbackSrc={''}/>
                    </a>
                   )
              })
              
          : null
}

function getSocialLink(socialLink: string){
  let response: {socialLink: string, image: any} = {socialLink: socialLink, image: ''};

  let link = _.toLower(socialLink);
  if (link.startsWith('https://') || link.startsWith('http://')){

  }else{
    link = 'https://' + link;
  }

  let hostname = new URL(link).hostname;

  if (link.search('twitter.com') >= 0){
    response.image = twitterColor;
  }else if (link.search('instagram.com') >= 0){
    response.image = instagramColor;
  }else if (link.search('facebook.com') >= 0){
    response.image = facebookColor;
  }else if (link.search('snapchat.com') >= 0){
    response.image = snapchatColor;
  }else if (link.search('youtube.com') >= 0){
    response.image = youtubeColor;
  }else if (link.search('linkedin.com') >= 0){
    response.image = linkedinColor;
  }else if (link.search('whatsapp.com') >= 0){
    response.image = whatsappColor;
  }else if (link.search('pinterest.com') >= 0){
    response.image = pinterestColor;
  }
  else {
    response.image = shareColor;
  }
  return {url: link, image: response.image, hostname: hostname}
}


function AppStoreButton(props: any){
    
  return (
      <a href={props.action} className={(props.img)?"me-1":"btn btn-primary"}>
          {
              (props.img)?<Image className={props.className} alt={props.children} loader={imageLoader} src={props.img} height={40} width={120} fallbackSrc={''}/>:null
          }
          {(props.img)?null:props.children}
      </a>
  );
}