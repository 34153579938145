

import firebase, { firestore } from '../firebase.js';
import { Reward, RewardData } from './reward';
import {isChars, isNumbers, isEmailAddress } from '../shared'; 
import { settings } from '../config/settings';
import axios from 'axios';

export async function fetchRewardList(businessId: string, afterTimestamp=0, searchText=''){

  console.log('reward, firebaseActions, fetchRewardList, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/rewards';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  let response =  await axios.get(rewardUrl, {headers});
  let rewardCollection = response.data;
  let rewardList: Reward[] = [];


  if (rewardCollection.length > 0){
      console.log('reward, firebaseActions');
      
      rewardCollection.forEach((item: any)=>{
        let reward = new Reward(item as RewardData);
        console.log(JSON.stringify(reward));
        rewardList.push(reward);
      });

  }else{
      console.log(`reward, firebaseActions, fetchRewardList, reward list is empty for businessId - ${businessId}`);
  }

  return rewardList;
}

export async function createReward(businessId: string, reward: Reward){

  console.log('firebaseActions, createReward, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/rewards';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }

  reward.lastModified = new Date().getTime();

  console.log(JSON.stringify(reward));
  let response = await axios.post(rewardUrl, reward.serialize(), {headers});

  return new Reward(response.data);
}

export async function updateReward(businessId: string, reward: Reward){

  console.log('firebaseActions, updateReward, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/rewards/' + reward.rewardId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  let response = await axios.patch(rewardUrl, reward.serialize(), {headers});

  return new Reward(response.data);

}

export async function deleteReward(businessId: string, reward: Reward){

  console.log('firebaseActions, deleteReward, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/rewards/' + reward.rewardId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  await axios.delete(rewardUrl, {headers});
  return reward;
}
