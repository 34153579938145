import { BusinessHours, Hour } from 'app/business';
import { displayTime } from 'app/shared';
import _ from 'lodash';
import { Component } from 'react';
import { Col, Row  } from 'react-bootstrap';



export interface Props {
  businessHours: BusinessHours,
}

export interface State {
  days: any;
}

export class ShowBusinessHours extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('ShowBusinessHours initialized');

    this.state = {
      days:{
        mon: {
          openTime: 0,
          closeTime: 0,
          isClosed: true
        },
        tue: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        wed: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        thu: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        fri: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        sat: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        sun: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        }
      }
    }

    this.props.businessHours.hours.forEach((hour: Hour) => {
      hour.days.map(day => {
        this.state.days[day].openTime = hour.openTime;
        this.state.days[day].closeTime = hour.closeTime;
        this.state.days[day].isClosed = hour.isClosed; 
      })
    })

  }

  componentDidUpdate(previoursProps: Props){
    if(!_.isEqual(previoursProps.businessHours.hours, this.props.businessHours.hours)){
      let days = this.state.days;

      // need to reset before assigning new values
      for (const key in days){
        days[key].openTime = 0;
        days[key].closeTime = 0;
        days[key].isClosed = true
      }

      this.props.businessHours.hours.forEach((hour: Hour) => {
        hour.days.map(day => {
          days[day].openTime = hour.openTime;
          days[day].closeTime = hour.closeTime;
          days[day].isClosed = hour.isClosed; 
        })
      })
      this.setState({ days: days})
    }
  }

  render(){

  return (
    
        this.renderShowBusinessHours()
           
   );
  }

  renderShowBusinessHours() {
    let businessHours = this.props.businessHours;
    
    return (

          <div className="d-flex flex-column justify-content-start p-2">
              {
                (businessHours.isOpen24Hours) ? (this.renderAsOpen24Hours())
                : (businessHours.isClosed ? this.renderAsClosed() : this.renderDays1())
                
              }
          </div>  
    )
  }

  renderAsOpen24Hours(){
    return(
        <Row className="">
              <Col xs={12}>
                <span className="float-left text-muted">
                  Open 24 Hours
                </span>
              </Col>
        </Row>
    );
  }

  renderAsClosed(){
    return (
      <Row className="">
          <Col xs={12}>
            <span className="float-left text-muted">
              Closed
            </span>
          </Col>
      </Row>
    );
  }

  renderDays(){
    return (
      <table style={{width: '100%', color: 'inherit'}}>
        
        {
          Object.keys(this.state.days).map(day => {
            return (
              <tr className="">
           
                  <td className="float-left">
                    <a className="mb-1">{_.upperFirst(day)}</a>
                  </td>
             
                
                  <td className="">
                    <a className="mb-1">{(this.state.days[day].isClosed)?'Closed': `${displayTime(this.state.days[day].openTime)} - ${displayTime(this.state.days[day].closeTime)}`}</a>
                  </td>
              
              </tr>
            );
          })
        }
      </table>   

    );
  }

  renderDays1(){
    return (
      <div style={{display: 'grid', 'gridTemplateColumns': '20% 80%'}}>
        
        {
          Object.keys(this.state.days).map(day => {
            return (
              <>
           
                  <div className="pr-2 text-start">
                    <a>{_.upperFirst(day)}</a>
                  </div>
             
                
                  <div className="text-start">
                    <a>{(this.state.days[day].isClosed)?'Closed': `${displayTime(this.state.days[day].openTime)} - ${displayTime(this.state.days[day].closeTime)}`}</a>
                  </div>
              
              </>
            );
          })
        }
      </div>   

    );
  }
}
