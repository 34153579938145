import { Component } from 'react';
import Icon from './icon';


export interface Props {
 className?: string 
 path: string,
 label: string,
}

export interface State {

}

export class IconWithLabel extends Component <Props, State>{  

  constructor(props: Props){
    super(props);
    console.log('IconWithLabel initialized');

     this.state = {
 
     }
  }

  render() {

    return (
        <div className="align-items-center" {...this.props}>
          <Icon className="pr-3" path={this.props.path}/>
          <span className="menu-title">{this.props.label}</span>
        </div>
    );
  }
}


export default IconWithLabel;