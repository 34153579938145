import type from './types';
import {type as authType} from '../user';
import { CurrentPlans, PlanState } from './plans';

const INITIAL_STATE = new PlanState ();
   

export const planReducer = (state = INITIAL_STATE, action: { type: string; currentPlans: CurrentPlans;}) => {


    switch (action.type){


        case type.CURRENT_PLANS_LOADED:
        {
            console.log('plansReducer, '+action.type);          
            return {...state, state: type.CURRENT_PLANS_LOADED, currentPlans: action.currentPlans};
        }

        case authType.SIGNED_OUT:
            console.log('plansReducer, '+action.type);
            return {...state, state: type.PLANS_INITIALIZED, currentPlans: new CurrentPlans({})};

        default:
            console.log('plansReducer, default - '+ action.type);
            return state;
    }
};
