import React, { Component } from 'react';
import './product.css';
import { Product } from './product';
import ProductListItem  from './productListItem';

export interface Props {
  businessId: string,
  productList: Product[],
  canEdit: boolean,
}

export interface State {
  
}

export class ProductList extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductList initialized');
  }

  render() {
    let { businessId, productList, canEdit } = this.props;

    return (
      <div className="row product-item-wrapper">
        {
          productList.map((item)=>{
            return (
              <ProductListItem 
                key={item.productId}
                businessId={businessId}
                product={item}
                canEdit={canEdit}
                onDelete={this.onDelete.bind(this)}
              />
            );
          })
        }
      </div>
    )
  }

  onDelete(productId: string){
      //product is deleted, do any clean up if needed.
  }
}
