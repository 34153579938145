import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { onAuthStateChange } from './firebase';
import { onSignIn, onSignOut} from './user';


class App extends Component {
  state = {}

  componentDidMount() {
    this.onRouteChanged();
    onAuthStateChange(this.props.onSignIn, this.props.onSignOut);
  }
  render () {
    let navbarComponent = this.state.isNavbarRequired ? <Navbar/> : '';
    let sidebarComponent = this.state.isSidebarRequired && (this.props.businessState.selectedBusiness) ? <Sidebar key={this.props.businessState.selectedBusiness.businessId}/> : '';
    let SettingsPanelComponent = this.state.isSettingsPanelRequired ? <SettingsPanel/> : '';
    let footerComponent = this.state.isFooterRequired ? <Footer/> : '';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
              { SettingsPanelComponent }
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    let isFullPageLayout = false;
    const fullPageLayoutRoutes = ['/login',  '/signup',  '/passwordReset', '/updatePassword', '/verifyEmail', '/error-404', '/error-500'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      console.log(`Pathname - ${this.props.location.pathname}`);
      if ((this.props.location.pathname === fullPageLayoutRoutes[i]) || (this.props.location.pathname.search('/online/page') !== -1)){
        this.setState({
          isNavbarRequired: false,
          isSidebarRequired: false,
          isSettingsPanelRequired: false,
          isFooterRequired: false
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        isFullPageLayout = true;
        break;
      } else {
        this.setState({
          isNavbarRequired: true,
          isSidebarRequired: true,
          isSettingsPanelRequired: true,
          isFooterRequired: true
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
        document.querySelector('.page-body-wrapper').classList.remove('nav-only-page-wrapper');
      }
    }

    if(!isFullPageLayout){ 
      const restrictedRoutes = ['/activateAccount', '/createBusiness', '/selectBusiness', '/userProfile'];
      for ( let i = 0; i < restrictedRoutes.length; i++ ) {
        if (this.props.location.pathname === restrictedRoutes[i]) {
          this.setState({
            isNavbarRequired: true,
            isSidebarRequired: false,
            isSettingsPanelRequired: false,
            isFooterRequired: true
          })
          document.querySelector('.page-body-wrapper').classList.add('nav-only-page-wrapper');
          break;
        }
      }
    }

  }

  onRouteChanged1(){


  }

}

const mapStateToProps = (state) => {
  return { 
            businessState: state.business,
            
          };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (user) => {
      dispatch(onSignIn(user));
    },
    onSignOut: () => {
      dispatch(onSignOut());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));