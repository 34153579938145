import type from './types';
import { createBusiness, updateBusiness, uploadPicture, deletePicture, deleteBusiness } from './firebaseActions';
import { Role } from 'app/userPermissions';
import { BusinessHours } from './businessHours';
import { Record } from 'app/record';
import { Metadata } from 'app/shared/metadata';
import _ from 'lodash';


export enum BusinessStatus {
    active = 'active',
    deleted = 'deleted',
    suspended = 'suspended'
};

export type Permission = {role: Role};

export type BusinessData = {
    businessId?: string;
    name?: any;
    description?: any;
    type?: any;
    keywords?: string[];
    country?: {name: string, iso2: string, dialCode: number, format: string, hasAreaCodes: boolean};
    countryCode?: string;
    phoneNumber?: any;
    emailAddress?: any;
    address?: any;
    bannerPicture?: string;
    profilePicture?: string;
    locations?: any;
    businessHours?: BusinessHours;
    userPermission?: Permission;
    deleteBannerPicture?: string;
    deleteProfilePicture?: string;
    newBannerPictureFile?: null;
    newProfilePictureFile?: null; 
    socialLinks?: string[];
    
    status?: BusinessStatus;
    metadata?: Metadata;
}

export class Business {
    businessId: string;
    name: any;
    description: any;
    type: any;
    keywords: string[];
    country: {name: string, iso2: string, dialCode: number, format: string, hasAreaCodes: boolean};
    countryCode: string;
    phoneNumber: any;
    emailAddress: any;
    address: any;
    bannerPicture: any;
    profilePicture: any;
    locations: any;
    businessHours: BusinessHours;
    userPermission: { role: Role; };
    deleteBannerPicture: string;
    deleteProfilePicture: string;
    newBannerPictureFile: any;
    newProfilePictureFile: any; 
    socialLinks: string[];

    status: BusinessStatus;
    metadata: Metadata;


    constructor(businessData: BusinessData){
        console.log('Business initialized');

        this.businessId = businessData.businessId || '';
        this.name = businessData.name || '';
        this.description = businessData.description || '';
        this.type = businessData.type || 'Online';
        this.keywords = businessData.keywords || [];
        this.country = businessData.country || {name:'United States', iso2:'us', dialCode: 1, format:'', hasAreaCodes:false};
        this.countryCode = businessData.countryCode || '';
        this.phoneNumber = businessData.phoneNumber || '';
        this.emailAddress = businessData.emailAddress || '';
        this.address = (businessData.address)? new Address(businessData.address): new Address({});

        this.bannerPicture = businessData.bannerPicture || '';
        this.profilePicture = businessData.profilePicture || '';

        this.locations = businessData.locations || [];
        this.businessHours = businessData.businessHours? new BusinessHours(businessData.businessHours): new BusinessHours({});

        this.userPermission = businessData.userPermission || { role: Role.Viewer};

        this.deleteBannerPicture = businessData.deleteBannerPicture || '';
        this.deleteProfilePicture = businessData.deleteProfilePicture || '';
        this.newBannerPictureFile = businessData.newBannerPictureFile || '';
        this.newProfilePictureFile = businessData.newProfilePictureFile || '';

        this.socialLinks = businessData.socialLinks || [];

        this.status = businessData.status || BusinessStatus.active;
        this.metadata = businessData.metadata? new Metadata(businessData.metadata): new Metadata({});
    }

    isDifferent(business: Business){
        console.log('Business isDifferent');

        let isDifferent = false;
        if (this.name !== business.name || 
            this.description !== business.description || 
            this.type !== business.type ||   
            this.phoneNumber !== business.phoneNumber || 
            this.countryCode !== business.countryCode || 
            this.emailAddress !== business.emailAddress){
            isDifferent = true;
        }

        if (!isDifferent && !_.isEqual(this.keywords, business.keywords)){
            isDifferent = true;
        }

        if (!isDifferent && this.address.isDifferent(business.address)){
            isDifferent = true;
        }

        if (!isDifferent && (business.newBannerPictureFile != null || business.newProfilePictureFile != null)){
            isDifferent = true;
        }

        if (!isDifferent && (business.deleteBannerPicture !== '' || business.deleteProfilePicture !== '')){
            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        console.log('Business serialize');

        return {
            name: this.name,
            description: this.description,
            type: this.type, 
            keywords: this.keywords,
            country: this.country,
            countryCode: this.countryCode,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            address: this.address.serialize(),
            bannerPicture: this.bannerPicture,
            profilePicture: this.profilePicture,
            socialLinks: this.socialLinks,
            metadata: this.metadata.serialize()
        }
    }

    copy(business: Business){
        Object.assign(this, business);
    }

    async create(){
        console.log('Business, create');
        this.businessId = new Record().generateBusinessId();
        await createBusiness(this);
        return this;
    }

    async update(){
        console.log('Business, update');
        await deletePicture(this.deleteBannerPicture)

        if(this.newBannerPictureFile){
            this.bannerPicture = await uploadPicture(this.businessId, this.newBannerPictureFile);
        }

        await deletePicture(this.deleteProfilePicture);

        if(this.newProfilePictureFile){
            this.profilePicture = await uploadPicture(this.businessId, this.newProfilePictureFile);
        }
        await updateBusiness(this);
        return this;
    }

    async delete(reasonToDelete: string, callToDiscuss: boolean){
        console.log('Business, delete');
        this.status = BusinessStatus.deleted;
        await deleteBusiness(this, reasonToDelete, callToDiscuss);
        return this;
    }
}

export type AddressData = {

    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
}

export class Address {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;

    constructor({street, city, state, zipCode, country}: AddressData){
        this.street = street || '';
        this.city = city || '';
        this.state = state || '';
        this.zipCode = zipCode || '';
        this.country = country || '';
    }

    isDifferent(address: Address){
        let isDifferent = false;
        if (this.street !== address.street || this.city !== address.city || this.state !== address.state || this.zipCode !== address.zipCode){
            isDifferent = true;
        }
        return isDifferent;
    }

    serialize(){
        return {
            street: this.street,
            city: this.city,
            state: this.state, 
            zipCode: this.zipCode,
            country: this.country
        };
    }
}

export type BusinessStateData = {
    state?: string;
    businessList?: Business[];
    selectedBusiness?: Business;
}

export class BusinessState {
    state: string;
    businessList: Business[];
    selectedBusiness: Business;
    canAdminister: () => boolean;
    canCreate: () => boolean;
    canEdit: () => boolean;
    canDelete: () => boolean;
    setSelectedBusiness: (businessId: string) => void;
    getBusiness: (businessId: string) => Business | undefined;
    retrieveSelectedBusinessFromLocalStorage: () => Business | undefined;

    constructor(businessStateData: BusinessStateData){
        console.log('BusinessState initialized');

        this.state = businessStateData.state || type.INITIALIZED;
        this.businessList = businessStateData.businessList || [];
        this.selectedBusiness = businessStateData.selectedBusiness || new Business({});

        this.canAdminister = function(){
            let canAdminister = false;
            if (this.selectedBusiness){
                canAdminister = (this.selectedBusiness.userPermission.role === Role.Admin);
            }
            return canAdminister;
        }

        this.canCreate = function(){
            return ((this.selectedBusiness.userPermission.role === Role.Admin) || (this.selectedBusiness?.userPermission.role === Role.Editor));
        }

        this.canEdit = function (){
            return ((this.selectedBusiness?.userPermission.role === Role.Admin) || (this.selectedBusiness?.userPermission.role === Role.Editor));
        }

        this.canDelete = function(){
            return ((this.selectedBusiness?.userPermission.role === Role.Admin) || (this.selectedBusiness?.userPermission.role === Role.Editor));
        }

        this.setSelectedBusiness = function (businessId: string){
            console.log(`BusinessState, setSelectedBusiness, businessId - ${businessId}`);
            
            let selectedBusiness = undefined;
            
            if (businessId === ''){
                //check whether business id is available in local storage
                selectedBusiness = this.retrieveSelectedBusinessFromLocalStorage();
            }else{
                selectedBusiness = this.getBusiness(businessId);
            }

            if (selectedBusiness === undefined && this.businessList[0]){
                selectedBusiness = this.businessList[0];
            }else if (selectedBusiness === undefined){
                selectedBusiness = new Business({});
            }

            this.selectedBusiness = selectedBusiness;
            this.saveSelectedBusinessIdToLocalStorage(this.selectedBusiness);
        }

        this.getBusiness = function(businessId: string): Business | undefined{
            return this.businessList.find(business => {
                 return (business.businessId === businessId)
             })
        }
    
        this.retrieveSelectedBusinessFromLocalStorage = function(): Business | undefined {
            let selectedBusinessId = localStorage.getItem('selectedBusinessId');
            let selectedBusiness = undefined;
            if (selectedBusinessId){
                selectedBusiness = this.getBusiness(selectedBusinessId);
            }
            return selectedBusiness;
        }
    }

    private saveSelectedBusinessIdToLocalStorage = function(selectedBusiness: Business): void{
        localStorage.setItem('selectedBusinessId', selectedBusiness.businessId);
    }

    static retrieveSelectedBusinessIdFromLocalStorage = function(){
        return localStorage.getItem('selectedBusinessId');
    }
   
}