
import React, { Component } from 'react';

interface Props {
    countryCode: string;
    phoneNumber: string;
  }

interface State { }

export default class PhoneNumberText extends Component<Props, State> {
    render(){
        const { countryCode, phoneNumber } = this.props;

            return (
                <div {...this.props} >{`+${countryCode} ${phoneNumber}`}</div>
            );
    }
}

