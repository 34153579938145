export type SettingsData = {
    attributes:{},
    style:{
        'background-color': string
    }
}

export type HeaderSettings = SettingsData & {
    attributes:{
        showHours: boolean
    },
}

export type FooterSettings = SettingsData & {
    attributes:{
        showContact: boolean,
        showHours: boolean,
        showSocialLinks: boolean
    },
}

export type PageSettingsData = {
    header?: HeaderSettings;
    footer?:FooterSettings;
    assets?:{
        images: ImageAsset[]
    }
}

export class ImageAsset {
    url: string;
    size: number;

    constructor(url: string, size: number){
        this.url = url;
        this.size = size
    }

    serialize(){
        return {
            url: this.url,
            size: this.size,
        }
    }
}


export class PageSettings{
    header: HeaderSettings;
    footer:FooterSettings;
    assets:{
        images:ImageAsset[]
    }
    

    constructor(pageSettingsData: PageSettingsData){

        this.header = pageSettingsData.header || { attributes: { showHours: true}, style:{ 'background-color': ''}};
        this.footer = pageSettingsData.footer || { attributes: { showContact: true, showHours: true, showSocialLinks: false}, style:{ 'background-color': ''}};
        this.assets = {images: []}
        this.assets.images = pageSettingsData.assets?.images.map(imageAssets => new ImageAsset(imageAssets.url, imageAssets.size)) || [];
        
    }

    isDifferent(pageSettings: PageSettings){
        return true;
    }

    serialize(){
        return {
            header: this.header,
            footer: this.footer,
            assets: {images: this.assets.images.map(imageAsset => imageAsset.serialize())}
        }
    }

    addImage(url:string, size:number){
        this.assets.images.push(new ImageAsset(url, size));
    }

    removeImage(url: string){
        this.assets.images = this.assets.images.filter(imageAsset => url !== imageAsset.url);
    }

    getImageUrls(){
        return this.assets.images.map(imageAsset => imageAsset.url);
    }
}
