
const USER_PERMISSION_INITIALIZED = 'USER_PERMISSION_INITIALIZED';
const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
const USER_PERMISSION_LOADED = 'USER_PERMISSION_LOADED';
const USER_PERMISSION_LIST_EMPTY = 'USER_PERMISSION_LIST_EMPTY';
const FAILED_TO_LOAD_USER_PERMISSIONS = 'FAILED_TO_LOAD_USER_PERMISSIONS';

const INITIATE_CREATE_USER_PERMISSION = 'INITIATE_CREATE_USER_PERMISSION';
const INITIATE_UPDATE_USER_PERMISSION = 'INITIATE_UPDATE_USER_PERMISSION';
const INITIATE_DELETE_USER_PERMISSION = 'INITIATE_DELETE_USER_PERMISSION';

const CREATE_USER_PERMISSION = 'CREATE_USER_PERMISSION';
const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
const DELETE_USER_PERMISSION = 'DELETE_USER_PERMISSION';


export default {
    USER_PERMISSION_INITIALIZED,
    LOAD_USER_PERMISSIONS,
    USER_PERMISSION_LOADED,
    USER_PERMISSION_LIST_EMPTY,
    FAILED_TO_LOAD_USER_PERMISSIONS,

    INITIATE_CREATE_USER_PERMISSION,
    INITIATE_UPDATE_USER_PERMISSION,
    INITIATE_DELETE_USER_PERMISSION,

    CREATE_USER_PERMISSION,
    UPDATE_USER_PERMISSION,
    DELETE_USER_PERMISSION
};

