
import { GJSTagNames } from '../grapesjsEditor';

export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.SectionWithImage) ;

  editor.Components.addType(GJSTagNames.SectionWithImage, {
    model: {
      defaults: {
        components: getComponents(),
        styles: getStyles(),
        attributes: {
          
        },
        toolbar: [],
        traits: [
         
        ]
      },

      init(){
        console.log(`GrapesJS, ${GJSTagNames.SectionWithImage} model initialized`);
      }
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.SectionWithImage.toUpperCase();
    }
  });

  editor.BlockManager.add('SectionWithImage', {
    label: getBlockLabel(),
    category: 'Sections',
    content: '<SectionWithImage></SectionWithImage>'
  });

};

function getBlockLabel(){
  return `<div class='gjs-fonts'>
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 24 24"">
              <path d="M22 13H14V11H22V13M22 7H14V9H22V7M14 17H22V15H14V17M12 9V15C12 16.1 11.1 17 10 17H4C2.9 17 2 16.1 2 15V9C2 7.9 2.9 7 4 7H10C11.1 7 12 7.9 12 9M10.5 15L8.3 12L6.5 14.3L5.3 12.8L3.5 15H10.5Z" />
            </svg>
            <div>Image</div>
          </div>`
}

function getComponents(){
  return `
  <section class="am-sect d-flex justify-content-center">
    <div class="container-width">
      <div class="container d-flex flex-column flex-lg-row justify-content-center align-items-center">
        <img class="img-phone" onmousedown="return false" src=""/>
        <div class="am-content col-12 col-lg-6 d-flex flex-column justify-content-center">
          <div class="am-pre">ASSET MANAGER</div>
          <div class="am-title">Manage your images with Asset Manager</div>
          <div class="am-desc">You can create image blocks with the command from the left panel and edit them with double click</div>
          <div class="am-post">Image uploading is not allowed in this demo</div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </section>`
}


function getStyles(){
  return `
    .container-width{
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
    }
    .am-sect{
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .img-phone{
      float: left;
      margin: 10px;
    }
    .am-content{
      float:left;
      
      padding:7px;
      color: #444;
      font-weight: 100;
    }
    .am-pre{
      padding:7px;
      color:#b1b1b1;
      font-size:15px;
    }
    .am-title{
      padding:7px;
      font-size:25px;
      font-weight: 400;
    }
    .am-desc{
      padding:7px;
      font-size:17px;
      line-height:25px;
    }
    .am-post{
      padding:7px;
      line-height:25px;
      font-size:13px;
    }
    .clearfix{ clear:both}`
    
}