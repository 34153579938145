import type from './types';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import { SKU, SKUStatus, SKUName } from 'app/billing/payment';


export enum PlanType{
    free = 'free',
    start = 'start',
    grow = 'grow',
    scale = 'scale'
}

export class Plan{
    plan: PlanType;
    skus: SKU[];
    subTotal: number;
    getSKU: (skuName: SKUName) => any;
    
    constructor(planData: any){
        
        this.plan = planData.plan || '';
        this.skus = [];
        this.subTotal = planData.subTotal || 0;
        

        for (let key in planData.skus){
            let value = planData.skus[key];

            let skuStatus: SKUStatus = SKUStatus.active;
            
            this.skus.push(SKU.create({name: key as SKUName, 
                                    status: skuStatus,
                                    displayName: value.displayName, 
                                    displayPosition: value.displayPosition,  
                                    aggregated: value.aggregated,
                                    showInBilling: value.showInBilling,
                                    freeUnits: value.freeUnits, 
                                    unitCost: value.unitCost, 
                                    units: 0,
                                    cost: 0}));
        }

        this.getSKU = function(skuName: SKUName){
            let sku =  this.skus.find(sku => sku.name === skuName);
            if (!sku){
                sku = SKU.create({name: SKUName.dummy, status: SKUStatus.suspended, displayName: 'Dummy', displayPosition: 0, aggregated: false, showInBilling: false, freeUnits: 0, unitCost:0, units:0 });
            }
            return sku
        }
    }
}

export class CurrentPlans{
    plans: {[key: string]: Plan} = {};

    constructor(currentPlansData: {[key: string]: Plan}){
        for (let key in currentPlansData){
            this.plans[key] = new Plan(currentPlansData[key]);
        }
    }
}


export class PlanState {
    state: string;
    currentPlans: CurrentPlans;
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: Business) => boolean;
    getPlan: (planType: PlanType) => Plan;

    constructor(){
        console.log('PaymentState initialized');

        this.state = type.PLANS_INITIALIZED;
        this.currentPlans = new CurrentPlans({});

        this.canCreate = function(selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canEdit = function (selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness: Business){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.getPlan = function(planType: PlanType){
            return this.currentPlans.plans[planType];
        }
    }
}
