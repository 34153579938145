import { Component } from 'react';
import BusinessContact from './businessContact';
import { ShowBusinessHours } from './showBusinessHours';
import { Business, BusinessHours } from 'app/business';
import FollowUs from './followUs';
import { PageLinks, PageLinkLocation } from '../pageLinks';
import BusinessPageLinks from './businessPageLinks';



export interface Props {
    business: Business;
    businessHours: BusinessHours;
    links: PageLinks;
    showContact: boolean;
    showHours: boolean;
    showSocialLinks: boolean;
 }

class BusinessFooter extends Component<Props>{

    render(){
        const { business, businessHours, links, showContact, showHours, showSocialLinks } = this.props;

        return(
            <footer className=" pt-3">
                <div className="container">
                    <div className="d-flex row align-items-baseline justify-content-between">
                        <div className="col-12 col-md-4 col-lg-3 mb-4 d-flex flex-column justify-content-center">
                            <div className="p-2">
                                <h4 className="mb-10">{business.name}</h4>
                                <BusinessPageLinks links={this.props.links} location={PageLinkLocation.footer}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-7 mb-4 d-none d-lg-flex flex-lg-row justify-content-start"> 
                            {(showContact) ? <BusinessContact business={business}/> : null }
                            <div className="m-4"> </div>
                            {(showHours) ? <ShowBusinessHours businessHours={businessHours}/> : null }
                        </div>
                        {(showContact) ?
                            (<div className="col-12 col-md-4 col-lg-7 mb-4 d-flex d-lg-none flex-lg-row justify-content-between"> 
                                <BusinessContact business={business}/>
                            </div>
                            )
                            : null
                        }
                        {(showHours) ?
                            (<div className="col-12 col-md-4 col-lg-7 mb-4 d-flex d-lg-none flex-lg-row justify-content-between"> 
                                 <ShowBusinessHours businessHours={businessHours}/>
                            </div>
                            )
                            : null
                        }
                        <div className="col-12 col-md-4 col-lg-2 mb-4 d-flex flex-column justify-content-center">  
                            {(showSocialLinks) ? <FollowUs socialLinks={business.socialLinks}/> : null}
                        </div>
                    </div>
                </div>
            </footer>   
        );
    }
}

export default BusinessFooter;