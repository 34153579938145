
const PLANS_INITIALIZED = 'PLANS_INITIALIZED';
const LOAD_CURRENT_PLANS = 'LOAD_CURRENT_PLANS';
const CURRENT_PLANS_LOADED = 'CURRENT_PLANS_LOADED';

export default {
    PLANS_INITIALIZED,

    LOAD_CURRENT_PLANS,
    CURRENT_PLANS_LOADED,
};