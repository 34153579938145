import { combineReducers } from 'redux'

import { authReducer } from './app/user';
import { businessReducer } from './app/business';
import { customerReducer } from './app/customers';
import { inquiryReducer } from './app/inquiries';
import { rewardReducer } from 'app/rewards';
import { userPermissionReducer } from './app/userPermissions';
import { dashboardElementsReducer } from './app/dashboard';
import { analyticsReducer } from './app/analytics';
import { billingReducer } from './app/billing';
import { searchResultReducer } from 'app/search';
import { apiSettingReducer } from 'app/apiSettings';
import { pageLinksReducer } from 'app/page';
import { planReducer } from 'app/subscription';
import { productReducer } from 'app/product';


export default combineReducers({
    auth: authReducer,
    business: businessReducer,
    customer: customerReducer,
    inquiry: inquiryReducer,
    reward: rewardReducer,
    userPermission: userPermissionReducer,
    dashboardElements: dashboardElementsReducer,
    analytics: analyticsReducer,
    billing: billingReducer,
    searchResult: searchResultReducer,
    apiSetting: apiSettingReducer,
    pageLinks: pageLinksReducer,
    plan: planReducer,
    product: productReducer
})