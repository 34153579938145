import { Component } from 'react';
import { connect } from 'react-redux';
import { alert } from '../shared';
import { Spinner } from '../shared/Spinner';
import RewardInputDialog from './rewardInputDialog';
import { loadRewards, loadMoreRewards, initiateSaveReward, initiateDeleteReward } from "./actions";
import types, { ErrorCode } from './types';
import './rewards.css';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Reward, RewardState } from './reward';
import { RewardList } from './rewardList';


export interface Props {
  authState: AuthState,
  businessState: BusinessState,
  rewardState: RewardState,
  businessId: string,
  loadRewards: (businessId: string, searchText: string | undefined) => void,
  loadMoreRewards: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) => void,
  initiateSaveReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => void,
  initiateDeleteReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => void
}

export interface State {
  editable: boolean,
  searchText: string,
  showRewardDialog: boolean,
  selectedReward: Reward | null | undefined,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean
}

export class RewardListPage extends Component<Props, State> {
  private _handleScroll: () => void;
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('RewardList initialized');
    this._handleScroll = this.handleScroll.bind(this);

    this.state = {
      editable: this.props.rewardState.canCreate(this.props.businessState.selectedBusiness),
      showRewardDialog: false,
      selectedReward: null,
      searchText: '', 
      errorCode: '',
      saveInProgress: false
    }
    this.props.loadRewards(this.props.businessId, '');
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render(){
    let component = null;
    if(this.props.rewardState.state === types.REWARD_INITIALIZED || this.props.rewardState.state === types.LOAD_REWARDS){
      component = <Spinner/>;
    } else if (this.props.rewardState.rewardList.length > 0 || this.state.searchText !== ''){
      component = this.renderRewardList();
    }else{
      component = this.renderCreateReward();
    }
    return component;
  }

  renderRewardList() {
    let self = this;

    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-6 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>Rewards for {this.props.businessState.selectedBusiness.name} </h4>
                  </div>
                  <div className="col-12 col-md-3 grid-margin wrapper kanban-toolbar"> 
                    
                  </div>
                  <div className="col-0 col-md-3 grid-margin d-flex justify-content-end"> 
                    <button type="button" className="d-none d-md-block btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowRewardDialog(null)}>Create</button>
                  </div>
                </div>

                <div className="d-md-none d-block fixed-bottom reward-fixed-create-button bg-primary rounded-circle d-flex justify-content-center align-items-center">
                  <i  className="ti-plus h4 font-weight-bolder text-white m-0 " style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowRewardDialog(null)}></i>
                </div>
                
                
                <div className="row">
                  <div className="col-12">
                      <div className="table-responsive">
                        <RewardList 
                          businessId={this.props.businessId}
                          rewardList={this.props.rewardState.rewardList}
                          canEdit={this.props.rewardState.canEdit(this.props.businessState.selectedBusiness)}
                        />
                      </div>
                    </div>
                </div>
          </div>
        </div>
        <RewardInputDialog 
          key={this.state.selectedReward?this.state.selectedReward.rewardId:null} 
          show={this.state.showRewardDialog} 
          reward={this.state.selectedReward} 
          onHide={() => this.onHideRewardDialog()} 
          onCancel={() => this.onHideRewardDialog()} 
          onSave={(reward: Reward) => this.onSave(reward)}
          errorCode={this.state.errorCode} 
          saveInProgress={this.state.saveInProgress}
        />
      </div>
    )
  }

  renderCreateReward() {
    let self = this;

    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                  <h4 className="mb-4"> Start creating rewards. </h4>
                  <div className="d-flex mt-4 mt-md-0">
                    <button type="button" className="btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowRewardDialog(null)}>Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RewardInputDialog 
              key={this.state.selectedReward?this.state.selectedReward.rewardId:null} 
              show={this.state.showRewardDialog} 
              reward={this.state.selectedReward} 
              onHide={() => this.onHideRewardDialog()} 
              onCancel={() => this.onHideRewardDialog()} 
              onSave={(reward: Reward) => this.onSave(reward)}
              errorCode={this.state.errorCode} 
              saveInProgress={this.state.saveInProgress}
        />
      </div>
    )
  }

  onShowRewardDialog(rewardId: string | null){
    let reward = null;
    if (rewardId){
      reward = this.props.rewardState.rewardList.find((item)=>{
        return (item.rewardId === rewardId);
      });
    }
    this.setState({showRewardDialog: true, selectedReward: reward, errorCode: '', saveInProgress: false});
  }

  onHideRewardDialog(){
    this.setState({showRewardDialog: false, selectedReward: null, errorCode: '', saveInProgress: false})
  }

  onSave(reward: Reward){
    let self = this;
    this.setState({saveInProgress: true})
    this.props.initiateSaveReward(this.props.businessId, reward, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
        this.onHideRewardDialog();
      }, 
      (reward: Reward, errorCode: ErrorCode)=>{
        //on error
        alert.error('Failed to update!');
        self.setState({showRewardDialog: true, selectedReward: reward, errorCode: errorCode, saveInProgress: false});
      })
  }

  handleScroll(){
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if ((scrollHeight - scrollTop) === clientHeight){
      console.log('RewardListPage, load more rewards');
      if(this.props.rewardState.rewardList.length > 0){
        this.props.loadMoreRewards(this.props.businessId, this.props.rewardState.rewardList[this.props.rewardState.rewardList.length-1].lastModified, this.state.searchText);
      }
    }
  }
}


const mapStateToProps = (state: { auth: AuthState; business: BusinessState; reward: RewardState; }) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    rewardState: state.reward
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; reward?: Reward; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadRewards: (businessId: string, searchText: string | undefined) =>{
      dispatch(loadRewards(businessId, searchText));
    },
    loadMoreRewards: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) =>{
      dispatch(loadMoreRewards(businessId, afterTimestamp, searchText));
    },
    initiateSaveReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
      dispatch(initiateSaveReward(businessId, reward, onSuccess, onError))
    },
    initiateDeleteReward: (businessId: string, reward: Reward, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteReward(businessId, reward, onSuccess, onError))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardListPage);

