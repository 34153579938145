
export enum DomainType {
    default = 'default',
    custome = 'custome'
}

export enum DomainStatus {
    active = 'active',
    inactive = 'inactive',
    processing = 'processing'
}

export type DomainData = {
    domainId?: string;
    type?: DomainType,
    name?: string;
    createdAt?: number;
    lastModifiedAt?: number;
    isEnabled?: boolean;
    status?: DomainStatus;
    statusReason?: string
}


export class Domain{
    domainId: string;
    type: DomainType;
    name: string;
    createdAt: number;
    lastModifiedAt: number;
    isEnabled: boolean;
    status: DomainStatus;
    statusReason: string
    

    constructor(domainPropertyData: DomainData){

        this.domainId = domainPropertyData.domainId || '';
        this.type = domainPropertyData.type || DomainType.custome;
        this.name = domainPropertyData.name || '';
        this.createdAt = domainPropertyData.createdAt || 0;
        this.lastModifiedAt = domainPropertyData.lastModifiedAt || 0;
        this.isEnabled = (domainPropertyData.isEnabled)? domainPropertyData.isEnabled: false;
        this.status = domainPropertyData.status || DomainStatus.processing ;
        this.statusReason = domainPropertyData.statusReason || ''
    }

    isDifferent(domainProperty: Domain){
        return true;
    }

    serialize(){
        return {
            name: this.name,
            type: this.type,
            createdAt: this.createdAt,
            lastModifiedAt: this.lastModifiedAt,
            isEnabled: this.isEnabled,
            status: this.status,
            statusReason: this.statusReason
        }
    }

    copy(domain: Domain){
        Object.assign(this, domain);
    }
}
