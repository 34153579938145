import type from './types';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import { Inquiry, InquiryData } from 'app/inquiries/inquiry';
import { Customer, CustomerData } from 'app/customers/customer';
import { isChars, isNumbers, isEmailAddress } from 'app/shared';
import _ from 'lodash';
import { RecordObject } from 'app/record';
import { Product } from 'app/product/product';

export type SearchResultData = CustomerData | InquiryData;
export type SearchResult = Customer | Inquiry | Product;




export class SearchRequest {
    businessId: string;
    object?: RecordObject;
    name?: string;
    phoneNumber?: string;
    emailAddress?: string;
    id?: string;

    constructor(businessId: string, searchText: string){
        this.businessId = businessId;
        
        if (searchText != ''){

            if (isChars(searchText)){
                this.name = _.toLower(searchText);
            }else if (isNumbers(searchText)){
                this.phoneNumber = searchText;
            }else if (isEmailAddress(searchText)){
                this.emailAddress = searchText;
            }else{
                this.name = _.toLower(searchText); // else try name
            }
          }  
    }
}

export class SearchResultState {
    state: string;
    searchResultList: SearchResult[]
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(){
        console.log('SearchResultState initialized');

        this.state = type.SEARCH_RESULT_INITIALIZED;
        this.searchResultList = [];

        this.canCreate = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }
    }
}
