import type from './types';
import {type as authType} from '../user';
import { Inquiry, InquiryState } from './inquiry';

const INITIAL_STATE = new InquiryState ();
   

export const inquiryReducer = (state = INITIAL_STATE, action: { type: string; inquiryList: Inquiry[]; inquiry: Inquiry; }) => {

    switch (action.type){


        case type.INQUIRIES_LOADED:
            console.log('inquiryReducer, '+action.type);
            return {...state, state: type.INQUIRIES_LOADED, inquiryList: action.inquiryList};

        case type.MORE_INQUIRIES_LOADED:
            console.log('inquiryReducer, '+action.type);
            Array.prototype.push.apply(state.inquiryList, action.inquiryList);
            return {...state, state: type.INQUIRIES_LOADED, inquiryList: state.inquiryList};

        case type.INQUIRY_LIST_EMPTY:
            console.log('inquiryReducer, '+action.type);
            return {...state, state: type.INQUIRY_LIST_EMPTY, inquiryList:[]};

        case type.FAILED_TO_LOAD_INQUIRIES:
            console.log('inquiryReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_INQUIRIES};

        case type.CREATE_INQUIRY:
            console.log('inquiryReducer, '+action.type);

            state.inquiryList.push(action.inquiry);
            return {...state, state: type.INQUIRIES_LOADED}

                
        case type.UPDATE_INQUIRY:
            console.log('inquiryReducer, '+action.type);

            state.inquiryList.find(inquiry => {
                if (inquiry.inquiryId === action.inquiry.inquiryId){
                    inquiry.copy(action.inquiry);
                }
             })

            return {...state, state: type.INQUIRIES_LOADED}

        case type.DELETE_INQUIRY:
            console.log('inquiryReducer, '+action.type);

            let list = state.inquiryList.filter(inquiry => {
                if (inquiry.inquiryId !== action.inquiry.inquiryId){
                    return true;
                }
            })

            return {...state, state: type.INQUIRIES_LOADED, inquiryList: list}
    
        case authType.SIGNED_OUT:
            console.log('inquiryReducer, '+action.type);
            return {...state, state: type.INQUIRY_INITIALIZED, inquiryList:[]};

        default:
            console.log('inquiryReducer, default - '+ action.type);
            return state;
    }
};
