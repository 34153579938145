import React, { Component } from 'react';
import { connect } from 'react-redux';
import BillingView from './billingView';



export class Billing extends Component {
  
  render() {
    return (
      <BillingView key={this.props.business.selectedBusiness.businessId} businessId = {this.props.business.selectedBusiness.businessId}/>
    );
  }
}



const mapStateToProps = (state) => {
  return { 
    auth: state.auth,
    business: state.business 
  };
};


export default connect(mapStateToProps, null)(Billing);