import { Component } from 'react';
import { connect } from 'react-redux';
import { alert } from '../shared';
import { Spinner } from '../shared/Spinner';
import CustomerInputDialog from './customerInputDialog';
import { loadCustomers, loadMoreCustomers, initiateSaveCustomer, initiateDeleteCustomer } from "./actions";
import types, { ErrorCode } from './types';
import './customers.css';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Customer, CustomerState } from './customer';
import { CustomerList } from './customerList';
import { PaymentState } from 'app/billing/payment';


export interface Props {
  authState: AuthState,
  businessState: BusinessState,
  customerState: CustomerState,
  paymentState: PaymentState,
  businessId: string,
  loadCustomers: (businessId: string, searchText: string | undefined) => void,
  loadMoreCustomers: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) => void,
  initiateSaveCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => void,
  initiateDeleteCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => void
}

export interface State {
  editable: boolean,
  searchText: string,
  showCustomerDialog: boolean,
  selectedCustomer: Customer | null | undefined,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean
}

export class CustomerListPage extends Component<Props, State> {
  private _handleScroll: () => void;
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('CustomerList initialized');
    this._handleScroll = this.handleScroll.bind(this);

    this.state = {
      editable: this.props.customerState.canCreate(this.props.businessState.selectedBusiness),
      showCustomerDialog: false,
      selectedCustomer: null,
      searchText: '', 
      errorCode: '',
      saveInProgress: false
    }
    this.props.loadCustomers(this.props.businessId, '');
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render(){
    let component = null;
    if(this.props.customerState.state === types.CUSTOMER_INITIALIZED || this.props.customerState.state === types.LOAD_CUSTOMERS){
      component = <Spinner/>;
    } else if (this.props.customerState.customerList.length > 0 || this.state.searchText !== ''){
      component = this.renderCustomerList();
    }else{
      component = this.renderCreateCustomer();
    }
    return component;
  }

  renderCustomerList() {
    let self = this;

    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-6 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>Customers for {this.props.businessState.selectedBusiness.name} </h4>
                  </div>
                  <div className="col-12 col-md-3 grid-margin wrapper kanban-toolbar"> 
                    
                  </div>
                  <div className="col-0 col-md-3 grid-margin d-flex justify-content-end"> 
                    <button type="button" className="d-none d-md-block btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowCustomerDialog(null)}>Create</button>
                  </div>
                </div>

                <div className="d-md-none d-block fixed-bottom customer-fixed-create-button bg-primary rounded-circle d-flex justify-content-center align-items-center">
                  <i  className="ti-plus h4 font-weight-bolder text-white m-0 " style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowCustomerDialog(null)}></i>
                </div>
                
                
                <div className="row">
                  <div className="col-12">
                      <div className="table-responsive">
                        <CustomerList 
                          businessId={this.props.businessId}
                          customerList={this.props.customerState.customerList}
                          canEdit={this.props.customerState.canEdit(this.props.businessState.selectedBusiness)}
                        />
                      </div>
                    </div>
                </div>
          </div>
        </div>
        <CustomerInputDialog 
          key={this.state.selectedCustomer?this.state.selectedCustomer.customerId:null} 
          show={this.state.showCustomerDialog} 
          customer={this.state.selectedCustomer} 
          onHide={() => this.onHideCustomerDialog()} 
          onCancel={() => this.onHideCustomerDialog()} 
          onSave={(customer: Customer) => this.onSave(customer)}
          errorCode={this.state.errorCode} 
          saveInProgress={this.state.saveInProgress}
        />
      </div>
    )
  }

  renderCreateCustomer() {
    let self = this;

    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                  <h4 className="mb-4"> Start creating customers. </h4>
                  <div className="d-flex mt-4 mt-md-0">
                    <button type="button" className="btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowCustomerDialog(null)}>Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerInputDialog 
              key={this.state.selectedCustomer?this.state.selectedCustomer.customerId:null} 
              show={this.state.showCustomerDialog} 
              customer={this.state.selectedCustomer} 
              onHide={() => this.onHideCustomerDialog()} 
              onCancel={() => this.onHideCustomerDialog()} 
              onSave={(customer: Customer) => this.onSave(customer)}
              errorCode={this.state.errorCode} 
              saveInProgress={this.state.saveInProgress}
        />
      </div>
    )
  }

  onShowCustomerDialog(customerId: string | null){
    let customer = null;
    if (customerId){
      customer = this.props.customerState.customerList.find((item)=>{
        return (item.customerId === customerId);
      });
    }
    this.setState({showCustomerDialog: true, selectedCustomer: customer, errorCode: '', saveInProgress: false});
  }

  onHideCustomerDialog(){
    this.setState({showCustomerDialog: false, selectedCustomer: null, errorCode: '', saveInProgress: false})
  }

  onSave(customer: Customer){
    let self = this;
    this.setState({saveInProgress: true})
    this.props.initiateSaveCustomer(this.props.businessId, customer, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
        this.onHideCustomerDialog();
      }, 
      (customer: Customer, errorCode: ErrorCode)=>{
        //on error
        alert.error('Failed to update!');
        self.setState({showCustomerDialog: true, selectedCustomer: customer, errorCode: errorCode, saveInProgress: false});
      })
  }

  handleScroll(){
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if ((scrollHeight - scrollTop) === clientHeight){
      console.log('CustomerListPage, load more customers');
      if(this.props.customerState.customerList.length > 0){
        this.props.loadMoreCustomers(this.props.businessId, this.props.customerState.customerList[this.props.customerState.customerList.length-1].customerSince, this.state.searchText);
      }
    }
  }
}


const mapStateToProps = (state: { auth: AuthState; business: BusinessState; customer: CustomerState; billing: PaymentState}) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    customerState: state.customer,
    paymentState: state.billing
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; customer?: Customer; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadCustomers: (businessId: string, searchText: string | undefined) =>{
      dispatch(loadCustomers(businessId, searchText));
    },
    loadMoreCustomers: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) =>{
      dispatch(loadMoreCustomers(businessId, afterTimestamp, searchText));
    },
    initiateSaveCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
      dispatch(initiateSaveCustomer(businessId, customer, onSuccess, onError))
    },
    initiateDeleteCustomer: (businessId: string, customer: Customer, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteCustomer(businessId, customer, onSuccess, onError))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListPage);

