import _ from 'lodash';
import { Component } from 'react'

export interface Props{
  plan: string;
  price: number;
  setAsCurrntPlan: boolean;
  tagLine: string
}

class PlanCard extends Component<Props> {

  constructor(props: Props){
    super(props);
    console.log('PricingCard initialized');
  }

  render() {
    return (
      <div className="col-md-6 col-xl-3 grid-margin stretch-card pricing-card">
        <div className="card  pricing-card-body">
          <div className="text-center pricing-card-head">
            <h3><strong>{_.upperFirst(this.props.plan)}</strong></h3>
            <h3 className="font-weight-normal">${this.props.price}/month</h3>
            <h6 className="font-weight-light mb-4">{this.props.tagLine}</h6>
          </div>
          <div className="text-center d-flex justify-content-center">
            <div className="d-flex">
              {this.props.children}
            </div>
          </div>
          <div className="wrapper">
            { (this.props.setAsCurrntPlan)
              ?(<a className="btn btn-outline-primary btn-block disabled" ><strong>Current Plan</strong></a>)
              : (<a href="!#" onClick={event => event.preventDefault()} className="btn btn-primary btn-block">Coming soon</a>)
            }
          </div>
        </div>
      </div>
    )
  }
}

export default PlanCard;
