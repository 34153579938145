import * as React from "react";

interface Props extends React.ComponentPropsWithoutRef<'img'> {
    src: string,
    fallbackSrc: string,
    fallbackInitial?: string,
    placeholderColor?: string,
    className?: string,
    loader?: ({src, width}:{src:string, width:string|number|undefined}) => string
}

interface State {
    src: string,
    errored: boolean,
    loaded: boolean
}

export default class Img extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            src: (props.loader)? (props.loader({src:props.src, width:props.width})):props.src,
            errored: false,
            loaded: false
        };
    }

    onError = () => {
        if (!this.state.errored) {
            console.log(`onError - failed to load picture`)
            this.setState({
                src: (this.props.loader)? (this.props.loader({src:this.props.fallbackSrc, width:this.props.width})):this.props.fallbackSrc,
                errored: true,
            });
        }
    }

    onLoad = () => {
        if(!this.state.loaded){
            this.setState({loaded: true});
        }
    }

    render() {
        let style = {
            backgroundColor: this.props?.placeholderColor || "white"
        };

        if(this.state.loaded){
            style.backgroundColor = "transparent";
        }

        if(this.state.errored && this.props.fallbackInitial){
            return (<div className="img-with-char d-flex justify-content-center align-items-center"><span>{this.props.fallbackInitial?.charAt(0).toUpperCase()}</span></div>);
        }else{
            return (
                <img
                    style={style}
                    onLoad={this.onLoad}
                    onError={this.onError}
                    {...this.props}
                    src={this.state.src}
                />
            );
        }
    }
}