import firebase, { firestore } from '../firebase.js';
import axios from 'axios';
import { PageLinks, PageLinksData } from './pageLinks';
import { AvailablePageProperty, AvailablePagePropertyData, PageProperty } from './pageProperty';
import { Domain, DomainData, DomainType } from './domain';
import { ErrorCode } from './types';
import { PageSettings, PageSettingsData } from './pageSettings';
import { getFileExtension } from 'app/shared';

// page
export async function getPageStorageDownloadUrl(businessId: string, pageId: any){
  console.log(`firebaseActions, getPageStorageUrl, businessId - ${businessId}, pageId - ${pageId}`);
  let pageStorageDownloadUrl = '';
  try{
    let storageRef = firebase.storage().ref();
    let pageStorageRef = storageRef.child(`pages/${businessId}/${pageId}.json`);
    pageStorageDownloadUrl = await pageStorageRef.getDownloadURL();
  }catch(e){
    console.log(JSON.stringify(e))
  }
  return pageStorageDownloadUrl;
}


export async function uploadPage(businessId: string, pageId: any, data: any){
  console.log(`firebaseActions, uploadPage, businessId - ${businessId}, pageId - ${pageId}`);

  try{
    if (data){
      console.log(`firebaseActions, uploadPage, ${JSON.stringify(data)} `);
      let storageRef = firebase.storage().ref();
      let pageStorageRef = storageRef.child(`pages/${businessId}/${pageId}.json`);
      let blob = new Blob([JSON.stringify(data)], {type: "application/json"})
      await pageStorageRef.put(blob);
    }
  }catch(e){
    console.log(JSON.stringify(e))
  }
}


export async function downloadPage(businessId: string, pageId: any){
  console.log(`firebaseActions, downloadPage, businessId - ${businessId}, pageId - ${pageId}`);

  let response: any;
  try{
    //get donwloadurl
    let downloadUrl = await getPageStorageDownloadUrl(businessId, pageId);
    console.log(downloadUrl);
    let headers = {
      'Content-Type': 'application/json',
    }
    
    response = await axios.get(downloadUrl, {headers});
      
  }catch(e){
    console.log(JSON.stringify(e))
  }

  if (response){
    return response.data;
  }else{
    return {};
  }
}

// page links

export async function retrievePageLinks(businessId: string){

  console.log('firebaseActions, retrievePageLinks, businessId - '+ businessId);

  let pageLinks: PageLinks = new PageLinks({});
  let pageLinksCollectionRef =  firestore.collection('businesses').doc(businessId).collection('pageLinks');
  let pageLinksRef = pageLinksCollectionRef.doc(businessId);
 
  let pageLinksDoc = await pageLinksRef.get();
  if (pageLinksDoc.exists){
    pageLinks = new PageLinks(pageLinksDoc.data() as PageLinksData);
  }

  return pageLinks;

}

export async function updatePageLinks(businessId: string, pageLinks: PageLinks){

  console.log('firebaseActions, updatePageLinks, businessId - '+ businessId);

  let pageLinksCollectionRef =  firestore.collection('businesses').doc(businessId).collection('pageLinks');
  let pageLinksRef = pageLinksCollectionRef.doc(businessId);
  let data = pageLinks.serialize();
  console.log(JSON.stringify(data));
  await pageLinksRef.set(data);

}

// page property

export async function retrieveAvailablePageProperty(businessId: string){

  console.log('firebaseActions, retrieveAvailablePageProperty, businessId - '+ businessId);

  let availablePageProperty: AvailablePageProperty = new AvailablePageProperty({availablePageProperty: []});
  let availablePagePropertyCollectionRef =  firestore.collection('businesses').doc(businessId).collection('availablePageProperty');
  let availablePagePropertyRef = availablePagePropertyCollectionRef.doc(businessId);

  let availablePagePropertyDoc = await availablePagePropertyRef.get();
  if (availablePagePropertyDoc.exists){

    let pagePropertyList:PageProperty[] = [];
    for (const [key, value] of Object.entries(availablePagePropertyDoc.data()?.availablePageProperty)){
      pagePropertyList.push(new PageProperty(value as PageProperty));
    }
    availablePageProperty = new AvailablePageProperty({availablePageProperty:pagePropertyList});
  }

  return availablePageProperty;

}

export async function createPageProperty(businessId: string, pageProperty: PageProperty){

  console.log('firebaseActions, createPageProperty, businessId - '+ businessId);

  let availablePagePropertyCollectionRef =  firestore.collection('businesses').doc(businessId).collection('availablePageProperty');
  let availablePagePropertyRef = availablePagePropertyCollectionRef.doc(businessId);

  let data = pageProperty.serialize();
  console.log(JSON.stringify(data));

  await availablePagePropertyRef.set({
    'availablePageProperty': {
      [pageProperty.pagePropertyId]: data
    }
 }, {merge: true});

}

export async function updatePageProperty(businessId: string, pageProperty: PageProperty){

  console.log('firebaseActions, updatePageProperty, businessId - '+ businessId);

  let availablePagePropertyCollectionRef =  firestore.collection('businesses').doc(businessId).collection('availablePageProperty');
  let availablePagePropertyRef = availablePagePropertyCollectionRef.doc(businessId);

  pageProperty.lastModifiedAt = new Date().getTime();
  let data = pageProperty.serializeForPageUpdates();
  console.log(JSON.stringify(data));

  await availablePagePropertyRef.set({
    'availablePageProperty': {
      [pageProperty.pagePropertyId]: data
    }
 }, {merge: true});

}

export async function updatePageSize(businessId: string, pageProperty: PageProperty){

  console.log('firebaseActions, updatePageProperty, businessId - '+ businessId);

  let availablePagePropertyCollectionRef =  firestore.collection('businesses').doc(businessId).collection('availablePageProperty');
  let availablePagePropertyRef = availablePagePropertyCollectionRef.doc(businessId);

  pageProperty.lastModifiedAt = new Date().getTime();
  let data = pageProperty.serializeForSizeUpdates();
  console.log(JSON.stringify(data));

  await availablePagePropertyRef.set({
    'availablePageProperty': {
      [pageProperty.pagePropertyId]: data
    }
 }, {merge: true});

}

export async function deletePageProperty(businessId: string, pageProperty: PageProperty){

  console.log('firebaseActions, deletePageProperty, businessId - '+ businessId);

  let availablePagePropertyCollectionRef =  firestore.collection('businesses').doc(businessId).collection('availablePageProperty');
  let availablePagePropertyRef = availablePagePropertyCollectionRef.doc(businessId);

  let data = pageProperty.serialize();
  console.log(JSON.stringify(data));

  await availablePagePropertyRef.update({
    [`availablePageProperty.${pageProperty.pagePropertyId}`]:firebase.firestore.FieldValue.delete() 
 });

}


// domain list

export async function fetchDomainList(businessId: string){

  console.log('firebaseActions, fetchDomainList, businessId - '+ businessId);

  let domainList: Domain[] = [];
  let domainCollectionRef =  firestore.collection('businesses').doc(businessId).collection('domains');;

  let domainCollection =  await domainCollectionRef.get(); 
  
  if (domainCollection.docs.length > 0){
           
      domainCollection.forEach((doc)=>{
        let domain = new Domain(doc.data() as DomainData);
        domain.domainId = doc.id;
        domainList.push(domain);
      });

  }else{
      console.log(`firebaseActions, fetchDomainList, domain list is empty for businessId - ${businessId}`);
  }

  return domainList;
}

export async function createDomain(businessId: string, domain: Domain){

  console.log('firebaseActions, createDomain, businessId - '+ businessId);

  //check whether the domain is already existing.
  let businessKeyCollectionRef: any = firestore.collection('businesskey');
  let businessKeyRef: any = businessKeyCollectionRef.doc(domain.name);
  let businessKeyDoc: any = await businessKeyRef.get();

  if (businessKeyDoc.exists){
      throw ErrorCode.DOMAIN_ALREADY_EXISTING;
  }

  let domainCollectionRef =  firestore.collection('businesses').doc(businessId).collection('domains');
  //const FieldValue = firestore.FieldValue;

  //domain.domainSince = FieldValue.serverTimestamp();
  let data = domain.serialize();
  console.log(JSON.stringify(data));
  let res = await domainCollectionRef.add(data);
  domain.domainId = res.id;

  // add business key
  await businessKeyCollectionRef.doc(domain.name).set({businessId: businessId, isActive: (domain.type === DomainType.default)? true: false});
  return domain;
}

export async function updateDomain(businessId: string, domain: Domain){

  console.log('firebaseActions, updateDomain, businessId - '+ businessId);

  let domainCollectionRef =  firestore.collection('businesses').doc(businessId).collection('domains');
  let domainRef = domainCollectionRef.doc(domain.domainId);
  let data = domain.serialize();
  console.log(JSON.stringify(data));
  await domainRef.update(data);
  return domain;
}

export async function deleteDomain(businessId: string, domain: Domain){

  console.log('firebaseActions, deleteDomain, businessId - '+ businessId);

  let domainCollectionRef =  firestore.collection('businesses').doc(businessId).collection('domains');
  let domainRef = domainCollectionRef.doc(domain.domainId);

  await domainRef.delete();

  let businessKeyCollectionRef: any = firestore.collection('businesskey');
  await businessKeyCollectionRef.doc(domain.name).delete();
  return domain;
}

export async function changeDomain(businessId: string, domain: Domain, oldDomain: Domain){
  console.log('firebaseActions, changeDomain, businessId - '+ businessId);

  //check whether the domain is already existing.
  let businessKeyCollectionRef: any = firestore.collection('businesskey');
  let businessKeyRef: any = businessKeyCollectionRef.doc(domain.name);
  let businessKeyDoc: any = await businessKeyRef.get();

  if (businessKeyDoc.exists){
      throw ErrorCode.DOMAIN_ALREADY_EXISTING;
  }

  let domainRef =  firestore.collection('businesses').doc(businessId).collection('domains').doc(domain.domainId);
  //const FieldValue = firestore.FieldValue;

  //domain.domainSince = FieldValue.serverTimestamp();
  let data = domain.serialize();
  console.log(JSON.stringify(data));
  await domainRef.update(data);

  // add new and delete old business key 
  await businessKeyCollectionRef.doc(domain.name).set({businessId: businessId, isActive: (domain.type === DomainType.default)? true: false});
  await businessKeyCollectionRef.doc(oldDomain.name).delete();
  return domain;
}

// page settings

export async function retrievePageSettings(businessId: string){

  console.log('firebaseActions, retrievePageSettings, businessId - '+ businessId);

  let pageSettings: PageSettings = new PageSettings({});
  let pageSettingsCollectionRef =  firestore.collection('businesses').doc(businessId).collection('pageSettings');
  let pageSettingsRef = pageSettingsCollectionRef.doc(businessId);
 
  let pageSettingsDoc = await pageSettingsRef.get();
  if (pageSettingsDoc.exists){
    pageSettings = new PageSettings(pageSettingsDoc.data() as PageSettingsData);
  }

  return pageSettings;

}
export async function updatePageSettings(businessId: string, pageSettings: PageSettings){

  console.log('firebaseActions, updatePageSettings, businessId - '+ businessId);

  let pageSettingsCollectionRef =  firestore.collection('businesses').doc(businessId).collection('pageSettings');
  let pageSettingsRef = pageSettingsCollectionRef.doc(businessId);
  let data = pageSettings.serialize();
  console.log(JSON.stringify(data));
  await pageSettingsRef.set(data, {merge: true});
  return pageSettings;
}

export async function uploadAsset(businessId: string, pictureFile: File){
  console.log(`firebaseActions, uploadAsset, businessId - ${businessId}`);
  let pictureUrl: string = '';

  try{
    if (pictureFile){
      

      let timestamp = new Date().getTime();
      let fileExt = getFileExtension(pictureFile.name);
      
      if(fileExt === ""){
        throw `invalid file name - ${pictureFile.name}`;
      }

      let fileName = `${timestamp}.${fileExt}`
      let storageRef = firebase.storage().ref();
      let pictureRef = storageRef.child(`assets/${businessId}/${fileName}`);
      await pictureRef.put(pictureFile);
      pictureUrl = await pictureRef.getDownloadURL();
    }
  }catch(e){
    console.log(JSON.stringify(e))
  }
  return pictureUrl;
}

export async function deleteAsset(pictureUrl: string){
  console.log('firebaseActions, deleteAsset ');

  try{
    if (pictureUrl !== ''){
      let storage = firebase.storage();
      var httpsReference = storage.refFromURL(pictureUrl);
      await httpsReference.delete();
    }
  }catch(error){
    console.log(`firebaseActions, deleteAsset , failed to delete-${pictureUrl}`);
  }
}