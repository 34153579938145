import _ from 'lodash';
import { Component } from 'react';
import { Col, Row  } from 'react-bootstrap';
import { displayTime } from '../shared';

import { BusinessHours, Hour } from './businessHours';
import BusinessHoursInputDialog from './businessHoursInputDialog';


export interface Props {
  businessHours: BusinessHours,
  editable: boolean,
  onChange: (businessHours: BusinessHours) => void
}

export interface State {
  editBusinessHours: boolean;
  days: any;
}

export class ShowBusinessHours extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('ShowBusinessHours initialized');

    this.state = {
      editBusinessHours: false,
      days:{
        mon: {
          openTime: 0,
          closeTime: 0,
          isClosed: true
        },
        tue: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        wed: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        thu: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        fri: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        sat: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        },
        sun: {
            openTime: 0,
            closeTime: 0,
            isClosed: true
        }
      }
    }

    this.props.businessHours.hours.forEach((hour: Hour) => {
      hour.days.map(day => {
        this.state.days[day].openTime = hour.openTime;
        this.state.days[day].closeTime = hour.closeTime;
        this.state.days[day].isClosed = hour.isClosed; 
      })
    })

  }

  componentDidUpdate(previoursProps: Props){
    if(!_.isEqual(previoursProps.businessHours.hours, this.props.businessHours.hours)){
      let days = this.state.days;

      // need to reset before assigning new values
      for (const key in days){
        days[key].openTime = 0;
        days[key].closeTime = 0;
        days[key].isClosed = true
      }

      this.props.businessHours.hours.forEach((hour: Hour) => {
        hour.days.map(day => {
          days[day].openTime = hour.openTime;
          days[day].closeTime = hour.closeTime;
          days[day].isClosed = hour.isClosed; 
        })
      })
      this.setState({ days: days})
    }
  }

  render(){

  return (
    
        this.renderShowBusinessHours()
           
   );
  }

  renderShowBusinessHours() {
    let businessHours = this.props.businessHours;
    
    return (

          <div>
            <div className="py-4">
              <div>
                
                <h4> Business Hours <i style={{ display: (this.props.editable ? 'block-inline' : 'none') }} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onEditClick()}}></i> </h4>
                       
              </div>
              {
                (businessHours.isOpen24Hours) ? (this.renderAsOpen24Hours())
                : (businessHours.isClosed ? this.renderAsClosed() : this.renderDays())
                
              }
            </div>   
            <BusinessHoursInputDialog 
                show={this.state.editBusinessHours} 
                businessHours={businessHours} 
                onHide={() => {this.setState({editBusinessHours: false})}} 
                onCancel={() => {this.setState({editBusinessHours: false})}} 
                onSave={(updatedBusinessHours) => this.onChange(updatedBusinessHours)}              
            />
          </div>  
    )
  }

  renderAsOpen24Hours(){
    return(
        <Row className="py-1">
              <Col xs={12}>
                <span className="float-left text-muted">
                  Open 24 Hours
                </span>
              </Col>
        </Row>
    );
  }

  renderAsClosed(){
    return (
      <Row className="py-1">
          <Col xs={12}>
            <span className="float-left text-muted">
              Closed
            </span>
          </Col>
      </Row>
    );
  }

  renderDays(){
    return (
      <div>
        {
          Object.keys(this.state.days).map(day => {
            return (
              <Row className="py-1">
                <Col xs={3} xl={3}>
                  <span className="float-left">
                    {_.upperFirst(day)}
                  </span>
                </Col>
                <Col xs={9} xl={9}>
                  <span className=" text-muted">
                    {(this.state.days[day].isClosed)?'Closed': `Opens ${displayTime(this.state.days[day].openTime)} Closes ${displayTime(this.state.days[day].closeTime)}`}
                  </span>
                </Col>
              </Row>
            );
          })
        }
      </div>   

    );
  }

  onEditClick(){
    console.log('ShowBusinessHours, onEditClick');
    this.setState({editBusinessHours: true});
  }
 
    
  onChange(businessHours: BusinessHours){
    console.log('ShowBusinessHours, onChange');
    this.setState({editBusinessHours: false});
    console.log(`old - ${JSON.stringify(this.props.businessHours)}`);
    console.log(`new - ${JSON.stringify(businessHours)}`)
    this.props.onChange(businessHours);
  }
}
