import { PaymentState } from 'app/billing/payment';
import { Business, BusinessState, initiateDeleteBusiness } from 'app/business';
import { DeleteDialog } from 'app/shared';
import { Component } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { alert } from '../shared';
import { loadCurrentPlans } from './actions';
import FeatureList from './featureList';
import PlanList from './planList';
import { PlanState } from './plans';

export interface Props extends RouteComponentProps{
  
  businessState: BusinessState,
  planState: PlanState,
  billing: PaymentState,
  loadCurrentPlans: () => void,
  initiateDeleteBusiness: (business: Business, reasonToDelete: string, callToDiscuss: boolean, onSuccess: any, onError: any) => void
}


export interface State {
  showDeleteDialog: boolean;
}

class Pricing extends Component<Props, State> {

  constructor(props: Props){
    super(props);
    console.log('Pricing initialized');

    this.state = {
      showDeleteDialog: false,
    }

    this.props.loadCurrentPlans();
  }

  render() {

    let canAdminister = this.props.businessState.canAdminister();

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div>
              <div>
                <div className="container text-center pb-5">
                <h4 className="text-center">Choose a plan that suits you the best.</h4>
                  <p className="text-center mb-3">If you are not fully satisfied, you can cancel at anytime!!</p>
                    <PlanList planState={this.props.planState} currentEstimation={this.props.billing.payment.currentEstimation}/>
                    <FeatureList planState={this.props.planState} />                     
                    <div className="grid-margin stretch-card">
                      {(canAdminister)?<span className="text-danger " style={{cursor: 'pointer'}} onClick={() => {this.setState({showDeleteDialog: true})}}>Delete Business</span>:null}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteDialog id={this.props.businessState.selectedBusiness.businessId} title={'Sure you want to delete the business ?'} confirmationText={this.props.businessState.selectedBusiness.name} show={this.state.showDeleteDialog} onHide={() => this.setState({showDeleteDialog: false})} onCancel={() => this.setState({showDeleteDialog: false})} onDelete={() => this.onDelete()}/>
      </div>
    )
  }


  onDelete(){
    console.log(`Pricing, onDelete`)
    let self = this;
    this.setState({showDeleteDialog: false});
    this.props.initiateDeleteBusiness(this.props.businessState.selectedBusiness, '', false, 
      ()=>{
        //on success
        alert.info('Successfully deleted!');
        self.props.history.push('/')
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
    })
  }

}


const mapStateToProps = (state: {business: BusinessState; plan: PlanState, billing: PaymentState}) => {
  return { 
    businessState: state.business,
    planState: state.plan,
    billing: state.billing
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
  return {
    loadCurrentPlans: () => {
      dispatch(loadCurrentPlans());
    },
    initiateDeleteBusiness: (business: Business, reasonToDelete: string, callToDiscuss: boolean, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteBusiness(business, reasonToDelete, callToDiscuss, onSuccess, onError))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pricing));
