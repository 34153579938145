import type from './types';
import {type as authType} from '../user';
import { Customer, CustomerState } from './customer';

const INITIAL_STATE = new CustomerState ();
   

export const customerReducer = (state = INITIAL_STATE, action: { type: string; customerList: Customer[]; customer: Customer; }) => {

    switch (action.type){

        case type.CUSTOMERS_LOADED:
            console.log('customerReducer, '+action.type);
            return {...state, state: type.CUSTOMERS_LOADED, customerList: action.customerList};

        case type.MORE_CUSTOMERS_LOADED:
            console.log('customerReducer, '+action.type);
            Array.prototype.push.apply(state.customerList, action.customerList);
            return {...state, state: type.CUSTOMERS_LOADED, customerList: state.customerList};

        case type.CUSTOMER_LIST_EMPTY:
            console.log('customerReducer, '+action.type);
            return {...state, state: type.CUSTOMER_LIST_EMPTY, customerList:[]};

        case type.FAILED_TO_LOAD_CUSTOMERS:
            console.log('customerReducer, '+action.type);
            return {...state, state: type.FAILED_TO_LOAD_CUSTOMERS};

        case type.CUSTOMER_CREATED:
            console.log('customerReducer, '+action.type);
            state.customerList.unshift(action.customer); 
            return {...state, state: type.CUSTOMERS_LOADED, customerList: state.customerList}

                
        case type.CUSTOMER_UPDATED:
            console.log('customerReducer, '+action.type);

            state.customerList.find(customer => {
                if (customer.customerId === action.customer.customerId){
                    customer.copy(action.customer);
                }
             })

            return {...state, state: type.CUSTOMERS_LOADED}

        case type.CUSTOMER_DELETED:
            console.log('customerReducer, '+action.type);

            let list = state.customerList.filter(customer => {
                if (customer.customerId !== action.customer.customerId){
                    return true;
                }
            })

            return {...state, state: type.CUSTOMERS_LOADED, customerList: list}
    
        case authType.SIGNED_OUT:
            console.log('customerReducer, '+action.type);
            return {...state, state: type.CUSTOMER_INITIALIZED, customerList:[]};

        default:
            console.log('customerReducer, default - '+ action.type);
            return state;
    }
};
