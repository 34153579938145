import { channel } from 'redux-saga'
import { put, takeEvery, call, take, ChannelTakeEffect, cancelled } from 'redux-saga/effects';
import { subscribeForCurrentEstimation, unSubscribeForCurrentEstimation } from './firebaseActions';

import { CurrentEstimation, PastPayments } from './payment';

import type from './types';

const customChannel = channel();

//Action creators

export const loadCurrentEstimation = (businessId: string) => {
    console.log('loadCurrentEstimation, action, businessId - '+ businessId);
    return { type: type.LOAD_CURRENT_ESTIMATION, businessId }
};

export const loadPastPayments = (businessId: string) => {
    console.log('loadPastPayments, action, businessId - '+ businessId);
    return { type: type.LOAD_PAST_PAYMENTS, businessId }
};


export const retrievedCurrentEstimation = (currentEstimation: CurrentEstimation) => {
    return { type: type.CURRENT_ESTIMATION_LOADED, currentEstimation }
};

const retrievedPastPayments = (pastPayments: PastPayments) => {
    return { type: type.PAST_PAYMENTS_LOADED, pastPayments }
};

// Sagas

function* loadCurrentEstimationAsync(action: { businessId: string; }) {
    console.log('loadCurrentEstimationAsync, action - '+ JSON.stringify(action));
    try {
  
        const currentEstimation: CurrentEstimation = yield call(CurrentEstimation.fetch, action.businessId);
        console.log(JSON.stringify(currentEstimation));
        yield put(retrievedCurrentEstimation(currentEstimation));
        
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

function* loadPastPaymentsAsync(action: { businessId: string; }) {
    console.log('loadPastPaymentsAsync, action - '+ JSON.stringify(action));
    try {
  
        const pastPayments: PastPayments = yield call(PastPayments.fetch, action.businessId);
        console.log(JSON.stringify(pastPayments));
        yield put(retrievedPastPayments(pastPayments));
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}


export function* subscribeForBillingChanges(businessId: string){
    console.log('business, action, subscribeForChanges');

    subscribeForCurrentEstimation(businessId, 
        (currentEstimation: CurrentEstimation)=>{
            customChannel.put(retrievedCurrentEstimation(currentEstimation));
        }, 
        (err)=>{
            console.log('user, action, userSignedInAsync, on callback failed - '+ JSON.stringify(err));
        });
    
    try{
        while (true) {
            const action:ChannelTakeEffect<any> = yield take(customChannel)
            yield put(action)
        }
    } finally {
        
        unSubscribeForCurrentEstimation(businessId);
    }
}


export const billingSagas = [
    takeEvery<any>(type.LOAD_CURRENT_ESTIMATION, loadCurrentEstimationAsync),
    takeEvery<any>(type.LOAD_PAST_PAYMENTS, loadPastPaymentsAsync),
];