import _ from 'lodash';
import React from 'react';
import { Component } from 'react';
import { Form, Modal, Button, Col, Row  } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { BusinessHours, SpecialHour } from './businessHours';
import { ErrorCode } from './types';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import { Icon } from 'app/shared';
import { mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';

export interface Props {
 show: boolean,
 specialHours: SpecialHour[],
 onHide: () => void,
 onCancel: () => void, 
 onSave: (specialHours: SpecialHour[]) => void,
 errorCode?: ErrorCode | '',
 saveInProgress?: boolean
}

export interface State {
  validated: boolean;
  specialHours: any;
}

export class SpecialHoursInputDialog extends Component<Props, State> {
  specialHours: SpecialHour[]
  title: string;
  dayHourRef: React.RefObject<SpecialHourItem>[] = [];
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('SpecialHoursInputDialog initialized');
    this.specialHours = (this.props.specialHours)? new BusinessHours({specialHours: this.props.specialHours}).specialHours : new BusinessHours({}).specialHours;

    this.title =  'Edit special hours';
  
    this.state = {
      validated: false,
      specialHours: this.specialHours
    }
  }

  componentDidUpdate(previoursProps: Props){
    if (!_.isEqual(previoursProps.specialHours, this.props.specialHours)){
    
      this.setState({
        specialHours: new BusinessHours({specialHours: this.props.specialHours}).specialHours,
      })

    }
  }

  render() {

    this.dayHourRef = [];
     let {errorCode, saveInProgress } = this.props;

    let errorMessage = '';

    if (errorCode === ErrorCode.FAILED_TO_CREATE){
      errorMessage = `Failed to update specialHours, please try after sometime.`;
    }
    
    return (
  
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
       
        >
          <LoadingOverlay
            active={saveInProgress}
            spinner
            text='Loading ...'
            >
            <Modal.Header closeButton>
              {this.title}
            </Modal.Header>

            <Modal.Body>
              <Form className="form-sample" id="specialHoursInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                {
                  this.state.specialHours?.map((specialHour: SpecialHour, index: number) => {
                    
                    let ref: React.RefObject<SpecialHourItem> = React.createRef();
                    this.dayHourRef.push(ref);

                    return (
                      <SpecialHourItem 
                        ref={ref}
                        index={index}
                        date={specialHour.date}
                        openTime={specialHour.openTime}
                        closeTime={specialHour.closeTime}
                        isClosed={specialHour.isClosed}
                        disabled={false }
                        onDelete={(index: number) => this.onDeleteHour(index)}
                      />
                    );
                  })
                }    
                <div className="col-md-12 d-flex justify-content-center" onClick={() => this.onAddHour()}>
                  <Icon className="pr-3" path={mdiPlusCircleOutline} />
                </div>
                <div className="col-md-12">
                  <div>{errorMessage}</div>
                </div>
              </Form>

            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
              <button type="submit" form="specialHoursInfo"  className="btn btn-primary btn-sm">Save</button>
            </Modal.Footer>
          </LoadingOverlay>
      </Modal>
    );
  }
  

  onAddHour(){
    let specialHours = this.state.specialHours
    specialHours.push(new SpecialHour({}));
    this.setState({
      specialHours: specialHours
    })
  }

  onDeleteHour(index: number){
    
    let specialHours = this.state.specialHours
    specialHours?.splice(index, 1);
    this.setState({
      specialHours: specialHours
    })
    
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('SpecialHoursInputDialog, onSubmit');

    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let specialHours =  this.getFormData();
      console.log(`onSubmit, new - ${JSON.stringify(specialHours)}`);

      this.setState({validated: false});
      this.props.onSave(specialHours)
    }
  }

  getFormData(){

 
    let specialHours: SpecialHour[] = [];

    this.dayHourRef.map(ref => {
      let specialHour = ref.current?.getFormData();
      if (specialHour){
        specialHours.push(specialHour)
      }
    })

    return new BusinessHours({specialHours: specialHours}).specialHours; 
  }

}


export default SpecialHoursInputDialog;


export interface specialHourItemProps {
  index: number,
  date: number, 
  openTime: number | undefined,
  closeTime: number | undefined,
  isClosed: boolean | undefined,
  disabled: boolean,
  onDelete: (index: number) => void
 }
 
 export interface specialHourItemState {
   validated: boolean,
   specialHour: any
 }
 
 export class SpecialHourItem extends Component<specialHourItemProps, specialHourItemState> {

   constructor(props: Readonly<specialHourItemProps>){
     super(props);
     console.log('SpecialHourItem initialized');

     this.state = {
       validated: false,
       specialHour: {
        date:{
          placeholder: 'Date',
          value: this.props.date,
          type: 'number',
          required: false,
          readOnly: false
        },
         openTime:{
           placeholder: 'Open Time',
           value: this.props.openTime,
           type: 'text',
           required: false,
           readOnly: false
         },
         closeTime:{
           placeholder: 'Close Time',
           value: this.props.closeTime,
           type: 'text',
           required: false,
           readOnly: false
         },
         isClosed:{
           placeholder: 'Closed',
           value: this.props.isClosed,
           type: 'checkbox',
           required: false,
           readOnly: false
         },  
       }
     }

   }
 
   render() {
     let specialHour = this.state.specialHour;
     let self = this;
    
     let border = (this.props.index>0)? 'border-top': '';
     return ( 
          <div className={`py-2  d-flex align-items-center justify-content-between ${border}`}>  
            <Form className="col-11 form-sample" noValidate validated={this.state.validated}>
              <fieldset disabled={this.props.disabled}>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="d-inline-flex flex-wrap  flex-row align-items-baseline justify-content-center">
                      <div className="pr-2 d-inline-flex align-items-baseline">
                        <h5 className="text-muted pr-2">Date</h5>
                        <DatePicker className="border border-0" name='openTime' clearIcon={null} onChange={(date: any) => self.onDateChange(date)} value={new Date(specialHour.date.value)} />  
                      </div>
                      <div className="pr-2">
                        <Form.Group>
                          <Form.Check type={specialHour.isClosed.type} name='isClosed'  required={specialHour.isClosed.required} label={specialHour.isClosed.placeholder} onChange={e => self.onChange(e)} checked = {specialHour.isClosed.value} readOnly = {specialHour.isClosed.readOnly}/>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="d-inline-flex flex-wrap  flex-row align-items-baseline justify-content-center">
                      <div className="pr-2 d-inline-flex align-items-baseline">
                        <h5 className="text-muted pr-2">Opens</h5>
                        <TimePicker className="border border-0" disabled={this.state.specialHour.isClosed.value} name='openTime' disableClock={true} clearIcon={null} onChange={openTime => self.onOpenTimeChange(openTime)} value={new Date(specialHour.openTime.value)} />
                      </div>
                      <div className="pr-2 d-inline-flex align-items-baseline">
                        <h5 className="text-muted pr-2">Closes</h5>
                        <TimePicker className="border border-0" disabled={this.state.specialHour.isClosed.value} name='closeTime' disableClock={true} clearIcon={null} onChange={closeTime => self.onCloseTimeChange(closeTime)} value={new Date(specialHour.closeTime.value)} />
                      </div>
                    </div>
                  </div>
              </fieldset>
            </Form>
            <div className="col-1">
              <div className="d-inline-flex justify-content-end" onClick={() => this.onDeleteHour()}>
                <Icon className="pr-3" path={mdiTrashCanOutline} />
              </div>
            </div>
          </div>
     );
   }
 
   onDateChange(date: any){
    let specialHour = this.state.specialHour;
    specialHour.date.value = new Date(date).getTime();
    this.setState({specialHour:specialHour});
   }

   onOpenTimeChange(openTime: any){
    let specialHour = this.state.specialHour;
    specialHour.openTime.value = new Date(`2022-01-01T${openTime}:00`).getTime();
    this.setState({specialHour:specialHour});
   }

   onCloseTimeChange(closeTime: any){
    let specialHour = this.state.specialHour;
    specialHour.closeTime.value = new Date(`2022-01-01T${closeTime}:00`).getTime();
    this.setState({specialHour:specialHour});
   }

   onChange(e: any){
    console.log('SpecialHour, onChange');
    console.log(e);
    let specialHour = this.state.specialHour;
    let key = e.target.name;

    if (e.target.type === 'checkbox'){
     specialHour[key].value = e.target.checked;
    }else{
     specialHour[key].value = e.target.value;
    }
    
    this.setState({specialHour:specialHour});
  }
 
  onDeleteHour(){
    this.props.onDelete(this.props.index)
  }
 
   getFormData(){

     let specialHourObj = Object.keys(this.state.specialHour).reduce((acc: any, key: string) =>{
       if (key !== 'days'){
        acc[key] = this.state.specialHour[key].value;
       }
       return acc;
     }, {});

 
     return new SpecialHour(specialHourObj);
   }
 
 }
