import type from './types';
import { createCustomer, updateCustomer, deleteCustomer } from './firebaseActions';
import { format } from 'date-fns'
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import { RecordObject } from 'app/record';


export type CustomerData ={
    object?: RecordObject
    customerId?: string;
    name: string;
    countryCode?: string;
    phoneNumber: string;
    emailAddress: string;
    customerSince?: number;
    lastActivity?: string; 
}

export class Customer {
    object: RecordObject
    customerId: string;
    name: string;
    countryCode: string;
    phoneNumber: string;
    emailAddress: string;
    customerSince: number;
    lastActivity: string; 

    constructor(customerDate: CustomerData){
        console.log('Business initialized');

        this.object = RecordObject.customer;
        this.customerId = customerDate.customerId || '';
        this.name = customerDate.name || '';

        this.countryCode = customerDate.countryCode || '';
        this.phoneNumber = customerDate.phoneNumber || '';
        this.emailAddress = customerDate.emailAddress || '';
        this.customerSince = customerDate.customerSince || new Date().getTime();
        this.lastActivity = customerDate.lastActivity || 'None'

    }

    isDifferent(customer: Customer){
        let isDifferent = false;
        if (this.name !== customer.name || 
            this.countryCode !== customer.countryCode ||
            this.phoneNumber !== customer.phoneNumber || 
            this.emailAddress !== customer.emailAddress){
            isDifferent = true;
        }

        return isDifferent;
    }

    serialize(){
        return {
            name: this.name,
            countryCode: this.countryCode,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            customerSince: this.customerSince,
            lastActivity: this.lastActivity
        }
    }

    copy(customer: Customer){
        Object.assign(this, customer);
    }

    displayCustomerSince(){
        let date = new Date(this.customerSince);
        let sameYear = (date.getFullYear() == new Date().getFullYear());
        let sameday = (date.getDate() == new Date().getDate());
        let displayString = '';

        if (!sameYear){
            displayString = format(date, 'MM/dd/yy'); // MM/DD/YY
        }else if (!sameday){ 
            displayString = format(date, 'MMM d'); // Jan 20
        } else{
            displayString = format(date, "hh:mm aaaaa'm'") // 2:30 pm
        }
        return displayString; 
    }

    async create (businessId: string){
        await createCustomer(businessId, this);
    }

    async update(businessId: string){
        await updateCustomer(businessId, this);
    }

    async delete(businessId: string){
        await deleteCustomer(businessId, this);
    }
}


export class CustomerState {
    state: string;
    customerList: Customer[];
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(){
        console.log('CustomerState initialized');

        this.state = type.CUSTOMER_INITIALIZED;
        this.customerList = [];

        this.canCreate = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }
    }
}
