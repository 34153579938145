import { DeleteDialog, IconButton, Img } from 'app/shared';
import { Component } from 'react';
import { connect } from 'react-redux';
import { initiateSaveProduct, initiateDeleteProduct } from './actions';
import { Product } from './product';
import { alert } from '../shared';
import './product.css';
import { ErrorCode } from './types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';

export interface ProductListItemProp extends RouteComponentProps {
  businessId: string,
  product: Product,
  canEdit: boolean,
  onDelete?: (productId: string) => void,
}

export interface Props extends ProductListItemProp{
  initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void,
  initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => void
}

export interface State {
  product: Product,
  showProductDialog: boolean,
  showDeleteDialog: boolean,
  errorCode:  ErrorCode | '',
  saveInProgress: boolean
}

class _ProductListItem extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('ProductListItem initialized');

    this.state = {
      product: this.props.product,
      showProductDialog: false,
      showDeleteDialog: false,
      errorCode:   '',
      saveInProgress: false
    }
  }

  render() {

    let {productId, name, description, picture, isPublished, price, comparePrice, showVariants} = this.props.product;
      console.log(`picture url - ${picture}, price - ${price}`);
      return (
        <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-6 col-12 product-item stretch-card">
          <div className="card" style={isPublished?{}:{backgroundColor: '#80808047'}}>
            <div className="mx-2 my-2 d-flex align-items-center justify-content-end">
              <div className="d-flex justify-content-end">
                <i id={productId} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onEditProduct(productId)}}></i> 
                <i className="ti-trash menu-icon " onClick={(e)=> { e.stopPropagation(); this.onShowDeleteDialog()}}></i> 
              </div>
            </div>
            <div className="card-body">
              <div className="action-holder">
                <div className="d-none sale-badge bg-success">New</div>
              </div>
              <div className="product-img-outer">
                <Img className="product_image" key={Date.now()} src={picture} fallbackSrc='https://via.placeholder.com/92x92' alt="product"/>
              </div>
              <p className="product-title">{name}</p>
              <p className="product-price">{price}</p>
              <p className="product-actual-price">{comparePrice>price?comparePrice:''}</p>
              { showVariants
              ?(
                <ul className="product-variation">
                  <li><a href="!#" onClick={event => event.preventDefault()}>M</a></li>
                  <li><a href="!#" onClick={event => event.preventDefault()}>L</a></li>
                  <li><a href="!#" onClick={event => event.preventDefault()}>XL</a></li>
                </ul>
              ):null
              }
              <p className="product-description">{description}</p>
            </div>
          </div>
          <DeleteDialog 
              id={productId} 
              confirmationText={name} 
              show={this.state.showDeleteDialog} 
              onHide={() => this.setState({showDeleteDialog: false})} 
              onCancel={() => this.setState({showDeleteDialog: false})} 
              onDelete={(productId: string) => this.onDelete(productId)}/>
        </div>
      );
  }

  onEditProduct(productId: string){
    this.props.history.push(`/products/${productId}`);
  }

  onShowDeleteDialog(){
    this.setState({showDeleteDialog: true });
  }

  onDelete(productId: string){

    this.setState({showDeleteDialog: false });

    this.props.initiateDeleteProduct(this.props.businessId, this.state.product, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })

    if(this.props.onDelete){
      this.props.onDelete(productId)
    }
  }
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; product?: Product; onSuccess?: any; onError?: any; }) => void) => {
  return {
    initiateSaveProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateSaveProduct(businessId, product, onSuccess, onError))
    },
    initiateDeleteProduct: (businessId: string, product: Product, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteProduct(businessId, product, onSuccess, onError))
    }
  };
};

let X =  connect(null, mapDispatchToProps)(_ProductListItem);


class ProductListItem extends Component<ProductListItemProp> {
  render() {
    return <X {...this.props} />
  };
}

export default withRouter(ProductListItem);