import firebase from 'firebase'
import { UserSession} from './user';

var firebaseConfig = {
  apiKey: "AIzaSyBUiYabfP4OH1jo8IzjTLMLeLUhEvPAJ50",
  authDomain: "receipts-76467.firebaseapp.com",
  databaseURL: "https://receipts-76467.firebaseio.com",
  projectId: "receipts-76467",
  storageBucket: "receipts-76467.appspot.com",
  messagingSenderId: "377730056925",
  appId: "1:377730056925:web:c1f910c76f4ccd34419134",
  measurementId: "G-N4N445YQX6"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const firestore = firebase.firestore();
export const firebaseAuth = firebase.auth();

export default firebase;

export const onAuthStateChange = (onSignIn, onSignOut) => {
  console.log('firebase, onAuthStateChange');
  firebase.auth().onAuthStateChanged(userRecord => { 
      if (userRecord){
        //user signed in
        console.log(JSON.stringify(userRecord))
        onSignIn(new UserSession(userRecord));
      }else{
        // user signed out
        onSignOut();
      }
  });
}