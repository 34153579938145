import { Business, BusinessHours } from 'app/business';
import { BusinessHeader } from '../../business';
import { GJSTagNames } from '../grapesjsEditor';

export default (editor: any) => {
  console.log('Grapesjs, adding type - '+ GJSTagNames.SectionHeader) ;

  editor.Components.addType(GJSTagNames.SectionHeader, {
    extend: 'react-component',
    model: {
      defaults: {
        component: BusinessHeader,
        stylable: ['background-color', 'color', 'background'],
        resizable: false,
        editable: false,
        draggable: false,
        droppable: false,
        removable: false,
        attributes: {
          showHours: true,
          showAddress: true,
          showProfilePicture:true
        },
        toolbar: [],
        traits: [
          { 
            type: 'checkbox', 
            label: 'Hours', 
            name: 'showHours', 
          },
          { 
            type: 'checkbox', 
            label: 'Address', 
            name: 'showAddress', 
          },
          { 
            type: 'checkbox', 
            label: 'Profile Picture', 
            name: 'showProfilePicture', 
          },
        ]
      },
      
      init(){
        console.log(`GrapesJS, ${GJSTagNames.SectionHeader} model initialized`);
        // @ts-ignore
        //this.on('style:property:update', this.handleStyleChange);
        
        // @ts-ignore
        //this.listenTo(this, 'change:attributes:showHours', this.handleAttributeChange);
      },

      handleStyleChange() {
        console.log('Model Style updated ');
      },
      handleAttributeChange(changes: any) {
        // @ts-ignore
        let showHours: boolean = this.getAttributes().showHours;
        console.log(`Model Attributes updated, showHours - ${showHours}`);
        
      },      
    },
    isComponent: (el: { tagName: string; }) => {
        
        return el.tagName === GJSTagNames.SectionHeader.toUpperCase();
    }
  });
};
