import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../shared/Spinner';
import { loadSearchResults, loadMoreSearchResults } from "./actions";
import types from './types';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { SearchResultState } from './searchResult';
import { Customer, CustomerState } from 'app/customers/customer';
import { CustomerListItem } from 'app/customers/customerListItem';
import { InquiryListItem } from 'app/inquiries/inquiryListItem';
import { Inquiry, InquiryState } from 'app/inquiries/inquiry';
import { RecordObject } from 'app/record';
import { ProductSearchItem} from 'app/product';
import { Product } from 'app/product/product';


export interface Props {
  businessId: string,
  searchText: string,
  authState: AuthState,
  searchResultState: SearchResultState,
  businessState: BusinessState,
  customerState: CustomerState,
  inquiryState: InquiryState,
  loadSearchResults: (businessId: string, searchText: string | undefined) => void,
  loadMoreSearchResults: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) => void,
}

export interface State {

}

export class SearchResultListPage extends Component<Props, State> {
  private _handleScroll: () => void;
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('SearchResultListPage initialized');
    this._handleScroll = this.handleScroll.bind(this);
   
  }

  componentDidMount() {
    this.props.loadSearchResults(this.props.businessId, this.props.searchText);

    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render(){
    console.log('SearchResultListPage, render')
    let component = null;
    if(this.props.searchResultState.state === types.SEARCH_RESULT_INITIALIZED || this.props.searchResultState.state === types.LOAD_SEARCH_RESULTS){
      component = <Spinner/>;
    } else if (this.props.searchResultState.searchResultList.length > 0 ){
      console.log('SearchResultListPage, render search result')
      component = this.renderSearchResultList();
    } else {
      component = this.renderSearchEmptyResult();
    }
    return component;
  }

  renderSearchResultList() {

    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>Search results for "{this.props.searchText}"</h4>
                  </div>
                  
                </div>
               
                <div className="row">
                  <div className="col-12">
                      <div className="table-responsive">
                      {
                        this.props.searchResultState.searchResultList.map((item: Customer | Inquiry | Product)=>{

                          if(item.object === RecordObject.customer){
                            return (
                              <CustomerListItem
                                key={(item as Customer).customerId} 
                                businessId={this.props.businessId}
                                customer={item as Customer}
                                canEdit={this.props.customerState.canEdit(this.props.businessState.selectedBusiness)}
                              />
                            );
                          }else if (item.object === RecordObject.inquiry){
                            return( 
                              <InquiryListItem 
                                key={(item as Inquiry).inquiryId}
                                businessId={this.props.businessId}
                                inquiry={item as Inquiry}
                                canEdit={this.props.inquiryState.canEdit(this.props.businessState.selectedBusiness)}
                              />
                            );
                          }else if (item.object === RecordObject.product){
                            return( 
                              <ProductSearchItem
                                key={(item as Product).productId}
                                businessId={this.props.businessId}
                                product={item as unknown as Product}
                                canEdit={this.props.inquiryState.canEdit(this.props.businessState.selectedBusiness)}
                              />
                            );
                          }
                        })
                      }
                      </div>
                    </div>
                </div>
          </div>
        </div>
      </div>
    )
  }

  renderSearchEmptyResult(){
    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">
                <div className="row">
                  <div className="col-12 grid-margin "> 
                    <h4>Your search for "{this.props.searchText}" did not match any result. </h4>
                    <p><br></br></p>
                    <h6>Suggestions:</h6>
                    <ul>
                      <li> Make sure the keyword is spelled correctly</li>
                      <li> Try a different keyword</li>
                    </ul>
                  </div>
                </div>
          </div>
        </div>
      </div>
    )
  }
  
  handleScroll(){
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if ((scrollHeight - scrollTop) === clientHeight){
      console.log('SearchResultListPage, load more search results');
      if(this.props.searchResultState.searchResultList.length > 0){
        this.props.loadMoreSearchResults(this.props.businessId, 0, this.props.searchText);
      }
    }
  }
}


const mapStateToProps = (state: { auth: any; searchResult: any; business: any; customer: CustomerState; inquiry: InquiryState; }) => {
  return { 
    authState: state.auth,
    searchResultState: state.searchResult,
    businessState: state.business,
    customerState: state.customer,
    inquiryState: state.inquiry
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText: string; afterTimestamp?: number; }) => void) => {
  return {
    loadSearchResults: (businessId: string, searchText: string | undefined) =>{
      dispatch(loadSearchResults(businessId, searchText));
    },
    loadMoreSearchResults: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) =>{
      dispatch(loadMoreSearchResults(businessId, afterTimestamp, searchText));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultListPage);

