import type from './types';
import { fetchTotalCount, fetchCount } from './firebaseActions';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';

export class Analytics {
    totalCount: TotalCount; 
    customersCount: CustomersCount;
    inquiriesCount: InquiriesCount;

    constructor(){
        console.log('Analytics initialized');

       this.totalCount = new TotalCount({customers: 0, inquiries: 0, isUpdated: false});
       this.customersCount = new CustomersCount({yearlyCountCollection: [], isUpdated: false});
       this.inquiriesCount = new InquiriesCount({yearlyCountCollection: [], isUpdated: false});;
    }
}

export type TotalCountData = {
    customers: number;
    inquiries: number;
    isUpdated: boolean;
}

export class TotalCount{
    customers: number;
    inquiries: number;
    isUpdated: boolean;

    constructor({customers, inquiries, isUpdated}: TotalCountData){
        this.customers = customers || 0;
        this.inquiries = inquiries || 0;
        this.isUpdated = isUpdated || false;
    }

    static async fetch(businessId: string){
        let totalCountData: TotalCountData = await fetchTotalCount(businessId);
        return new TotalCount({customers: totalCountData.customers, inquiries: totalCountData.inquiries, isUpdated: true});
    }

    copy(totalCount: TotalCount){
        Object.assign(this, totalCount);
    }
}


export type YearlyCount = {
    year: string,
    byDate: Record<number, Record<number, number>>,
    byMonth:Record<number, number>,
    byYear:number
}

export type CustomersCountData = {
    yearlyCountCollection: YearlyCount[],
    isUpdated: boolean
}

export class CustomersCount{
    yearlyCountCollection: YearlyCount[];
    isUpdated: any;

    constructor({yearlyCountCollection, isUpdated}: CustomersCountData){
       
        this.yearlyCountCollection = yearlyCountCollection|| [];
        this.isUpdated = isUpdated || false;
    }

    static async fetch(businessId: string){
        let yearlyCountCollection: YearlyCount[] = await fetchCount(businessId, 'customers');
        return new CustomersCount({yearlyCountCollection: yearlyCountCollection, isUpdated: true});
    }

    copy(customersCount: CustomersCount){
        Object.assign(this, customersCount);
    }
}

export type InquiriesCountData = {
    yearlyCountCollection: YearlyCount[],
    isUpdated: boolean
}

export class InquiriesCount{
    yearlyCountCollection: YearlyCount[];
    isUpdated: any;

    constructor({yearlyCountCollection, isUpdated}: InquiriesCountData){
       
        this.yearlyCountCollection = yearlyCountCollection|| [];
        this.isUpdated = isUpdated || false;
    }

    static async fetch(businessId: string){
        let yearlyCountCollection: YearlyCount[] = await fetchCount(businessId, 'inquiries');
        return new CustomersCount({yearlyCountCollection: yearlyCountCollection, isUpdated: true});
    }

    copy(inquiriesCount: InquiriesCount){
        Object.assign(this, inquiriesCount);
    }
}

export class AnalyticsState {
    state: string;
    analytics: Analytics;
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: Business) => boolean;

    constructor(){
        console.log('AnalyticsState initialized');

        this.state = type.ANALYTICS_INITIALIZED;
        this.analytics = new Analytics();

        this.canCreate = function(selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }
    }
}
