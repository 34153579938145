import React, { Component } from 'react';

class EditButton extends Component {

  render () {
    return (
        <button {...this.props}>{this.props.children}</button>
    );
  }
}

export default EditButton;