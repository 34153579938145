import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Spinner } from '../shared/Spinner';

import { initiateSignup } from './actions';
import type from './types';
import { AuthState } from './auth';

export interface Props {
  auth: AuthState,
  initiateSignup: (email: string, password: string) => void,
}

export interface State {
  email: string,
  password: string,
  isEmailInvalid: boolean,
  isPasswordInvalid: boolean,
  emailErrorText: string,
  passwordErrorText: string,
  confirmPassword: string,
  isConfirmPasswordInvalid: boolean,
  confirmPasswordErrorText: string,
  isTermsAgreed: boolean,
  isInvalid: string,
  agreeErrorText: string
}


export class Signup extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('Signup initialized');
    this.state = {
      email: '',
      password: '',
      isEmailInvalid: false,
      isPasswordInvalid: false,
      emailErrorText: '',
      passwordErrorText: '',
      confirmPassword: '',
      isConfirmPasswordInvalid: false,
      confirmPasswordErrorText: '',
      isTermsAgreed: false,
      isInvalid: '',
      agreeErrorText: ''
    }
  }

  render(){
    let render;
    if (this.props.auth.state == type.SIGNED_OUT){
      render = this.signup();
     }else{
      render = this.loading();
     } 
     return render;
  }

  loading(){
    return ( <Spinner/> );
  }

  signup() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4>New here?</h4>
                <h6 className="font-weight-light">Signing up is easy. It only takes a few steps.</h6>
                <Form className="pt-3">
                  <Form.Group controlId="email" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="email" 
                      placeholder="Email" 
                      onChange={this.onChangeEmail.bind(this)} 
                      required
                      isInvalid = {this.state.isEmailInvalid}
                      value = {this.state.email}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.emailErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="password" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="password" 
                      placeholder="Password" 
                      onChange={this.onChangePassword.bind(this)} 
                      required
                      isInvalid = {this.state.isPasswordInvalid}
                      value = {this.state.password}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.passwordErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="confirmPassword" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="password" 
                      placeholder="Confirm Password" 
                      onChange={this.onChangeConfirmPassword.bind(this)} 
                      required
                      isInvalid = {this.state.isConfirmPasswordInvalid}
                      value = {this.state.confirmPassword}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.confirmPasswordErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex search-field flex-column form-group">
                    <div className="form-check">
                      <label className={"form-check-label text-muted "+ this.state.isInvalid}>
                        <input id="agree" type="checkbox" className="form-check-input" onChange={this.onAgree.bind(this)} />
                        <i className="input-helper"></i>
                        <a>I agree to </a> <a target="_blank" rel="noopener noreferrer" href="https://www.resquash.com/terms.html"  className="auth-link text-muted">Terms of Service</a> & <a target="_blank" rel="noopener noreferrer" href="https://www.resquash.com/privacy.html"  className="auth-link text-muted">Privacy Policy</a>
                      </label>
                      <div className="invalid-feedback">{this.state.agreeErrorText}</div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/" onClick={this.onSignup.bind(this)} type="submit">SIGN UP</Link>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Already have an account? <a href="/login" className="text-primary">Login</a>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <div className="text-danger"> {this.props.auth.errorMessage} </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onChangeEmail(e: { target: { value: any; }; }){
    this.setState({email: e.target.value, isEmailInvalid: false});
  }

  onChangePassword(e: { target: { value: any; }; }){
    this.setState({password: e.target.value, isPasswordInvalid: false});
  }

  onChangeConfirmPassword(e: { target: { value: any; }; }){
    this.setState({confirmPassword: e.target.value, isConfirmPasswordInvalid: false});
  }

  onAgree(e: { target: { checked: boolean; }; }){
    console.log('onAgree - '+ e.target.checked);
    this.setState({isTermsAgreed: e.target.checked, isInvalid: ''});
  }

  onSignup(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('Signup, onSignup');
    if (this.isFieldsValid()){
      this.props.initiateSignup(this.state.email, this.state.password);
    }
  }

  isFieldsValid(){

    let isFieldValid = true;

    if(this.state.email === ''){
      this.setState({isEmailInvalid: true, emailErrorText: 'Email adress required'});
      isFieldValid = false;
    }else if (!(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(this.state.email))){
      this.setState({isEmailInvalid: true, emailErrorText: 'Valid email adress required'});
      isFieldValid = false;
    }

    if(this.state.password === ''){
      this.setState({isPasswordInvalid: true, passwordErrorText: 'Password required'});
      isFieldValid = false;
    }

    if(this.state.confirmPassword === ''){
      this.setState({isConfirmPasswordInvalid: true, confirmPasswordErrorText: 'Confirm password'});
      isFieldValid = false;
    }else if (this.state.password !== this.state.confirmPassword){
      this.setState({isConfirmPasswordInvalid: true, confirmPasswordErrorText: 'Passwords do not match'});
      isFieldValid = false;
    }

    if(!this.state.isTermsAgreed){
      this.setState({isInvalid: 'is-invalid', agreeErrorText: 'You have to agree to Sign up'});
      isFieldValid =false;
    }

    console.log('isFieldValid - '+ JSON.stringify(isFieldValid));
    return isFieldValid;
  }

}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; email: string; password: string; }) => void) => {
  return {
    initiateSignup: (email: string, password: string) => {
      dispatch(initiateSignup(email, password))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);