import { channel } from 'redux-saga'
import { put, takeEvery, call, take } from 'redux-saga/effects';

import { TotalCount, CustomersCount, InquiriesCount } from './analytics';

import type from './types';

//Action creators

export const loadTotalCount = (businessId: string) => {
    console.log('analytics, action, loadTotalCount, businessId - '+ businessId);
    return { type: type.LOAD_TOTAL_COUNT, businessId }
};

export const loadCustomersCount = (businessId: string) => {
    console.log('analytics, action, loadCustomersCount, businessId - '+ businessId);
    return { type: type.LOAD_CUSTOMERS_COUNT, businessId }
};

export const loadInquiriesCount = (businessId: string) => {
    console.log('analytics, action, loadCustomersCount, businessId - '+ businessId);
    return { type: type.LOAD_INQUIRIES_COUNT, businessId }
};


const retrievedTotalCount = (totalCount: TotalCount) => {
    return { type: type.TOTAL_COUNT_LOADED, totalCount }
};

const retrievedCustomersCount = (customersCount: CustomersCount) => {
    return { type: type.CUSTOMERS_COUNT_LOADED, customersCount }
};

const retrievedInquiriesCount = (inquiriesCount: InquiriesCount) => {
    return { type: type.INQUIRIES_COUNT_LOADED, inquiriesCount }
};
// Sagas

function* loadTotalCountAsync(action: { businessId: string; }) {
    console.log('analytics, action, loadTotalCountAsync - '+ JSON.stringify(action));
    try {
  
        const totalCount: TotalCount = yield call(TotalCount.fetch, action.businessId);
        console.log(JSON.stringify(totalCount));
        yield put(retrievedTotalCount(totalCount));
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

function* loadCustomersCountAsync(action: { businessId: string; }) {
    console.log('analytics, action, loadCustomersCountAsync - '+ JSON.stringify(action));
    try {
  
        const customersCount: CustomersCount = yield call(CustomersCount.fetch, action.businessId);
        console.log(JSON.stringify(customersCount));
        yield put(retrievedCustomersCount(customersCount));
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

function* loadInquiriesCountAsync(action: { businessId: string; }) {
    console.log('analytics, action, loadInquiriesCountAsync - '+ JSON.stringify(action));
    try {
  
        const inquiriesCount: InquiriesCount = yield call(InquiriesCount.fetch, action.businessId);
        console.log(JSON.stringify(inquiriesCount));
        yield put(retrievedInquiriesCount(inquiriesCount));
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

export const analyticsSagas = [
    takeEvery<any>(type.LOAD_TOTAL_COUNT, loadTotalCountAsync),
    takeEvery<any>(type.LOAD_CUSTOMERS_COUNT, loadCustomersCountAsync),
    takeEvery<any>(type.LOAD_INQUIRIES_COUNT, loadInquiriesCountAsync),
];
