import { Component } from 'react';
import { Form, Modal, Button  } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Reward } from './reward';
import { ErrorCode } from './types';

export interface Props {
 show: boolean,
 reward: Reward | null | undefined,
 title?: string, 
 onHide: () => void,
 onCancel: () => void, 
 onSave: (reward: Reward) => void,
 errorCode?: ErrorCode | '',
 saveInProgress?: boolean
}

export interface State {
  validated: boolean,
  reward: any
}

export class RewardInputDialog extends Component<Props, State> {
  reward: Reward;
  title: string;
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('BusinessProfile initialized');
    this.reward = (this.props.reward)? this.props.reward : new Reward({name: 'Reward points for dollar spent'});
    let isEditDialog = (this.props.reward && this.props.reward.rewardId !== '')?true:false;

    this.title = (this.props.title)? this.props.title: ((isEditDialog)? 'Edit reward':'Create a new reward');
  
    this.state = {
      validated: false,
      reward: {
        name:{
          placeholder: 'Enter reward name',
          value: this.reward.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
          readOnly: false
        },
        amountSpentPerPoint:{
          placeholder: 'Amount spent to earn one point',
          value: this.reward.amountSpentPerPoint,
          type: 'text',
          pattern:'[1-9][0-9]*',
          required: true,
          errorText: '',
          readOnly: isEditDialog
        }, 
        minPointsToRedeem:{
          placeholder: 'Min points to redeem',
          value: this.reward.minPointsToRedeem,
          type: 'text',
          pattern:'[1-9][0-9]*',
          required: true,
          errorText: '',
          readOnly: isEditDialog
        }, 
        minAmountToRedeem:{
          placeholder: 'Discount value',
          value: this.reward.minAmountToRedeem,
          type: 'text',
          pattern:'[1-9][0-9]*',
          required: false,
          errorText: '',
          readOnly: false
         }
      }
    }
  }

  render() {
    let reward = this.state.reward;
    let self = this;
    let {errorCode, saveInProgress } = this.props;

    let errorMessage = '';

    if(errorCode === ErrorCode.REWARD_ALREADY_EXISTING){
      errorMessage = `A reward program is already existing.`;
    }else if (errorCode === ErrorCode.FAILED_TO_CREATE){
      errorMessage = `Failed to create a reward, please try after sometime.`;
    }
    
    return (
  
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >
          <LoadingOverlay
            active={saveInProgress}
            spinner
            text='Loading ...'
            >
            <Modal.Header closeButton>
              {this.title}
            </Modal.Header>

            <Modal.Body>

    
                  <Form className="form-sample" id="rewardInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                    <div className="py-2 row">
                      <Form.Group className="col-md-12">
                        <Form.Label>Reward name</Form.Label>
                        <Form.Control type={reward.name.type} name="name" pattern={reward.name.pattern} required={reward.name.required} placeholder={reward.name.placeholder} onChange={e => self.onChange(e)} value = {reward.name.value} readOnly = {reward.name.readOnly}/>
                        <Form.Control.Feedback type="invalid">Name required, can be alpha numaric </Form.Control.Feedback>
                      </Form.Group>

                      
                      <Form.Group className="col-md-12">
                        <Form.Label>Amount spent to earn one point</Form.Label>
                        <Form.Control type={reward.amountSpentPerPoint.type} name='amountSpentPerPoint' pattern={reward.amountSpentPerPoint.pattern} required={reward.amountSpentPerPoint.required} placeholder={reward.amountSpentPerPoint.placeholder} onChange={e => self.onChange(e)} value = {reward.amountSpentPerPoint.value} readOnly = {reward.amountSpentPerPoint.readOnly}/>
                        <Form.Control.Feedback type="invalid">Amount spent shoul be minimum 1 dollar</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="col-md-12">
                        <Form.Label>Minimum point(s) to redeem</Form.Label>
                        <Form.Control type={reward.minPointsToRedeem.type} name='minPointsToRedeem' pattern={reward.minPointsToRedeem.pattern} required={reward.minPointsToRedeem.required} placeholder={reward.minPointsToRedeem.placeholder} onChange={e => self.onChange(e)} value = {reward.minPointsToRedeem.value} readOnly = {reward.minPointsToRedeem.readOnly}/>
                        <Form.Control.Feedback type="invalid">Should be minimum 1 point</Form.Control.Feedback>
                      </Form.Group>
              
                      <Form.Group className="col-md-12">
                        <Form.Label>Discount value</Form.Label>
                        <Form.Control type={reward.minAmountToRedeem.type} name='minAmountToRedeem' pattern={reward.minAmountToRedeem.pattern} required={reward.minAmountToRedeem.required} placeholder={reward.minAmountToRedeem.placeholder} onChange={e => self.onChange(e)} value = {reward.minAmountToRedeem.value} readOnly = {reward.minAmountToRedeem.readOnly}/>
                        <Form.Control.Feedback type="invalid">Should be minimum 1 dollar</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Form>
                  <div className="col-md-12">
                    <div>{errorMessage}</div>
                  </div>

            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
              <button type="submit" form="rewardInfo"  className="btn btn-primary btn-sm">Save</button>
            </Modal.Footer>
          </LoadingOverlay>
      </Modal>
    );
  }

  onChange(e: any){
    console.log('CreateReward, onChange');

    let reward = this.state.reward;
    let key = e.target.name;

    reward[key].value = e.target.value;
    this.setState({reward:reward});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('CreateReward, onSubmit');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let reward =  this.getFormData();
      console.log(JSON.stringify(reward));

      this.setState({validated: false});
      this.props.onSave(reward)
    }
  }

  getFormData(){

 
    let rewardObj = Object.keys(this.state.reward).reduce((acc: any, key: string) =>{
      acc[key] = this.state.reward[key].value;
      return acc;
    }, {});


    return new Reward({...this.reward, ...rewardObj}); 
  }

}


export default RewardInputDialog;