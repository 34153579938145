
const CUSTOMER_INITIALIZED = 'CUSTOMER_INITIALIZED';
const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
const LOAD_MORE_CUSTOMERS = 'LOAD_MORE_CUSTOMERS';
const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
const MORE_CUSTOMERS_LOADED = 'MORE_CUSTOMERS_LOADED';
const CUSTOMER_LIST_EMPTY = 'CUSTOMER_LIST_EMPTY';
const FAILED_TO_LOAD_CUSTOMERS = 'FAILED_TO_LOAD_CUSTOMERS';

const INITIATE_CREATE_CUSTOMER = 'INITIATE_CREATE_CUSTOMER';
const INITIATE_UPDATE_CUSTOMER = 'INITIATE_UPDATE_CUSTOMER';
const INITIATE_DELETE_CUSTOMER = 'INITIATE_DELETE_CUSTOMER';

const CUSTOMER_CREATED = 'CUSTOMER_CREATED';
const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
const CUSTOMER_DELETED = 'CUSTOMER_DELETED';


export default {
    CUSTOMER_INITIALIZED,
    LOAD_CUSTOMERS,
    LOAD_MORE_CUSTOMERS,
    CUSTOMERS_LOADED,
    MORE_CUSTOMERS_LOADED,
    CUSTOMER_LIST_EMPTY,
    FAILED_TO_LOAD_CUSTOMERS,

    INITIATE_CREATE_CUSTOMER,
    INITIATE_UPDATE_CUSTOMER,
    INITIATE_DELETE_CUSTOMER,

    CUSTOMER_CREATED,
    CUSTOMER_UPDATED,
    CUSTOMER_DELETED,
};

export enum ErrorCode {
    CUSTOMER_ALREADY_EXISTING = '409',
    FAILED_TO_CREATE = '0'
}