
export interface PageComponent {
    id: string,
    tagName: string, 
    attributes: {}, 
    data?:{}
}

export type PagePropertyData = {
    pagePropertyId?: string;
    name?: string;
    path?: string;
    size?: number;
    components?: any[]
    createdAt?: number;
    lastModifiedAt?: number;
}


export class PageProperty{
    pagePropertyId: string;
    name: string;
    path: string;
    size: number;
    components: PageComponent[];
    createdAt: number;
    lastModifiedAt: number;
    

    constructor(pagePropertyData: PagePropertyData){

        this.pagePropertyId = pagePropertyData.pagePropertyId || '';
        this.name = pagePropertyData.name || '';
        this.path = this.name.toLocaleLowerCase().replace(/\s/g , "-"); //change it to all lower case and replace spaces with '-'
        this.size = pagePropertyData.size || 0;
        this.components = pagePropertyData.components || [];
        this.createdAt = pagePropertyData.createdAt || 0;
        this.lastModifiedAt = pagePropertyData.lastModifiedAt || 0;
    }

    isDifferent(pageProperty: PageProperty){
        return true;
    }

    serialize(){
        return {
            pagePropertyId: this.pagePropertyId,
            name: this.name,
            path: this.path,
            size: this.size,
            components: this.components,
            createdAt: this.createdAt,
            lastModifiedAt: this.lastModifiedAt,
        }
    }

    serializeForPageUpdates(){
        return { 
            name: this.name,
            path: this.path,
            lastModifiedAt: this.lastModifiedAt,
        }
    }

    serializeForSizeUpdates(){
        return { 
            
            size: this.size,
            components: this.components,
            lastModifiedAt: this.lastModifiedAt,
        }
    }
}

export type AvailablePagePropertyData = {

    availablePageProperty?: PageProperty[]
}

export class AvailablePageProperty {

    availablePageProperty: PageProperty[] = [];

    constructor(availablePagePropertyData: AvailablePagePropertyData){

       if (availablePagePropertyData.availablePageProperty){
            this.availablePageProperty = availablePagePropertyData.availablePageProperty.map(pageProperty => {
                return new PageProperty(pageProperty);
            })
        }
    }

    serialize(){
        console.log('AvailablePageProperty, serialize');
        return {
            
            availablePageProperty: this.availablePageProperty.map((pageProperty: PageProperty) =>{        
                         return pageProperty.serialize();
                     }),
            
        };
    }

    getLength(){
        return this.availablePageProperty.length;
    }
}
