import { Component } from 'react';
import { Form, Modal, Button  } from 'react-bootstrap';


export interface Props {
  id: string,
  show: boolean,
  onHide: () => void,
  title: string,
  confirmationText?: string,
  onCancel: () => void, 
  onConfirm: (id: string) => void

}

export interface State {
  validated: boolean, 
  confirmedText: string
}

export class ConfirmationDialog extends Component <Props, State>{
  title: string;
  confirmationText: string;
  

  constructor(props: Props){
    super(props);
    console.log('ConfirmationDialog initialized');

     this.title = this.props.title;
     this.confirmationText = (this.props.confirmationText)? this.props.confirmationText: Math.random().toString(36).substr(2, 5);

     this.state = {
       validated: false,
       confirmedText: ''
     }
  }

  render() {

    let self = this;
    return (
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >

        <Modal.Header closeButton>
          {this.title}
        </Modal.Header>

        <Modal.Body>
          <h4> Confirm by typing: {this.confirmationText}</h4>
          <Form className="form-sample" id="confirm"  noValidate validated={this.state.validated} onSubmit={this.onDelete.bind(this)}>
            <div className="py-2 row">
              <Form.Group className="col-md-12">
                <Form.Control type='text' required={true} onChange={e => self.onChange(e)} value = {this.state.confirmedText} pattern={this.confirmationText}/>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
          <button type="submit" form="confirm"  className="btn btn-primary btn-sm">Yes</button>
        </Modal.Footer>
      </Modal>
    );
  }

  onChange(e: any){
    console.log('DeleteDialog, onChange');
    this.setState({confirmedText:e.target.value});
  }

  onDelete(e: any){
    console.log('DeleteDialog, onDelete');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {


      this.setState({validated: false, confirmedText: ''});
      this.props.onConfirm(this.props.id);
    }
  }

}


export default ConfirmationDialog;