import { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Tab } from 'react-bootstrap';
import { Spinner } from '../shared/Spinner';
import { loadCurrentEstimation, loadPastPayments } from "./actions";
import Invoice from './invoice';
import PaymentList from './paymentList';
import types from './types';
import { PaymentState} from './payment';
import { BusinessState } from 'app/business';

export interface Props {
  businessId: string,
  business: BusinessState,
  billing: PaymentState;
  loadCurrentEstimation: (businessId: string) => void
}

export interface State {

}

export class BillingView extends Component<Props, State> {
  
  constructor(props: Props){
    super(props);
    console.log('BillingView initialized');
  
    this.state = {
      
    }

    this.props.loadCurrentEstimation(this.props.businessId);
  }

  render(){
    let component = null;

    if(this.props.billing.state == types.PAYMENT_INITIALIZED){
      component = <Spinner/>;
    }else{
      component = this.renderBillingView();
    }
    return component;
  }


  
  renderBillingView(){
    return ( 
    <div>
      <div className="row">
        <div className="col-xl-1 grid-margin"></div>
        <div className="col-12 col-xl-10 grid-margin">
          <div> 
              <h4 className="mb-md-0 pb-4 mb-4">Billing for {this.props.business.selectedBusiness.name} </h4>
              <div className="tab-pills-horizontal">
                <Tab.Container id="left-tabs-example" defaultActiveKey="usage">
                  <Nav variant="pills" className="border-0">
                    <Nav.Item>
                      <Nav.Link eventKey="usage" >
                        Usage
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="payments" >
                        Payments
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="card">
                    <Tab.Content className="border-0">
                      <Tab.Pane eventKey="usage">
                        <Invoice key={this.props.businessId} businessId = {this.props.business.selectedBusiness.businessId} transaction = {this.props.billing.payment.currentEstimation.transaction} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="payments">
                        <PaymentList key={this.props.businessId} businessId = {this.props.business.selectedBusiness.businessId} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>
        </div>
      </div>
    </div>
    );
  }
}


const mapStateToProps = (state: { auth: any; business: any; billing: any; }) => {
  return { 
    auth: state.auth,
    business: state.business,
    billing: state.billing
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; }) => void) => {
  return {
    loadCurrentEstimation: (businessId: string) =>{
      dispatch(loadCurrentEstimation(businessId));
    },
    loadPastPayments: (businessId: string) => {
      dispatch(loadPastPayments(businessId))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingView);

