import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { BusinessState } from 'app/business';
import { mdiHomeOutline, mdiSquareEditOutline, mdiAccountOutline, mdiForumOutline, mdiCogOutline, mdiCreditCardOutline, mdiArrangeSendBackward, mdiWalletGiftcard, mdiWeb, mdiPackageVariant } from '@mdi/js';
import IconWithLabel from './iconWithLabel';
import { PageLinksState } from 'app/page/pageLinks';
import { loadAvailablePageProperty, loadDomainList, loadPageLinks } from 'app/page/actions';
import { AvailablePageProperty } from 'app/page/pageProperty';

export interface Props {
  businessState: BusinessState,
  pageLinksState: PageLinksState,
  location: any,
  loadAvailablePageProperty: (businessId: string) => void,
  loadPageLinks: (businessId: string) => void,
  loadDomainList: (businessId: string) => void
}

export interface State {
 
}

class Sidebar extends Component<Props, State> {

  state  = {

  } as any [string]

  constructor(props: Props){
    super(props);

    this.props.loadAvailablePageProperty(this.props.businessState.selectedBusiness.businessId);
    this.props.loadPageLinks(this.props.businessState.selectedBusiness.businessId);
    this.props.loadDomainList(this.props.businessState.selectedBusiness.businessId);
  }

  toggleMenuState(menuState: string) {
    if (this.state[menuState]) {

      this.setState({[menuState] : false});

    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar')?.classList.remove('active');

    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/settings', state: 'settingsMenuOpen'},
      {path:'/online', state: 'onlineMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () {
    let businessId = this.props.businessState.selectedBusiness.businessId;

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <IconWithLabel className="pr-2" path={mdiHomeOutline} label="Home"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/businessProfile') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/businessProfile">
              <IconWithLabel className="pr-2" path={mdiSquareEditOutline} label="Profile"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/customers') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/customers">
              <IconWithLabel className="pr-2" path={mdiAccountOutline} label="Customers"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/products') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/products">
              <IconWithLabel className="pr-2" path={mdiPackageVariant} label="Products"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/inquiries') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/inquiries">
              <IconWithLabel className="pr-2" path={mdiForumOutline} label="Inquiries"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/rewards') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/rewards">
              <IconWithLabel className="pr-2" path={mdiWalletGiftcard} label="Rewards"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/loyalty') ? 'nav-item active' : 'nav-item' } style={{display: 'none' }}>
            <Link className="nav-link" to="/loyalty">
              <IconWithLabel className="pr-2" path={mdiForumOutline} label="Loyalty"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/settings') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.settingsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('settingsMenuOpen') } data-toggle="collapse">
              <IconWithLabel className="pr-2" path={mdiCogOutline} label="Settings"/>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.settingsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/settings/userPermissions') ? 'nav-link active' : 'nav-link' } to="/settings/userPermissions">Permissions</Link></li>
                <li className="nav-item" style={{ display: (this.props.businessState.canAdminister() ? 'block' : 'none') }}> <Link className={ this.isPathActive('/settings/api') ? 'nav-link active' : 'nav-link' } to="/settings/api">API</Link></li>
              </ul>
            </Collapse>
          </li>
        </ul>
        <ul className="nav border-top border-gray" >
          <li className={ this.isPathActive('/online') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.onlineMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('onlineMenuOpen') } data-toggle="collapse">
              <IconWithLabel className="pr-2" path={mdiWeb} label="Online"/>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.onlineMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/online/setup') ? 'nav-link active' : 'nav-link' } to="/online/setup">Setup</Link></li>
                {
                  this.props.pageLinksState.availablePageProperty.availablePageProperty.map(pageProperty =>{
                    return (
                      <li className="nav-item" style={{ display: (this.props.businessState.canAdminister() ? 'block' : 'none') }}> <Link className={ this.isPathActive(`/online/page/${pageProperty.pagePropertyId}`) ? 'nav-link active' : 'nav-link' } to={`/online/page/${pageProperty.pagePropertyId}?b=${businessId}`} target="_blank"> <span> {pageProperty.name} </span></Link></li>
                    )
                  })
                }
              </ul>
            </Collapse>
          </li>
        </ul>
        <ul className="nav border-top border-gray" >
          <li className={ this.isPathActive('/billing') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/billing">
              <IconWithLabel className="pr-2" path={mdiCreditCardOutline} label="Billing"/>
            </Link>
          </li>
          <li className={ this.isPathActive('/plan') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/plan">
              <IconWithLabel className="pr-2" path={mdiArrangeSendBackward} label="Plan Upgrade"/>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path: string) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body: any = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

const mapStateToProps = (state: { business: any; pageLinks: any; }) => {
  return { 
            businessState: state.business,
            pageLinksState: state.pageLinks 
          };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; availablePageProperty?: AvailablePageProperty; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadAvailablePageProperty: (businessId: string) =>{
      dispatch(loadAvailablePageProperty(businessId));
    },
    loadPageLinks: (businessId: string) =>{
      dispatch(loadPageLinks(businessId));
    },
    loadDomainList: (businessId: string) =>{
      dispatch(loadDomainList(businessId));
    },
  };
};

let _sidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export default withRouter(_sidebar as any);
