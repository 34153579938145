

import firebase, { firestore } from '../firebase.js';
import { Customer, CustomerData } from './customer';
import {isChars, isNumbers, isEmailAddress } from '../shared'; 
import { settings } from '../config/settings';
import axios from 'axios';

export async function fetchCustomerList(businessId: string, afterTimestamp=0, searchText=''){

  console.log('customer, firebaseActions, fetchCustomerList, businessId - '+ businessId);

  let customerList: Customer[] = [];
  let customerCollectionRef =  firestore.collection('businesses').doc(businessId).collection('customers');;

  let customerCollectionQuery = customerCollectionRef.orderBy('customerSince', 'desc');

  if (afterTimestamp != 0){
    customerCollectionQuery  = customerCollectionQuery.startAfter(afterTimestamp);
  }

  if (searchText != ''){

    if (isChars(searchText)){
      customerCollectionQuery = customerCollectionQuery.where('name', '==', searchText);
    }else if (isNumbers(searchText)){
      customerCollectionQuery = customerCollectionQuery.where('phoneNumber', '==', searchText);
    }else if (isEmailAddress(searchText)){
      customerCollectionQuery = customerCollectionQuery.where('emailAddress', '==', searchText);
    }
  }  
  
  let customerCollection =  await customerCollectionQuery.limit(10).get();  
  
  if (customerCollection.docs.length > 0){
      console.log('customer, firebaseActions');
      
      customerCollection.forEach((doc)=>{
        let customer = new Customer(doc.data() as CustomerData);
        customer.customerId = doc.id;
        customerList.push(customer);
      });

  }else{
      console.log(`customer, firebaseActions, fetchCustomerList, customer list is empty for businessId - ${businessId}`);
  }

  return customerList;
}

export async function createCustomer(businessId: string, customer: Customer){

  console.log('firebaseActions, createCustomer, businessId - '+ businessId);

  let customerUrl = settings.apiURL + '/customers';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }

  customer.customerSince = new Date().getTime();

  let response = await axios.post(customerUrl, customer.serialize(), {headers});

  return new Customer(response.data);
}

export async function updateCustomer(businessId: string, customer: Customer){

  console.log('firebaseActions, updateCustomer, businessId - '+ businessId);

  let customerUrl = settings.apiURL + '/customers/' + customer.customerId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  let response = await axios.patch(customerUrl, customer.serialize(), {headers});

  return new Customer(response.data);

}

export async function deleteCustomer(businessId: string, customer: Customer){

  console.log('firebaseActions, deleteCustomer, businessId - '+ businessId);

  let customerUrl = settings.apiURL + '/customers/' + customer.customerId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  await axios.delete(customerUrl, {headers});
  return customer;
}
