import _ from 'lodash';
import { Component } from 'react';
import { Col, Row  } from 'react-bootstrap';
import { DeleteDialog, Img as Image } from 'app/shared';
import { imageLoader, Social } from '../shared';

import SocialLinkInputDialog from './socialLinkInputDialog';


export interface Props {
  socialLinks: string[],
  editable: boolean,
  onChange: (socialLinks: string[]) => void
}

export interface State {
  showAddDialog: boolean;
  showDeleteDialog: boolean;
  socialLinks: string[];
  indexToDelete: string;
}

export class ShowSocialLinks extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('ShowSocialLinks initialized');

    this.state = {
      showAddDialog: false,
      showDeleteDialog: false,
      socialLinks: this.props.socialLinks,
      indexToDelete: '' ,
    }

  }

  componentDidUpdate(previoursProps: Props){
    if(!_.isEqual(previoursProps.socialLinks, this.props.socialLinks)){
      this.setState({socialLinks: this.props.socialLinks});
    }
  }

  render(){

    return (

        this.renderShowSocialLinks()
            
    );
  }

  renderShowSocialLinks() {
    let socialLinks = this.state.socialLinks;
    
    return (

          <div>
            <div className="py-4">
              <div>
                
                <h4> Social <i style={{ display: (this.props.editable ? 'block-inline' : 'none') }} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onShowAddDialog()}}></i> </h4>
                       
              </div>
              {
                (socialLinks.length > 0 ? this.renderSocialLinks() : this.renderSetupSocialLinks())
                
              }
            </div>   
            <SocialLinkInputDialog 
                key={new Date().getMilliseconds()}
                show={this.state.showAddDialog} 
                onHide={() => {this.setState({showAddDialog: false})}} 
                onCancel={() => {this.setState({showAddDialog: false})}} 
                onSave={(socialLink: string) => this.onAdd(socialLink)}              
            />
            <DeleteDialog id={this.state.indexToDelete} show={this.state.showDeleteDialog} onHide={() => this.setState({showDeleteDialog: false})} onCancel={() => this.setState({showDeleteDialog: false})} onDelete={(index: string) => this.onDelete(index)}/>
          </div>  
    )
  }

  renderSetupSocialLinks(){
    return(
        <Row className="py-1">
              <Col xs={12}>
                <span className="float-left text-muted">
                  Specify social links if needed
                </span>
              </Col>
        </Row>
    );
  }

  renderSocialLinks(){
    return (
      <div>
        {
          this.state.socialLinks.map((socialLink: string, index: number) => {
            let social = new Social({url: socialLink});
            console.log(`${socialLink}-index-${index}`);
            console.log(`${social.getIcon()}`);
            return (
              <div className="d-flex align-items-center pb-3 border-bottom">
                <Image key={social.getIcon()} className="img-fluid" loader={imageLoader} src={social.getIcon()} height="40" width="40" alt={social.socialPlatform} fallbackSrc={social.getFallbackIcon()}/>
                <div className="ml-3">
                  <h6 className="mb-1">{social.url}</h6>
                </div>
                <i className="ti-trash ml-auto px-1 py-1" onClick={(e)=> { e.stopPropagation(); this.onShowDeleteDialog(index as unknown as string)}}></i>
              </div>
            );
          })
        }
      </div>   

    );
  }


  onShowAddDialog(){
    console.log('ShowSocialLinks, onShowAddDialog');
    this.setState({showAddDialog: true});
  }

  onShowDeleteDialog(index: string){
    console.log('ShowSocialLinks, onShowDeleteDialog');
    this.setState({showDeleteDialog: true, indexToDelete: index});
  }
 

    
  onAdd(socialLink: string){
    console.log('ShowSocialLinks, onAdd');
    this.setState({showAddDialog: false});
    let socialLinks = this.state.socialLinks;
    socialLinks.push(socialLink);
    this.setState({socialLinks: socialLinks});
    this.props.onChange(socialLinks);
  }


  onDelete(index: string){
    console.log(`ShowSocialLinks, onDelete, index-${index}`);
    this.state.socialLinks.splice((index as unknown as number), 1);
    this.setState({socialLinks: this.state.socialLinks, showDeleteDialog: false, indexToDelete: ''});
    this.props.onChange(this.state.socialLinks);
  }

}
