

import firebase, { firestore } from '../firebase.js';
import { Product, ProductData } from './product';
import {isChars, isNumbers, isEmailAddress, getFileExtension } from '../shared'; 
import { settings } from '../config/settings';
import axios from 'axios';

export async function fetchProductList(businessId: string, afterTimestamp=0, searchText=''){

  console.log('product, firebaseActions, fetchProductList, businessId - '+ businessId);

  let productList: Product[] = [];
  let productCollectionRef =  firestore.collection('businesses').doc(businessId).collection('products');


  let productCollection =  await productCollectionRef.get();  
  
  if (productCollection.docs.length > 0){
      console.log('product, firebaseActions');
      
      productCollection.forEach((doc)=>{
        let product = new Product(doc.data() as ProductData);
        product.productId = doc.id;
        productList.push(product);
      });

  }else{
      console.log(`product, firebaseActions, fetchProductList, product list is empty for businessId - ${businessId}`);
  }

  return productList;
}

export async function createProduct(businessId: string, product: Product){

  console.log('firebaseActions, createProduct, businessId - '+ businessId);

  if(product.newPictureFile){
    product.picture = await uploadPicture(businessId, product.newPictureFile);
  }

  let productUrl = settings.apiURL + '/products';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }

  let response = await axios.post(productUrl, product.serialize(), {headers});

  return new Product(response.data);
}

export async function updateProduct(businessId: string, product: Product){

  console.log('firebaseActions, updateProduct, businessId - '+ businessId);

  await deletePicture(product.deletePicture);

  if(product.newPictureFile){
      product.picture = await uploadPicture(businessId, product.newPictureFile);
  }

  let productUrl = settings.apiURL + '/products/' + product.productId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  let response = await axios.patch(productUrl, product.serialize(), {headers});

  return new Product(response.data);

}

export async function deleteProduct(businessId: string, product: Product){

  console.log('firebaseActions, deleteProduct, businessId - '+ businessId);

  let productUrl = settings.apiURL + '/products/' + product.productId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  await axios.delete(productUrl, {headers});
  return product;
}

export async function uploadPicture(businessId: string, pictureFile: File){
  console.log('firebaseActions, uploadPicture ');
  let pictureUrl = '';

  if (pictureFile){
    let timestamp = new Date().getTime();
   
    let fileName = `${timestamp}.jpeg`
    let storageRef = firebase.storage().ref();
    let pictureRef = storageRef.child(`images/${businessId}/products/${fileName}`);
    await pictureRef.put(pictureFile);
    pictureUrl = await pictureRef.getDownloadURL();
  }
  return pictureUrl;
}

export async function deletePicture(pictureUrl: string){
  console.log('firebaseActions, deletePicture ');

  try{
    if (pictureUrl !== ''){
      let storage = firebase.storage();
      var httpsReference = storage.refFromURL(pictureUrl);
      await httpsReference.delete();
    }
  }catch(error){
    console.log('firebaseActions, deletePicture , failed to delete-');
  }
}

