

import firebase, { firestore } from '../firebase.js';
import { DashboardElement, DashboardElementData } from './dashboardElement';

export type DashboardData = {
  elements: {
    CustomersGraph?: DashboardElementData,
    InquiriesGraph?: DashboardElementData,
    TotalCustomers?: DashboardElementData,
    TotalInquires?: DashboardElementData
  }
}

export async function fetchDashboardElements(businessId: string){

  console.log('dashboardElements, firebaseActions, fetchDashboardElements, businessId - '+ businessId);

  let dashboardElements: DashboardElement[] = [];
  let dashboardElementsDocRef =  firestore.collection('businesses').doc(businessId).collection('dashboards').doc('default');

  let dashboardElementsDoc = await dashboardElementsDocRef.get();
  
  if (dashboardElementsDoc.exists){
      console.log('dashboardElements, firebaseActions');
      let dashboard: DashboardData  = dashboardElementsDoc.data() as DashboardData;

      Object.keys(dashboard.elements as any).forEach((key)=>{
        let dashboardElement = new DashboardElement(((dashboard.elements as any)[key]) as DashboardElementData);
        dashboardElements.push(dashboardElement);
      });

  }else{
      console.log(`dashboardElements, firebaseActions, fetchDashboardElements, dashboardElements list is empty for businessId - ${businessId}`);
  }

  return dashboardElements;
}


export async function updateDashboardElements(businessId: string, dashboardElements: DashboardElement[]){

  console.log('firebaseActions, updateDashboardElements, businessId - '+ businessId);

  let dashboardElementsCollectionRef =  firestore.collection('businesses').doc(businessId).collection('dashboards');
  let dashboardElementsRef = dashboardElementsCollectionRef.doc('default');

  let data: DashboardData = {elements:{ }};
  dashboardElements.map((item)=>{
    (data.elements as any)[item.type]= item.serialize();
  });

  console.log(JSON.stringify(data));
  await dashboardElementsRef.update(data);
  return dashboardElements;
}

