import type from './types';
import { createProduct, updateProduct, deleteProduct } from './firebaseActions';
import { Business } from 'app/business';
import { Role } from 'app/userPermissions';
import _ from 'lodash';
import { trim } from 'app/shared';
import { RecordObject } from 'app/record';


export interface ProductData {
    businessId?: string;
    productId?: string;
    isPhysicalProduct?: boolean;

    name?: string;
    description?: string;
    picture?: string;
    vendor?: string;
    isPublished?: boolean;
    collection?: string;
    tags?: string[];

    price?: number;
    comparePrice?: number;
    cost?: number;
    showPrice?: boolean;
    quantity?: number;
    hasUnlimitedQuantity?: boolean;

    deletePicture?: string;
    newPictureFile?: null;

    showVariants?: boolean;
}

export class Product {
    object: RecordObject = RecordObject.product; 
    businessId: string;
    productId: string;
    isPhysicalProduct: boolean;

    name: string;
    description: string;
    picture: string;
    vendor: string;
    isPublished: boolean;
    collection: string;
    tags: string[]

    price: number;
    comparePrice: number;
    cost: number;
    showPrice: boolean;

    quantity: number;
    hasUnlimitedQuantity: boolean;
    deletePicture: string;
    newPictureFile: any;

    showVariants: boolean;

    

    constructor(productData: ProductData){
        console.log('Product initialized');

        this.businessId = (productData.businessId)?trim(productData.businessId): '';
        this.productId = (productData.productId)?trim(productData.productId): '';
        this.isPhysicalProduct = (productData.isPhysicalProduct)?productData.isPhysicalProduct: false;

        this.name = (productData.name)?trim(productData.name):'';
        this.description = (productData.description)?trim(productData.description):'';
        this.picture = (productData.picture)?trim(productData.picture):'';
        this.vendor = (productData.vendor)?trim(productData.vendor):'';
        this.isPublished = productData.isPublished || false;
        this.collection = (productData.collection)?trim(productData.collection):'';
        this.tags = productData.tags || [];


        this.price = (productData.price)?productData.price:0;
        this.comparePrice = (productData.comparePrice)?productData.comparePrice:0;
        this.cost = (productData.cost)?productData.cost:0;
        this.showPrice = productData.showPrice || false;

        this.quantity = productData.quantity || 0;
        this.hasUnlimitedQuantity = productData.hasUnlimitedQuantity || false;

        this.deletePicture = productData.deletePicture || '';
        this.newPictureFile = productData.newPictureFile || null;

        this.showVariants = productData.showVariants || false;
    }

    isDifferent(product: Product){        
        return (!_.isEqual(this, product));
    }

    serialize(){
        return {
            isPhysicalProduct: this.isPhysicalProduct,
            
            name: this.name, 
            description: this.description,
            picture: this.picture,
            vendor: this.vendor,
            isPublished: this.isPublished,
            collection: this.collection,
            tags: this.tags,

            price: this.price,
            comparePrice: this.comparePrice,
            cost: this.cost,
            showPrice: this.showPrice,

            quantity: this.quantity,
            hasUnlimitedQuantity: this.hasUnlimitedQuantity,

            showVariants: this.showVariants
        }
    }

    copy(product: Product){
        Object.assign(this, product);
    }
   
    async create (businessId: string){
        await createProduct(businessId, this);
    }

    async update(businessId: string){
        await updateProduct(businessId, this);
    }

    async delete(businessId: string){
        await deleteProduct(businessId, this);
    }

}


export type ProductStateData = {
    state?: string;
    productList?: Product[];
}

export class ProductState {
    state: string;
    productList: Product[];
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(productStateData: ProductStateData){
        console.log('ProductState initialized');

        this.state = productStateData.state || type.PRODUCT_INITIALIZED;
        this.productList = productStateData.productList || [];

        this.canCreate = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }
    }
}
