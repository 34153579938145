import React, {  } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthState, type } from './user'

interface Props{
  path: string;
  component: React.ComponentType<any>;
  auth?: AuthState;
}

const PublicRoute = ({auth, component: Component, ...rest}: Props) => {
  console.log(`PublicRoute ... auth state - ${auth?.state}, path- ${rest.path}`);

  let location = useLocation<{from: {pathname: string}}>();
  console.log(`PublicRoute, location - ${JSON.stringify(location)}`);


  return ( 
    <Route  {...rest} render={(props) => (
      auth?.state !== type.SIGNED_IN
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/selectBusiness', state: props.location.state }} />
    )} />
  );

  /*if (props.auth && props.auth.state !== type.SIGNED_IN){
    return <Component {...props} />
  }else{
    return <Redirect to={{
      pathname: '/dashboard',
      state: { from: props }
    }} />
  }*/
}

const mapStateToProps = (state: { auth: AuthState }) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(PublicRoute);
