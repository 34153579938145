import { displayDate } from 'app/shared/utilities';
import _ from 'lodash';
import { Component } from 'react';
import { Col, Row  } from 'react-bootstrap';
import { displayTime } from '../shared';

import { BusinessHours, SpecialHour } from './businessHours';
import SpecialHoursInputDialog from './specialHoursInputDialog';


export interface Props {
  specialHours: SpecialHour[],
  editable: boolean,
  onChange: (specialHours: SpecialHour[]) => void
}

export interface State {
  editBusinessHours: boolean;
  specialHours: SpecialHour[];
}

export class ShowSpecialHours extends Component<Props, State>{

  constructor(props: Props){
    super(props);
    console.log('ShowSpecialHours initialized');

    this.state = {
      editBusinessHours: false,
      specialHours: new BusinessHours({specialHours: this.props.specialHours}).specialHours
    }

  }

  componentDidUpdate(previoursProps: Props){
    if(!_.isEqual(previoursProps.specialHours, this.props.specialHours)){
      
      let specialHours = new BusinessHours({specialHours: this.props.specialHours}).specialHours;
      
      this.setState({ specialHours: specialHours})
    }
  }

  render(){

  return (
        this.renderShowSpecialHours()
   );
  }

  renderShowSpecialHours() {
    let specialHours = this.state.specialHours;
    
    return (

          <div>
            <div className="py-4">
              <div>
                
                <h4> Special Hours <i style={{ display: (this.props.editable ? 'block-inline' : 'none') }} className="ti-pencil menu-icon pr-2 pr-sm-4" onClick={(e)=> { e.stopPropagation(); this.onEditClick()}}></i> </h4>
                       
              </div>
              {
                (specialHours.length > 0 ? this.renderSpecialHours() : this.renderSetupSpecialHours())
                
              }
            </div>   
            <SpecialHoursInputDialog 
                show={this.state.editBusinessHours} 
                specialHours={specialHours} 
                onHide={() => {this.setState({editBusinessHours: false})}} 
                onCancel={() => {this.setState({editBusinessHours: false})}} 
                onSave={(specialHours) => this.onChange(specialHours)}              
            />
          </div>  
    )
  }

  renderSetupSpecialHours(){
    return(
        <Row className="py-1">
              <Col xs={12}>
                <span className="float-left text-muted">
                  Specify special hours if needed
                </span>
              </Col>
        </Row>
    );
  }

  renderSpecialHours(){
    return (
      <div>
        {
          this.state.specialHours.map((specialHour: SpecialHour) => {
            return (
              <Row className="py-1">
                <Col xs={3} xl={3}>
                  <span className="float-left">
                    {`${displayDate(specialHour.date)}`}
                  </span>
                </Col>
                <Col xs={9} xl={9}>
                  <span className=" text-muted">
                    {(specialHour.isClosed)?'Closed': `Opens ${displayTime(specialHour.openTime)} Closes ${displayTime(specialHour.closeTime)}`}
                  </span>
                </Col>
              </Row>
            );
          })
        }
      </div>   

    );
  }

  onEditClick(){
    console.log('ShowSpecialHours, onEditClick');
    this.setState({editBusinessHours: true});
  }
 
    
  onChange(specialHours: SpecialHour[]){
    console.log('ShowSpecialHours, onChange');
    this.setState({editBusinessHours: false});
    this.props.onChange(specialHours);
  }
}
