import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, Button  } from 'react-bootstrap';

import { Inquiry } from './inquiry';
import { PaymentState, SKU, SKUName, SKUStatus } from 'app/billing/payment';
import { ErrorCode } from 'app/business/types';
import { SKUStatusDialog } from 'app/shared';

export interface Props {
  show: boolean,
  inquiry: Inquiry | null | undefined,
  title?: string, 
  paymentState: PaymentState,
  onHide: () => void,
  onCancel: () => void, 
  onSave: (inquiry: Inquiry) => void,
  errorCode?: ErrorCode | '',
  saveInProgress?: boolean
 }
 
 export interface State {
   validated: boolean,
   inquiry: any
 }

export class InquiryInputDialog extends Component<Props, State> {
  inquiry: Inquiry;
  title: string;
  isEditDialog: boolean;
  

  constructor(props: Props){
    super(props);
    console.log('BusinessProfile initialized');
    this.isEditDialog = (this.props.inquiry)?true:false;
    this.inquiry = (this.props.inquiry)? this.props.inquiry : new Inquiry({name: '', phoneNumber: '', emailAddress: ''});
    this.title = (this.props.title)? this.props.title: ((this.props.inquiry)? 'Edit inquiry':'Create a new inquiry');
  
    this.state = {
      validated: false,
      inquiry: {
        name:{
          placeholder: 'Enter customer name',
          value: this.inquiry.name,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
        },
        phoneNumber:{
          placeholder: 'Enter customer phone number',
          value: this.inquiry.phoneNumber,
          type: 'tel',
          pattern:'[0-9]{1,}',
          required: true,
          errorText: '',
        }, 
        emailAddress:{
          placeholder: 'Enter customer email address',
          value: this.inquiry.emailAddress,
          type: 'email',
          required: true,
          errorText: '',
         },
         inquiryMessage:{
          placeholder: 'Enter message',
          value: this.inquiry.inquiryMessage,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
        },
        inquiryInternalNotes:{
          placeholder: 'Enter internal notes',
          value: this.inquiry.inquiryInternalNotes,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
        },
        inquiryStatus:{
          placeholder: 'Provide inquiry status',
          value: this.inquiry.inquiryStatus,
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: true,
          errorText: '',
        },
      }
    }
  }

  componentDidMount(){
    this.inquiry = (this.props.inquiry)? this.props.inquiry : new Inquiry({name: '', phoneNumber: '', emailAddress: ''});
    let inquiryState = {
      inquiryStatus: {
        value: this.inquiry.inquiryStatus
      }, 
    }
    this.setState({inquiry: Object.assign(this.state.inquiry, inquiryState)});
  }


  render() {
    let inquiriesSKU = this.props.paymentState.getSKU(SKUName.inquiries);

      if ((inquiriesSKU.status === SKUStatus.active) || 
          (inquiriesSKU.status === SKUStatus.maxLimitReached && this.isEditDialog)){
        return this.renderForm();
      }else{
        return this.renderStatusMessage(inquiriesSKU);
      }
  }

  renderForm() {
    let inquiry = this.state.inquiry;
    let self = this;
    return (
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >

        <Modal.Header closeButton>
          {this.title}
        </Modal.Header>

        <Modal.Body>

              <Form className="form-sample" id="inquiryInfo"  noValidate validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
                <div className="py-2 row">
                  <Form.Group className="col-md-12">
                    <Form.Control type={inquiry.name.type} name="name" pattern={inquiry.name.pattern} required={inquiry.name.required} placeholder={inquiry.name.placeholder} onChange={e => self.onChange(e)} value = {inquiry.name.value} readOnly = {inquiry.name.readOnly}/>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                    <Form.Control type={inquiry.phoneNumber.type} name='phoneNumber' pattern={inquiry.phoneNumber.pattern} required={inquiry.phoneNumber.required} placeholder={inquiry.phoneNumber.placeholder} onChange={e => self.onChange(e)} value = {inquiry.phoneNumber.value} readOnly = {inquiry.phoneNumber.readOnly}/>
                  </Form.Group>
          
                  <Form.Group className="col-md-12">
                    <Form.Control type={inquiry.emailAddress.type} name='emailAddress' pattern={inquiry.emailAddress.pattern} required={inquiry.emailAddress.required} placeholder={inquiry.emailAddress.placeholder} onChange={e => self.onChange(e)} value = {inquiry.emailAddress.value} readOnly = {inquiry.emailAddress.readOnly}/>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                    <Form.Control className="form-control" as="textarea" rows={4} name="inquiryMessage" required={inquiry.inquiryMessage.required} placeholder={inquiry.inquiryMessage.placeholder} onChange={e => self.onChange(e)} value = {inquiry.inquiryMessage.value} readOnly = {inquiry.inquiryMessage.readOnly}/>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                    <Form.Control className="form-control" as="textarea" rows={4} name="inquiryInternalNotes"  required={inquiry.inquiryInternalNotes.required} placeholder={inquiry.inquiryInternalNotes.placeholder} onChange={e => self.onChange(e)} value = {inquiry.inquiryInternalNotes.value} readOnly = {inquiry.inquiryInternalNotes.readOnly}/>
                  </Form.Group>

                  <Form.Group className="col-md-12">
                    <Form.Control as="select" name='inquiryStatus'  required={inquiry.inquiryStatus.required} placeholder={inquiry.inquiryStatus.placeholder} onChange={e => self.onChange(e)} defaultValue='Unread' value = {inquiry.inquiryStatus.value} readOnly = {inquiry.inquiryStatus.readOnly}>
                      <option value="Unread">Unread</option>
                      <option value="Read">Read</option>
                      <option value="FollowUp">FollowUp</option>
                    </Form.Control>
                  </Form.Group>

                </div>
              </Form>

        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="light btn-sm m-2" onClick={() => this.props.onCancel()}>Cancel</Button>
          <button type="submit" form="inquiryInfo"  className="btn btn-primary btn-sm">Save</button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderStatusMessage(sku: SKU){
    return(
      <SKUStatusDialog title={this.title} show={this.props.show} onHide={() => this.props.onHide()} onCancel={() => this.props.onCancel()} sku={sku}/>
    );
  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>){
    console.log('CreateInquiry, onChange');

    let inquiry = this.state.inquiry;
    let key = e.target.name;

    inquiry[key].value = e.target.value;
    this.setState({inquiry:inquiry});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('CreateInquiry, onSubmit');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let inquiry =  this.getFormData();
      console.log(JSON.stringify(inquiry));

      this.setState({validated: false});
      this.props.onSave(inquiry)
    }
  }

  getFormData(){

 
    let inquiryObj = Object.keys(this.state.inquiry).reduce((acc:any, key: string) =>{
      acc[key] = this.state.inquiry[key].value;
      return acc;
    }, {});


    return new Inquiry({...this.inquiry, ...inquiryObj}); 
  }

}


const mapStateToProps = (state: { auth: any; business: any; pageLinks: any; billing: any}) => {
  return {
    paymentState: state.billing
  };
};

const mapDispatchToProps = () => {
  return {
    
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(InquiryInputDialog));