import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import StepWizard  from 'react-step-wizard';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import countryTelData, { Country } from 'country-telephone-data';

import type from './types';
import { Business, BusinessState } from './business';
import { initiateCreateBusiness, setSelectedBusiness } from './actions';
import { Form, InputGroup } from 'react-bootstrap';
import { AuthState } from 'app/user';
import { Role } from 'app/userPermissions';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './business.css';
import ReactTags, { Tag } from 'react-tag-autocomplete'

export interface Props {
  history: RouteComponentProps["history"],
  authState: AuthState,
  businessState: BusinessState,
  initiateCreateBusiness: (business: Business, onSuccess: any, onError: any) => void
  setSelectedBusiness: (businessId: string) => void
}

export interface State {
  business: Business,
  createInProgress: boolean
}

export class CreateBusiness extends Component<Props, State> {

  constructor(props: Readonly<Props>){
    super(props);
    console.log('CreateBusiness initialized');

    this.state = {
      business: new Business({}),
      createInProgress: false
    }
  }

  render(){

    let render;

    if(this.props.businessState.state == type.INITIALIZED){
        //render = <Spinner/>
        render = this.createBusiness();
    }else{
       render = this.createBusiness();
    }
    return render;
  }

  createBusiness() {
    return (
      <div>
        <div className="d-flex justify-content-center p-5">
          <div className="w-50 mx-0">
            <div className="mx-auto">
              <div className="text-left py-5 px-4 px-sm-5">
                <h4>Let's get started setting up your business</h4>
                <h6>This is going to be quick!</h6>
                <LoadingOverlay
                    active={this.state.createInProgress}
                    spinner
                    text='Creating ...'
                    >
                    <StepWizard>
                      <BusinessInfo onNext={(data: any) => this.onNext(data)}/>  
                      <BusinessContact onCreate={(data: any) => this.onCreate(data)}/>
                    </StepWizard>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onNext(data: any){
    let business = Object.assign({}, this.state.business, data);
    this.setState({business: business});
  }

  onCreate(data: any){
    let self = this;
    let business = new Business(Object.assign({}, this.state.business, data, {userPermission:{role: Role.Admin}}));
    this.setState({business: business, createInProgress: true});
    this.props.initiateCreateBusiness(business, 
      (business: Business)=> {
        console.log(`CreateBusiness onCreate, successful`);
        setTimeout(() => {
          this.setState({createInProgress: false});
          self.props.setSelectedBusiness(business.businessId);
          self.props.history.push('/');
        }, 3500); // give some time for backend cloud functions to complete creation process
      }, 
      (e: any) =>{
        console.log(`CreateBusiness onCreate, failed, error-${JSON.stringify(e)}`);
      }
    );
  }

}

const mapStateToProps = (state: { auth: AuthState; business: BusinessState; }) => {
  return { 
          authState: state.auth,
          businessState: state.business 
        };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId?: string; userId?: string; }) => void) => {
  return {
    initiateCreateBusiness: (business: Business, onSuccess: any, onError: any) => {
      dispatch(initiateCreateBusiness(business, onSuccess, onError))
    },
    setSelectedBusiness: (businessId: string) => {
      dispatch(setSelectedBusiness(businessId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBusiness);


export interface BusinessInfoProps {
  nextStep?: any
  onNext: (data: any) => void
}

export interface BsinessInfoState {
  state: boolean;
  validated: boolean,
  business: any
}

class BusinessInfo extends Component<BusinessInfoProps, BsinessInfoState> {
  reactTags: any;

  constructor(props: BusinessInfoProps | Readonly<BusinessInfoProps>){
    super(props);
    console.log('BusinessInfo initialized');
    this.reactTags = createRef()

    this.state = {
      state: false,
      validated: false,
      business: {
        name:{
          placeholder: 'Enter business name',
          value: '',
          type: 'text',
          pattern:'[a-zA-Z0-9 &]{3,32}',
          required: true,
          errorText: '',
        },
        description: {
          placeholder: 'Tell what you do for others to recognize you',
          value: '',
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,32}',
          required: false,
          errorText: '',
        },
        type: {
          placeholder: 'Select type of business',
          value: '',
          type: 'text',
          pattern:'[a-zA-Z]{1,32}',
          required: true,
          errorText: '',
        },
        keywords: {
          placeholder: 'Enter search keywords',
          value: [],
          type: 'text',
          pattern:'[a-zA-Z0-9& ]{1,14}',
          required: false,
          errorText: '',
        },
      }
    }
  }

  render(){
    let business = this.state.business;
    let self = this;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <Form className="form-sample" id="businessInfo1"  validated={this.state.validated} onSubmit={this.onSave.bind(this)}>
              <div className="py-2 row">
                <Form.Group className="col-12">
                  <Form.Control type={business.name.type} name="name" pattern={business.name.pattern} required={business.name.required} placeholder={business.name.placeholder} onChange={e => self.onChange(e)} value = {business.name.value} readOnly = {business.name.readOnly}/>
                </Form.Group>
                <Form.Group className="col-12">
                  <Form.Control className="form-control" as="textarea" rows={4} name="description" required={business.description.required} placeholder={business.description.placeholder} onChange={e => self.onChange(e)} value = {business.description.value} readOnly = {business.description.readOnly}/>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="col-12 col-md-5">
                  <Form.Control className="form-control" as="select" name="type"  onChange={e => self.onChange(e)} value = {business.type.value} readOnly = {business.type.readOnly}>
                    <option>Online</option>
                    <option>Instore</option>
                    <option>Online & Instore</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="col-12 col-md-7">
                  <ReactTags
                    ref={this.reactTags}
                    tags={business.keywords.value}
                    onDelete={(i) => {this.onTagDelete(i)}}
                    onAddition={(tag) => {this.onTagAdd(tag)}}
                    allowNew
                    placeholderText='Search Tags'
                    inputAttributes={{required: false}}
                  />
                </Form.Group> 
              </div>                                                                     
            </Form>
          

          <div className="d-flex justify-content-end ">
            <button type="submit" form="businessInfo1" className="btn btn-primary btn-sm" onClick={(e) => this.onSave(e)}>Next</button>
          </div>
          </div>
        </div>
      </div>
    );

  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>){
    console.log('BusinessInfo, onChange');

    let business = this.state.business;
    let [parentKey, childKey] = e.target.name.split('.');

    if (childKey && childKey !== null){
      business[parentKey][childKey].value = e.target.value;
    }else{
      business[parentKey].value = e.target.value;
    }
    
    this.setState({business:business});
  }

  onTagAdd(tag: Tag){
    console.log(`onTagAdd, tag, ${JSON.stringify(tag)}`);
    let business = this.state.business;
    business['keywords'].value.push({id: tag.id, name:tag.name.toLowerCase()});
    this.setState({business: business});
  }

  onTagDelete(i: number){
    console.log(`onTagDelete, index, ${JSON.stringify(i)}`);
    let business = this.state.business;
    business['keywords'].value = business.keywords.value.filter((tag: any, index: number) => index !== i)
    this.setState({business: business});
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('BusinessInfo, onSave');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget.form;
    this.setState({validated: true});
    if (form.checkValidity()) {

      let data =  this.getFormData();
      console.log(JSON.stringify(data));
      this.props.onNext(data);
      this.props.nextStep();
      // reset 
      

      this.setState({validated: false});
    }else{
      console.log('checkvalidity is true')
    }

  }

  getFormData(){

    let businessObj = Object.keys(this.state.business).reduce((acc: any, key: string) =>{
      if (key === 'keywords'){
        acc[key] = this.state.business[key].value.map((keyword: Tag ) => {return keyword.name})
      }else{
        acc[key] = this.state.business[key].value;
      }
      return acc;
    }, {});  

    return businessObj; 
  }
}

export interface BusinessContactProps {
  previousStep?: any
  onCreate: (data: any) => void
}

export interface BsinessContactState {
  state: boolean;
  validated: boolean,
  business: any
}

class BusinessContact extends Component<BusinessContactProps, BsinessContactState> {

  constructor(props: BusinessContactProps | Readonly<BusinessContactProps>){
    super(props);
    console.log('BusinessContact initialized');

    this.state = {
      state: false,
      validated: false,
      business: {
        country:{
          placeholder: 'Select a country',
          value: [],
          type: 'text',
          required: true,
          errorText: 'Invalid country ',
          dirtyFlag: false
        },
        countryCode:{
          placeholder: 'Country code',
          value: '',
          type: 'text',
          required: true,
          errorText: 'Invalid country code',
        },
        phoneNumber:{
          placeholder: 'Enter phone number',
          value: '',
          type: 'tel',
          pattern:'[0-9]{1,}',
          required: true,
          errorText: '',
        },
        emailAddress:{
          placeholder: 'Enter email address',
          value: '',
          type: 'email',
          required: true,
          errorText: '',
         },
      }
    }
  }

  render(){
    let business = this.state.business;
    let self = this;

    return (
      <div>
        <div className="card">
          <div className="card-body">
            <Form className="form-sample" id="businessContact"   validated={this.state.validated} onSubmit={this.onSave.bind(this)}>                                                      
            
              <div className="py-2 row">
                <InputGroup className="col-6 mb-3">
                  <InputGroup.Text id="basic-addon1">{`+`}</InputGroup.Text>
                  <Typeahead
                    id="country-code-dropdown"
                    isInvalid={business['country'].value.length === 0}
                    labelKey={option => this.onLabelKey(option)}
                    options={countryTelData.allCountries}
                    placeholder={business.country.placeholder}
                    onChange={(e) => this.onCountryChange(e)}
                    onInputChange={(text, e) => this.onInputChange(text, e)}
                    selected={business.country.value}
                    renderInput={({ inputRef, referenceElementRef,...inputProps }: any) => (
                      <Form.Control
                        {...inputProps}
                        required={true}
                        isInvalid={business.country.dirtyFlag}
                        pattern={business.country.dirtyFlag?"___":".*"}
                        ref={(input: any) => {
                          // Be sure to correctly handle these refs. In many cases, both can simply receive
                          // the underlying input node, but `referenceElementRef can receive a wrapper node if
                          // your custom input is more complex (See TypeaheadInputMulti for an example).
                          inputRef(input);
                          referenceElementRef(input);
                        }}
                      />
                    )}
                    
                  />
                  <Form.Control.Feedback type='invalid'> {business.country.errorText} </Form.Control.Feedback>    
                </InputGroup>

                <Form.Group className="col-6 mb-3">
                  <Form.Control type={business.phoneNumber.type} name='phoneNumber' pattern={business.phoneNumber.pattern} required={business.phoneNumber.required} placeholder={business.phoneNumber.placeholder} onChange={e => self.onChange(e)} value = {business.phoneNumber.value} readOnly = {business.phoneNumber.readOnly}/>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="col-12">
                  <Form.Control type={business.emailAddress.type} name='emailAddress' pattern={business.emailAddress.pattern} required={business.emailAddress.required} placeholder={business.emailAddress.placeholder} onChange={e => self.onChange(e)} value = {business.emailAddress.value} readOnly = {business.emailAddress.readOnly}/>
                </Form.Group>
              </div>           
            </Form>
          
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.props.previousStep}>Back</button>
              <button type="submit" form="businessContact" className="btn btn-primary btn-sm" onClick={(e) => this.onSave(e)}>Create</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>){
    console.log('BusinessInfo, onChange');

    let business = this.state.business;
    let [parentKey, childKey] = e.target.name.split('.');

    if (childKey && childKey !== null){
      business[parentKey][childKey].value = e.target.value;
    }else{
      business[parentKey].value = e.target.value;
    }
    
    this.setState({business:business});
  }

  onCountryChange(e: Country[]){
    console.log(JSON.stringify(e));
    let business = this.state.business;
    if (e[0]){
      business['country'].value = e;
      business['country'].dirtyFlag = false;
      business['countryCode'].value = e[0].dialCode;
    }else{
      business['country'].value = e;
    }
    this.setState({business: business});
  }

  onInputChange(text: string, e:Event){
    console.log(`onInputChange, text, ${JSON.stringify(text)}`);
    let business = this.state.business;
    business['country'].dirtyFlag = true;
    this.setState({business: business});
  }

  onLabelKey(option: { name: string; dialCode: any; }){
    
    if (option.name && option.dialCode){
      let removeIndex = option.name.indexOf('('); 
      if (removeIndex >0){
        return `${option.dialCode} ${option.name.substring(0, removeIndex)}`
      }else{                     
        return `${option.dialCode} ${option.name}`
      }
    }else{
      return ''
    }
  }

  onSave(e: { preventDefault: () => void; stopPropagation: () => void; currentTarget: any; }){
    console.log('BusinessInfo, onSave');
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget.form;
    this.setState({validated: true});

    if (form.checkValidity()) {

      let data =  this.getFormData();
      console.log(JSON.stringify(data));
      this.props.onCreate(data);
      // reset 
      

      this.setState({validated: false});
    }

  }

  getFormData(){

    let businessObj = Object.keys(this.state.business).reduce((acc: any, key: string) =>{
      if (key === 'country'){
        acc[key] = this.state.business[key].value[0];
      }else {
        acc[key] = this.state.business[key].value;
      }
      return acc;
    }, {});  

    return businessObj; 
  }

}