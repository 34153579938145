import type from './types';
import { Business } from "app/business";
import { Role } from 'app/userPermissions';
import { makeid } from 'app/shared';
import { AvailablePageProperty } from './pageProperty';
import { Domain } from './domain';
import { PageSettings } from './pageSettings';

export enum PageLinkType {
    default = 'default',
    custome = 'custome'
}

export enum PageLinkLocation {
    header = 'header',
    footer = 'footer'
}

export type PageLinkData = {
    pageLinkId?: string;
    type?: PageLinkType;
    label?: string;
    path?: string;
    isEnabled?: boolean;
    position?: number;
    location?: PageLinkLocation
}


export class PageLink{
    pageLinkId: string;
    type: PageLinkType;
    label: string;
    path: string;
    isEnabled: boolean;
    position: number;
    location: PageLinkLocation



    constructor(pageLinkData: PageLinkData){
        this.pageLinkId = pageLinkData.pageLinkId || '';
        this.type = pageLinkData.type || PageLinkType.custome;
        this.label = pageLinkData.label || '';
        this.path = pageLinkData.path || '';
        this.isEnabled = (pageLinkData.isEnabled)? pageLinkData.isEnabled: false ;
        if(pageLinkData.position){
            this.position = pageLinkData.position
        }else{
            this.position = 0;
        }
        
        this.location = pageLinkData.location || PageLinkLocation.header;
    }

    copy(pageLink: PageLink){
        Object.assign(this, pageLink);
    }

    isDifferent(pageLink: PageLink){
        return true;
    }

    serialize(){
        return {
            pageLinkId: this.pageLinkId,
            type: this.type,
            label: this.label,
            path: this.path,
            isEnabled: this.isEnabled,
            position: this.position,
            location: this.location
        }
    }
}

export type PageLinksData = {

    headerPageLinks?: PageLink[];
    footerPageLinks?: PageLink[];
}

export class PageLinks {

    headerPageLinks: PageLink[] = [];
    footerPageLinks: PageLink[] = [];

    constructor(pageLinksData: PageLinksData){

        if (pageLinksData.headerPageLinks){
            this.headerPageLinks = pageLinksData.headerPageLinks.map(pageLinkData => {
                return new PageLink(pageLinkData);
            })
        }

        if (pageLinksData.footerPageLinks){
            this.footerPageLinks = pageLinksData.footerPageLinks.map(pageLinkData => {
                return new PageLink(pageLinkData);
            })
        }


    }

    serialize(){
        console.log('PageLinks, serialize');
        return {
            
            headerPageLinks: this.headerPageLinks.map((pageLink: PageLink) =>{
                         
                return pageLink.serialize();
            }),

            footerPageLinks: this.footerPageLinks.map((pageLink: PageLink) =>{
                
                return pageLink.serialize();
            }),
            
        };
    }

    addDefaultPageLinks(){
        if (this.headerPageLinks.length === 0){
            this.headerPageLinks.push(this.getProductPageLink())
        }
    }

    getProductPageLink(){
        return  new PageLink({
                        pageLinkId: makeid(5),
                        type: PageLinkType.default,
                        label: 'Products',
                        path: 'products',
                        isEnabled: true,
                        position: 0,
                        location: PageLinkLocation.header
                    })
    }
}

export type PageLinksStateData = {
    state?: string, 
    pageLinks?: PageLinks, 
    availablePageProperty?: AvailablePageProperty,
    pageSettings?: PageSettings,
    domainList?: Domain[]
}

export class PageLinksState {
    state: string;
    pageLinks: PageLinks;
    availablePageProperty: AvailablePageProperty;
    pageSettings: PageSettings;
    domainList: Domain[];
    canCreate: (selectedBusiness: Business) => boolean;
    canEdit: (selectedBusiness: Business) => boolean;
    canDelete: (selectedBusiness: any) => boolean;

    constructor(pageLinksStateData: PageLinksStateData){
        console.log('PageLinksState initialized');

        this.state = pageLinksStateData.state || type.PAGE_LINKS_INITIALIZED;
        this.pageLinks = pageLinksStateData.pageLinks || new PageLinks({});
        this.availablePageProperty = pageLinksStateData.availablePageProperty || new AvailablePageProperty({availablePageProperty: []});
        this.pageSettings = pageLinksStateData.pageSettings || new PageSettings({});
        this.domainList = pageLinksStateData.domainList || [];

        this.canCreate = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }

        this.canEdit = function (selectedBusiness){
            return ((selectedBusiness.userPermission.role === Role.Admin) || (selectedBusiness.userPermission.role === Role.Editor));
        }

        this.canDelete = function(selectedBusiness){
            return this.canEdit(selectedBusiness);
        }
    }
}