
import { settings } from 'app/config';
import { format } from 'date-fns';
import countryTelData from 'country-telephone-data';
import { getStoreState } from 'index.js';
import { AuthState } from 'app/user';
import _ from 'lodash';

export const toSentenceCase = (theString: string) => {
	var newString = theString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
  return newString;
}

export const isChars = (string: string) => {
    const regex = /[A-Za-z]/g;
    const found = string.match(regex);
    return (found != null)? true : false;
}

export const isNumbers = (string: string) => {
    const regex = /[0-9]/g;
    const found = string.match(regex);
    return (found != null)? true : false;
}

export const isEmailAddress = (string: string) => {
    const regex = /[@]/g;
    const found = string.match(regex);
    return (found != null)? true : false;
}

export function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj
  }


export function makeid(length: number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return result;
}

export function getConfirmationText() {
    return Math.random().toString(36).substr(2, 5);
}

export function displayTime(time: number){
    return format(time, "hh:mm aaaaa'm'") // 2:30 pm
}


export function displayDate(date: number){
    return format(date, 'MM/dd/yy') // 02/07/2022
}

export function displaySince(since: number){
    let date = new Date(since);
    let sameYear = (date.getFullYear() == new Date().getFullYear());
    let sameday = (date.getDate() == new Date().getDate());
    let displayString = '';

    if (!sameYear){
        displayString = format(date, 'MM/dd/yy'); // MM/DD/YY
    }else if (!sameday){ 
        displayString = format(date, 'MMM d'); // Jan 20
    } else{
        displayString = format(date, "hh:mm aaaaa'm'") // 2:30 pm
    }
    return displayString; 
}

export const imageLoader = (params: {src: string, width:string|number|undefined}) => {
    const {src, width} = params;
    return `${settings.storage}${src}?alt=media&w=${width}&q=${75}`
}

export const getCountryData = ({countryISO2Name, dialCode}: {countryISO2Name?: string, dialCode?: string}) => {

    if (countryISO2Name){
        return countryTelData.allCountries.find (country => {
            return (countryISO2Name === country.iso2);
        })
    }else if (dialCode){
        return countryTelData.allCountries.find (country => {
            return (dialCode === country.dialCode);
        })
    }else{
        return undefined;
    }
}

export const getCurrentUser = () => { 
    return (getStoreState().auth as AuthState).user;
}

export const normalizeUrl = (url: string|undefined) => {

    let link = _.toLower(url?url:'');
    if (link.startsWith('https://') || link.startsWith('http://')){

    }else{
        link = 'https://' + link;
    }
    return link;
}

export const getFileExtension = (fileName: string) => {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function trim(string: string){
    return _.trim(string);
}