import React, { Component } from 'react';
import './customers.css';
import { Customer } from './customer';
import { CustomerListItem } from './customerListItem';

export interface Props {
  businessId: string,
  customerList: Customer[],
  canEdit: boolean,
}

export interface State {
  
}

export class CustomerList extends Component<Props, State> {
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('CustomerList initialized');
  }

  render() {
    let { businessId, customerList, canEdit } = this.props;

    return (
      <div>
        {
          customerList.map((item)=>{
            return (
              <CustomerListItem 
                key={item.customerId}
                businessId={businessId}
                customer={item}
                canEdit={canEdit}
                onDelete={this.onDelete.bind(this)}
              />
            );
          })
        }
      </div>
    )
  }

  onDelete(customerId: string){
      //customer is deleted, do any clean up if needed.
  }
}
