
import React, { Component } from 'react';
import _ from 'lodash';
import HoursText from './hoursText';
import AddressText from './addressText';
import BusinessName from './businessName';
import BusinessProfileIcon from './businessProfileIcon';
import PhoneNumberText from './phoneNumberText';
import EmailAddressText from './emailAddressText';
import { Business } from 'app/business';


export interface Props {
  business: Business,
}

export default class BusinessContact extends Component<Props> {
    static propTypes: {};

    constructor(props: Props){
      super(props)
    }

    render(){
        let {countryCode, phoneNumber, emailAddress, address } = this.props.business;
       
        return (
              <div className="d-flex flex-column justify-content-start p-2">
                <div className="d-flex flex-column align-items-start">
                        <PhoneNumberText countryCode={countryCode} phoneNumber={phoneNumber}/>
                        <EmailAddressText emailAddress={emailAddress}/>
                        <AddressText address={address}/>
                </div> 
            </div>
        );
    }
}