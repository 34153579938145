import { Component } from 'react';
import { connect } from 'react-redux';
import { alert, ConfirmationDialog, IconButton, WarningMessage } from '../shared';
import { Spinner } from '../shared/Spinner';
import { loadAPISettings, rotateAPISecret } from "./actions";
import types from './types';
import { APISettingState, OAuth } from './apiSetting';
import { BusinessState } from '../business';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import { mdiContentCopy, mdiReload, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { Redirect } from 'react-router-dom';

export interface Props {
  apiSettingState: APISettingState,
  businessState: BusinessState,
  businessId: string
  loadAPISettings: (businessId: string) => void
  rotateAPISecret: (businessId: string, clientId: string, onSuccess: ()=> void, onError: ()=> void) => void
}

export interface State {
  rotateSecretForClientId: string,
  showRotateSecretConfirmationDialog: boolean,
  showSecret: boolean
}

export class APISettingsPage extends Component <Props, State>{
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('APISettingsPage initialized');
  
    this.state = {
      rotateSecretForClientId: '',
      showRotateSecretConfirmationDialog: false,
      showSecret: false
    }
    this.props.loadAPISettings(this.props.businessId);
  }

  render(){
    let component = null;
    if (!this.props.businessState.canAdminister()){
      component = <Redirect to='/dashboard'/>;
    }else if(this.props.apiSettingState.state === types.API_SETTINGS_INITIALIZED){
      component = <Spinner/>;
    }else if (this.props.apiSettingState.state === types.API_SETTINGS_LOADED){
      if(this.props.apiSettingState.apiSetting.oAuth.length > 0){
        component = this.renderUserPermissionList();
      }else{
        component = this.renderAPISettingsNotFound("API settings not available, please contact us to resolve this");
      }
    }else{
      component = this.renderAPISettingsNotFound("You don't have privilege to manage API settings");
    }
    return component;
  }


  renderUserPermissionList() {
    let self = this;
    let canRotateSecret = this.props.apiSettingState.canRotateSecret(this.props.businessState.selectedBusiness);
    
    return (
    
      <div>
        <div className="row">
          <div className="col-xl-1 grid-margin"/>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-8 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>API Settings for {this.props.businessState.selectedBusiness.name}. </h4>
                  </div>
                </div>
                  
                <div className="row">
                  <div className="col-12 grid-margin">
                    {
                      this.props.apiSettingState.apiSetting.oAuth.map((item: OAuth)=>{
                        return (
                          <div className="card">
                            <div className="card-header" style={{backgroundColor: 'white'}}>
                              <h5> API Keys</h5>
                            </div>
                            <div className="card-body p-4">

                              <Form className="form-sample" id="oAuth"  noValidate >
                                <div className="py-2 row">
                                  <Form.Group className="col-md-12">
                                    <Row className="align-items-center">
                                      <Col xs={12} sm={2} md={2}>
                                        <Form.Label >Client Id</Form.Label>
                                      </Col>
                                      <Col xs={12} sm={10} md={7}>
                                        <Row className="align-items-center justify-content-start">
                                          <Col xs={7} sm={8} md={8}>
                                            <Form.Control type="text" name="Client Id" value = {item.clientId} readOnly = {true} />
                                          </Col>
                                          <Col xs={5} sm={4} md={4} className="align-items-center justify-content-start pl-0">
                                            <IconButton 
                                              path={mdiContentCopy} 
                                              toolTipText="Copy to clipboard"
                                              onClick={()=> this.copyToClipboard(item.clientId)}
                                              />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form.Group>

                                  <Form.Group className="col-md-12">
                                    <Row className="align-items-center">
                                      <Col xs={12} sm={2} md={2}>
                                        <Form.Label >Client Secret</Form.Label>
                                      </Col>
                                      <Col xs={12} sm={10} md={7}>
                                        <Row className="align-items-center justify-content-start">
                                          <Col xs={7} sm={8} md={8}>
                                            <Form.Control style={{backgroundColor: "FFFFFF"}} type={this.state.showSecret?"text":"password"} name="Client Id" value = {item.clientSecret} readOnly = {true} />
                                          </Col>
                                          <Col xs={5} sm={4} md={4} className="align-items-center justify-content-start pl-0">
                                            
                                             <IconButton path={mdiContentCopy} toolTipText="Copy to clipboard" onClick={()=> this.copyToClipboard(item.clientSecret)} />
                                            {
                                              (canRotateSecret)
                                              ?
                                            
                                              <IconButton path={mdiReload} toolTipText="Rotate client secret" onClick={()=> this.setState({showRotateSecretConfirmationDialog: true, rotateSecretForClientId: item.clientId })} />
                                            
                                              :null
                                            }
                                            {
                                              (canRotateSecret)
                                              ?
                                             
                                              <IconButton path={this.state.showSecret?mdiEyeOffOutline:mdiEyeOutline} toolTipText="Toggle visibility" onClick={()=> this.setState({showSecret: !this.state.showSecret})} />
                                            
                                              :null
                                            }
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </div>
                              </Form>
                              <WarningMessage message="Never share your secret keys with anyone. If a secret key is compromised, please rotate it."/>
                            </div>
                          </div>
                          );
                          })
                        }
                      </div>
                    </div>
              </div>
            </div>
            <ConfirmationDialog 
            key={new Date().getMilliseconds()}
            id={this.state.rotateSecretForClientId} 
            title='Sure you want to rotate the secret ?'
            show={this.state.showRotateSecretConfirmationDialog} 
            onHide={() => this.setState({showRotateSecretConfirmationDialog: false, rotateSecretForClientId: '' })} 
            onCancel={() => this.setState({showRotateSecretConfirmationDialog: false, rotateSecretForClientId:''})} 
            onConfirm={(clientId: string) => this.onRotateSecretConfirmation(clientId)}/>
          </div>
    )
  }

  renderAPISettingsNotFound(message: string) {
      return (
    
        <div>
          <div className="row">
            <div className="col-xl-1 grid-margin"/>
            <div className="col-12 col-xl-10 grid-margin">

              <div className="row">
                <div className="col-12 col-md-8 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                  <h4>API Settings for {this.props.businessState.selectedBusiness.name}. </h4>
                </div>
              </div>
                
              <div className="row">
                <div className="col-12 grid-margin">

                  <div className="card h-100">
                    <div className="card-body p-3">
                      <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                        <h4 className="mb-4"> {message} </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
    )
  }

  onRotateSecretConfirmation(clientId: string){

    this.setState({showRotateSecretConfirmationDialog: false, rotateSecretForClientId:''})
    
    this.props.rotateAPISecret(this.props.businessId, clientId, 
      ()=>{
        //on success
        //alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })
  }

  copyToClipboard(text: string){
    navigator.clipboard.writeText(text);
  }
}


const mapStateToProps = (state: { auth: any; business: any; userPermission: any; apiSetting: any}) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    userPermissionState: state.userPermission,
    apiSettingState: state.apiSetting
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; clientId?: string; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadAPISettings: (businessId: string) =>{
      dispatch(loadAPISettings(businessId));
    },
    rotateAPISecret: (businessId: string, clientSecret: string, onSuccess: any, onError: any) => {
      dispatch(rotateAPISecret(businessId, clientSecret, onSuccess, onError))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(APISettingsPage);

