import { Component } from 'react';
import { connect } from 'react-redux';
import { alert } from '../shared';
import { Spinner } from '../shared/Spinner';
import InquiryInputDialog from './inquiryInputDialog';
import { loadInquiries, loadMoreInquiries, initiateSaveInquiry, initiateDeleteInquiry } from "./actions";
import types from './types';
import './inquiries.css';
import { BusinessState } from 'app/business';
import { AuthState } from 'app/user';
import { Inquiry, InquiryState } from './inquiry';
import { InquiryList } from './inquiryList';


export interface Props {
  authState: AuthState,
  businessState: BusinessState,
  inquiryState: InquiryState,
  businessId: string,
  loadInquiries: (businessId: string, searchText: string | undefined) => void,
  loadMoreInquiries: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) => void,
  initiateSaveInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => void,
  initiateDeleteInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => void
}

export interface State {
  editable: boolean,
  searchText: string,
  showInquiryDialog: boolean,
  selectedInquiry: Inquiry | null | undefined,
}

export class InquiryListPage extends Component<Props, State> {
  private _handleScroll: () => void;
  
  constructor(props: Readonly<Props>){
    super(props);
    console.log('InquiryList initialized');
    this._handleScroll = this.handleScroll.bind(this);

    this.state = {
      editable: this.props.inquiryState.canCreate(this.props.businessState.selectedBusiness),
      showInquiryDialog: false,
      selectedInquiry: null,
      searchText: '', 
    }
    this.props.loadInquiries(this.props.businessId, '');
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render(){
    let component = null;
    if(this.props.inquiryState.state === types.INQUIRY_INITIALIZED || this.props.inquiryState.state === types.LOAD_INQUIRIES){
      component = <Spinner/>;
    } else if (this.props.inquiryState.inquiryList.length > 0 || this.state.searchText !== ''){
      component = this.renderInquiryList();
    }else{
      component = this.renderCreateInquiry();
    }
    return component;
  }

  renderInquiryList() {
    let self = this;

    return (
    
      <div>
        <div className="row" onScroll={this.handleScroll}>
          <div className="col-xl-1 grid-margin"></div>
          <div className="col-12 col-xl-10 grid-margin">

                <div className="row">
                  <div className="col-12 col-md-3 grid-margin d-flex align-items-center justify-content-center justify-content-md-start"> 
                    <h4>Inquiries for {this.props.businessState.selectedBusiness.name} </h4>
                  </div>
                  <div className="col-12 col-md-6 grid-margin wrapper kanban-toolbar"> 
                    
                  </div>
                  <div className="col-0 col-md-3 grid-margin d-flex justify-content-end"> 
                    <button type="button" className="d-none d-md-block btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowInquiryDialog(null)}>Create</button>
                  </div>
                </div>

                <div className="d-md-none d-block fixed-bottom inquiry-fixed-create-button bg-primary rounded-circle d-flex justify-content-center align-items-center">
                  <i  className="ti-plus h4 font-weight-bolder text-white m-0 " style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowInquiryDialog(null)}></i>
                </div>
                
                
                <div className="row">
                  <div className="col-12">
                      <div className="table-responsive">
                        <InquiryList 
                          businessId={this.props.businessId}
                          inquiryList={this.props.inquiryState.inquiryList}
                          canEdit={this.props.inquiryState.canEdit(this.props.businessState.selectedBusiness)}
                        />
                      </div>
                    </div>
                </div>
          </div>
        </div>
        <InquiryInputDialog key={this.state.selectedInquiry?this.state.selectedInquiry.inquiryId:null} show={this.state.showInquiryDialog} inquiry={this.state.selectedInquiry} onHide={() => this.setState({showInquiryDialog: false, selectedInquiry: null})} onCancel={() => this.setState({showInquiryDialog: false})} onSave={(inquiry: Inquiry) => this.onSave(inquiry)}/>
      </div>
    )
  }

  renderCreateInquiry() {
    let self = this;

    return (
    
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center justify-content-center flex-wrap ">
                  <h4 className="mb-4"> Start creating inquirys. </h4>
                  <div className="d-flex mt-4 mt-md-0">
                    <button type="button" className="btn btn-primary" style={{ display: (this.state.editable ? 'block' : 'none') }} onClick={()=> this.onShowInquiryDialog(null)}>Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InquiryInputDialog key={this.state.selectedInquiry?this.state.selectedInquiry.inquiryId:null} show={this.state.showInquiryDialog} inquiry={this.state.selectedInquiry} onHide={() => this.setState({showInquiryDialog: false, selectedInquiry: null})} onCancel={() => this.setState({showInquiryDialog: false})} onSave={(inquiry: Inquiry) => this.onSave(inquiry)}/>
      </div>
    )
  }

  onShowInquiryDialog(inquiryId: string | null){
    let inquiry = null;
    if (inquiryId){
      inquiry = this.props.inquiryState.inquiryList.find((item)=>{
        return (item.inquiryId === inquiryId);
      });
    }
    this.setState({showInquiryDialog: true, selectedInquiry: inquiry});
  }

  onSave(inquiry: Inquiry){
    this.setState({showInquiryDialog: false, selectedInquiry: null});
    let self = this;

    this.props.initiateSaveInquiry(this.props.businessId, inquiry, 
      ()=>{
        //on success
        alert.info('Successfully updated!');
      }, 
      ()=>{
        //on error
        alert.error('Failed to update!');
      })
  }

  handleScroll(){
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if ((scrollHeight - scrollTop) === clientHeight){
      console.log('InquiryListPage, load more inquirys');
      if(this.props.inquiryState.inquiryList.length > 0){
        this.props.loadMoreInquiries(this.props.businessId, this.props.inquiryState.inquiryList[this.props.inquiryState.inquiryList.length-1].inquiryDate, this.state.searchText);
      }
    }
  }
}


const mapStateToProps = (state: { auth: AuthState; business: BusinessState; inquiry: InquiryState; }) => {
  return { 
    authState: state.auth,
    businessState: state.business,
    inquiryState: state.inquiry
  };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; businessId: string; searchText?: string; afterTimestamp?: number; inquiry?: Inquiry; onSuccess?: any; onError?: any; }) => void) => {
  return {
    loadInquiries: (businessId: string, searchText: string | undefined) =>{
      dispatch(loadInquiries(businessId, searchText));
    },
    loadMoreInquiries: (businessId: string, afterTimestamp: number | undefined, searchText: string | undefined) =>{
      dispatch(loadMoreInquiries(businessId, afterTimestamp, searchText));
    },
    initiateSaveInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
      dispatch(initiateSaveInquiry(businessId, inquiry, onSuccess, onError))
    },
    initiateDeleteInquiry: (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
      dispatch(initiateDeleteInquiry(businessId, inquiry, onSuccess, onError))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InquiryListPage);

