import { Component } from 'react';
import { Modal, Button  } from 'react-bootstrap';


export interface Props {
  text: string,
  show: boolean,
  onHide: () => void,
  onOk: () => void, 
}

export interface State {
  validated: boolean, 
}

export class InformationDialog extends Component <Props, State>{
 

  constructor(props: Props){
    super(props);
    console.log('InformationDialog initialized');

    this.state = {
      validated: false,
    }
  }

  render() {

    return (
      <Modal
        show={this.props.show}
        onHide={ () => this.props.onHide()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        >

        <Modal.Body>
              <h4>{this.props.text}</h4>
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="light btn-sm m-2" onClick={() => this.props.onOk()}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}


export default InformationDialog;