
import type from './types';
import { User, UserSession } from './user';

export class AuthState {
  state: string;
  user!: User;
  userSession!: UserSession;
  errorMessage!: string;
  isNewUser!: string;

  constructor(){
    console.log('Auth initialized');

    this.state = type.INITIALIZED;
  }

}