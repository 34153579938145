

import firebase, { firestore } from '../firebase.js';
import { Business, BusinessStatus } from './business'
import { Role } from 'app/userPermissions';
import { settings } from '../config/settings';
import axios from 'axios';
import { BusinessHours } from './businessHours';
import { getCurrentUser } from 'app/shared';

export async function fetchBusinessList({userId}: {userId: string}){

  console.log('business, firebaseActions, fetchBusinessList, userId - '+ userId);

  let businessList: Business[] = [];
  let businessListPromise: Promise<Business>[] = [];

  let userCollectionRef =  firestore.collection('users');
  let businessListRef = userCollectionRef.doc(userId).collection('businesses');

  let businessCollection = await businessListRef.get();
  
  if (businessCollection.docs.length > 0){
      console.log('business, firebaseActions');
      
      businessCollection.forEach((doc)=>{
        businessListPromise.push(getBusinessWithPermission({businessId: doc.id, userId}));
      });

      businessList = await Promise.all(businessListPromise);

  }else{
      console.log('business, firebaseActions, fetchBusinessList, business list  not found');
  }

  businessList = businessList.filter(business => { return business.status === BusinessStatus.active});

  return businessList;
}

export async function getBusiness({businessId}: {businessId: string}){
  console.log('business, firebaseActions, getBusiness, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/businesses/' + businessId;
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken

  }
  
  let response = await axios.get(rewardUrl, {headers});

  return new Business(response.data);
}

export async function getBusinessUserPermission({businessId, userId}:{businessId: string, userId: string}){
  console.log('business, firebaseActions, getBusinessUserPermission, businessId - '+ businessId+' userId - '+ userId);

  let permissions = {role: Role.Viewer};
  let businessUserPermissionRef = firestore.collection('businesses').doc(businessId).collection('users').doc(userId);
  let businessUserPermissionDoc = await businessUserPermissionRef.get();
  if (businessUserPermissionDoc.exists){
    permissions = businessUserPermissionDoc.data() as any;
  }
  return permissions;
}

export async function getBusinessWithPermission({businessId, userId}:{businessId: string, userId: string}){
  console.log('business, firebaseActions, getBusinessWithPermission, businessId - '+ businessId+' userId - '+ userId);

  let [business, permissions] = await Promise.all([getBusiness({businessId}), getBusinessUserPermission({businessId, userId})]);

  if(business)
    business.userPermission = permissions;
  else
    business = new Business({})
  return business;
}

export async function createBusiness(business: Business){

  console.log('firebaseActions, createBusiness, businessId - '+ business.businessId);

  let businessCollectionRef =  firestore.collection('businesses');
  let businessRef = businessCollectionRef.doc(business.businessId);
  business.metadata.create(getCurrentUser().userId);
  let data = business.serialize();
  console.log(JSON.stringify(data));
  await businessRef.set(data);
  return business;
}

export async function updateBusiness(business: Business){

  console.log('firebaseActions, updateBusiness, businessId - '+ business.businessId);

  let businessCollectionRef =  firestore.collection('businesses');
  let businessRef = businessCollectionRef.doc(business.businessId);
  business.metadata.update();
  let data = business.serialize();
  console.log(JSON.stringify(data));
  await businessRef.update(data);
  return business;
}

export async function deleteBusiness(business: Business, reasonToDelete: string, callToDiscuss: boolean){

  console.log('firebaseActions, deleteBusiness, businessId - '+ business.businessId);

  let businessCollectionRef =  firestore.collection('businesses');
  let businessRef = businessCollectionRef.doc(business.businessId);
  business.metadata.delete(getCurrentUser().userId, reasonToDelete, callToDiscuss);
  
  await businessRef.set({status: business.status, metadata: business.metadata.serialize()}, {merge:true});
}

export async function uploadPicture(folderId: string, pictureFile: any){
  console.log('firebaseActions, uploadPicture ');
  let pictureUrl = '';

  if (pictureFile){
    let timestamp = new Date().getTime();
    let storageRef = firebase.storage().ref();
    let pictureRef = storageRef.child(`images/${folderId}/${timestamp}.jpg`);
    let uploadTask = await pictureRef.put(pictureFile);
    pictureUrl = await pictureRef.getDownloadURL();
  }
  return pictureUrl;
}

export async function deletePicture(pictureUrl: string){
  console.log('firebaseActions, deletePicture ');

  try{
    if (pictureUrl !== ''){
      let storage = firebase.storage();
      var httpsReference = storage.refFromURL(pictureUrl);
      await httpsReference.delete();
    }
  }catch(error){
    console.log('firebaseActions, deletePicture , failed to delete-');
  }
}

export async function updateBusinessHours(businessId: string, businessHours: BusinessHours){
  console.log('business, firebaseActions, updateBusinessHours, businessId - '+ businessId);

  let rewardUrl = settings.apiURL + '/businesshours';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': businessId,
    'Authorization': 'Bearer '+ idToken
  }

  console.log('business, firebaseActions, updateBusinessHours, sending ....');
  businessHours.lastModified = new Date().getTime();
  console.log('testing, ' + JSON.stringify(businessHours));
  if (businessHours instanceof BusinessHours){
    console.log('businessHours instanceof BusinessHours');
  }else{
    console.log('businessHours is not an instanceof BusinessHours');
  }
  await axios.patch(rewardUrl, businessHours.serialize(), {headers});
  console.log('business, firebaseActions, updateBusinessHours, completed ....');
  return businessHours;
}

export async function updateBusinessSocialLinks(businessId: string, socialLinks: string[]){

  console.log('firebaseActions, updateBusinessSocialLinks, businessId - '+ businessId);

  let businessCollectionRef =  firestore.collection('businesses');
  let businessRef = businessCollectionRef.doc(businessId);
  await businessRef.set({socialLinks: socialLinks}, {merge:true});
  return socialLinks
}