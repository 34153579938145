
const styles = {
    icon:{
        selectFontSize: 40,
        tabFontSize: 24,
        primaryFontSize: 24,
        secondaryFontSize: 20,
        navFontSize: 20,
        infoFontSize: 20
    },
    color:{
        action: '#F5A623',
        black: 'black',
        white: 'white',
        gray: 'gray',
        gray2: '#4F4F4F',
        gray5: '#E0E0E0',
        gray6: '#F2F2F2',
        danger: 'red'
    },
    text:{
        titleFontSize: 24,
        largeFontSize: 20,
        mediumFontSize: 17,
        smallFontSize: 14,
        tinyFontSize: 12
    },
    header:{
        marginLeft: 8,
        marginRight: 8
    }
}

export default styles;

export const container = {
    flexDirection: 'column',
    paddingHorizontal: 15
}


export const formError = {
    text: {
      fontSize: styles.text.tinyFontSize,
      color: styles.color.danger
    }
  };

export const button = {

    backgroundColor: styles.color.gray5,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'transparent',
}

export const signIn = {
    button:{
        alignItems: 'center',
        marginLeft: 8,
        width: 200
    },
    image: {
        width: 200,
        height:50,
        resizeMode: 'stretch',
        shadowOffset: {
          width: 2,
          height: 2
        },
        shadowOpacity: 0.35
    }
  };