import _ from "lodash";
import { RecordCodeToObject, RecordObject, RecordObjectToCode } from "./types";


export class Record {

    generateBusinessId(){
        return this.getRandomId(RecordObject.business);
    }
    generateCustomerId(){
        return this.getRandomId(RecordObject.customer);
    }
    generateInquiryId(){
        return this.getRandomId(RecordObject.inquiry);
    }
    generateRewardId(){
        return this.getRandomId(RecordObject.reward);
    }

    getRecordObject(id: string){
        let code: string = id.substring(0, 3);
        let recordObject: RecordObject | undefined = RecordCodeToObject.get(code);
        return recordObject;
    }

    getRandomId(recordObject: RecordObject){
        let recordCode = RecordObjectToCode.get(recordObject);

        if (recordCode){
            let random1 = Math.random().toString().substring(2, 8)
            let random2 = Math.random().toString().substring(2, 8)

            return [recordCode, random1, random2].join('-');
        }else{
            throw 'Record code not found';
        }
    }
}