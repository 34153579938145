import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Spinner } from '../shared/Spinner';

import { updatePassword, checkPasswordResetCode } from './actions';
import type from './types';
import { AuthState } from './auth';

export interface Props {
  auth: AuthState,
  updatePassword: (code: any, password: string) => void,
}

export interface State {
  email: string,
  code: any,
  password: string,
  isPasswordInvalid: boolean,
  passwordErrorText: string,
  confirmPassword: string,
  isConfirmPasswordInvalid: boolean,
  confirmPasswordErrorText: string
}

export class UpdatePassword extends Component<Props, State> {

  constructor(props: Props){
    super(props);
    console.log('UpdatePassword initialized');
    this.state = {
      email: '',
      code: '',
      password: '',
      isPasswordInvalid: false,
      passwordErrorText: '',
      confirmPassword: '',
      isConfirmPasswordInvalid: false,
      confirmPasswordErrorText: ''
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('oobCode');

    let self = this;
    checkPasswordResetCode(code, 
      (email)=>{
        self.setState({code: code, email: email});
      }, 
      (error)=>{
        self.setState({code: null});
      }
    );
  }

  render(){

    let render;

    if (this.props.auth.state === type.INITIALIZED || this.props.auth.state === type.UPDATE_PASSWORD_IN_PROGRESS) { 
      render = this.loading();
    }else if(this.props.auth.state === type.UPDATE_PASSWORD_SUCCESS){

        let text1 = 'Password Reset';
        let text2 = 'You have successfully changed your password.';
        render = this.displayStatus(text1, text2, {label: 'Login now', to:'/login'});
    }else if(this.props.auth.state === type.UPDATE_PASSWORD_FAILED && 
      (this.props.auth.errorMessage == type.PASSWORD_RESET_CODE_EXPIRED || 
        this.props.auth.errorMessage == type.PASSWORD_RESET_CODE_INVALID )){

        let text1 = 'Password Reset';
        let text2 = 'Sorry, this password reset request has expired. Please reset again.';
        render = this.displayStatus(text1, text2, {label: 'Reset now', to:'/passwordReset'});
    }else if (this.state.code === null){
        let text1 = 'Password Reset';
        let text2 = 'Sorry, this password reset request is invalid. Please reset again.';
        render = this.displayStatus(text1, text2, {label: 'Reset now', to:'/passwordReset'});
    }else{
        render = this.updatePassword();
    }

    return render;
  }

  loading(){
    return ( <Spinner/> );
  }

  displayStatus(text1: string, text2: string, {label, to}:{label: string, to: string}) {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4>{text1}</h4>
                <h6 className="font-weight-light">{text2}</h6>
                <a href={to}  className="auth-link text-black">{label}</a>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  updatePassword() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2> <strong> Resquash </strong></h2>
                <h4>Reset your password</h4>
                <h6 className="font-weight-light">Strong passwords include numbers, letters, and punctuation marks.</h6>
                <h4>{this.state.email}</h4>
                <Form className="pt-3">
                  <Form.Group controlId="password" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="password" 
                      placeholder="Password" 
                      onChange={this.onChangePassword.bind(this)} 
                      required
                      isInvalid = {this.state.isPasswordInvalid}
                      value = {this.state.password}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.passwordErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="confirmPassword" className="d-flex search-field flex-column">
                    <Form.Control 
                      type="password" 
                      placeholder="Confirm Password" 
                      onChange={this.onChangeConfirmPassword.bind(this)} 
                      required
                      isInvalid = {this.state.isConfirmPasswordInvalid}
                      value = {this.state.confirmPassword}
                      size="lg" className="h-auto" />
                      <Form.Control.Feedback type="invalid">
                        {this.state.confirmPasswordErrorText}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/" onClick={this.onSubmit.bind(this)} type="submit">SUBMIT</Link>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <div className="text-danger"> {this.props.auth.errorMessage} </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

  onChangePassword(e: { target: { value: any; }; }){
    this.setState({password: e.target.value, isPasswordInvalid: false});
  }

  onChangeConfirmPassword(e: { target: { value: any; }; }){
    this.setState({confirmPassword: e.target.value, isConfirmPasswordInvalid: false});
  }

  onSubmit(e: { preventDefault: () => void; }){
    e.preventDefault();
    console.log('UpdatePassword, onSubmit');
    if (this.isFieldsValid()){
      this.props.updatePassword(this.state.code, this.state.password);
    }
  }

  isFieldsValid(){

    let isFieldValid = true;

    if(this.state.password === ''){
      this.setState({isPasswordInvalid: true, passwordErrorText: 'Password required'});
      isFieldValid = false;
    }

    if(this.state.confirmPassword === ''){
      this.setState({isConfirmPasswordInvalid: true, confirmPasswordErrorText: 'Confirm password'});
      isFieldValid = false;
    }else if (this.state.password !== this.state.confirmPassword){
      this.setState({isConfirmPasswordInvalid: true, confirmPasswordErrorText: 'Passwords do not match'});
      isFieldValid = false;
    }

    console.log('isFieldValid - '+ JSON.stringify(isFieldValid));
    return isFieldValid;
  }

}

const mapStateToProps = (state: { auth: any; }) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; code: any; newPassword: string; }) => void) => {
  return {
    updatePassword: (code: any, password: string) => {
      dispatch(updatePassword(code, password))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);