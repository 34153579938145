import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UpgradeButton extends Component {

  render () {
    return (
      <Link className="nav-link" to="/plan">
        <span> Upgrade </span>
      </Link>
    );
  }
}

export default UpgradeButton;