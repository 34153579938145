

import firebase from '../firebase.js';
import axios from 'axios'; 
import { SearchRequest, SearchResult, SearchResultData} from './searchResult';
import { Customer, CustomerData } from 'app/customers/customer';
import { Inquiry, InquiryData } from 'app/inquiries/inquiry';
import { settings } from '../config/settings';
import { RecordObject } from 'app/record';
import { Product, ProductData } from 'app/product/product';


export async function fetchSearchResultList(searchRequest: SearchRequest){

  console.log(`searchResult, firebaseActions, fetchSearchResultList, businessId-${searchRequest.businessId}`);

  let searchResultList: SearchResult[] = [];
  let searchUrl = settings.apiURL + '/search';
  let idToken: string|undefined = await firebase.auth().currentUser?.getIdToken();
  console.log(JSON.stringify(searchRequest));

  let headers = {
    'Content-Type': 'application/json',
    'X-BusinessId': searchRequest.businessId,
    'Authorization': 'Bearer '+ idToken

  }

  let response = await axios.post(searchUrl, searchRequest, {headers});
    
  if (response.data.length > 0){
      console.log('searchResult, firebaseActions');
      
      response.data.forEach((searchResultData: SearchResultData)=>{
        let searchResult;

        if (searchResultData.object === RecordObject.customer){
          searchResult = new Customer(searchResultData as CustomerData);
        }else if(searchResultData.object === RecordObject.inquiry){

          searchResult = new Inquiry(searchResultData as InquiryData);
        }else if(searchResultData.object === RecordObject.product){

          searchResult = new Product(searchResultData as ProductData);
        }

        if (searchResult){
          searchResultList.push(searchResult);
        }
      
      });

  }else{
      console.log(`searchResult, firebaseActions, fetchSearchResultList, searchResult list is empty for businessId - ${searchRequest.businessId}`);
  }

  return searchResultList;
}
