const REWARD_INITIALIZED = 'REWARD_INITIALIZED';
const LOAD_REWARDS = 'LOAD_REWARDS';
const LOAD_MORE_REWARDS = 'LOAD_MORE_REWARDS';
const REWARDS_LOADED = 'REWARDS_LOADED';
const MORE_REWARDS_LOADED = 'MORE_REWARDS_LOADED';
const REWARD_LIST_EMPTY = 'REWARD_LIST_EMPTY';
const FAILED_TO_LOAD_REWARDS = 'FAILED_TO_LOAD_REWARDS';

const INITIATE_CREATE_REWARD = 'INITIATE_CREATE_REWARD';
const INITIATE_UPDATE_REWARD = 'INITIATE_UPDATE_REWARD';
const INITIATE_DELETE_REWARD = 'INITIATE_DELETE_REWARD';

const REWARD_CREATED = 'REWARD_CREATED';
const REWARD_UPDATED = 'REWARD_UPDATED';
const REWARD_DELETED = 'REWARD_DELETED';


export default {
    REWARD_INITIALIZED,
    LOAD_REWARDS,
    LOAD_MORE_REWARDS,
    REWARDS_LOADED,
    MORE_REWARDS_LOADED,
    REWARD_LIST_EMPTY,
    FAILED_TO_LOAD_REWARDS,

    INITIATE_CREATE_REWARD,
    INITIATE_UPDATE_REWARD,
    INITIATE_DELETE_REWARD,

    REWARD_CREATED,
    REWARD_UPDATED,
    REWARD_DELETED,
};

export enum ErrorCode {
    REWARD_ALREADY_EXISTING = '409',
    FAILED_TO_CREATE = '0'
}