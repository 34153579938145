import type from './types';
import {type as authType} from '../user';
import { Analytics, AnalyticsState } from './analytics';

const INITIAL_STATE = new AnalyticsState ();
   

export const analyticsReducer = (state = INITIAL_STATE, action: { type: string; totalCount: any; customersCount: any; inquiriesCount: any; }) => {

    let analytics: Analytics = new Analytics();
    switch (action.type){


        case type.TOTAL_COUNT_LOADED:
            console.log('analyticsReducer, '+action.type);
            analytics = state.analytics;
            analytics.totalCount = action.totalCount;
            return {...state, state: type.TOTAL_COUNT_LOADED, analytics: analytics};

        case type.CUSTOMERS_COUNT_LOADED:
            console.log('analyticsReducer, '+action.type);
            analytics = state.analytics;
            analytics.customersCount = action.customersCount;
            return {...state, state: type.CUSTOMERS_COUNT_LOADED, analytics: analytics};
    
        case type.INQUIRIES_COUNT_LOADED:
            console.log('analyticsReducer, '+action.type);
            analytics = state.analytics;
            analytics.inquiriesCount = action.inquiriesCount;
            return {...state, state: type.INQUIRIES_COUNT_LOADED, analytics: analytics};

        case authType.SIGNED_OUT:
            console.log('analyticsReducer, '+action.type);
            return {...state, state: type.ANALYTICS_INITIALIZED, analytics: new Analytics()};

        default:
            console.log('analyticsReducer, default - '+ action.type);
            return state;
    }
};
