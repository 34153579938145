import { channel } from 'redux-saga'
import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchBusinessList, updateBusinessHours, updateBusinessSocialLinks } from './firebaseActions';
import { Business, BusinessState } from './business';
import { BusinessHours } from './businessHours';
import { getStoreState } from 'index';
import { backgroundListener } from './listener';

const customChannel = channel();

//Action creators

export const loadBusinesses = (userId: string) => {
    console.log('business, action, loadBusinesses, userId - '+ userId);
    return { type: type.LOAD_BUSINESS, userId }
};

export const setSelectedBusiness = (businessId: string) => {
    console.log('user, action, setSelectedBusiness');
    return { type: type.SET_SELECTED_BUSINESS, businessId }
};

export const initiateCreateBusiness = (business: Business, onSuccess: any, onError: any) => {
    console.log('user, action, initiateCreateBusiness');
    return { type: type.INITIATE_CREATE_BUSINESS, business, onSuccess, onError }
};

export const initiateUpdateBusiness = (business: Business, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateBusiness');
    return { type: type.INITIATE_UPDATE_BUSINESS, business, onSuccess, onError }
};

export const initiateDeleteBusiness = (business: Business, reasonToDelete: string, callToDiscuss: boolean, onSuccess: any, onError: any) => {
    console.log('user, action, initiateDeleteBusiness');
    return { type: type.INITIATE_DELETE_BUSINESS, business, reasonToDelete, callToDiscuss, onSuccess, onError }
};

export const initiateUpdateBusinessHours = (businessId: string, businessHours: BusinessHours, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateBusiness');
    return { type: type.INITIATE_UPDATE_BUSINESS_HOURS, businessId, businessHours, onSuccess, onError }
};

export const initiateUpdateBusinessSocialLinks = (businessId: string, socialLinks: string[], onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateBusinessSocialLinks');
    return { type: type.INITIATE_UPDATE_BUSINESS_SOCIAL_LINKS, businessId, socialLinks, onSuccess, onError }
};

const retrievedBusinessList = (businessList: Business[]) => {
    return { type: type.LOADED, businessList }
};

const emptyBusinessList = () => {
    return { type: type.EMPTY }
};

const failedToRetrieveBusinessList = () => {
    return { type: type.FAILED_TO_LOAD }
};

const selectedBusiness = (businessId: string) => {
    return { type: type.BUSINESS_SELECTED, businessId}
};

const businessCreated = (business: Business) => {
    return { type: type.BUSINESS_CREATED, business}
};

const businessUpdated = (business: Business) => {
    return { type: type.BUSINESS_UPDATED, business}
};

const businessDeleted = (business: Business) => {
    return { type: type.BUSINESS_DELETED, business}
};


const businessHoursUpdated = (businessHours: BusinessHours) => {
    return { type: type.BUSINESS_HOURS_UPDATED, businessHours}
};

const businessSocialLinksUpdated = (socialLinks: string[]) => {
    return { type: type.BUSINESS_SOCIAL_LINKS_UPDATED, socialLinks}
};

// Sagas

function* loadBusinessesAsync(action: { userId: any; }) {
    console.log('business, action, loadBusinessesAsync - '+ JSON.stringify(action));
    try {
  
        const businessList: Business[] = yield call(fetchBusinessList, {userId: action.userId});
        console.log(JSON.stringify(businessList));
        if (businessList.length > 0){
            yield put(retrievedBusinessList(businessList));
        }else{
            yield put(emptyBusinessList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveBusinessList());
    }
}

function* setSelectedBusinessAsync(action: { businessId: string; }) {
    console.log('business, action, setSelectedBusinessAsync');

    yield put(selectedBusiness(action.businessId));
    yield backgroundListener.put(action);
    
}


function* createBusinessAsync(action: { business: Business; onSuccess: (business: Business) => void; onError: (error: any) => void; }) {
    console.log('business, action, createBusinessAsync');
   
    try {
        let business: Business = yield call(action.business.create.bind(action.business));
        yield put(businessCreated(action.business));
        action.onSuccess(business);
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError(error);
    }
}

function* updateBusinessAsync(action: { business: Business; onSuccess: (business: Business) => void; onError: (error: any) => void; }) {
    console.log('business, action, updateBusinessAsync');
   
    try {
        let business: Business = yield call(action.business.update.bind(action.business));
        yield put(businessUpdated(action.business));
        action.onSuccess(business);
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError(error);
        
    }
}

function* deleteBusinessAsync(action: { business: Business; reasonToDelete: string; callToDiscuss: boolean; onSuccess: () => void; onError: (arg0: unknown) => void; }) {
    console.log('business, action, deleteBusinessAsync');
   
    try {
        yield call(action.business.delete.bind(action.business, action.reasonToDelete, action.callToDiscuss));
        yield put(businessDeleted(action.business));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError(error);
        
    }
}

function* updateBusinessHoursAsync(action: { businessId: string; businessHours: BusinessHours; onSuccess: () => void; onError: () => void; }) {
    console.log('business, action, updateBusinessHoursAsync');
   
    try {
        yield call(updateBusinessHours, action.businessId, action.businessHours);
        yield put(businessHoursUpdated(action.businessHours));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* updateBusinessSocialLinksAsync(action: { businessId: string; socialLinks: string[]; onSuccess: () => void; onError: () => void; }) {
    console.log('business, action, updateBusinessSocialLinksAsync');
   
    try {
        yield call(updateBusinessSocialLinks, action.businessId, action.socialLinks);
        yield put(businessSocialLinksUpdated(action.socialLinks));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const businessSagas = [
    takeEvery<any>(type.LOAD_BUSINESS, loadBusinessesAsync),
    takeEvery<any>(type.SET_SELECTED_BUSINESS, setSelectedBusinessAsync),
    takeEvery<any>(type.INITIATE_CREATE_BUSINESS, createBusinessAsync),
    takeEvery<any>(type.INITIATE_UPDATE_BUSINESS, updateBusinessAsync),
    takeEvery<any>(type.INITIATE_DELETE_BUSINESS, deleteBusinessAsync),
    takeEvery<any>(type.INITIATE_UPDATE_BUSINESS_HOURS, updateBusinessHoursAsync),
    takeEvery<any>(type.INITIATE_UPDATE_BUSINESS_SOCIAL_LINKS, updateBusinessSocialLinksAsync),
];
