

import firebase, { firestore, firebaseAuth } from '../firebase.js';


import { User } from './user';
import { SignInCancelled, SignInFailed, SignOutFailed }  from './types';
import { AppSettings } from '../config';


export function signInWithEmailAndPassword({email, password}: {email: string, password: string}) {
  return firebaseAuth.signInWithEmailAndPassword(email, password);
}

export async function signInWithGoogle(settings: AppSettings){

  console.log('auth, firebaseActions, signInWithGoogle');

  try {

    let provider = new firebase.auth.GoogleAuthProvider();

    // login with credential
    const userSession = await firebaseAuth.signInWithPopup(provider);

    console.info('auth, firebaseActions ' + JSON.stringify(userSession));
    return userSession;

  } catch (e: any) {

    if (e.code == -5){
      console.log('auth, firebaseActions, signin cancelled by user');
      throw new SignInCancelled();
    }else {
      console.log(JSON.stringify(e));
      console.log('auth, firebaseActions, signInfailed errorCode - '+e.code);
      throw new SignInFailed(e.code);
    }

  }
}

export async function signOut() {
  console.log('auth, firebaseActions, signOut');

  try{
    return firebaseAuth.signOut();
  }catch(e){
    throw new SignOutFailed();
  }
  
}

export function sendPasswordResetEmail({email}: {email: string}) {
  return firebaseAuth.sendPasswordResetEmail(email);
}

export function confirmPasswordReset({code, newPassword}: {code: any, newPassword: string}) {
  return firebaseAuth.confirmPasswordReset(code, newPassword);
}

export function createUserWithEmailAndPassword({email, password}: {email: string, password: string}) {
  return firebaseAuth.createUserWithEmailAndPassword(email, password);
}

export function verifyPasswordResetCode(code: any) {
  return firebaseAuth.verifyPasswordResetCode(code);
}

export function checkWhetherAcountIsActivated() {
  return firebaseAuth.currentUser;
}

export function verifyEmail() {
  let firebaseUser: any =  firebaseAuth.currentUser;

  return firebaseUser.sendEmailVerification();
}

export function applyActionCode(code: any) {
  return firebaseAuth.applyActionCode(code);
}

export async function userOnChange(uId: string, onSuccess: (user: User) => void, onError: (error: Error) => void){


  let userUIdsCollectionRef = firestore.collection('userUIDs');
  let userUIdRef = userUIdsCollectionRef.doc(uId);

  userUIdRef.onSnapshot(
      async (docSnapshot: any) => {
          if(docSnapshot.exists){
              let userId = docSnapshot.data().userId;
              console.log('firebaseActions, userOnChange, userId - '+ userId);
              let user = await getUser({userId});
              if (user){
                onSuccess(user);
              }
          }
      },
      err => {
          console.log('firebaseActions, userOnChange, error - '+JSON.stringify(err));
          onError(err);
      }
  );
}

export async function getUser({userId}: {userId: string}){

  console.log('firebaseActions, getUser, userId - '+ userId);

  let user = null;
  let userCollectionRef =  firestore.collection('users');
  let userRef = userCollectionRef.doc(userId);
  let userDoc: any = await userRef.get();
  
  if (userDoc.exists){
      console.log('firebaseActions - '+JSON.stringify(userDoc.data()));
      user = new User(userDoc.data());
      user.userId = userId;
  }
  else{
      console.log('firebaseActions, getUser, user information not found');
  }
  return user;
}

export async function createUser(user: User){
  console.log('firebaseActions, createUser');

  let userCollectionRef =  firestore.collection('users');
  let data = user.serialize();
  const resp = await userCollectionRef.add(data);
  user.userId = resp.id;
  return user;
}

export async function modifyUser({user}: {user: User}){

  console.log('firebaseActions, modifyUser, userId - '+ user.userId);

  let userCollectionRef =  firestore.collection('users');
  let userRef = userCollectionRef.doc(user.userId);
  let data = user.serialize();
  const resp = await userRef.update(data);
  return user;
}


export async function getUserId({uId}: {uId: string}){
  console.log('firebaseActions, getUserId, uId - '+ uId);

  let userId = null;
  let userUIdsCollectionRef = firestore.collection('userUIDs');
  let userUIdRef = userUIdsCollectionRef.doc(uId);

  let userIdDoc: any = await userUIdRef.get();
  if (userIdDoc.exists){
    userId = userIdDoc.data().userId;
  }else{
      console.log('firebaseActions, userId not found');
  }
  return userId;
}