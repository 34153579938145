import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BusinessState } from '../business';
import APISettingsPage from './apiSettingsPage';

export interface Props {
  businessState: BusinessState;
}

export interface State {

}

export class APISettings extends Component <Props, State> {
  

  constructor(props: Readonly<Props>){
    super(props);
    console.log('APISettings initialized');
  
    this.state = {
     
    }
  }
  render() {
    return (
      <APISettingsPage key={this.props.businessState.selectedBusiness.businessId} businessId = {this.props.businessState.selectedBusiness.businessId}/>
    );
  }
}



const mapStateToProps = (state: { business: any; }) => {
  return { 
    businessState: state.business 
  };
};


export default connect(mapStateToProps, null)(APISettings);