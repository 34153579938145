import { put, takeEvery, call } from 'redux-saga/effects';

import type from './types';
import { fetchInquiryList } from './firebaseActions';
import { Inquiry } from './inquiry';

//Action creators

export const loadInquiries = (businessId: string, searchText='') => {
    console.log('inquiries, action, loadInquiries, businessId - '+ businessId);
    return { type: type.LOAD_INQUIRIES, businessId, searchText }
};

export const loadMoreInquiries = (businessId: string, afterTimestamp=0, searchText='') => {
    console.log('inquiries, action, loadMoreInquiries, businessId - '+ businessId);
    return { type: type.LOAD_MORE_INQUIRIES, businessId, afterTimestamp, searchText }
};


export const initiateCreateInquiry = (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateInquiry');
    return { type: type.INITIATE_CREATE_INQUIRY, businessId, inquiry, onSuccess, onError }
};

export const initiateSaveInquiry = (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
    console.log('user, action, initiateSaveInquiry');
    let action = null;
    if (inquiry.inquiryId != ''){
        action = { type: type.INITIATE_UPDATE_INQUIRY, businessId, inquiry, onSuccess, onError };
    }else{
        action = { type: type.INITIATE_CREATE_INQUIRY, businessId, inquiry, onSuccess, onError };
    }
    return action;
};

export const initiateDeleteInquiry = (businessId: string, inquiry: Inquiry, onSuccess: any, onError: any) => {
    console.log('user, action, initiateUpdateInquiry');
    return { type: type.INITIATE_DELETE_INQUIRY, businessId, inquiry, onSuccess, onError }
};

const retrievedInquiryList = (inquiryList: Inquiry[]) => {
    return { type: type.INQUIRIES_LOADED, inquiryList }
};

const retrievedMoreInquiryList = (inquiryList: Inquiry[]) => {
    return { type: type.MORE_INQUIRIES_LOADED, inquiryList }
};

const emptyInquiryList = () => {
    return { type: type.INQUIRY_LIST_EMPTY }
};

const failedToRetrieveInquiryList = () => {
    return { type: type.FAILED_TO_LOAD_INQUIRIES }
};

const createInquiry = (inquiry: Inquiry) => {
    return { type: type.CREATE_INQUIRY, inquiry}
};

const updateInquiry = (inquiry: Inquiry) => {
    return { type: type.UPDATE_INQUIRY, inquiry}
};

const deleteInquiry = (inquiry: Inquiry) => {
    return { type: type.DELETE_INQUIRY, inquiry}
};

// Sagas

function* loadInquirysAsync(action: { businessId: string; searchText: string | undefined; }) {
    console.log('inquiry, action, loadInquirysAsync - '+ JSON.stringify(action));
    try {
  
        const inquiryList: Inquiry[] = yield call(fetchInquiryList, action.businessId, 0, action.searchText);
        if (inquiryList.length > 0){
            yield put(retrievedInquiryList(inquiryList));
        }else{
            yield put(emptyInquiryList());
        }
    } catch (error) {

        console.log(JSON.stringify(error));      
        yield put(failedToRetrieveInquiryList());
    }
}

function* loadMoreInquirysAsync(action: { businessId: string; afterTimestamp: number | undefined; searchText: string | undefined; }) {
    console.log('inquiry, action, loadInquirysAsync - '+ JSON.stringify(action));
    try {
  
        const inquiryList: Inquiry[] = yield call(fetchInquiryList, action.businessId, action.afterTimestamp, action.searchText);
        if (inquiryList.length > 0){
            yield put(retrievedMoreInquiryList(inquiryList));
        }
    } catch (error) {
        console.log(JSON.stringify(error));      
    }
}

function* createInquiryAsync(action: { inquiry: Inquiry; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('inquiry, action, createInquiryAsync');
   
    try {
        yield call(action.inquiry.create.bind(action.inquiry), action.businessId);
        yield put(createInquiry(action.inquiry));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* updateInquiryAsync(action: { inquiry: Inquiry; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('inquiry, action, updateInquiryAsync');
   
    try {
        yield call(action.inquiry.update.bind(action.inquiry), action.businessId);
        yield put(updateInquiry(action.inquiry));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}

function* deleteInquiryAsync(action: { inquiry: Inquiry; businessId: string; onSuccess: () => void; onError: () => void; }) {
    console.log('inquiry, action, deleteInquiryAsync');
   
    try {
        yield call(action.inquiry.delete.bind(action.inquiry), action.businessId);
        yield put(deleteInquiry(action.inquiry));
        action.onSuccess();
    } catch (error) {

        console.log(JSON.stringify(error));
        action.onError();
    }
}


export const inquirySagas = [
    takeEvery<any>(type.LOAD_INQUIRIES, loadInquirysAsync),
    takeEvery<any>(type.LOAD_MORE_INQUIRIES, loadMoreInquirysAsync),
    takeEvery<any>(type.INITIATE_CREATE_INQUIRY, createInquiryAsync),
    takeEvery<any>(type.INITIATE_UPDATE_INQUIRY, updateInquiryAsync),
    takeEvery<any>(type.INITIATE_DELETE_INQUIRY, deleteInquiryAsync)
];
