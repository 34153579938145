
const API_SETTINGS_INITIALIZED = 'API_SETTINGS_INITIALIZED';
const LOAD_API_SETTINGS = 'LOAD_API_SETTINGS';
const API_SETTINGS_LOADED = 'API_SETTINGS_LOADED';
const FAILED_TO_LOAD_API_SETTINGS = 'FAILED_TO_LOAD_API_SETTINGS';

const ROTATE_API_SECRET = 'ROTATE_API_SECRET';
const API_SECRET_UPDATED = 'API_SECRET_UPDATED';


export default {
    API_SETTINGS_INITIALIZED,
    LOAD_API_SETTINGS,
    API_SETTINGS_LOADED,
    FAILED_TO_LOAD_API_SETTINGS,

    ROTATE_API_SECRET,

    API_SECRET_UPDATED
};

