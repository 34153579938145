import type from './types';
import {type as authType} from '../user';
import { Payment, PaymentState } from './payment';

const INITIAL_STATE = new PaymentState ();
   

export const billingReducer = (state = INITIAL_STATE, action: { type: string; currentEstimation: any; pastPayments: any; }) => {

    let payment = new Payment();
    switch (action.type){


        case type.CURRENT_ESTIMATION_LOADED:
        {
            console.log('billingReducer, '+action.type);

            payment = state.payment;
            payment.currentEstimation = action.currentEstimation;
            return {...state, state: type.CURRENT_ESTIMATION_LOADED, payment: payment};
        }

        case type.PAST_PAYMENTS_LOADED:
            console.log('billingReducer, '+action.type);

            payment = state.payment;
            payment.pastPayments = action.pastPayments;
            return {...state, state: type.PAST_PAYMENTS_LOADED, payment: payment};

        case authType.SIGNED_OUT:
            console.log('billingReducer, '+action.type);
            return {...state, state: type.PAYMENT_INITIALIZED, payment: new Payment()};

        default:
            console.log('billingReducer, default - '+ action.type);
            return state;
    }
};
