

import firebase, { firestore } from '../firebase';
import { Role, UserPermission } from './userPermission';
import { User, createUser } from '../user';

export async function fetchUserPermissionList(businessId: string){

  console.log('userPermission, firebaseActions, fetchUserPermissionList, businessId - '+ businessId);

  let userPermissionList: UserPermission[] = [];
  let userPermissionPromiseList: Promise<UserPermission>[] = [];
  let userPermissionCollectionRef =  firestore.collection('businesses').doc(businessId).collection('users');;

  let userPermissionCollection = await userPermissionCollectionRef.get();
  
  if (userPermissionCollection.docs.length > 0){
      console.log('userPermission, firebaseActions');
      
      userPermissionCollection.forEach((doc)=>{
        userPermissionPromiseList.push(getUserPermission(doc.id, doc.data().role.toLowerCase()));
      });

      userPermissionList = await Promise.all(userPermissionPromiseList);

  }else{
      console.log(`userPermission, firebaseActions, fetchUserPermissionList, userPermission list is empty for businessId - ${businessId}`);
  }

  return userPermissionList;
}

export async function getUserPermission(userId: string, role: Role){
  console.log('business, firebaseActions, getUserPermission, userId - '+ userId);

  let userPermission = new UserPermission({});
  let userRef =  firestore.collection('users').doc(userId);

  let userDoc = await userRef.get();
  if (userDoc.exists){
    let firstName = userDoc.data()?.firstName;
    let emailAddress = userDoc.data()?.email;
    userPermission = new UserPermission({userId, firstName, emailAddress, role });
  }

  return userPermission;
}

export async function createUserPermission(businessId: string, userPermission: UserPermission){

  console.log('firebaseActions, createUserPermission, businessId - '+ businessId);

  let userId = null;

  // check whether email id already exist, if so retrieve the user id and perform update. 

  let emailIdsCollectionRef = firestore.collection('emailIds');
  let emailIdRef = emailIdsCollectionRef.doc(userPermission.emailAddress);

  let emailIdDoc = await emailIdRef.get();

  if (emailIdDoc.exists){
    
    userId = emailIdDoc.data()?.userId;
    userPermission.userId = userId;
    console.log(`firebaseActions, createUserPermission, emailId exists, businessId - ${businessId}, userId - ${userPermission.userId}`);

  }else{
      //user does not exist, so go-ahead and create a new user. 
      let user = new User({firstName: '', lastName: '', email: userPermission.emailAddress, phoneNo: '', receiptId: '', location: '', status: ''});
      user = await createUser(user);
      userPermission.userId = user.userId;
      console.log(`firebaseActions, createUserPermission, new user, businessId - ${businessId}, userId - ${userPermission.userId}`);
  }
  
  return await updateUserPermission(businessId, userPermission)

}

export async function updateUserPermission(businessId: string, userPermission: UserPermission){

  console.log(`firebaseActions, updateUserPermission, businessId - ${businessId}, userId - ${userPermission.userId}`);

  let userPermissionCollectionRef =  firestore.collection('businesses').doc(businessId).collection('users');
  let userPermissionRef = userPermissionCollectionRef.doc(userPermission.userId);
  let data = userPermission.serialize();
  console.log(JSON.stringify(data));
  await userPermissionRef.set(data); // this will also create an entry in users/{userId}/businesses/{businessId} through userPermissions cloud function
  return userPermission;
}

export async function deleteUserPermission(businessId: string, userPermission: UserPermission){

  console.log('firebaseActions, deleteUserPermission, businessId - '+ businessId);

  let userPermissionCollectionRef =  firestore.collection('businesses').doc(businessId).collection('users');
  let userPermissionRef = userPermissionCollectionRef.doc(userPermission.userId);

  await userPermissionRef.delete();
  return userPermission;
}
